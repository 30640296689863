import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { UnitOfMeasure } from 'services/api/data-contracts';
import { stringSorter } from '../utils/tableSorters';
import { DecoratedText } from 'pages/components/DecoratedText';

export const getUnitOfMeasureColumns = (): ColumnsType<UnitOfMeasure> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (value, record) => <DecoratedText isActive={record.isActive}>{value}</DecoratedText>,
    sorter: (a, b) => stringSorter(a.name, b.name)
  },
  {
    title: 'Symbol',
    dataIndex: 'shortName',
    key: 'shortName',
    render: (value, record) => <DecoratedText isActive={record.isActive}>{value}</DecoratedText>,
    sorter: (a, b) => stringSorter(a.shortName, b.shortName)
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    render: (value, record) => <DecoratedText isActive={record.isActive}>{value}</DecoratedText>,
    ellipsis: true
  }
];
