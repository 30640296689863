import { ConfigProvider, Empty, Table } from 'antd';
import React, { useMemo } from 'react';
import { InvoiceHydrated, Payment, SettlementInvoice } from 'services/api/data-contracts';
import { getColumns } from './columns';
import { CONTROL_SIZE } from 'consts/common';
import { InvoiceItemsExpandableTable } from '../InvoiceItemsExpandableTable/InvoiceItemsExpandableTable';
import styled from 'styled-components';
import { useInvoiceStore } from 'store/invoiceStore';
import { usePaymentStore } from 'store/paymentStore';
import { useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { useDrawerStore } from 'store/drawerStore';
import { useNavigate } from 'react-router-dom';

const StyledTable = styled(
  Table<
    | SettlementInvoice
    | (Payment & {
        totalByOrderService: number;
      })
  >
)`
  #actions {
    display: none;
    position: absolute;
    right: 0;
  }

  #actions > button {
    margin-right: 10px;
  }

  .ant-table-cell-row-hover #actions {
    display: block;
  }

  .unposted {
    color: rgba(0, 0, 0, 0.45);
  }
`;

interface IProps {
  invoices: SettlementInvoice[];
  isFullScreenMode: boolean;
  orderNumber: string;
  serviceId: number;
}

export const InvoicesExpandableTable = ({
  invoices,
  isFullScreenMode,
  orderNumber,
  serviceId
}: IProps) => {
  const { setInvoice } = useInvoiceStore(({ setCurrent }) => ({
    setInvoice: setCurrent
  }));
  const { setPayment } = usePaymentStore(({ setCurrent }) => ({ setPayment: setCurrent }));
  const { clearOrderRowsState } = useSettlementDashboardStore(({ clearOrderRowsState }) => ({
    clearOrderRowsState
  }));
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const navigate = useNavigate();

  const handleOpenItem = (id: number, type: 'invoice' | 'payment') => {
    if (type === 'invoice') {
      setInvoice({ id } as unknown as InvoiceHydrated);
    } else {
      setPayment({ id });
    }

    setDrawerOpen(type);
    navigate(`/dashboard/settlement/${type}/${id}${window.location.search}`);

    // Reset selected services
    clearOrderRowsState();
  };

  const dataSource = invoices.flatMap((invoice) => {
    const creditNotes =
      invoice.creditNotes.map(
        ({ creditNote, totalByOrderService }) =>
          ({
            ...creditNote,
            totalByOrderService,
            key: creditNote.id
          }) as Payment & {
            totalByOrderService: number;
          }
      ) || [];

    return [{ ...invoice, key: invoice?.id }, ...creditNotes];
  });
  const columns = useMemo(() => getColumns(handleOpenItem), []);

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty description="Invoices not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}>
      <StyledTable
        dataSource={dataSource}
        columns={columns}
        size={CONTROL_SIZE}
        pagination={false}
        rowClassName={(row) =>
          ('isPosted' in row ? row.isPosted : (row as Payment).posted) ? '' : 'unposted'
        }
        expandable={{
          expandedRowRender: (record) => {
            const invoice = record as SettlementInvoice;

            const invoiceItems = (invoice.invoiceItems || []).map((item) => ({
              ...item,
              isPosted: invoice.isPosted,
              type: invoice.type,
              invoiceNumber: invoice.invoiceNumber,
              currency: invoice.currency,
              contractId: invoice.contractId
            }));

            return (
              <InvoiceItemsExpandableTable
                invoiceItems={invoiceItems}
                isFullScreenMode={isFullScreenMode}
                orderNumber={orderNumber}
                serviceId={serviceId}
              />
            );
          },
          rowExpandable: (record) => 'invoiceItems' in record && record.invoiceItems.length > 0,
          columnWidth: 32
        }}
      />
    </ConfigProvider>
  );
};
