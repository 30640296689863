import React from 'react';
import { ConfigProvider, Empty, Select } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useGetServiceNames } from 'queries/serviceName';
import { useServiceNameStore } from 'store/serviceNameStore';
import { sortBy } from 'lodash';

interface IProps {
  onChange: (values: number[]) => void;
}

export const ServiceNameMultipleInput = ({ onChange }: IProps) => {
  const { list } = useServiceNameStore(({ list }) => ({ list }));

  const { isLoading } = useGetServiceNames();

  const options = sortBy(list, 'name').map(({ name, id, isActive }) => ({
    label: name,
    value: id,
    disabled: !isActive
  }));

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
      <Select
        allowClear
        style={{ width: '100%' }}
        mode="multiple"
        maxTagCount="responsive"
        placeholder="All service names"
        options={options}
        size={CONTROL_SIZE}
        showSearch
        optionFilterProp="label"
        loading={isLoading}
        popupMatchSelectWidth={false}
        onChange={(values) => {
          onChange(values);
        }}
      />
    </ConfigProvider>
  );
};
