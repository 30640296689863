import React, { createContext, useEffect, useMemo, useState } from 'react';
import { DictionaryName, DictionaryTypesMap } from '../models';
import { useForm } from 'antd/es/form/Form';
import { isEmpty } from 'lodash';
import { FormInstance } from 'antd/es/form';
import { Button, Empty, Flex, Result, Spin, Tooltip } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { hasCurrentUserRole, UserRole } from 'services/auth/hasUserRole';
import { QueryObserverLoadingResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { formComponent, viewComponent } from './components';
import { getTitle } from '../utils/getTitle';
import { settingsStore } from 'services/settings/SettingsStore';
import { Filter } from '../Dictionaries';

interface IProps {
  dictionary?: DictionaryName;
  data?: DictionaryTypesMap[DictionaryName];
  setData?: (value?: DictionaryTypesMap[DictionaryName]) => void;
  onDelete: (id: number | string) => void;
  onSubmit: (record: DictionaryTypesMap[DictionaryName]) => void;
  onClose: () => void;
  isCreationMode: boolean;
  filter?: Filter;
  setCreationMode: (value: boolean) => void;
  getItem: (
    enabled: boolean,
    id: string
  ) => QueryObserverLoadingResult<AxiosResponse<DictionaryTypesMap[DictionaryName], unknown>>;
}

type IButton = {
  onClick: () => void;
  title: string;
  type?: 'primary' | 'dashed';
  danger?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
  tooltip?: string;
};

const SpinWrapper = styled('div')`
  height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormContext = createContext<FormInstance<DictionaryTypesMap[DictionaryName]> | null>(
  null
);

export const DataContext = createContext<DictionaryTypesMap[DictionaryName] | undefined>(undefined);

export const DictionaryDetails = ({
  data,
  onClose,
  onDelete,
  onSubmit,
  dictionary,
  isCreationMode,
  filter,
  getItem,
  setData,
  setCreationMode
}: IProps) => {
  const [isEditMode, setEditMode] = useState(false);
  const [form] = useForm<DictionaryTypesMap[DictionaryName]>();

  const { dictionaryName, entityId } = useParams();
  const itemQuery = getItem(
    dictionaryName === dictionary &&
      !!entityId &&
      (!isNaN(+entityId) || dictionary === 'currency-rate'),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    entityId!
  );

  const currentTenant = settingsStore.getCurrentTenant();

  useEffect(() => {
    setEditMode(false);
  }, [data]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    } else {
      form.setFieldsValue({});
      form.resetFields();
    }
  }, [data]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        const formData = { ...data, ...form.getFieldsValue() };

        if (!isEmpty(formData)) {
          onSubmit(formData);
        }

        isCreationMode ? handleClose() : handleCancel();
      })
      .catch((err) => err);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleClose = () => {
    onClose();
    handleCancel();
    form.resetFields();
  };

  const isRemovalAvailable = useMemo(() => {
    switch (dictionary) {
      case 'airport':
      case 'country':
      case 'city':
      case 'flight-purpose':
      case 'flight-type':
      case 'unit-of-measure':
        return hasCurrentUserRole([UserRole.TenantManager]);
      case 'aircraft':
      case 'counterparty':
      case 'service':
        return hasCurrentUserRole([]);
      case 'contract':
      case 'currency-rate':
      case 'own-price':
      case 'rebate':
      case 'service-name':
      case 'vat-rate':
        return hasCurrentUserRole([UserRole.Settlement]);
      default:
        return false;
    }
  }, [dictionary]);

  const isUpdateAvailable = useMemo(() => {
    switch (dictionary) {
      case 'airport':
      case 'country':
      case 'city':
      case 'flight-purpose':
      case 'flight-type':
      case 'unit-of-measure':
        return hasCurrentUserRole([UserRole.TenantManager]);
      case 'aircraft':
        return hasCurrentUserRole([]);
      case 'currency-rate':
      case 'own-price':
      case 'rebate':
      case 'service-name':
      case 'vat-rate':
        return hasCurrentUserRole([UserRole.Settlement]);
      case 'counterparty':
      case 'contract':
      case 'service':
        return hasCurrentUserRole([UserRole.Dispatcher, UserRole.Settlement]);
      default:
        return false;
    }
  }, [dictionary, currentTenant]);

  const isCloneAvailable = useMemo(() => {
    switch (dictionary) {
      case 'rebate':
        return hasCurrentUserRole([UserRole.Settlement]);
      default:
        return false;
    }
  }, [dictionary, currentTenant]);

  const actionButtons = useMemo(() => {
    const buttons: IButton[] = [];

    if (isCreationMode || isEditMode) {
      buttons.push(
        { title: 'Cancel changes', onClick: isCreationMode ? handleClose : handleCancel },
        { title: 'Save', onClick: handleSubmit, type: 'primary' }
      );
    } else if (data) {
      isCloneAvailable &&
        buttons.push({
          title: 'Copy',
          onClick: () => {
            if (setData) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { id, ...rest } = data;
              setData(rest as unknown as DictionaryTypesMap[DictionaryName]);
            }
            setCreationMode(true);
          },
          icon: <CopyOutlined />,
          type: 'dashed',
          tooltip: 'Make a copy and edit it'
        });

      isUpdateAvailable &&
        buttons.push({
          title: 'Edit',
          onClick: () => setEditMode(true),
          icon: <EditOutlined />,
          type: 'dashed'
        });

      isRemovalAvailable &&
        buttons.push({
          title: 'Delete',
          onClick: () => {
            onDelete(data.id);
            handleClose();
          },
          icon: <DeleteOutlined />,
          type: 'dashed',
          danger: true,
          disabled: dictionary === 'currency-rate' && filter?.name !== currentTenant.mainCurrency
        });
    }

    return (
      <Flex justify="flex-end" align="center" gap={8} style={{ marginBottom: 16 }}>
        {buttons.map((btn) => (
          <Tooltip mouseEnterDelay={1} title={btn.tooltip || btn.title} key={btn.title}>
            <Button
              size={CONTROL_SIZE}
              onClick={btn.onClick}
              type={btn.type}
              icon={btn.icon}
              danger={btn.danger}
              disabled={btn.disabled}>
              {btn.title}
            </Button>
          </Tooltip>
        ))}
      </Flex>
    );
  }, [isCreationMode, isEditMode, data]);

  if (dictionary && !!entityId && isNaN(+entityId) && dictionary !== 'currency-rate') {
    return (
      <Result
        status="404"
        title={`Unable to get ${getTitle(dictionary).toLowerCase()} with id "${entityId}"`}
        subTitle="Please check your URL or select the item in a table"
      />
    );
  }

  if (itemQuery?.isFetching) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    );
  }

  if ((!data || !dictionary) && !isCreationMode) {
    return (
      <Empty
        description="Please select the dictionary item"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        style={{ marginTop: 'calc(100vh / 5)' }}
      />
    );
  }

  return (
    <DataContext.Provider value={data}>
      <FormContext.Provider value={form}>
        {actionButtons}
        {isEditMode || isCreationMode
          ? formComponent[dictionary as DictionaryName]
          : viewComponent[dictionary as DictionaryName]}
      </FormContext.Provider>
    </DataContext.Provider>
  );
};
