import React, { useContext } from 'react';
import { ContractPaymentType, PaymentStatus } from 'services/api/data-contracts';
import { Alert, Button, Checkbox, Flex, Form, Input, InputNumber, Select } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { PaperClipOutlined } from '@ant-design/icons';
import { getSelectOptions } from 'helpers';
import { CounterpartyInput } from 'components/CounterpartyInput/CounterpartyInput';
import { StyledDatePicker } from 'components/common/StyledDatePicker';
import { PaymentCreationMode, usePaymentStore } from 'store/paymentStore';
import { PaymentWithDayjs } from 'consts/payment';
import { useWatch } from 'antd/es/form/Form';
import { FormContext } from '../../PaymentDrawer';
import { FormInstance } from 'antd/es/form';
import { useSelectAutoFocus } from 'helpers';
import { ContractInput } from 'components/ContractInput/ContractInput';
import { ContractCurrencyInput } from 'components/CurrencyInput/ContractCurrencyInput';

export const PaymentForm = () => {
  const [warning, setWarning] = React.useState(false);
  const { current } = usePaymentStore(({ current }) => ({ current }));

  const { form, isTouched, setTouched } = useContext(FormContext);

  const type = useWatch('type', form as FormInstance);
  const payerId = useWatch('payerId', form as FormInstance);
  const supplierId = useWatch('supplierId', form as FormInstance);
  const contractId = useWatch('contractId', form as FormInstance);

  const typeRef = useSelectAutoFocus();

  const isIncoming = type === ContractPaymentType.Incoming;

  const { paymentCreationMode } = usePaymentStore(({ paymentCreationMode }) => ({
    paymentCreationMode
  }));

  if (!form) return null;

  return (
    <Form
      style={{ justifyContent: 'center' }}
      layout="vertical"
      requiredMark={false}
      form={form}
      autoComplete="off"
      colon={false}
      data-testid="form"
      className="styled-form"
      onValuesChange={(updated: PaymentWithDayjs) => {
        const didChange = form.isFieldsTouched() !== isTouched;
        if (didChange) {
          setTouched && setTouched(form.isFieldsTouched());
        }

        if (updated.amount) {
          form.setFieldValue('advance', updated.amount);
        }

        if (updated.type || updated.payerId || updated.supplierId) {
          form?.setFieldValue('contractId', undefined);
        }
      }}>
      <Button
        size={CONTROL_SIZE}
        style={{ marginBottom: '16px' }}
        type="dashed"
        icon={<PaperClipOutlined style={{ transform: 'rotate(135deg)' }} />}
        disabled>
        Upload a document
      </Button>

      <Flex gap={12} align="flex-start" justify="start">
        <Form.Item
          name="type"
          rules={[{ required: true, message: 'Type is required' }]}
          data-testid="type">
          <Select
            options={getSelectOptions(Object.values(ContractPaymentType))}
            size={CONTROL_SIZE}
            style={{ width: '100px' }}
            placeholder="Type"
            ref={typeRef}
          />
        </Form.Item>

        <Form.Item name="status" rules={[{ required: true }]} data-testid="status">
          <Select
            options={getSelectOptions(Object.values(PaymentStatus))}
            size={CONTROL_SIZE}
            style={{ width: '100px' }}
            placeholder="Status"
          />
        </Form.Item>

        <Form.Item
          name="posted"
          valuePropName="checked"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  [PaymentStatus.TRAN, PaymentStatus.PLAN].includes(getFieldValue('status')) &&
                  !!value
                ) {
                  return Promise.reject(
                    new Error('Planned or transferred payment cannot be posted')
                  );
                }

                return Promise.resolve();
              }
            })
          ]}>
          <Checkbox onChange={({ target: { checked } }) => current?.id && setWarning(!checked)}>
            Posted
          </Checkbox>
        </Form.Item>
      </Flex>

      {warning && (
        <Alert
          type="warning"
          showIcon
          message={
            <span>
              All payment purposes linked to this Payment will be deleted.
              <br />
              Related Amount and Amount Due of Invoice, Advance of Payment will be recalculated.
            </span>
          }
          style={{ marginBottom: 8 }}
        />
      )}

      {!!type && (
        <>
          <Flex gap={12} align="flex-start">
            <Flex gap={12} style={{ flexBasis: '50%', width: '100%' }}>
              <div style={{ width: '50%' }}>
                <CounterpartyInput
                  label={isIncoming ? 'Payer' : 'Supplier'}
                  name={isIncoming ? 'payerId' : 'supplierId'}
                  required
                  disabled={paymentCreationMode === PaymentCreationMode.FROM_INVOICE}
                  onChange={(counterparty) =>
                    form.setFieldValue(isIncoming ? 'payerId' : 'supplierId', counterparty?.id)
                  }
                />
              </div>

              <div style={{ width: '50%' }}>
                <ContractInput
                  label="Contract"
                  name="contractId"
                  onChange={(contractId) => form.setFieldValue('contractId', contractId)}
                  counterpartyId={isIncoming ? payerId : supplierId}
                  counterpartyType={isIncoming ? 'customer' : 'supplier'}
                />
              </div>
            </Flex>

            <Form.Item
              name={isIncoming ? 'payerBankAccount' : 'supplierBankAccount'}
              label={`${isIncoming ? 'Payer' : 'Supplier'}’s Bank Account`}
              style={{ flexBasis: '50%' }}>
              <Input size={CONTROL_SIZE} placeholder="0000000000000000000000" />
            </Form.Item>
          </Flex>

          <Flex gap={12} align="flex-start">
            <Flex gap={12} style={{ flexBasis: '50%' }}>
              <Form.Item
                name="plannedDate"
                label="Planned date"
                style={{ flexBasis: '50%' }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        [PaymentStatus.TRAN, PaymentStatus.PLAN].includes(
                          getFieldValue('status')
                        ) &&
                        !value
                      ) {
                        return Promise.reject(
                          new Error('Planned date is required for planned or transferred payment')
                        );
                      }

                      return Promise.resolve();
                    }
                  })
                ]}>
                <StyledDatePicker
                  id="plannedDate"
                  placeholder="Select planned date"
                  format={DATE_FORMAT}
                  onSetDate={(value) => {
                    form && form.setFieldValue('plannedDate', value);
                  }}
                />
              </Form.Item>

              <Form.Item
                name="sentDate"
                label="Sent date"
                style={{ flexBasis: '50%' }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        getFieldValue('status') === PaymentStatus.DONE &&
                        getFieldValue('type') === ContractPaymentType.Outgoing &&
                        !value
                      ) {
                        return Promise.reject(
                          new Error('Sent date is required for completed outgoing payment')
                        );
                      }

                      if (
                        getFieldValue('status') === PaymentStatus.TRAN &&
                        getFieldValue('type') === ContractPaymentType.Incoming &&
                        !value
                      ) {
                        return Promise.reject(
                          new Error('Sent date is required for transferred incoming payment')
                        );
                      }

                      return Promise.resolve();
                    }
                  })
                ]}>
                <StyledDatePicker
                  id="sentDate"
                  placeholder="Select sent date"
                  format={DATE_FORMAT}
                  onSetDate={(value) => {
                    form && form.setFieldValue('sentDate', value);
                  }}
                />
              </Form.Item>
            </Flex>

            <Flex gap={12} style={{ flexBasis: '50%' }}>
              <Form.Item
                name="receivedDate"
                label="Received date"
                style={{ flexBasis: '50%' }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue('status') === PaymentStatus.DONE && !value) {
                        return Promise.reject(
                          new Error('Received date is required for completed payment')
                        );
                      }

                      return Promise.resolve();
                    }
                  })
                ]}>
                <StyledDatePicker
                  id="receivedDate"
                  format={DATE_FORMAT}
                  placeholder="Select received date"
                  onSetDate={(value) => {
                    form && form.setFieldValue('receivedDate', value);
                  }}
                />
              </Form.Item>
            </Flex>
          </Flex>

          <Flex gap={12} align="flex-start">
            <Flex gap={12} style={{ flexBasis: '50%' }}>
              <div style={{ flexBasis: '84px' }}>
                <ContractCurrencyInput
                  contractId={contractId}
                  onSetCurrency={(value) => form.setFieldValue('currency', value)}
                />
              </div>

              <Form.Item
                name="amount"
                label="Amount"
                style={{ flexGrow: 1 }}
                rules={[{ min: 0, type: 'number' }, { required: true }]}
                normalize={(value) => +value}>
                <InputNumber
                  type="number"
                  size={CONTROL_SIZE}
                  placeholder="0.00"
                  style={{ width: '100%' }}
                  precision={2}
                />
              </Form.Item>
            </Flex>

            <Flex gap={12} style={{ flexBasis: '50%' }}>
              <Form.Item name="advance" label="Advance" style={{ flexBasis: '273px' }}>
                <InputNumber
                  type="number"
                  size={CONTROL_SIZE}
                  placeholder="0.00"
                  style={{ width: '100%' }}
                  disabled
                />
              </Form.Item>
            </Flex>
          </Flex>
        </>
      )}

      <Form.Item name="notes" label="Notes">
        <Input.TextArea
          size={CONTROL_SIZE}
          style={{ width: '100%' }}
          autoSize={{ minRows: 10, maxRows: 20 }}
        />
      </Form.Item>
    </Form>
  );
};
