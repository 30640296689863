import React, { useEffect } from 'react';
import { Flex, Grid } from 'antd';
import { FlightsWidget } from '../widgets/FlightsWidget/FlightsWidget';
import { DispatcherOrdersWidget } from '../widgets/DispatcherOrdersWidget/DispatcherOrdersWidget';
import { DispatcherFilters } from './filters/DispatcherFilters';
import { DashboardContext, DashboardType } from '../consts';
import { MessagesWidget } from '../widgets/MessagesWidget/MessagesWidget';
import { settingsStore } from 'services/settings/SettingsStore';
import { FlightDrawer } from 'pages/widgets/FlightsWidget/components/FlightDetails/FlightDrawer';
import styled from 'styled-components';
import { OrderServiceDrawer } from '../widgets/components/OrderServiceDrawer';
import { OrderServicesBulkOperationsPopover } from 'pages/widgets/components/ServicesBulkOperationsPopover/OrderServicesBulkOperationsPopover';
import { AddNewButton } from './AddNewButton';
import { useDrawerStore } from 'store/drawerStore';
import { ProgramDrawer } from '../widgets/DispatcherOrdersWidget/components/ProgramDetails/ProgramDrawer';
import { useNavigate } from 'react-router-dom';
import { useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { OrderDrawer } from '../widgets/components/OrderDrawer/OrderDrawer';
import { ProgramServicesBulkOperationsPopover } from '../widgets/components/ServicesBulkOperationsPopover/ProgramServicesBulkOperationsPopover';

const DrawerWrapper = styled(Flex)`
  flex-grow: 1;
  position: relative;

  .ant-drawer,
  .ant-drawer-mask,
  .ant-drawer-content-wrapper {
    border-radius: 8px;
  }

  .ant-drawer-content {
    border-radius: 0 8px 8px 0;

    &.drawer {
      border-radius: 0 8px 8px 0;
      border: 1px solid #d9d9d9;
    }

    &.flight-program-drawer {
      border-radius: 8px;
      border: 1px solid #d9d9d9;
    }
  }
`;

const dashboardType = {
  type: DashboardType.Dispatcher
};

const { useBreakpoint } = Grid;

export const DispatcherDashboard = () => {
  const {
    closeAllDrawers,
    isNoDrawersOpen,
    isFlightDrawerOpen,
    isOrderDrawerOpen,
    isServiceDrawerOpen,
    isProgramDrawerOpen
  } = useDrawerStore(({ closeAllDrawers, openDrawers }) => ({
    closeAllDrawers,
    isNoDrawersOpen: openDrawers.length === 0,
    isFlightDrawerOpen: openDrawers.includes('flight'),
    isOrderDrawerOpen: openDrawers.includes('order'),
    isServiceDrawerOpen: openDrawers.includes('orderService'),
    isProgramDrawerOpen: openDrawers.includes('flightProgram')
  }));
  const { setOrderEditMode, setOpenOrder } = useOrdersDashboardStore();

  const screens = useBreakpoint();

  const navigate = useNavigate();

  useEffect(() => {
    isNoDrawersOpen && navigate(`/dashboard/dispatcher${window.location.search}`);
  }, [isNoDrawersOpen]);

  useEffect(
    () => () => {
      closeAllDrawers();

      setOrderEditMode(false);
      setOpenOrder(undefined);
    },
    []
  );

  useEffect(() => {
    settingsStore.setCurrentDashboard(DashboardType.Dispatcher);
  }, []);

  return (
    <DashboardContext.Provider value={dashboardType}>
      <DispatcherFilters />

      <Flex style={{ height: 'calc(100vh - 128px)' }} gap={screens.xxl ? 20 : 12}>
        <div
          style={{
            flexBasis: 556,
            minWidth: 556,
            position: 'relative',
            flexGrow: 1.3,
            overflow: 'hidden'
          }}>
          <MessagesWidget />
        </div>

        <DrawerWrapper>
          {!isFlightDrawerOpen && (
            <OrderServicesBulkOperationsPopover placement={'left'} align={{ offset: [272, 0] }} />
          )}

          {!isFlightDrawerOpen && <ProgramServicesBulkOperationsPopover placement={'left'} />}

          <FlightsWidget />

          <div
            style={{
              flexBasis: 500,
              minWidth: 500,
              width: 0,
              flexGrow: 1,
              overflow: 'hidden',
              position: 'relative',
              borderRadius: '8px',
              marginLeft: screens.xxl ? '20px' : '12px'
            }}>
            <DispatcherOrdersWidget />
          </div>

          {isProgramDrawerOpen && <ProgramDrawer />}

          {isOrderDrawerOpen && <OrderDrawer />}

          {isFlightDrawerOpen && <FlightDrawer />}

          {isServiceDrawerOpen && <OrderServiceDrawer />}
        </DrawerWrapper>
      </Flex>

      <AddNewButton />
    </DashboardContext.Provider>
  );
};
