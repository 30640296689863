import React, { useCallback, useContext, useState } from 'react';
import { useDashboardStore } from 'store/dashboardStore';
import { Button, Flex, Skeleton, Tooltip } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import {
  FlightWidgetItem,
  LinkActionTypes,
  LinkedEntityTypes,
  LinkedFlight,
  LinkedInvoice,
  LinkedMessage,
  LinkedOrder,
  LinkedProgram,
  Order,
  Payment
} from 'services/api/data-contracts';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import { OrdersWidgetMode, useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { useFlightStore } from 'store/flightStore';
import { useOrderStore } from 'store/orderStore';
import { GoUnlink } from 'react-icons/go';
import { DashboardContext, DashboardType } from '../../../consts';
import { useMessageStore } from 'store/messageStore';
import { useUpdateMessageLinks } from 'queries/links/useUpdateMessageLinks';
import { renderLinkTags } from './renderTags';
import { useFlightProgramStore } from 'store/flightProgramStore';

const LinkButton = styled(Button)`
  padding: 0;

  span {
    color: black;
    font-weight: 400;
  }
`;

interface IProps {
  targetEntityType: LinkedEntityTypes;
  targetEntityId?: string | number;
  isLoading: boolean;
  linkedFlights?: LinkedFlight[];
  linkedOrders?: LinkedOrder[];
  linkedMessages?: LinkedMessage[];
  linkedInvoices?: LinkedInvoice[];
  linkedPrograms?: LinkedProgram[];
  linkedCreditNotes?: Payment[];
}

export const Links = ({
  targetEntityType,
  targetEntityId,
  isLoading,
  linkedFlights = [],
  linkedOrders = [],
  linkedMessages = [],
  linkedInvoices = [],
  linkedPrograms = [],
  linkedCreditNotes = []
}: IProps) => {
  const [isOpen, setOpen] = useState(false);

  const { type: dashboardType } = useContext(DashboardContext);

  const { setLinkFilters } = useDashboardStore();
  const { setOrdersFilter, setSelectedOrders, ordersList, widgetMode } = useOrdersDashboardStore();
  const { order } = useOrderStore(({ current }) => ({ order: current }));
  const { setFlightsFilter, setSelectedFlights, setFlightsPageNumber } = useFlightsDashboardStore();
  const { flight } = useFlightStore(({ current }) => ({ flight: current }));
  const { setSelectedMessages, currentMessage } = useMessageStore();
  const { setSelectedPrograms, programList, program } = useFlightProgramStore(
    ({ setSelectedPrograms, list, current }) => ({
      setSelectedPrograms,
      programList: list,
      program: current
    })
  );

  const shouldApplyOpenedOrderAsFilter =
    dashboardType === DashboardType.Dispatcher && widgetMode !== OrdersWidgetMode.Orders && !!order;

  const linkMutation = useUpdateMessageLinks();

  const filterTargets = Object.values(LinkedEntityTypes)
    /* todo: remove 1st filter when program filter checkbox in footer is enabled */
    .filter((type) => type !== LinkedEntityTypes.Program)
    .filter((type) => type !== targetEntityType);

  const handleFilter = () => {
    setLinkFilters(filterTargets.map((target) => `${targetEntityType}-${target}`));

    if (filterTargets.includes(LinkedEntityTypes.Order)) setOrdersFilter({});
    if (filterTargets.includes(LinkedEntityTypes.Flight)) {
      setFlightsFilter({});
      setFlightsPageNumber(1);
    }

    switch (targetEntityType) {
      case LinkedEntityTypes.Order:
        const newSelectedOrders = shouldApplyOpenedOrderAsFilter
          ? [order as Order]
          : ordersList.filter(({ number }) => number === order?.number);
        setSelectedOrders(newSelectedOrders);
        break;
      case LinkedEntityTypes.Flight:
        setSelectedFlights([flight as FlightWidgetItem]);
        break;
      case LinkedEntityTypes.Message:
        setSelectedMessages([
          { id: currentMessage?.id, subject: currentMessage?.subject, flags: currentMessage?.flags }
        ]);
        break;
      case LinkedEntityTypes.Program:
        setSelectedPrograms(programList.filter(({ id }) => id === program?.id));
        break;
    }
  };

  const renderUnlinkIcon = useCallback(
    (isRemovable?: boolean) =>
      targetEntityType === LinkedEntityTypes.Message || isRemovable ? (
        <Tooltip mouseEnterDelay={1} title="Unlink">
          <GoUnlink size={12} />
        </Tooltip>
      ) : null,
    [targetEntityType]
  );

  const handleUnlink = async (source: {
    entityId: string | number;
    entityType: LinkedEntityTypes;
  }) => {
    if (targetEntityId && source.entityId && source.entityType) {
      linkMutation.mutate({
        action: LinkActionTypes.Unlink,
        target: [{ entityType: targetEntityType, entityId: targetEntityId }],
        source: [source]
      });
    }
  };

  const renderTags = (
    links: (LinkedFlight | LinkedOrder | LinkedProgram | LinkedMessage | LinkedInvoice | Payment)[],
    linkedEntityType: LinkedEntityTypes
  ) =>
    renderLinkTags({
      links,
      linkedEntityType,
      onUnlink: handleUnlink,
      renderUnlinkIcon,
      targetEntityId
    });

  const links = [
    ...linkedFlights,
    ...linkedOrders,
    ...linkedPrograms,
    ...linkedMessages,
    ...linkedInvoices,
    ...linkedCreditNotes
  ];

  if (isLoading || linkMutation.isLoading) {
    return (
      <div data-testid={linkMutation.isLoading ? 'unlink-loader' : 'loader'}>
        <Skeleton.Input size={CONTROL_SIZE} style={{ width: 200 }} />
      </div>
    );
  }

  if (!links.length) {
    return null;
  }

  return (
    <Flex gap={8} vertical data-testid="links">
      <Flex align="center" gap={12}>
        <Flex gap={4} align="center">
          <LinkButton
            type="link"
            onClick={() => setOpen((prev) => !prev)}
            data-testid="expand-links">
            {links.length} {links.length === 1 ? 'link' : 'links'} <DownOutlined />
          </LinkButton>
        </Flex>
        <Button
          type="link"
          size={CONTROL_SIZE}
          onClick={handleFilter}
          disabled={dashboardType !== DashboardType.Dispatcher}>
          Apply as a filter
        </Button>
      </Flex>

      {isOpen && (
        <Flex wrap="wrap">
          {renderTags(linkedFlights, LinkedEntityTypes.Flight)}
          {renderTags(linkedOrders, LinkedEntityTypes.Order)}
          {renderTags(linkedPrograms, LinkedEntityTypes.Program)}
          {renderTags(linkedMessages, LinkedEntityTypes.Message)}
          {renderTags(linkedInvoices, LinkedEntityTypes.Invoice)}
          {renderTags(linkedCreditNotes, LinkedEntityTypes.CreditNote)}
        </Flex>
      )}
    </Flex>
  );
};
