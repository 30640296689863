/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BalanceReportName,
  BalanceViewResponse,
  Error,
  PaymentTemplatesListResponse,
  ReconciliationReportRequestBody,
  UnpaidInvoicesRequestBody,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Balance<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get balance view info
   *
   * @tags balance
   * @name GetBalance
   * @summary Get balance view info
   * @request GET:/balance
   * @secure
   */
  getBalance = (
    query: {
      currency?: string;
      /** @format date */
      startDate: string;
      /** @format date */
      endDate: string;
      counterpartyId: number;
      /** Filter by a list of contractIds separated by comma. Example - contractIds=15,72 etc. */
      contractIds?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<BalanceViewResponse, Error>({
      path: `/balance`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Generate Unpaid Invoices report
   *
   * @tags balance
   * @name GenerateUnpaidInvoicesReport
   * @summary Generate Unpaid Invoices report
   * @request POST:/balance/generate-report/unpaid-invoices
   * @secure
   */
  generateUnpaidInvoicesReport = (data: UnpaidInvoicesRequestBody, params: RequestParams = {}) =>
    this.http.request<File, ValidationError | Error>({
      path: `/balance/generate-report/unpaid-invoices`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Generate Reconciliation report
   *
   * @tags balance
   * @name GenerateReconciliationReport
   * @summary Generate Reconciliation report
   * @request POST:/balance/generate-report/reconciliation
   * @secure
   */
  generateReconciliationReport = (
    data: ReconciliationReportRequestBody,
    params: RequestParams = {}
  ) =>
    this.http.request<File, ValidationError | Error>({
      path: `/balance/generate-report/reconciliation`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Get a list of balance templates
   *
   * @tags balance
   * @name GetBalanceTemplates
   * @summary Get a list of balance templates
   * @request GET:/balance/templates
   * @secure
   */
  getBalanceTemplates = (
    query: {
      /** report name of balance reports */
      reportName: BalanceReportName;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PaymentTemplatesListResponse, Error>({
      path: `/balance/templates`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
}
