import React, { ReactNode, useContext } from 'react';
import { Button, Checkbox, Flex, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  CloseSquareOutlined,
  MailOutlined,
  ProfileOutlined,
  SendOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import { useDashboardStore } from 'store/dashboardStore';
import { useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { OrdersWidgetMode, useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { orderTypes } from 'consts/order';
import { DashboardContext, DashboardType } from '../../consts';
import { CONTROL_SIZE, DEFAULT_LIMIT } from 'consts/common';
import { blue } from '@ant-design/colors';
import { LinkedEntityTypes } from 'services/api/data-contracts';
import { BsCalendarWeek } from 'react-icons/bs';

const StyledFlex = styled(Flex)`
  span:has(span.label) {
    padding-inline-start: 6px;
    padding-inline-end: 0;
  }
`;

const ContainerWithBorderLeft = styled.div`
  display: inline;
  border-left: 1px solid #0000000f;
  padding-left: 12px;
`;

const iconsMap: Record<LinkedEntityTypes, ReactNode> = {
  [LinkedEntityTypes.Message]: <MailOutlined />,
  [LinkedEntityTypes.Flight]: <SendOutlined />,
  [LinkedEntityTypes.Order]: <ProfileOutlined />,
  [LinkedEntityTypes.Invoice]: null,
  [LinkedEntityTypes.Program]: <BsCalendarWeek />,
  [LinkedEntityTypes.CreditNote]: null
};

interface IProps {
  totalItemsCount: number;
  selectedItemsCount: number;
  showClearSelection?: boolean;
  onSelectAll: (e: CheckboxChangeEvent) => void;
  widgetType: LinkedEntityTypes;
  showSelected?: boolean;
  children?: React.ReactNode;
}

export const Footer = ({
  totalItemsCount,
  selectedItemsCount,
  onSelectAll,
  widgetType,
  showSelected,
  children,
  showClearSelection
}: IProps) => {
  const { linkFilters, setLinkFilters } = useDashboardStore(({ linkFilters, setLinkFilters }) => ({
    linkFilters,
    setLinkFilters
  }));
  const { setFlightsFilter, setFlightsPageNumber } = useFlightsDashboardStore(
    ({ setFlightsFilter, setFlightsPageNumber, selectedFlights }) => ({
      setFlightsFilter,
      setFlightsPageNumber,
      selectedFlights
    })
  );
  const { setOrdersFilter, ordersWidgetMode } = useOrdersDashboardStore(
    ({ setOrdersFilter, selectedOrders, widgetMode }) => ({
      setOrdersFilter,
      selectedOrders,
      ordersWidgetMode: widgetMode
    })
  );

  const { type: dashboardType } = useContext(DashboardContext);

  const getFilterTargets = (): LinkedEntityTypes[] | undefined => {
    switch (widgetType) {
      case LinkedEntityTypes.Flight:
        return [
          LinkedEntityTypes.Message,
          ...[
            ordersWidgetMode === OrdersWidgetMode.Programs
              ? LinkedEntityTypes.Program
              : LinkedEntityTypes.Order
          ]
        ];
      case LinkedEntityTypes.Message:
        const isDispatcherDashboard = dashboardType === DashboardType.Dispatcher;
        const isSettlementDashboard = dashboardType === DashboardType.Settlement;

        return [
          ...(isDispatcherDashboard ? [LinkedEntityTypes.Flight] : []),
          ...(isDispatcherDashboard
            ? [
                ordersWidgetMode === OrdersWidgetMode.Programs
                  ? LinkedEntityTypes.Program
                  : LinkedEntityTypes.Order
              ]
            : []),
          ...(isSettlementDashboard ? [LinkedEntityTypes.Order] : [])
        ];
      case LinkedEntityTypes.Order:
      case LinkedEntityTypes.Program:
        return [
          LinkedEntityTypes.Message,
          ...(dashboardType === DashboardType.Dispatcher ? [LinkedEntityTypes.Flight] : [])
        ];
    }
  };

  const renderSelectAllCheckbox = () => (
    <Checkbox
      style={{ marginRight: '8px' }}
      onChange={onSelectAll}
      indeterminate={!!selectedItemsCount && selectedItemsCount !== totalItemsCount}
      checked={selectedItemsCount === totalItemsCount}
      id={`select-all-${widgetType}`}
      data-testid={`select-all-${widgetType}`}
    />
  );

  const renderClearSelectionButton = () => (
    <Tooltip title="Reset selected">
      <Button
        size={CONTROL_SIZE}
        type="text"
        icon={<CloseSquareOutlined style={{ color: blue[5] }} />}
        onClick={() => onSelectAll({ target: { checked: false } } as CheckboxChangeEvent)}
        style={{ marginRight: '4px' }}
        id={`clear-all-${widgetType}`}
        data-testid={`clear-all-${widgetType}`}
      />
    </Tooltip>
  );

  const renderFilterCheckbox = (filterTarget: LinkedEntityTypes) => {
    const checkboxId = `${widgetType}-${filterTarget}`;

    const onChange = ({ target }: CheckboxChangeEvent) => {
      const filterType = target.id || '';
      const filterValue = target.checked;

      setLinkFilters(
        filterValue ? [...linkFilters, filterType] : linkFilters.filter((i) => i !== filterType)
      );

      if (filterTarget === LinkedEntityTypes.Flight && filterValue) {
        setFlightsFilter({});
        setFlightsPageNumber(1);
      } else if (filterTarget === LinkedEntityTypes.Order && filterValue) {
        setOrdersFilter({ orderTypes: orderTypes });
      }
    };

    return (
      <Checkbox
        key={checkboxId}
        id={checkboxId}
        data-testid={checkboxId}
        checked={linkFilters.includes(checkboxId)}
        disabled={
          dashboardType === DashboardType.Settlement ||
          (ordersWidgetMode === OrdersWidgetMode.Programs &&
            filterTarget === LinkedEntityTypes.Program) ||
          (ordersWidgetMode === OrdersWidgetMode.Services &&
            (filterTarget === LinkedEntityTypes.Program ||
              filterTarget === LinkedEntityTypes.Order))
        }
        onChange={onChange}>
        <span className="label">{iconsMap[filterTarget]}</span>
      </Checkbox>
    );
  };

  const showCounter = showSelected && !!totalItemsCount;

  return (
    <Flex align="center" justify="space-between">
      <div style={{ lineHeight: '24px' }}>
        {showCounter && (
          <div style={{ minWidth: 110, display: 'inline-block' }}>
            {totalItemsCount <= 15
              ? renderSelectAllCheckbox()
              : showClearSelection && renderClearSelectionButton()}
            {selectedItemsCount} out of {totalItemsCount}
            {widgetType === LinkedEntityTypes.Order && totalItemsCount === DEFAULT_LIMIT && '+'}
          </div>
        )}
        {children && (
          <ContainerWithBorderLeft style={{ marginLeft: showCounter ? 12 : 118 }}>
            {children}
          </ContainerWithBorderLeft>
        )}
      </div>

      <StyledFlex gap={12}>
        {!!totalItemsCount && showSelected ? (
          <>
            {widgetType !== LinkedEntityTypes.Flight && (
              <span style={{ color: '#00000073' }}>Filter:</span>
            )}
            {getFilterTargets()?.map(renderFilterCheckbox)}
          </>
        ) : (
          <br />
        )}
      </StyledFlex>
    </Flex>
  );
};
