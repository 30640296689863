import {
  OrderStatus,
  PermitType,
  ValidityUnits,
  OrderType as Type
} from 'services/api/data-contracts';
import { RangeValue } from 'store/helpers/types';
import { OrdersFilter } from 'store/ordersDashboardStore';
import { getUTCDate } from 'helpers';

export enum OrderType {
  GH = 'GROUND HANDLING',
  FUEL = 'FUEL',
  PMT = 'PERMIT',
  ADD = 'ADDITIONAL SERVICES'
  // 'NAVIGATION'
}

export const FUEL_SERVICE_NAME = 'FUEL';

export const orderTypeFullName: Record<Type, string> = {
  [Type.ADD]: 'Additional Services',
  [Type.FUEL]: 'Fuel',
  [Type.GH]: 'Ground Handling',
  [Type.PMT]: 'Permit',
  [Type.NAV]: 'Navigation'
};

export const orderTypes = [Type.GH, Type.FUEL, Type.PMT, Type.ADD];

export const orderStatuses = Object.values(OrderStatus);

export const permitTypes = Object.values(PermitType);

export const validityUnits = Object.values(ValidityUnits);

export const defaultOrdersFilterForDispatcher: OrdersFilter = {
  orderEndDate: [getUTCDate(), getUTCDate().add(5, 'year')] as RangeValue,
  orderTypes
};

export const defaultOrdersFilterForSettlement: OrdersFilter = {
  orderTypes
};

export enum UOM_TAGS {
  USG = 'USG',
  KG = 'KG',
  MT = 'MT',
  LTR = 'LTR'
}

export const USG_PRECISION = 2;
export const KG_PRECISION = 4;
export const MT_PRECISION = 3;
export const LTR_PRECISION = 0;
