import React, { useContext, useState } from 'react';
import { Button, Dropdown, message } from 'antd';
import { CONTROL_SIZE, MESSAGE_DURATION } from 'consts/common';
import {
  CreditNoteInput,
  CreditNoteStatus,
  ReportEntityNames,
  ReportFormat,
  TransactionType
} from 'services/api/data-contracts';
import type { MenuProps } from 'antd';
import { DashboardContext, DashboardType, DEFAULT_DOCUMENT_TEMPLATE_NAME } from 'pages/consts';
import { paymentsApi } from 'services/api';
import { CreditNoteStatusLabel } from 'pages/SettlementDashboard/helpers/constants';
import { useUpdatePayment } from 'queries/payment';
import { usePaymentStore } from 'store/paymentStore';
import { useBalanceReportStore } from 'store/balanceReportStore';

interface IProps {
  setReadyForSendingInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  transactionType?: TransactionType;
}

export const ChangeStatusButton = ({ setReadyForSendingInProgress, transactionType }: IProps) => {
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);

  const { current } = usePaymentStore(({ current }) => ({
    current
  }));
  const { setShouldUpdateBalanceView } = useBalanceReportStore(
    ({ setShouldUpdateBalanceView }) => ({
      setShouldUpdateBalanceView
    })
  );

  const { type: dashboardType } = useContext(DashboardContext);

  const updateMutation = useUpdatePayment();

  if (!current) {
    return null;
  }

  const handleViewUpdate = () => {
    dashboardType === DashboardType.Balance && setShouldUpdateBalanceView(true);
  };

  const handleReadyForSendingClick = async () => {
    setIsUpdateInProgress(true);
    setReadyForSendingInProgress(true);

    try {
      const templatesQueryResult = await paymentsApi.getPaymentTemplates({
        templateType:
          transactionType === TransactionType.CreditNote
            ? ReportEntityNames.CreditNotes
            : transactionType === TransactionType.Rebate
              ? ReportEntityNames.RebateCreditNotes
              : ReportEntityNames.Payments
      });

      const defaultTemplate = (templatesQueryResult.data?.items || []).find((t) =>
        t?.name?.includes(DEFAULT_DOCUMENT_TEMPLATE_NAME)
      );

      if (!defaultTemplate) {
        message.error('Failed to get default template for file generation.');
        return;
      }

      const response = await paymentsApi.generatePaymentReport(
        current?.id as number,
        { templateId: defaultTemplate.id, format: ReportFormat.Pdf },
        {
          format: 'arraybuffer',
          headers: {
            Accept: 'application/pdf, application/json, application/text'
          }
        }
      );

      if (response.data) {
        message.success(
          `File for credit note "${current?.creditNoteNumber}" has been successfully generated.`,
          MESSAGE_DURATION
        );

        await updateMutation.mutateAsync({
          ...(current as CreditNoteInput),
          creditNoteStatus: CreditNoteStatus.RFSN,
          id: current.id as number,
          items: current.creditNoteItems
        });
        handleViewUpdate();
      }
    } catch (error) {
      console.error(error);
      message.error('Error generating report and updating credit note status.');
    } finally {
      setIsUpdateInProgress(false);
      setReadyForSendingInProgress(false);
    }
  };

  const handleIsSentClick = async () => {
    await updateMutation.mutateAsync({
      ...(current as CreditNoteInput),
      creditNoteStatus: CreditNoteStatus.SENT,
      id: current.id as number,
      items: current.creditNoteItems
    });
    handleViewUpdate();
  };

  const getMenuItem = (label: string, key: string, onClick: () => void) => ({
    label,
    key,
    onClick
  });

  const items: MenuProps['items'] = [
    ...(current.creditNoteStatus !== CreditNoteStatus.RFSN
      ? [getMenuItem(CreditNoteStatusLabel.READY_FOR_SENDING, '3', handleReadyForSendingClick)]
      : []),
    ...(current.creditNoteStatus !== CreditNoteStatus.SENT
      ? [getMenuItem(CreditNoteStatusLabel.IS_SENT, '4', handleIsSentClick)]
      : [])
  ];

  const isLoading = isUpdateInProgress || updateMutation.isLoading;

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button size={CONTROL_SIZE} loading={isLoading}>
        Change the status
      </Button>
    </Dropdown>
  );
};
