import { ColumnsType } from 'antd/es/table';
import { Checkbox, Tag } from 'antd';
import React from 'react';
import { OrderType, Service } from 'services/api/data-contracts';
import { PresetColorType } from 'antd/es/_util/colors';
import { capitalize } from 'lodash';
import { stringSorter } from '../utils/tableSorters';
import { getOrderIcon } from 'helpers';
import { cyan, geekblue, orange, purple, red } from '@ant-design/colors';
import { DecoratedText } from 'pages/components/DecoratedText';

export const tagColors: Record<OrderType, PresetColorType> = {
  [OrderType.ADD]: 'purple',
  [OrderType.FUEL]: 'red',
  [OrderType.GH]: 'geekblue',
  [OrderType.NAV]: 'cyan',
  [OrderType.PMT]: 'orange'
};

export const tagIconColors: Record<OrderType, string[]> = {
  [OrderType.ADD]: purple,
  [OrderType.FUEL]: red,
  [OrderType.GH]: geekblue,
  [OrderType.NAV]: cyan,
  [OrderType.PMT]: orange
};

export const getServiceColumns = (): ColumnsType<Service> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (value, record) => <DecoratedText isActive={record.isActive}>{value}</DecoratedText>,
    sorter: (a, b) => stringSorter(a.name, b.name),
    ellipsis: true
  },
  {
    title: 'Order types',
    key: 'orderTypes',
    render: (value, record) => (
      <DecoratedText isActive={record.isActive}>
        {record.orderTypes.map((type: OrderType) => (
          <Tag
            color={tagColors[type]}
            key={type}
            icon={getOrderIcon(type, tagIconColors[type][6])}
            style={{ display: 'inline-flex', alignItems: 'center', gap: 6 }}>
            {type.toUpperCase()}
          </Tag>
        ))}
      </DecoratedText>
    ),
    width: 360
  },
  {
    title: 'Location',
    dataIndex: 'locationType',
    key: 'locationType',
    render: (value, record) => (
      <DecoratedText isActive={record.isActive}>{capitalize(value)}</DecoratedText>
    ),
    width: 92
  },
  {
    title: 'Default',
    dataIndex: 'isDefault',
    key: 'isDefault',
    align: 'end',
    render: (value) => <Checkbox checked={value} disabled />,
    width: 65
  }
];
