import React, { useEffect, useMemo, useState } from 'react';
import { Button, ConfigProvider, Divider, Flex, Form, Space } from 'antd';
import { InvoiceItemHydrated, InvoiceType, Rebate, RebateType } from 'services/api/data-contracts';
import { CONTROL_SIZE } from 'consts/common';
import styled from 'styled-components';
import { useUpdateInvoiceItems } from 'queries/invoiceItems';
import { InvoiceItemForm } from './InvoiceItemForm';
import { useInvoiceStore } from 'store/invoiceStore';
import { PlusOutlined } from '@ant-design/icons';
import { sortBy, uniqBy } from 'lodash';
import { useWatch } from 'antd/es/form/Form';
import { useVatRateStore } from 'store/vatRateStore';
import {
  calculateAmounts,
  handleAmountChange,
  setOwnPriceAndAgentFee,
  validatePriceAndAmount,
  handleQuantityChange,
  handleServiceChange
} from './helpers';
import { getAmountValue } from 'helpers';

const StyledForm = styled(Form<InvoiceItemsFormType>)`
  .ant-form-item {
    margin-bottom: 6px;
  }

  .ant-form-item-label {
    padding-bottom: 0 !important;
    white-space: nowrap !important;
  }

  .icon-button {
    color: rgba(0, 0, 0, 0.45);
    margin-right: 6px;
    margin-left: 6px;
  }
`;

export type OptionType = { label: string; value: string | number; description: string };

export const getAgentFeeOption = (
  fee: number,
  isFromContract?: boolean,
  rebate?: Rebate | null
): OptionType => {
  let label = `${fee}%`;
  let description = `${fee}%${isFromContract ? ' - Contract' : ''}`;

  if (rebate?.type === RebateType.AgentFeeExtra && rebate.value) {
    const originalAgentFee = fee - rebate.value;
    label = `${originalAgentFee}+${rebate.value}%`;
    description = `${originalAgentFee}% + ${rebate.value}% rebate`;
  }

  return {
    label,
    description,
    value: fee
  };
};

export type InvoiceItemsFormType = { invoiceItems: InvoiceItemHydrated[] };

interface IProps {
  data: InvoiceItemHydrated[];
  onSetEditMode: (isEditMode: boolean) => void;
}

export const InvoiceItemsForm = ({ data, onSetEditMode }: IProps) => {
  const { invoiceServices, invoiceId, contractAgentFee, isIssuedInvoice } = useInvoiceStore(
    ({ current }) => ({
      invoiceServices: current?.orderServices || [],
      invoiceId: current?.id,
      contractAgentFee: current?.contract.agentFee,
      isIssuedInvoice: current?.type === InvoiceType.Issued
    })
  );
  const { list: vatRates } = useVatRateStore(({ list }) => ({ list }));

  const uniqueAgentFees = useMemo(() => {
    const agentFees = [
      { fee: contractAgentFee, rebate: null },
      ...data.map((item) => ({ fee: item.agentFee, rebate: item.rebate }))
    ];
    const existingAgentFees = agentFees.filter((f) => !!f.fee);

    return uniqBy(existingAgentFees, 'fee');
  }, [data, contractAgentFee]);

  const [agentFeeOptions, setAgentFeeOptions] = useState<OptionType[]>([
    { label: 'Yes', description: 'Yes', value: 'isOwnPrice' },
    ...sortBy(uniqueAgentFees, 'fee').map((option) =>
      getAgentFeeOption(option.fee ?? 0, option.fee === contractAgentFee, option?.rebate)
    )
  ]);

  const [form] = Form.useForm<InvoiceItemsFormType>();
  const invoiceItems = useWatch('invoiceItems', form) || [];

  const agentFeeTotal = invoiceItems.reduce((acc, item) => acc + (item?.agentFeeAmount || 0), 0);
  const vatTotal = invoiceItems.reduce((acc, item) => acc + (item?.vatAmount || 0), 0);
  const invoiceTotal = invoiceItems.reduce(
    (acc, item) => acc + (item?.total || 0) + (item?.agentFeeAmount || 0),
    0
  );

  useEffect(() => {
    if (data.length) {
      form.setFieldsValue({
        invoiceItems: data.map((item) => ({
          ...item,
          ['ownPrice_agentFee']: item.isOwnPrice ? 'isOwnPrice' : item.agentFee
        }))
      });
    }

    return () => {
      form.setFieldsValue({ invoiceItems: [] });
      form.resetFields();
    };
  }, []);

  const updateMutation = useUpdateInvoiceItems({ invoiceId });

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      const data = form.getFieldValue('invoiceItems');

      const defaultPrice = 0;

      const invoiceItems = data.map((item: Partial<InvoiceItemHydrated>) => ({
        ...item,
        price: item.price ? +item.price : defaultPrice,
        invoiceId
      }));

      updateMutation.mutate({ id: invoiceId as number, data: invoiceItems });

      onSetEditMode(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleValuesChange = (
    changed: Partial<InvoiceItemsFormType>,
    { invoiceItems }: InvoiceItemsFormType
  ) => {
    if (!changed.invoiceItems) return;

    const changedItemsIdx = Object.keys(changed.invoiceItems);

    if (invoiceItems[invoiceItems.length - 1] === undefined) return;

    const updatedInvoiceItems = invoiceItems.map((invoiceItem, idx) => {
      if (!invoiceItem || !changedItemsIdx.includes(idx.toString())) return invoiceItem;

      const changedItem = changed.invoiceItems?.[idx] as Partial<InvoiceItemHydrated>;

      invoiceItem = setOwnPriceAndAgentFee(invoiceItem, changedItem);
      validatePriceAndAmount(invoiceItem, changedItem, idx, isIssuedInvoice, form);
      invoiceItem = calculateAmounts(invoiceItem, changedItem, vatRates, isIssuedInvoice);

      invoiceItem = handleQuantityChange(invoiceItem, changedItem, isIssuedInvoice);
      invoiceItem = handleAmountChange(invoiceItem, changedItem);
      invoiceItem = handleServiceChange(invoiceItem, changedItem, isIssuedInvoice, invoiceServices);

      return invoiceItem;
    });

    form.setFieldValue('invoiceItems', updatedInvoiceItems);
  };

  return (
    <ConfigProvider form={{ validateMessages: { required: 'Required' } }}>
      <StyledForm
        form={form}
        initialValues={{ invoiceItems: data }}
        colon={false}
        size={CONTROL_SIZE}
        requiredMark={false}
        layout="vertical"
        data-testid="invoice-items-form"
        onValuesChange={handleValuesChange}>
        <Form.List name="invoiceItems">
          {(rows, { add, remove }) => (
            <Flex vertical gap={20}>
              {rows.map((row) => (
                <InvoiceItemForm
                  formListItemKey={row.name}
                  key={row.key}
                  invoiceItems={invoiceItems}
                  remove={remove}
                  form={form}
                  agentFeeOptions={agentFeeOptions}
                  setAgentFeeOptions={setAgentFeeOptions}
                />
              ))}

              {invoiceItems.length > 0 && (
                <Flex vertical gap={8} style={{ marginRight: 30 }}>
                  {agentFeeTotal > 0 && (
                    <Flex gap={8} justify="end" style={{ fontSize: 12 }}>
                      <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Agent fee total </span>
                      {getAmountValue(agentFeeTotal)}
                    </Flex>
                  )}

                  <Divider style={{ margin: 0 }} />

                  <Flex gap={32} justify="end" style={{ fontSize: 14 }}>
                    {[
                      {
                        label: 'VAT amount',
                        value: getAmountValue(vatTotal) || '-'
                      },
                      {
                        label: 'Total',
                        value: getAmountValue(invoiceTotal) || '-'
                      }
                    ].map((prop) => (
                      <span key={prop.label}>
                        <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{prop.label}</span>{' '}
                        <b style={{ fontWeight: 500 }}>{prop.value}</b>
                      </span>
                    ))}
                  </Flex>
                </Flex>
              )}

              <Flex justify="space-between">
                <Button type="link" onClick={() => add()} icon={<PlusOutlined />}>
                  Add a new one
                </Button>

                <Space>
                  <Button size={CONTROL_SIZE} onClick={() => onSetEditMode(false)}>
                    Cancel
                  </Button>
                  <Button
                    size={CONTROL_SIZE}
                    type="primary"
                    onClick={handleSubmit}
                    loading={updateMutation.isLoading}>
                    Save
                  </Button>
                </Space>
              </Flex>
            </Flex>
          )}
        </Form.List>
      </StyledForm>
    </ConfigProvider>
  );
};
