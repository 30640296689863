import React, { useEffect, useMemo } from 'react';
import { Avatar, Flex, Layout, Tooltip } from 'antd';
import { useLocation } from 'react-router-dom';
import stc from 'string-to-color';
import styled from 'styled-components';
import { idTokenStore } from 'services/auth/IdTokenStore';
import { hasCurrentUserRole, UserRole } from 'services/auth/hasUserRole';
import { profileApi } from 'services/api';
import { settingsStore } from 'services/settings/SettingsStore';
import { VersionPopover } from './VersionPopover';
import { AppMenu } from './AppMenu';
import { Clock } from './Clock';
import { TenantSelectorAndLogout } from './TenantSelectorAndLogout';

const StyledHeader = styled(Layout.Header)`
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled('h1')`
  margin: 0 20px 0 0;
  padding: 4px 15px;
  font-size: 18px;
  font-weight: 700;
  color: white;
`;

interface IProps {
  onGoHome: () => void;
}

export const AppHeader = ({ onGoHome }: IProps) => {
  const [tenantName, setTenantName] = React.useState('');

  const location = useLocation();

  const { userName, userShortName } = idTokenStore.getUserData();
  const currentTenantId = settingsStore.getCurrentTenantId();

  const color = stc(userName);

  useEffect(() => {
    const idToken = idTokenStore.extractToken();

    if (idToken) {
      idTokenStore.setToken(idToken);
    }
  }, []);

  useEffect(() => {
    currentTenantId &&
      (async () => {
        const response = await profileApi.getProfileInfo({});

        settingsStore.setAppSettings(response?.data);
        setTenantName(
          response?.data.profile.settings.tenants.find((t) => t.id === currentTenantId)?.name || ''
        );
      })();
  }, [currentTenantId]);

  const showClock = useMemo(
    () =>
      location.pathname.startsWith('/dashboard/dispatcher') ||
      (location.pathname.startsWith('/dictionaries') && hasCurrentUserRole([UserRole.Dispatcher])),
    [location.pathname]
  );

  return (
    <StyledHeader>
      <Flex gap={8} align="center">
        <Logo>Shannon Air</Logo>
        <AppMenu />
      </Flex>

      <Flex gap={16} align="center">
        {showClock && <Clock />}

        <VersionPopover />

        {userName && userShortName && (
          <Tooltip mouseEnterDelay={1} title={userName}>
            <Avatar
              size={24}
              style={{ backgroundColor: color, display: 'flex', alignItems: 'center' }}>
              {userShortName}
            </Avatar>
          </Tooltip>
        )}

        <TenantSelectorAndLogout
          tenantName={tenantName}
          setTenantName={setTenantName}
          onGoHome={onGoHome}
        />
      </Flex>
    </StyledHeader>
  );
};
