import React, { useEffect, useState } from 'react';
import { Flex } from 'antd';
import { BalanceFilters } from './filters/BalanceFilters';
import { BalanceWidget } from '../widgets/BalanceWidget/BalanceWidget';
import { useBalanceReportStore } from 'store/balanceReportStore';
import { BalanceCalculationView } from './components/BalanceCalculationView/BalanceCalculationView';
import { useGetBalanceReport } from 'queries/balance/useGetBalanceReport';
import { useInvoiceStore } from 'store/invoiceStore';
import { InvoiceDrawer } from '../widgets/InvoicesWidget/components/InvoiceDetails/InvoiceDrawer';
import { usePaymentPurposeStore } from 'store/paymentPurposeStore';
import { DashboardContext, DashboardType } from '../consts';
import { ServicesDrawer } from '../widgets/components/ServicesDrawer/ServicesDrawer';
import { CalculatorDrawer } from '../widgets/components/CalculatorDrawer/CalculatorDrawer';
import { PaymentPurposeDrawer } from 'pages/widgets/components/PaymentPurposeDrawer/PaymentPurposeDrawer';
import { settingsStore } from 'services/settings/SettingsStore';
import { AddNewButton } from './AddNewButton';
import { PaymentDrawer } from '../widgets/components/PaymentDrawer/PaymentDrawer';
import { useDrawerStore } from 'store/drawerStore';
import { useNavigate } from 'react-router-dom';
import { CreditNoteWithoutItemsDrawer } from './components/CreditNoteWithoutItemsDrawer/CreditNoteWithoutItemsDrawer';
import { DocumentsDrawer } from 'pages/widgets/components/DocumentsDrawer/DocumentsDrawer';

const dashboardType = {
  type: DashboardType.Balance
};

export const BalanceReport = () => {
  const [isAddServiceDrawerOpen, setAddServiceDrawerOpen] = useState(false);

  const { isContractViewMode, balanceView, setBalanceView, setContractViewMode } =
    useBalanceReportStore(({ isContractViewMode, data, setData, setContractViewMode }) => ({
      isContractViewMode,
      balanceView: data,
      setBalanceView: setData,
      setContractViewMode
    }));
  const { setCurrentCreditNote } = useInvoiceStore(({ setCurrentCreditNote }) => ({
    setCurrentCreditNote
  }));
  const { setCurrent: setCurrentPaymentPurpose, setEditModeBase } = usePaymentPurposeStore(
    ({ setCurrent, setEditModeBase }) => ({
      setCurrent,
      setEditModeBase
    })
  );
  const {
    setDrawerClosed,
    closeAllDrawers,
    isNoDrawersOpen,
    isCreditNoteDrawerOpen,
    isPaymentDrawerOpen,
    isInvoiceDrawerOpen,
    isPaymentPurposeDrawerOpen,
    isCalculatorDrawerOpen,
    isDocumentsDrawerOpen
  } = useDrawerStore(({ setDrawerClosed, closeAllDrawers, openDrawers }) => ({
    setDrawerClosed,
    closeAllDrawers,
    isNoDrawersOpen: openDrawers.length === 0,
    isCreditNoteDrawerOpen: openDrawers.includes('creditNote'),
    isPaymentDrawerOpen: openDrawers.includes('payment'),
    isInvoiceDrawerOpen: openDrawers.includes('invoice'),
    isPaymentPurposeDrawerOpen: openDrawers.includes('paymentPurpose'),
    isCalculatorDrawerOpen: openDrawers.includes('calculator'),
    isDocumentsDrawerOpen: openDrawers.includes('documents')
  }));

  const { isFetching, isLoading } = useGetBalanceReport();

  const navigate = useNavigate();

  useEffect(() => {
    isNoDrawersOpen && navigate(`/report/balance${window.location.search}`);
  }, [isNoDrawersOpen]);

  useEffect(
    () => () => {
      closeAllDrawers();
    },
    []
  );

  useEffect(() => {
    settingsStore.setCurrentDashboard(DashboardType.Balance);
  }, []);

  useEffect(
    () => () => {
      setBalanceView(undefined);
      setContractViewMode(false);
    },
    [setBalanceView, setContractViewMode]
  );

  return (
    <DashboardContext.Provider value={dashboardType}>
      <Flex vertical gap={20}>
        <BalanceFilters />

        {balanceView?.contracts && balanceView?.contracts?.length > 1 && (
          <BalanceCalculationView balance={balanceView?.balance} />
        )}

        <Flex style={{ flexWrap: 'nowrap' }}>
          {!isContractViewMode ? (
            <BalanceWidget entitiesList={balanceView} isLoading={isFetching} />
          ) : (
            <Flex vertical gap={20}>
              {balanceView?.contracts?.map((contract) => (
                <BalanceWidget
                  key={contract.contractId}
                  contract={contract}
                  isLoading={isLoading || isFetching}
                />
              ))}
            </Flex>
          )}
        </Flex>
      </Flex>

      <AddNewButton />

      {isPaymentDrawerOpen && <PaymentDrawer />}

      {isInvoiceDrawerOpen && <InvoiceDrawer setAddServiceDrawerOpen={setAddServiceDrawerOpen} />}

      {isAddServiceDrawerOpen && (
        <ServicesDrawer isOpen={isAddServiceDrawerOpen} setOpen={setAddServiceDrawerOpen} />
      )}

      {isCalculatorDrawerOpen && <CalculatorDrawer />}

      {isCreditNoteDrawerOpen && (
        <CreditNoteWithoutItemsDrawer
          onClose={() => {
            setDrawerClosed('creditNote');
            setCurrentCreditNote(undefined);
          }}
          isOpen={isCreditNoteDrawerOpen}
        />
      )}

      {isPaymentPurposeDrawerOpen && (
        <PaymentPurposeDrawer
          onClose={() => {
            setDrawerClosed('paymentPurpose');
            setCurrentPaymentPurpose(undefined);
            setEditModeBase(undefined);
          }}
          isOpen={isPaymentPurposeDrawerOpen}
        />
      )}

      {isDocumentsDrawerOpen && (
        <DocumentsDrawer
          onClose={() => {
            setDrawerClosed('documents');
          }}
          isOpen={isDocumentsDrawerOpen}
        />
      )}
    </DashboardContext.Provider>
  );
};
