import { DictionaryName, DictionaryTypesMap, IFilter, TDictionaryQueries } from '../models';
import { UseMutateFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { CurrencyRateWithId } from 'store/currencyRateStore';
import {
  useCreateFlightType,
  useDeleteFlightType,
  useGetFlightType,
  useGetFlightTypes,
  useUpdateFlightType
} from 'queries/flightType';
import {
  useCreateFlightPurpose,
  useDeleteFlightPurpose,
  useGetFlightPurpose,
  useGetFlightPurposes,
  useUpdateFlightPurpose
} from 'queries/flightPurpose';
import {
  useCreateService,
  useDeleteService,
  useGetService,
  useGetServices,
  useUpdateService
} from 'queries/service';
import {
  useCreateCountry,
  useDeleteCountry,
  useGetCountries,
  useGetCountry,
  useUpdateCountry
} from 'queries/country';
import {
  useCreateCity,
  useDeleteCity,
  useGetCities,
  useGetCity,
  useUpdateCity
} from 'queries/city';
import {
  useCreateAirport,
  useDeleteAirport,
  useGetAirport,
  useGetAirports,
  useUpdateAirport
} from 'queries/airport';
import {
  useCreateUnitOfMeasure,
  useDeleteUnitOfMeasure,
  useGetUnitOfMeasure,
  useGetUnitsOfMeasure,
  useUpdateUnitOfMeasure
} from 'queries/unitOfMeasure';
import {
  useCreateServiceName,
  useDeleteServiceName,
  useGetServiceName,
  useGetServiceNames,
  useUpdateServiceName
} from 'queries/serviceName';
import {
  useCreateAircraft,
  useDeleteAircraft,
  useGetAircraft,
  useGetAircrafts,
  useUpdateAircraft
} from 'queries/aircraft';
import {
  useCreateCounterparty,
  useDeleteCounterparty,
  useGetCounterparties,
  useGetCounterparty,
  useUpdateCounterparty
} from 'queries/counterparty';
import {
  useCreateContract,
  useDeleteContract,
  useGetContract,
  useGetContracts,
  useUpdateContract
} from 'queries/contract';
import {
  useCreateCurrencyRate,
  useDeleteCurrencyRate,
  useGetCurrencyRate,
  useGetCurrencyRates,
  useUpdateCurrencyRate
} from 'queries/currencyRate';
import {
  useCreateVatRate,
  useDeleteVatRate,
  useGetVatRate,
  useGetVatRates,
  useUpdateVatRate
} from 'queries/vatRate';
import {
  useCreateOwnPrice,
  useDeleteOwnPrice,
  useGetOwnPrice,
  useGetOwnPrices,
  useUpdateOwnPrice
} from 'queries/ownPrice';
import { settingsStore } from 'services/settings/SettingsStore';
import {
  RebateQueryType,
  useCreateRebate,
  useDeleteRebate,
  useGetRebate,
  useGetRebates,
  useUpdateRebate
} from 'queries/rebate';
import { SERVER_DATE_FORMAT } from 'consts/common';
import { IS_SPECIAL_PRICE } from '../components/DictionaryHeader/constants/own-prices';
import { REBATE_TYPE_OPTIONS } from '../components/DictionaryHeader/constants/rebates';

export const useDictionaryQueries = (name: DictionaryName, filter: IFilter) => {
  const { mainCurrency = '' } = settingsStore.getCurrentTenant();

  switch (name) {
    case 'aircraft': {
      const getList = useGetAircrafts(filter);
      const getItem = (enabled = true, id: string) => useGetAircraft(enabled, +id);

      const deleteMutation = useDeleteAircraft();
      const createMutation = useCreateAircraft();
      const updateMutation = useUpdateAircraft();

      return {
        isLoading:
          getList.isFetching ||
          deleteMutation.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading,
        onDelete: deleteMutation.mutate,
        onCreate: createMutation.mutate,
        onUpdate: updateMutation.mutate,
        getItem
      } as TDictionaryQueries<DictionaryTypesMap['aircraft']>;
    }

    case 'airport': {
      const getList = useGetAirports(filter, true);
      const getItem = (enabled = true, id: string) => useGetAirport(enabled, +id);

      const deleteMutation = useDeleteAirport();
      const createMutation = useCreateAirport();
      const updateMutation = useUpdateAirport();

      return {
        isLoading:
          getList.isFetching ||
          deleteMutation.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading,
        onDelete: deleteMutation.mutate,
        onCreate: createMutation.mutate,
        onUpdate: updateMutation.mutate,
        getItem
      } as TDictionaryQueries<DictionaryTypesMap['airport']>;
    }

    case 'city': {
      const getList = useGetCities(filter, true);
      const getItem = (enabled = true, id: string) => useGetCity(enabled, +id);

      const deleteMutation = useDeleteCity();
      const createMutation = useCreateCity();
      const updateMutation = useUpdateCity();

      return {
        isLoading:
          getList.isFetching ||
          deleteMutation.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading,
        onDelete: deleteMutation.mutate,
        onCreate: createMutation.mutate,
        onUpdate: updateMutation.mutate,
        getItem
      } as TDictionaryQueries<DictionaryTypesMap['city']>;
    }

    case 'contract': {
      const getList = useGetContracts(filter.name);
      const getItem = (enabled = true, id: string) => useGetContract(enabled, +id);

      const deleteMutation = useDeleteContract();
      const createMutation = useCreateContract();
      const updateMutation = useUpdateContract();

      return {
        isLoading:
          getList.isFetching ||
          deleteMutation.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading,
        onDelete: deleteMutation.mutate,
        onCreate: createMutation.mutate,
        onUpdate: updateMutation.mutate,
        getItem
      } as TDictionaryQueries<DictionaryTypesMap['contract']>;
    }

    case 'counterparty': {
      const getList = useGetCounterparties({ search: filter.name });
      const getItem = (enabled = true, id: string) => useGetCounterparty(enabled, +id);

      const deleteMutation = useDeleteCounterparty();
      const createMutation = useCreateCounterparty();
      const updateMutation = useUpdateCounterparty();

      return {
        isLoading:
          getList.isFetching ||
          deleteMutation.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading,
        onDelete: deleteMutation.mutate,
        onCreate: createMutation.mutate,
        onUpdate: updateMutation.mutate,
        getItem
      } as TDictionaryQueries<DictionaryTypesMap['counterparty']>;
    }

    case 'country': {
      const getList = useGetCountries(filter, true);
      const getItem = (enabled = true, id: string) => useGetCountry(enabled, +id);

      const deleteMutation = useDeleteCountry();
      const createMutation = useCreateCountry();
      const updateMutation = useUpdateCountry();

      return {
        isLoading:
          getList.isFetching ||
          deleteMutation.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading,
        onDelete: deleteMutation.mutate,
        onCreate: createMutation.mutate,
        onUpdate: updateMutation.mutate,
        getItem
      } as TDictionaryQueries<DictionaryTypesMap['country']>;
    }

    case 'currency-rate': {
      const getList = useGetCurrencyRates({
        page: filter.page,
        pageSize: filter.pageSize,
        sourceCurrency: filter.name || mainCurrency
      });
      const getItem = (enabled = true, date: string) => useGetCurrencyRate({ date }, enabled);

      const deleteMutation = useDeleteCurrencyRate();
      const createMutation = useCreateCurrencyRate();
      const updateMutation = useUpdateCurrencyRate();

      return {
        isLoading:
          getList.isFetching ||
          deleteMutation.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading,
        onDelete: deleteMutation.mutate as unknown as UseMutateFunction<
          void,
          unknown,
          string,
          unknown
        >,
        onCreate: createMutation.mutate as unknown as UseMutateFunction<
          AxiosResponse<CurrencyRateWithId>,
          unknown,
          CurrencyRateWithId,
          unknown
        >,
        onUpdate: updateMutation.mutate as unknown as UseMutateFunction<
          AxiosResponse<CurrencyRateWithId>,
          unknown,
          CurrencyRateWithId,
          unknown
        >,
        getItem
      } as TDictionaryQueries<DictionaryTypesMap['currency-rate']>;
    }

    case 'flight-purpose': {
      const getList = useGetFlightPurposes();
      const getItem = (enabled = true, id: string) => useGetFlightPurpose(enabled, +id);

      const deleteMutation = useDeleteFlightPurpose();
      const createMutation = useCreateFlightPurpose();
      const updateMutation = useUpdateFlightPurpose();

      return {
        isLoading:
          getList.isFetching ||
          deleteMutation.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading,
        onDelete: deleteMutation.mutate,
        onCreate: createMutation.mutate,
        onUpdate: updateMutation.mutate,
        getItem
      } as TDictionaryQueries<DictionaryTypesMap['flight-purpose']>;
    }

    case 'flight-type': {
      const getList = useGetFlightTypes();
      const getItem = (enabled = true, id: string) => useGetFlightType(enabled, +id);

      const deleteMutation = useDeleteFlightType();
      const createMutation = useCreateFlightType();
      const updateMutation = useUpdateFlightType();

      return {
        isLoading:
          getList.isFetching ||
          deleteMutation.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading,
        onDelete: deleteMutation.mutate,
        onCreate: createMutation.mutate,
        onUpdate: updateMutation.mutate,
        getItem
      } as TDictionaryQueries<DictionaryTypesMap['flight-type']>;
    }

    case 'own-price': {
      const getList = useGetOwnPrices({
        name: filter.name,
        activeDate: filter.activeDate ? filter.activeDate.format(SERVER_DATE_FORMAT) : undefined,
        isSpecialPrice:
          filter.isSpecialPrice === IS_SPECIAL_PRICE.YES
            ? true
            : filter.isSpecialPrice === IS_SPECIAL_PRICE.NO
              ? false
              : undefined,
        page: filter.page,
        pageSize: filter.pageSize
      });
      const getItem = (enabled = true, id: string) => useGetOwnPrice(+id, enabled);

      const deleteMutation = useDeleteOwnPrice();
      const createMutation = useCreateOwnPrice();
      const updateMutation = useUpdateOwnPrice();

      return {
        isLoading:
          getList.isFetching ||
          deleteMutation.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading,
        onDelete: deleteMutation.mutate,
        onCreate: createMutation.mutate,
        onUpdate: updateMutation.mutate,
        getItem
      } as TDictionaryQueries<DictionaryTypesMap['own-price']>;
    }

    case 'rebate': {
      const getList = useGetRebates({
        name: filter.name,
        type:
          filter.rebateType === REBATE_TYPE_OPTIONS.DISCOUNT
            ? RebateQueryType.Discount
            : filter.rebateType === REBATE_TYPE_OPTIONS.EXTRA
              ? RebateQueryType.Extra
              : undefined,
        pageSize: filter.pageSize,
        page: filter.page
      });
      const getItem = (enabled = true, id: string) => useGetRebate(+id, enabled);

      const deleteMutation = useDeleteRebate();
      const createMutation = useCreateRebate();
      const updateMutation = useUpdateRebate();

      return {
        isLoading:
          getList.isFetching ||
          deleteMutation.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading,
        onDelete: deleteMutation.mutate,
        onCreate: createMutation.mutate,
        onUpdate: updateMutation.mutate,
        getItem
      } as TDictionaryQueries<DictionaryTypesMap['rebate']>;
    }

    case 'service': {
      const getList = useGetServices();
      const getItem = (enabled = true, id: string) => useGetService(enabled, +id);

      const deleteMutation = useDeleteService();
      const createMutation = useCreateService();
      const updateMutation = useUpdateService();

      return {
        isLoading:
          getList.isFetching ||
          deleteMutation.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading,
        onDelete: deleteMutation.mutate,
        onCreate: createMutation.mutate,
        onUpdate: updateMutation.mutate,
        getItem
      } as TDictionaryQueries<DictionaryTypesMap['service']>;
    }

    case 'service-name': {
      const getList = useGetServiceNames();
      const getItem = (enabled = true, id: string) => useGetServiceName(enabled, +id);

      const deleteMutation = useDeleteServiceName();
      const createMutation = useCreateServiceName();
      const updateMutation = useUpdateServiceName();

      return {
        isLoading:
          getList.isFetching ||
          deleteMutation.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading,
        onDelete: deleteMutation.mutate,
        onCreate: createMutation.mutate,
        onUpdate: updateMutation.mutate,
        getItem
      } as TDictionaryQueries<DictionaryTypesMap['service-name']>;
    }

    case 'unit-of-measure': {
      const getList = useGetUnitsOfMeasure();
      const getItem = (enabled = true, id: string) => useGetUnitOfMeasure(enabled, +id);

      const deleteMutation = useDeleteUnitOfMeasure();
      const createMutation = useCreateUnitOfMeasure();
      const updateMutation = useUpdateUnitOfMeasure();

      return {
        isLoading:
          getList.isFetching ||
          deleteMutation.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading,
        onDelete: deleteMutation.mutate,
        onCreate: createMutation.mutate,
        onUpdate: updateMutation.mutate,
        getItem
      } as TDictionaryQueries<DictionaryTypesMap['unit-of-measure']>;
    }

    case 'vat-rate': {
      const getList = useGetVatRates();
      const getItem = (enabled = true, id: string) => useGetVatRate(enabled, +id);

      const deleteMutation = useDeleteVatRate();
      const createMutation = useCreateVatRate();
      const updateMutation = useUpdateVatRate();

      return {
        isLoading:
          getList.isFetching ||
          deleteMutation.isLoading ||
          createMutation.isLoading ||
          updateMutation.isLoading,
        onDelete: deleteMutation.mutate,
        onCreate: createMutation.mutate,
        onUpdate: updateMutation.mutate,
        getItem
      } as TDictionaryQueries<DictionaryTypesMap['vat-rate']>;
    }
  }
};
