import React from 'react';
import { Flex } from 'antd';
import {
  InvoiceDetailsBlock,
  InvoiceItemsBlock,
  PaymentPurposeBlock,
  CreditNotesBlock,
  InvoiceServicesBlock
} from './accordions';
import { useInvoiceStore } from 'store/invoiceStore';

interface IProps {
  setAddServiceDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InvoiceDetails = ({ setAddServiceDrawerOpen }: IProps) => {
  const { isInvoiceEditMode } = useInvoiceStore(({ isInvoiceEditMode }) => ({
    isInvoiceEditMode
  }));

  return (
    <Flex gap={8} vertical>
      <InvoiceDetailsBlock />

      {!isInvoiceEditMode && (
        <>
          <InvoiceItemsBlock />
          <InvoiceServicesBlock setAddServiceDrawerOpen={setAddServiceDrawerOpen} />
          <PaymentPurposeBlock />
          <CreditNotesBlock />
        </>
      )}
    </Flex>
  );
};
