import React, { useState } from 'react';
import { Button, Divider, message, Radio, Space } from 'antd';
import { CloseButton } from 'components/Drawer';
import { DownloadOutlined, MailOutlined } from '@ant-design/icons';
import { CONTROL_SIZE } from 'consts/common';
import { useMessageStore } from 'store/messageStore';
import { getSelectOptions, handleError } from 'helpers';
import { AxiosError, AxiosResponse } from 'axios';
import { LinkedEntityTypes, ReportFormat } from 'services/api/data-contracts';
import { invoicesApi } from 'services/api';
import { SIGNATURE_NAME } from 'queries/messages/consts';
import { NEXT_DOCUMENT_FETCH_DELAY } from './consts';
import { delay } from './helpers/delay';
import { DocumentItem, InvoiceDocument } from './columns';
import { getFilename } from './helpers/getFilename';
import { gray } from '@ant-design/colors';

interface IProps {
  onClose: () => void;
  selectedDocuments: DocumentItem[];
  isEmailWidgetRendered: boolean;
  counterpartyName?: string;
  hasError?: boolean;
}

export const HeaderButtons = ({
  onClose,
  selectedDocuments,
  counterpartyName,
  hasError,
  isEmailWidgetRendered
}: IProps) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isAttachInProgress, setAttachInProgress] = useState(false);
  const [isReportLoading, setReportLoading] = useState(false);
  const [format, setFormat] = useState(ReportFormat.Pdf);
  const { setAttachmentsData, setCurrentMessage, signatures, setNewMessageMode, currentMessage } =
    useMessageStore();

  const openLoadingMessage = () => {
    messageApi.open({
      key: 'loading-attachment',
      type: 'loading',
      content: `Attachment of ${selectedDocuments.length} document${selectedDocuments.length > 1 ? 's' : ''} in process...`
    });
  };

  const fetchReport = async (file: DocumentItem) => {
    const response = await invoicesApi.downloadReportById(
      file.fileId as string,
      {
        format /* TODO: do we really need to pass format? */,
        isUserFile: file.isUserFile
      },
      {
        format: 'arraybuffer',
        headers: {
          Accept:
            format === ReportFormat.Pdf
              ? 'application/pdf, application/json, application/text'
              : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        }
      }
    );

    const contentType = response.headers['content-type'];
    const blob = new Blob([response.data], { type: contentType });
    const baseFilename = `report_${counterpartyName}`;
    const defaultFilename = `${baseFilename}.${format}`;
    const contentDisposition = response.headers['content-disposition'] || '';
    const match = contentDisposition.match(/filename="(.+)"/);
    const filename = file.isUserFile
      ? (file as InvoiceDocument).filename || match[1]
      : getFilename(file, format, counterpartyName) || defaultFilename;

    const entityToLink = (file as InvoiceDocument).invoiceId
      ? {
          entityType: LinkedEntityTypes.Invoice,
          entityId: (file as InvoiceDocument).invoiceId
        }
      : {};

    return { blob, filename, contentType, ...entityToLink };
  };

  const onAttachToMessage = () => {
    const signature = signatures?.find(({ name }) => name === SIGNATURE_NAME.NEW);
    setCurrentMessage({
      ...currentMessage,
      subject: `Attached files for ${counterpartyName || ''}`,
      ...(signature ? { body: signature.text } : {})
    });
  };

  const catchReportError = async (err: AxiosError | TypeError | unknown) => {
    if (err instanceof AxiosError && err.response) {
      const decodedResponse = new TextDecoder('utf-8').decode(
        (err.response as AxiosResponse<BufferSource>).data
      );

      await handleError(
        new AxiosError(err.message, err.code, err.config, err.request, {
          data: JSON.parse(decodedResponse)
        } as AxiosResponse<BufferSource>)
      );
    }
  };

  const handleAttachToMessage = async () => {
    if (!selectedDocuments.length) {
      message.error('No files are selected.');
      return;
    }
    setAttachInProgress(true);
    openLoadingMessage();

    const fileBlobDataArray = [];

    try {
      for (const [index, file] of selectedDocuments.entries()) {
        if (!file.fileId) {
          message.error(`File id for document ${index + 1} is not found.`);
          continue;
        }
        const { blob, filename, contentType, entityId, entityType } = await fetchReport(file);
        const newFile = { filename, contentType, blob, entityId, entityType };
        fileBlobDataArray.push(newFile);
        setAttachmentsData(fileBlobDataArray);
        onAttachToMessage();
        setNewMessageMode('withInvoiceAttachment');
        await delay(NEXT_DOCUMENT_FETCH_DELAY);
      }
    } catch (err) {
      await catchReportError(err);
    } finally {
      setAttachInProgress(false);
      message.destroy('loading-attachment');
    }
  };

  const handleDownload = async () => {
    if (!selectedDocuments.length) {
      message.error('No files are selected.');
      return;
    }

    setReportLoading(true);

    try {
      for (const [index, file] of selectedDocuments.entries()) {
        if (!file.fileId) {
          message.error(`File id for document ${index + 1} is not found.`);
          continue;
        }
        const { blob, filename } = await fetchReport(file);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        await delay(NEXT_DOCUMENT_FETCH_DELAY);
      }
    } catch (err) {
      await catchReportError(err);
    } finally {
      setReportLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Space>
        {!hasError && (
          <>
            <Radio.Group
              options={getSelectOptions(Object.values(ReportFormat))}
              onChange={({ target: { value } }) => setFormat(value)}
              value={format}
              optionType="button"
              buttonStyle="solid"
              size={CONTROL_SIZE}
            />
            <Divider
              style={{ margin: 0, padding: 0, paddingInline: '2px!important', color: gray[0] }}>
              |
            </Divider>
            <Button
              size={CONTROL_SIZE}
              icon={<DownloadOutlined />}
              disabled={isReportLoading}
              onClick={handleDownload}
              loading={isReportLoading}>
              Download
            </Button>
            {isEmailWidgetRendered && (
              <Button
                size={CONTROL_SIZE}
                icon={<MailOutlined />}
                loading={isAttachInProgress}
                onClick={handleAttachToMessage}>
                Send in email
              </Button>
            )}
          </>
        )}

        <CloseButton onClick={onClose} />
      </Space>
    </>
  );
};
