import React, { useEffect, useMemo } from 'react';
import { ConfigProvider, Empty, Flex, Table } from 'antd';
import { CONTROL_SIZE, DEFAULT_DOCUMENTS_LIST_LIMIT } from 'consts/common';
import { DocumentItem, getColumns } from './columns';
import { chain } from 'lodash';
import { InvoiceHydratedWithAdditionalData } from 'store/invoiceStore';
import { useGetDocumentsList } from 'queries/documents/useGetDocumentsList';

interface IProps {
  onRowSelect: (record: DocumentItem, isSelected: boolean) => void;
  selectedDocuments: DocumentItem[];
  setSelectedDocuments: React.Dispatch<React.SetStateAction<DocumentItem[]>>;
  invoice?: InvoiceHydratedWithAdditionalData;
  invoiceCounterpartyId?: number;
  counterpartyName?: string;
  creditnoteCounterpartyId?: number;
}

export const DrawerContent = ({
  onRowSelect,
  selectedDocuments,
  setSelectedDocuments,
  invoice,
  invoiceCounterpartyId,
  counterpartyName,
  creditnoteCounterpartyId
}: IProps) => {
  const isEnabled = invoice ? !!invoiceCounterpartyId : !!creditnoteCounterpartyId;

  const { data, isFetching } = useGetDocumentsList(
    {
      counterpartyId: invoice
        ? (invoiceCounterpartyId as number)
        : (creditnoteCounterpartyId as number),
      limit: DEFAULT_DOCUMENTS_LIST_LIMIT
    },
    isEnabled
  );
  const columns = useMemo(() => getColumns(counterpartyName), []);

  const rows = useMemo(
    () =>
      chain(data?.data.items)
        .filter((item) => Boolean(item.fileSize))
        .value(),
    [data?.data.items]
  );

  useEffect(() => {
    setSelectedDocuments(rows);
  }, [rows]);

  return (
    <>
      <ConfigProvider
        renderEmpty={() => (
          <Empty description="Documents not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}>
        <Table
          columns={columns}
          dataSource={rows}
          rowKey="fileId"
          size={CONTROL_SIZE}
          loading={isFetching}
          pagination={false}
          rowSelection={{
            onSelect: onRowSelect,
            hideSelectAll: true,
            selectedRowKeys: selectedDocuments.map(({ fileId }) => fileId as string)
          }}
        />
      </ConfigProvider>
      <Flex
        vertical
        align="center"
        justify="center"
        style={{
          height: 65,
          width: '100%',
          textAlign: 'center',
          color: '#00000073'
        }}>
        <div>
          Invoices that have been set to ‘Ready for sending’ status within 2 days are displayed.
        </div>

        <div>
          To view earlier files, change the file back to ‘Posted’ status and then back to ‘Ready for
          sending’.
        </div>
      </Flex>
    </>
  );
};
