import React, { useEffect, useState } from 'react';
import { Flex, notification, Progress } from 'antd';
import { MESSAGE_DURATION } from 'consts/common';

export const useProgressNotification = () => {
  const [uploadData, setUploadData] = useState({ fileName: '', percent: 0, errorMessage: '' });

  const startProgressStatus = (fileName: string) => {
    setUploadData({ fileName, percent: 0, errorMessage: '' });
  };

  const updateProgressStatus = (percent: number) => {
    setUploadData((prevState) => ({ ...prevState, percent }));
  };

  const finishProgressStatus = (error = '') => {
    setUploadData((prevState) => ({ ...prevState, errorMessage: error, percent: 100 }));
  };

  const getTitleMessage = () => {
    if (uploadData.errorMessage) {
      return 'Upload failed';
    }
    return uploadData.percent < 100 ? 'Uploading file in progress...' : 'Upload finished';
  };

  const getDescriptionMessage = () => {
    if (uploadData.percent < 100) {
      return (
        <Flex vertical>
          {`Uploading file ${uploadData.fileName}...`}
          <Progress percent={uploadData.percent} status="active" />
        </Flex>
      );
    } else if (uploadData.errorMessage) {
      return `${uploadData.errorMessage}`;
    } else {
      return `The file ${uploadData.fileName} was successfully uploaded.`;
    }
  };

  useEffect(() => {
    if (uploadData.fileName) {
      notification.warning({
        key: 'uploading',
        message: getTitleMessage(),
        description: getDescriptionMessage(),
        duration: uploadData.percent < 100 ? 0 : MESSAGE_DURATION,
        placement: 'topLeft',
        style: {
          width: 320
        }
      });
    }
  }, [uploadData]);

  return { startProgressStatus, updateProgressStatus, finishProgressStatus };
};
