import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { sortByDate } from './helpers';
import {
  MessageListEntry,
  MessageDetails as Message,
  PageMessages,
  AttachmentEntry as Attachment,
  AccountTemplate,
  AccountTemplatesList
} from 'services/emailEngineApi/data-contracts';
import { RangeValue } from 'store/helpers/types';
import { EntityId, LinkedEntityTypes, LinkEntityItem } from 'services/api/data-contracts';
import { idTokenStore } from 'services/auth/IdTokenStore';
import Handlebars from 'handlebars';

export type MessageWithBody = Message & { body?: string };
export type MessageTag = Pick<MessageListEntry, 'subject' | 'id' | 'flags'>;
export type NewMessageMode =
  | 'reply'
  | 'forward'
  | 'new'
  | 'replyAll'
  | 'fromOrder'
  | 'withInvoiceAttachment'
  | 'withPaymentAttachment'
  | false;

export interface MessagesFilter {
  recipientOrSender?: string;
  emailSubject?: string;
  emailBody?: string;
  messageSentDate?: RangeValue;
  withAttachment?: string;
  flags?: string[];
  showDone?: boolean;
  links?: string;
}

export type EmailAccountSignature = Omit<AccountTemplate, 'description'> & {
  description: string;
  text?: string;
};

export type EmailAccountSignatures = EmailAccountSignature[];

export type LinkedEntity = {
  entityType?: LinkedEntityTypes;
  entityId?: EntityId;
};

export type AttachmentData = {
  filename: string;
  contentType: string;
  blob: Blob;
} & LinkedEntity;

type MessageStore = {
  currentMessage?: MessageWithBody;
  setCurrentMessage: (current?: MessageWithBody) => void;
  openMessageId?: string;
  setOpenMessageId: (openMessageId?: string) => void;
  downloadAttachmentId?: string;
  setDownloadAttachmentId: (downloadAttachmentId?: string) => void;
  list: PageMessages;
  setList: (list: PageMessages) => void;
  messagesListItemsCount: number;
  setMessagesListItemsCount: (messagesListItemsCount?: number) => void;
  selectedMessages: MessageTag[];
  setSelectedMessages: (selectedMessages: MessageTag[]) => void;
  optimisticUpdateMessage: MessageListEntry | null;
  setOptimisticUpdateMessage: (message: MessageListEntry | null) => void;
  messagesFilter: MessagesFilter;
  setMessagesFilter: (messagesFilter?: MessagesFilter) => void;
  currentViewedAttachment: Attachment | null;
  setCurrentViewedAttachment: (attachment?: Attachment | null) => void;
  newMessageMode: NewMessageMode;
  setNewMessageMode: (isReplyOrForwardMode: NewMessageMode) => void;
  emailAddressOptions: string[];
  setEmailAddressOptions: (emailAddressOptions: string[]) => void;
  linkedMessagesIdsFilter: string[];
  setLinkedMessagesIdsFilter: (linkedMessagesIdsFilter: string[]) => void;
  pendingEntitiesToLinkFromTemplate?: Array<LinkEntityItem>;
  setPendingEntitiesToLinkFromTemplate: (
    pendingEntitiesToLinkFromTemplate?: Array<LinkEntityItem>
  ) => void;
  attachmentsData?: AttachmentData[];
  setAttachmentsData: (attachmentsData: AttachmentData[]) => void;
  templates: AccountTemplatesList;
  setTemplates: (templates: AccountTemplatesList) => void;
  signatures: EmailAccountSignatures;
  setSignatures: (signatures: EmailAccountSignatures) => void;
  allMailPath?: string;
  setAllMailPath: (allMailPath: string) => void;
};

export const defaultMessagesFilter = {
  showDone: false
};

export const useMessageStore = create<MessageStore>()(
  devtools((set) => ({
    currentMessage: undefined,
    setCurrentMessage: (currentMessage?) => {
      set({ currentMessage });
    },
    openMessageId: undefined,
    setOpenMessageId: (openMessageId?) => {
      set({ openMessageId });
    },
    downloadAttachmentId: undefined,
    setDownloadAttachmentId: (downloadAttachmentId?) => {
      set({ downloadAttachmentId });
    },
    list: [],
    setList: (list) => {
      const sortedList = sortByDate(list, 'date');
      set({ list: sortedList });
    },
    messagesListItemsCount: 0,
    setMessagesListItemsCount: (messagesListItemsCount = 0) => {
      set({ messagesListItemsCount });
    },
    selectedMessages: [],
    setSelectedMessages: (selectedMessages) => {
      set({ selectedMessages });
    },
    optimisticUpdateMessage: null,
    setOptimisticUpdateMessage: (message) => set(() => ({ optimisticUpdateMessage: message })),
    messagesFilter: defaultMessagesFilter,
    setMessagesFilter: (messagesFilter) => {
      set({ messagesFilter });
    },
    currentViewedAttachment: null,
    setCurrentViewedAttachment: (currentViewedAttachment) => {
      set({ currentViewedAttachment });
    },
    newMessageMode: false,
    setNewMessageMode: (newMessageMode) => {
      set({ newMessageMode });
    },
    emailAddressOptions: [],
    setEmailAddressOptions: (emailAddressOptions) => {
      set({ emailAddressOptions });
    },
    linkedMessagesIdsFilter: [],
    setLinkedMessagesIdsFilter: (linkedMessagesIdsFilter) => {
      set({ linkedMessagesIdsFilter });
    },
    pendingEntitiesToLinkFromTemplate: [],
    setPendingEntitiesToLinkFromTemplate: (pendingEntitiesToLinkFromTemplate) => {
      set({ pendingEntitiesToLinkFromTemplate });
    },
    attachmentsData: [],
    setAttachmentsData: (attachmentsData) => {
      set({ attachmentsData });
    },
    templates: [],
    setTemplates: (templates) => {
      set({ templates });
    },
    signatures: [],
    setSignatures: (data) => {
      const { userName = '' } = idTokenStore.getUserData();
      const signatures = data.map((signature) => {
        const textTemplate = Handlebars.compile(signature.text);

        return {
          ...signature,
          text: textTemplate({ userName }).toUpperCase()
        } as EmailAccountSignature;
      });

      set({ signatures });
    },
    allMailPath: undefined,
    setAllMailPath: (allMailPath) => {
      set({ allMailPath });
    }
  }))
);
