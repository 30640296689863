import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { Tooltip } from 'antd';
import { CreditNoteItemHydrated } from 'services/api/data-contracts';
import { getAmountValue, getUTCDate } from 'helpers';
import { DATE_FORMAT } from 'consts/common';

export const getColumns = (): ColumnsType<CreditNoteItemHydrated> => [
  {
    title: 'Invoice',
    dataIndex: 'invoice',
    key: 'invoice',
    render: (_, record) => (
      <div
        style={{
          width: '100%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}>
        {[
          record.invoiceItem.invoice?.invoiceNumber,
          record.invoiceItem.invoice?.isPosted ? 'Posted' : 'Not posted',
          getUTCDate(record.invoiceItem.invoice?.createdAt)?.format(DATE_FORMAT)
        ]
          .filter((i) => !!i)
          .join(' | ')}
      </div>
    ),
    width: 240
  },
  {
    title: 'Invoice item',
    dataIndex: 'service',
    key: 'service',
    ellipsis: true,
    render: (_, record) => (
      <Tooltip title={record.invoiceItem.serviceName.name}>
        {record.invoiceItem.serviceName.name || '-'}
      </Tooltip>
    )
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (_, record) =>
      record.invoiceItem.createdAt
        ? getUTCDate(record.invoiceItem.createdAt).format(DATE_FORMAT)
        : '-',
    width: 100
  },
  {
    title: 'Cur',
    dataIndex: 'currency',
    key: 'currency',
    render: (_, record) => record.invoiceItem.invoice?.currency || '-',
    width: 46
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (_, record) => (
      <Tooltip title={getAmountValue(record.totalAmount)}>
        <div
          style={{
            width: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}>
          {getAmountValue(record.totalAmount)}
        </div>
      </Tooltip>
    ),
    width: 100,
    align: 'end'
  },
  {
    title: 'Rebate Note',
    dataIndex: 'notes',
    key: 'notes',
    ellipsis: true
  }
];
