import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { paymentsApi } from 'services/api';
import { CreditNoteInput, PaymentHydrated, PaymentInput } from 'services/api/data-contracts';
import { PAYMENT_QUERY_KEY } from './consts';
import { MESSAGE_DURATION } from 'consts/common';
import { splitCamelCase } from 'helpers';
import { INVOICE_QUERY_KEY } from '../invoice/consts';
import { SETTLEMENT_WIDGET_QUERY_KEY } from '../settlement/consts';
import { REBATE_REPORT_QUERY_KEY } from '../rebate/consts';

const createItem = async (data: PaymentInput | CreditNoteInput) =>
  await paymentsApi.addPayment(data);

export const useCreatePayment = (onSuccess?: (payment: PaymentHydrated) => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createItem,
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: [PAYMENT_QUERY_KEY] });
      await queryClient.refetchQueries([INVOICE_QUERY_KEY]);

      message.success(
        `${splitCamelCase(response.data.transactionType)} has been successfully created`,
        MESSAGE_DURATION
      );

      onSuccess && onSuccess(response.data);

      // due to performance issues with settlement widget moved after onSuccess callback
      await queryClient.refetchQueries([SETTLEMENT_WIDGET_QUERY_KEY]);
      await queryClient.refetchQueries([REBATE_REPORT_QUERY_KEY]);
    }
  });
};
