/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * Account type
 * @example "outlook"
 */
export enum Type {
  Imap = 'imap',
  Gmail = 'gmail',
  GmailService = 'gmailService',
  Outlook = 'outlook',
  MailRu = 'mailRu',
  Oauth2 = 'oauth2'
}

/**
 * Account state
 * @example "connected"
 */
export enum State {
  Init = 'init',
  Syncing = 'syncing',
  Connecting = 'connecting',
  Connected = 'connected',
  AuthenticationError = 'authenticationError',
  ConnectError = 'connectError',
  Unset = 'unset',
  Disconnected = 'disconnected'
}

/**
 * Lifetime event counters
 * @example {"messageNew":30,"messageDeleted":5}
 */
export type AccountCountersEvents = object;

export interface AccountCounters {
  /** Lifetime event counters */
  events?: AccountCountersEvents;
}

/**
 * Requested grant type
 * @example "refresh_token"
 */
export enum Grant {
  RefreshToken = 'refresh_token',
  AuthorizationCode = 'authorization_code'
}

/** List of requested OAuth2 scopes */
export type OauthScopes = string[];

/**
 * Server response
 * @example {"error":"invalid_grant","error_description":"Bad Request"}
 */
export type Response = object;

/** Information about the OAuth2 token request that failed */
export interface TokenRequest {
  /** Requested grant type */
  grant?: Grant;
  /**
   * OAuth2 provider
   * @maxLength 256
   * @example "gmail"
   */
  provider?: string;
  /**
   * HTTP status code for the OAuth2 request
   * @example 400
   */
  status?: number;
  /**
   * OAuth2 client ID used to authenticate this request
   * @example "1023289917884-h3nu00e9cb7h252e24c23sv19l8k57ah.apps.googleusercontent.com"
   */
  clientId?: string;
  /** List of requested OAuth2 scopes */
  scopes?: OauthScopes;
  /** Server response */
  response?: Response;
}

export interface AccountErrorEntry {
  /**
   * Error message from EmailEngine
   * @example "Token request failed"
   */
  response?: string;
  /**
   * Server response code or error identifier
   * @example "OauthRenewError"
   */
  serverResponseCode?: string;
  /** Information about the OAuth2 token request that failed */
  tokenRequest?: TokenRequest;
}

export interface AccountResponseItem {
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /**
   * Display name for the account
   * @maxLength 256
   * @example "My Email Account"
   */
  name?: string;
  /**
   * Default email address of the account
   * @example "user@example.com"
   */
  email?: string;
  /** Account type */
  type: Type;
  /**
   * OAuth2 application ID
   * @maxLength 256
   * @example "AAABhaBPHscAAAAH"
   */
  app?: string;
  /** Account state */
  state: State;
  /**
   * Account-specific webhook URL
   * @example "https://myservice.com/imap/webhooks"
   */
  webhooks?: string;
  /**
   * Proxy URL
   * @example "socks://proxy.example.com:1080"
   */
  proxy?: string;
  /**
   * Hostname to use for the SMTP EHLO/HELO greeting
   * @example "my.proxy.tld"
   */
  smtpEhloName?: string;
  counters?: AccountCounters;
  /**
   * Last sync time
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  syncTime?: string;
  lastError?: AccountErrorEntry;
}

export type AccountEntries = AccountResponseItem[];

export interface AccountsFilterResponse {
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  accounts?: AccountEntries;
}

export interface DetectedAuthenticationInfo {
  /**
   * Account username
   * @maxLength 256
   * @example "myuser@gmail.com"
   */
  user?: string;
}

export interface ResolvedServerSettings {
  auth?: DetectedAuthenticationInfo;
  /**
   * Hostname to connect to
   * @example "imap.gmail.com"
   */
  host: string;
  /**
   * Service port number
   * @min 1
   * @max 65536
   * @example 993
   */
  port: number;
  /**
   * Should connection use TLS. Usually true for port 993
   * @default false
   * @example true
   */
  secure?: boolean;
}

export interface DiscoveredServerSettings {
  auth?: DetectedAuthenticationInfo;
  /**
   * Hostname to connect to
   * @example "imap.gmail.com"
   */
  host: string;
  /**
   * Service port number
   * @min 1
   * @max 65536
   * @example 993
   */
  port: number;
  /**
   * Should connection use TLS. Usually true for port 993
   * @default false
   * @example true
   */
  secure?: boolean;
}

export interface DiscoveredEmailSettings {
  imap?: ResolvedServerSettings;
  smtp?: DiscoveredServerSettings;
  /**
   * Source for the detected info
   * @example "srv"
   */
  _source?: string;
}

export interface BlocklistsResponseItem {
  /**
   * List ID
   * @maxLength 256
   * @example "example"
   */
  listId: string;
  /**
   * Count of blocked addresses in this list
   * @example 12
   */
  count?: number;
}

export type BlocklistsEntries = BlocklistsResponseItem[];

export interface BlocklistsResponse {
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  blocklists?: BlocklistsEntries;
}

export interface GatewayResponseItem {
  /**
   * Gateway ID
   * @maxLength 256
   * @example "example"
   */
  gateway: string;
  /**
   * Display name for the gateway
   * @maxLength 256
   * @example "My Email Gateway"
   */
  name?: string;
  /**
   * Count of email deliveries using this gateway
   * @example 100
   */
  deliveries?: number;
  /**
   * Last delivery time
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  lastUse?: string;
  lastError?: AccountErrorEntry;
}

export type GatewayEntries = GatewayResponseItem[];

export interface GatewaysFilterResponse {
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  gateways?: GatewayEntries;
}

export enum LicenseDetails {
  False = 'false'
}

export interface LicenseResponse {
  /**
   * Indicates whether an active license is registered
   * @example true
   */
  active?: boolean;
  /**
   * Type of the active license
   * @example "EmailEngine License"
   */
  type?: string;
  details?: LicenseDetails;
  /**
   * Indicates whether email connections are suspended due to licensing issues
   * @example false
   */
  suspended?: boolean;
}

/**
 * OAuth2 provider
 * @example "gmail"
 */
export enum OAuth2Provider {
  Gmail = 'gmail',
  GmailService = 'gmailService',
  Outlook = 'outlook',
  MailRu = 'mailRu'
}

/**
 * Google Cloud Project ID
 * @maxLength 256
 * @example "project-name-425411"
 */
export enum GoogleProjectId {
  False = 'false'
}

export interface OAuth2ResponseItem {
  /**
   * OAuth2 application ID
   * @maxLength 256
   * @example "AAABhaBPHscAAAAH"
   */
  id: string;
  /**
   * Display name for the app
   * @maxLength 256
   * @example "My OAuth2 App"
   */
  name?: string;
  /**
   * OAuth2 application description
   * @maxLength 1024
   * @example "App description"
   */
  description?: string;
  /**
   * Title for the application button
   * @maxLength 256
   * @example "App title"
   */
  title?: string;
  /** OAuth2 provider */
  provider: OAuth2Provider;
  /**
   * Is the application enabled
   * @example true
   */
  enabled?: boolean;
  /**
   * `true` for older OAuth2 apps set via the settings endpoint
   * @example true
   */
  legacy?: boolean;
  /**
   * The time this entry was added
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created: string;
  /**
   * The time this entry was updated
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  updated?: string;
  /**
   * Is the application listed in the hosted authentication form
   * @example true
   */
  includeInListing?: boolean;
  /**
   * Client or Application ID for 3-legged OAuth2 applications
   * @example "4f05f488-d858-4f2c-bd12-1039062612fe"
   */
  clientId?: string;
  /**
   * Client secret for 3-legged OAuth2 applications. Actual value is not revealed.
   * @example "******"
   */
  clientSecret?: string;
  /**
   * Authorization tenant value for Outlook OAuth2 applications
   * @example "common"
   */
  authority?: string;
  /**
   * Redirect URL for 3-legged OAuth2 applications
   * @example "https://myservice.com/oauth"
   */
  redirectUrl?: string;
  /**
   * Service client ID for 2-legged OAuth2 applications
   * @example "9103965568215821627203"
   */
  serviceClient?: string;
  /** Google Cloud Project ID */
  googleProjectId?: GoogleProjectId;
  /**
   * Show only Google Workspace accounts on the OAuth2 login page
   * @example false
   */
  googleWorkspaceAccounts?: boolean;
  /**
   * Service Client Email for 2-legged OAuth2 applications
   * @example "name@project-123.iam.gserviceaccount.com"
   */
  serviceClientEmail?: string;
  /**
   * PEM formatted service secret for 2-legged OAuth2 applications. Actual value is not revealed.
   * @example "******"
   */
  serviceKey?: string;
  lastError?: AccountErrorEntry;
}

export type OAuth2Entries = OAuth2ResponseItem[];

export interface OAuth2FilterResponse {
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  apps?: OAuth2Entries;
}

/**
 * How this message was added to the queue
 * @example "smtp"
 */
export enum Source {
  Smtp = 'smtp',
  Api = 'api'
}

export type To = string[];

/** SMTP envelope */
export interface Envelope {
  /** @example "sender@example.com" */
  from?: string;
  to?: To;
}

/**
 * Current state of the sending
 * @example "queued"
 */
export enum Status {
  Queued = 'queued',
  Processing = 'processing',
  Submitted = 'submitted',
  Error = 'error'
}

/** Error information if status is "error" */
export interface OutboxListProgressError {
  /**
   * Error message
   * @example "Authentication failed"
   */
  message?: string;
  /**
   * Error code
   * @example "EAUTH"
   */
  code?: string;
  /**
   * SMTP response code
   * @example 502
   */
  statusCode?: string;
}

export interface OutboxEntryProgress {
  /** Current state of the sending */
  status?: Status;
  /**
   * Response from the SMTP server. Only if status is "processing"
   * @example "250 Message Accepted"
   */
  response?: string;
  /** Error information if status is "error" */
  error?: OutboxListProgressError;
}

export interface OutboxEntry {
  /**
   * Outbox queue ID
   * @example "1869c5692565f756b33"
   */
  queueId?: string;
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /** How this message was added to the queue */
  source?: Source;
  /**
   * Message ID
   * @maxLength 996
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /** SMTP envelope */
  envelope?: Envelope;
  /**
   * Message subject
   * @maxLength 10240
   * @example "What a wonderful message"
   */
  subject?: string;
  /**
   * The time this message was queued
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created?: string;
  /**
   * When this message is supposed to be delivered
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  scheduled?: string;
  /**
   * Next delivery attempt
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  nextAttempt?: string;
  /**
   * How many times EmailEngine has tried to deliver this email
   * @example 3
   */
  attemptsMade?: number;
  /**
   * How many delivery attempts to make until message is considered as failed
   * @example 3
   */
  attempts?: number;
  progress?: OutboxEntryProgress;
}

export type OutboxListEntries = OutboxEntry[];

export interface OutboxListResponse {
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  messages?: OutboxListEntries;
}

/** List of webhook events to subscribe to */
export type WebhookEvents = string[];

export interface WebhooksCustomHeader {
  /**
   * @maxLength 1024
   * @example "Authorization"
   */
  key: string;
  /**
   * @maxLength 10240
   * @default ""
   * @example "Bearer <secret-token>"
   */
  value?: string;
}

/** Custom HTTP headers added to webhook requests */
export type WebhooksCustomHeaders = WebhooksCustomHeader[];

/** List of additional email headers to include in webhook notifications */
export type NotifyHeaders = string[];

/**
 * Sets the indexing method for IMAP accounts. Choose "full" to build a complete index that tracks deleted and updated emails, or "fast" to only detect newly received emails
 * @example "full"
 */
export enum ImapIndexer {
  Full = 'full',
  Fast = 'fast'
}

export interface LogSettings {
  /**
   * Enable logs for all accounts
   * @default false
   * @example false
   */
  all?: boolean;
  /**
   * Maximum number of log lines to keep
   * @min 0
   * @max 1000000
   * @default 10000
   */
  maxLogLines?: number;
}

/** How to select local IP address for IMAP connections */
export enum ImapStrategy {
  Default = 'default',
  Dedicated = 'dedicated',
  Random = 'random'
}

/** How to select local IP address for SMTP connections */
export enum SmtpStrategy {
  Default = 'default',
  Dedicated = 'dedicated',
  Random = 'random'
}

/** A list of pooled local IP addresses that can be used for IMAP and SMTP connections */
export type LocalAddresses = string[];

/**
 * Default locale identifier
 * @maxLength 100
 * @example "fr"
 */
export enum Locale {
  En = 'en',
  Et = 'et',
  Fr = 'fr',
  De = 'de',
  Ja = 'ja'
}

export interface SettingsQueryResponse {
  /** If set to false, webhooks will not be emitted */
  webhooksEnabled?: boolean;
  /**
   * The URL where webhooks will be sent
   * @example "https://myservice.com/imap/webhooks"
   */
  webhooks?: string;
  /** List of webhook events to subscribe to */
  webhookEvents?: WebhookEvents;
  /** Custom HTTP headers added to webhook requests */
  webhooksCustomHeaders?: WebhooksCustomHeaders;
  /** List of additional email headers to include in webhook notifications */
  notifyHeaders?: NotifyHeaders;
  /**
   * The base URL of the EmailEngine service
   * @example "https://emailengine.example.com"
   */
  serviceUrl?: string;
  /** If true, rewrites HTML links in sent emails to track link clicks */
  trackClicks?: boolean;
  /** If true, inserts a tracking beacon image in HTML emails to monitor email opens */
  trackOpens?: boolean;
  /** Sets the indexing method for IMAP accounts. Choose "full" to build a complete index that tracks deleted and updated emails, or "fast" to only detect newly received emails */
  imapIndexer?: ImapIndexer;
  /** Enables Gmail category tab detection for incoming emails when set to true. Applies only to Gmail IMAP accounts; Gmail API accounts automatically include category information */
  resolveGmailCategories?: boolean;
  /** If true, allows connections to servers with insecure or untrusted SSL/TLS certificates. Applies to both IMAP and SMTP connections. Use with caution */
  ignoreMailCertErrors?: boolean;
  /** If true, generates a summary of the email using OpenAI ChatGPT */
  generateEmailSummary?: boolean;
  /**
   * OpenAI API key
   * @example "verysecret"
   */
  openAiAPIKey?: string;
  /**
   * OpenAI API model
   * @example "gpt-3.5-turbo"
   */
  openAiModel?: string;
  /**
   * OpenAI API URL
   * @example "https://api.openai.com"
   */
  openAiAPIUrl?: string;
  /**
   * OpenAI API model for chat
   * @example "gpt-3.5-turbo"
   */
  documentStoreChatModel?: string;
  /**
   * Controls the randomness of the OpenAI API responses. Higher values (up to 2) make output more random, while lower values make it more focused and deterministic
   * @min 0
   * @max 2
   * @example 0.8
   */
  openAiTemperature?: number;
  /**
   * Controls the cumulative probability for nucleus sampling in the OpenAI API. A value between 0 and 1
   * @min 0
   * @max 1
   * @example 0.1
   */
  openAiTopP?: number;
  /**
   * Prompt to send to the LLM for analyzing emails
   * @maxLength 6144
   * @example "You are an assistant scanning incoming emails..."
   */
  openAiPrompt?: string;
  /** If true, generates vector embeddings for the email */
  openAiGenerateEmbeddings?: boolean;
  /** If true, sends "New Email" webhooks for incoming emails only */
  inboxNewOnly?: boolean;
  /**
   * HMAC secret for signing public requests
   * @example "verysecret"
   */
  serviceSecret?: string;
  /**
   * The URL of the authentication server to fetch authentication data from
   * @example "https://myservice.com/authentication"
   */
  authServer?: string;
  /** If true, the global proxy is enabled */
  proxyEnabled?: boolean;
  /**
   * Proxy URL
   * @example "socks://proxy.example.com:1080"
   */
  proxyUrl?: string;
  /**
   * Hostname to use for the SMTP EHLO/HELO greeting
   * @example "my.proxy.tld"
   */
  smtpEhloName?: string;
  /** Include message text in webhook notifications */
  notifyText?: boolean;
  /** Pre-process HTML in webhook notifications to be web-safe */
  notifyWebSafeHtml?: boolean;
  /**
   * Maximum size of message text to include in webhook notifications (in bytes)
   * @min 0
   */
  notifyTextSize?: number;
  /** Include calendar events in webhook notifications */
  notifyCalendarEvents?: boolean;
  logs?: LogSettings;
  /** How to select local IP address for IMAP connections */
  imapStrategy?: ImapStrategy;
  /** How to select local IP address for SMTP connections */
  smtpStrategy?: SmtpStrategy;
  /** A list of pooled local IP addresses that can be used for IMAP and SMTP connections */
  localAddresses?: LocalAddresses;
  /** Enable SMTP interface */
  smtpServerEnabled?: boolean;
  /**
   * SMTP interface port
   * @min 0
   * @max 65536
   */
  smtpServerPort?: number;
  /** SMTP host to bind to */
  smtpServerHost?: string;
  /** Enable PROXY protocol for SMTP server */
  smtpServerProxy?: boolean;
  /** Enable SMTP authentication */
  smtpServerAuthEnabled?: boolean;
  /**
   * SMTP client password. Set to `null` to disable
   * @maxLength 1024
   */
  smtpServerPassword?: string;
  /** Enable TLS for the SMTP interface. Requires a valid certificate */
  smtpServerTLSEnabled?: boolean;
  /** Enable IMAP proxy interface */
  imapProxyServerEnabled?: boolean;
  /**
   * IMAP proxy interface port
   * @min 0
   * @max 65536
   */
  imapProxyServerPort?: number;
  /** IMAP proxy host to bind to */
  imapProxyServerHost?: string;
  /** Enable PROXY protocol for the IMAP proxy server */
  imapProxyServerProxy?: boolean;
  /**
   * IMAP proxy client password. Set to `null` to disable
   * @maxLength 1024
   */
  imapProxyServerPassword?: string;
  /** Enable TLS for the IMAP proxy interface. Requires a valid certificate */
  imapProxyServerTLSEnabled?: boolean;
  /**
   * Number of completed or failed queue entries to keep
   * @min 0
   */
  queueKeep?: number;
  /**
   * Number of times to retry sending an email before it is considered as failed
   * @min 0
   */
  deliveryAttempts?: number;
  /**
   * HTML code displayed at the top of public pages like the hosted authentication form
   * @maxLength 1048576
   */
  templateHeader?: string;
  /**
   * HTML code added to the HEAD tag of public pages like the hosted authentication form
   * @maxLength 1048576
   */
  templateHtmlHead?: string;
  /**
   * JSON object to be used as the `env` variable in pre-processing scripts
   * @maxLength 1048576
   * @example "{"key": "value"}"
   */
  scriptEnv?: string;
  /** Enable support for reverse proxies */
  enableApiProxy?: boolean;
  /** Default locale identifier */
  locale?: Locale;
  /**
   * Default timezone identifier
   * @maxLength 100
   * @example "Europe/Tallinn"
   */
  timezone?: string;
  /**
   * Filter function for LLM pre-processing (JavaScript)
   * @maxLength 524288
   * @example "return true; // passes all emails"
   */
  openAiPreProcessingFn?: string;
}

/** Counts of accounts in different connection states */
export interface ConnectionsStats {
  /**
   * Accounts not yet initialized
   * @example 2
   */
  init?: number;
  /**
   * Successfully connected accounts
   * @example 8
   */
  connected?: number;
  /**
   * Connection is being established
   * @example 7
   */
  connecting?: number;
  /**
   * Authentication failed
   * @example 3
   */
  authenticationError?: number;
  /**
   * Connection failed due to technical error
   * @example 5
   */
  connectError?: number;
  /**
   * Accounts without valid IMAP settings
   * @example 0
   */
  unset?: number;
  /**
   * IMAP connection was closed
   * @example 1
   */
  disconnected?: number;
}

export type CounterStats = object;

export interface SettingsResponse {
  /**
   * EmailEngine version number
   * @example "2.49.4"
   */
  version?: string;
  /**
   * EmailEngine license
   * @example "LICENSE_EMAILENGINE"
   */
  license?: string;
  /**
   * Number of registered accounts
   * @example 26
   */
  accounts?: number;
  /**
   * Node.js Version
   * @example "16.10.0"
   */
  node?: string;
  /**
   * Redis Version
   * @example "6.2.4"
   */
  redis?: string;
  /** Counts of accounts in different connection states */
  connections?: ConnectionsStats;
  counters?: CounterStats;
}

/**
 * Markup language for HTML ("html" or "markdown")
 * @default "html"
 */
export enum Format {
  Html = 'html',
  Markdown = 'markdown'
}

export interface AccountTemplate {
  /**
   * Template ID
   * @maxLength 256
   * @example "AAABgS-UcAYAAAABAA"
   */
  id: string;
  /**
   * Name of the template
   * @maxLength 256
   * @example "Transaction receipt"
   */
  name: string;
  /**
   * Optional description of the template
   * @maxLength 1024
   * @example "Something about the template"
   */
  description?: string;
  /** Markup language for HTML ("html" or "markdown") */
  format?: Format;
  /**
   * The time this template was created
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created?: string;
  /**
   * The time this template was last updated
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  updated?: string;
}

export type AccountTemplatesList = AccountTemplate[];

export interface AccountTemplatesResponse {
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  templates?: AccountTemplatesList;
}

export interface RootTokensItem {
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /**
   * Token description
   * @maxLength 1024
   * @example "Token description"
   */
  description: string;
  /**
   * Related metadata in JSON format
   * @maxLength 1048576
   * @example "{"example": "value"}"
   */
  metadata?: string;
  /**
   * IP address of the requester
   * @example "127.0.0.1"
   */
  ip?: string;
}

export type RootTokensEntries = RootTokensItem[];

export interface RootTokensResponse {
  tokens?: RootTokensEntries;
}

export interface WebhookRoutesListEntry {
  /**
   * Webhook ID
   * @maxLength 256
   * @example "AAABgS-UcAYAAAABAA"
   */
  id: string;
  /**
   * Name of the route
   * @maxLength 256
   * @example "Send to Slack"
   */
  name: string;
  /**
   * Optional description of the webhook route
   * @maxLength 1024
   * @example "Something about the route"
   */
  description?: string;
  /**
   * The time this route was created
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created?: string;
  /**
   * The time this route was last updated
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  updated?: string;
  /**
   * Is the route enabled
   * @example true
   */
  enabled?: boolean;
  /**
   * The URL where webhooks will be sent
   * @example "https://myservice.com/imap/webhooks"
   */
  targetUrl?: string;
  /**
   * How many times this route has been applied
   * @example 123
   */
  tcount?: number;
}

export type WebhookRoutesList = WebhookRoutesListEntry[];

export interface WebhookRoutesListResponse {
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  webhooks?: WebhookRoutesList;
}

/**
 * Check changes only on selected paths. Either a single string path or an array of paths. Can use references like "\Sent" or "\Inbox". Set to `null` to unset.
 * @example ["*"]
 */
export type AccountPath = string[];

/**
 * Sets the account-specific IMAP indexing method. Choose "full" to build a complete index that tracks deleted and updated emails, or "fast" to only detect newly received emails. Defaults to global setting.
 * @example "full"
 */
export enum IMAPIndexer {
  Full = 'full',
  Fast = 'fast'
}

/**
 * An array of mailbox paths. If set, then EmailEngine opens additional IMAP connections against these paths to detect changes faster. Note: Connection counts are usually highly limited.
 * @example ["[Gmail]/Spam","\\Sent"]
 */
export type SubconnectionPaths = string[];

/**
 * OAuth2 access token for the email account
 * @maxLength 16384
 * @example false
 */
export enum AccessToken {
  False = 'false'
}

/** Authentication credentials for the email account */
export enum Authentication {
  False = 'false'
}

/** Optional TLS configuration settings */
export interface TLS {
  /**
   * Whether to reject invalid TLS certificates
   * @default true
   * @example true
   */
  rejectUnauthorized?: boolean;
  /**
   * The minimum TLS version to allow
   * @maxLength 256
   * @example "TLSv1.2"
   */
  minVersion?: string;
}

/** IMAP configuration */
export interface IMAPResponse {
  /** Authentication credentials for the email account */
  auth?: Authentication;
  /**
   * Set to true to use an external authentication server instead of providing username/password directly
   * @example false
   */
  useAuthServer?: boolean;
  /**
   * The hostname of the IMAP server to connect to
   * @example "imap.gmail.com"
   */
  host?: string;
  /**
   * The port number of the IMAP service
   * @min 1
   * @max 65535
   * @example 993
   */
  port?: number;
  /**
   * Whether to use TLS for the connection (usually true for port 993)
   * @default false
   * @example true
   */
  secure?: boolean;
  /** Optional TLS configuration settings */
  tls?: TLS;
  /**
   * Delay in seconds between full resynchronizations
   * @default 900
   * @example 900
   */
  resyncDelay?: number;
  /**
   * Set to true to disable IMAP handling for this account
   * @example false
   */
  disabled?: boolean;
  /**
   * Folder where sent messages are uploaded. Defaults to the account's 'Sent Mail' folder. Set to `null` to unset.
   * @maxLength 1024
   * @example "Sent Mail"
   */
  sentMailPath?: string;
  /**
   * Folder for drafts. Defaults to the account's 'Drafts' folder. Set to `null` to unset.
   * @maxLength 1024
   * @example "Drafts"
   */
  draftsMailPath?: string;
  /**
   * Folder for spam messages. Defaults to the account's 'Junk' folder. Set to `null` to unset.
   * @maxLength 1024
   * @example "Junk"
   */
  junkMailPath?: string;
  /**
   * Folder for deleted emails. Defaults to the account's 'Trash' folder. Set to `null` to unset.
   * @maxLength 1024
   * @example "Trash"
   */
  trashMailPath?: string;
  /**
   * Folder for archived emails. Defaults to the account's 'Archive' folder. Set to `null` to unset.
   * @maxLength 1024
   * @example "Archive"
   */
  archiveMailPath?: string;
}

/** SMTP configuration */
export interface SMTPResponse {
  /** Authentication credentials for the email account */
  auth?: Authentication;
  /**
   * Set to true to use an external authentication server instead of providing username/password directly
   * @example false
   */
  useAuthServer?: boolean;
  /**
   * The hostname of the SMTP server to connect to
   * @example "smtp.gmail.com"
   */
  host: string;
  /**
   * The port number of the SMTP service
   * @min 1
   * @max 65535
   * @example 587
   */
  port: number;
  /**
   * Whether to use TLS for the connection (usually true for port 465)
   * @default false
   * @example false
   */
  secure?: boolean;
  /** Optional TLS configuration settings */
  tls?: TLS;
}

export interface OAuth2Authentication {
  /**
   * Username of the shared mailbox (Microsoft 365 only)
   * @maxLength 256
   * @example "shared.mailbox@outlook.com"
   */
  delegatedUser?: string;
  /**
   * Account ID of another account to authenticate the shared mailbox. If provided, EmailEngine uses the credentials of this account instead of the current one.
   * @maxLength 256
   * @example "user123"
   */
  delegatedAccount?: string;
  /**
   * Email account username
   * @maxLength 256
   * @example "user@outlook.com"
   */
  user?: string;
}

/** OAuth2 configuration */
export interface Oauth2Response {
  /**
   * Set to true to obtain a redirect link to the OAuth2 consent screen.
   * @example false
   */
  authorize?: boolean;
  /**
   * URL to redirect the user after the OAuth2 consent screen. Only valid if `authorize` is true.
   * @example "https://myapp/account/settings.php"
   */
  redirectUrl?: string;
  /**
   * OAuth2 Application ID for an application configured in EmailEngine.
   * @maxLength 256
   * @example "AAABhaBPHscAAAAH"
   */
  provider?: string;
  auth?: OAuth2Authentication;
  /**
   * Set to true to use an external authentication server instead of EmailEngine managing access tokens.
   * @example false
   */
  useAuthServer?: boolean;
  /**
   * OAuth2 access token for the email account.
   * @maxLength 16384
   * @example "ya29.a0ARrdaM8a..."
   */
  accessToken?: string;
  /**
   * OAuth2 refresh token for the email account.
   * @maxLength 16384
   * @example "1//09Ie3CtORQYm..."
   */
  refreshToken?: string;
  /**
   * Specifies the Outlook account type: 'consumers', 'organizations', 'common', or an organization ID.
   * @maxLength 1024
   * @example "consumers"
   */
  authority?: string;
  /**
   * Expiration date and time of the access token.
   * @format date-time
   * @example "2021-03-22T13:13:31.000Z"
   */
  expires?: string;
}

/**
 * Informational account state
 * @example "connected"
 */
export enum AccountInfoState {
  Init = 'init',
  Syncing = 'syncing',
  Connecting = 'connecting',
  Connected = 'connected',
  AuthenticationError = 'authenticationError',
  ConnectError = 'connectError',
  Unset = 'unset',
  Disconnected = 'disconnected'
}

/** Was the last SMTP attempt successful or not */
export enum SMTPStatusStatus {
  Ok = 'ok',
  Error = 'error'
}

/** Information about the last SMTP connection attempt */
export interface SMTPInfoStatus {
  /**
   * When was the status for SMTP connection last updated
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  created?: string;
  /** Was the last SMTP attempt successful or not */
  status?: SMTPStatusStatus;
  /**
   * SMTP response message for delivery attempt
   * @example "250 OK"
   */
  response?: string;
  /**
   * Error information
   * @example "Authentication failed"
   */
  description?: string;
  /**
   * Error status code
   * @example 500
   */
  responseCode?: number;
  /**
   * Error type identifier
   * @example "EAUTH"
   */
  code?: string;
  /**
   * SMTP command that failed
   * @example "AUTH PLAIN"
   */
  command?: string;
}

/** Custom HTTP headers added to webhook requests */
export type AccountWebhooksCustomHeaders = WebhooksCustomHeader[];

/**
 * OAuth2 Base Scopes
 * @example "imap"
 */
export enum BaseScopes {
  Imap = 'imap',
  Api = 'api',
  Pubsub = 'pubsub'
}

/** Account quota information if query argument quota=true. This value will be false if the server does not provide quota information. */
export enum AccountQuota {
  False = 'false'
}

export interface AccountResponse {
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /**
   * Display name for the account
   * @maxLength 256
   * @example "My Email Account"
   */
  name?: string;
  /**
   * Default email address of the account
   * @example "user@example.com"
   */
  email?: string;
  /**
   * Copy submitted messages to Sent folder
   * @example true
   */
  copy?: boolean;
  /**
   * Store recent logs
   * @example false
   */
  logs?: boolean;
  /**
   * Send webhooks for messages starting from provided date. The default is the account creation date. Only applies for IMAP accounts.
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  notifyFrom?: string;
  /** Check changes only on selected paths. Either a single string path or an array of paths. Can use references like "\Sent" or "\Inbox". Set to `null` to unset. */
  path?: AccountPath;
  /** Sets the account-specific IMAP indexing method. Choose "full" to build a complete index that tracks deleted and updated emails, or "fast" to only detect newly received emails. Defaults to global setting. */
  imapIndexer?: IMAPIndexer;
  /** An array of mailbox paths. If set, then EmailEngine opens additional IMAP connections against these paths to detect changes faster. Note: Connection counts are usually highly limited. */
  subconnections?: SubconnectionPaths;
  /**
   * Account-specific webhook URL
   * @example "https://myservice.com/imap/webhooks"
   */
  webhooks?: string;
  /**
   * Proxy URL
   * @example "socks://proxy.example.com:1080"
   */
  proxy?: string;
  /**
   * Hostname to use for the SMTP EHLO/HELO greeting
   * @example "my.proxy.tld"
   */
  smtpEhloName?: string;
  /** IMAP configuration */
  imap?: IMAPResponse;
  /** SMTP configuration */
  smtp?: SMTPResponse;
  /** OAuth2 configuration */
  oauth2?: Oauth2Response;
  /** Informational account state */
  state?: AccountInfoState;
  /** Information about the last SMTP connection attempt */
  smtpStatus?: SMTPInfoStatus;
  /** Custom HTTP headers added to webhook requests */
  webhooksCustomHeaders?: AccountWebhooksCustomHeaders;
  /**
   * Optional locale
   * @maxLength 100
   * @example "fr"
   */
  locale?: string;
  /**
   * Optional timezone
   * @maxLength 100
   * @example "Europe/Tallinn"
   */
  tz?: string;
  /** Account type */
  type: Type;
  /**
   * OAuth2 application ID
   * @maxLength 256
   * @example "AAABhaBPHscAAAAH"
   */
  app?: string;
  /** OAuth2 Base Scopes */
  baseScopes?: BaseScopes;
  counters?: AccountCounters;
  /** Account quota information if query argument quota=true. This value will be false if the server does not provide quota information. */
  quota?: AccountQuota;
  /**
   * Last sync time
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  syncTime?: string;
  lastError?: AccountErrorEntry;
}

export interface BlocklistListResponseItem {
  /**
   * Listed email address
   * @example "user@example.com"
   */
  recipient: string;
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /**
   * Message ID
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /**
   * Which mechanism was used to add the entry
   * @example "api"
   */
  source?: string;
  /**
   * Why this entry was added
   * @example "api"
   */
  reason?: string;
  /** Which IP address triggered the entry */
  remoteAddress?: string;
  /**
   * Which user agent triggered the entry
   * @example "Mozilla/5.0 (Macintosh)"
   */
  userAgent?: string;
  /**
   * The time this entry was added or updated
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created: string;
}

export type BlocklistListEntries = BlocklistListResponseItem[];

export interface BlocklistListResponse {
  /**
   * List ID
   * @maxLength 256
   * @example "example"
   */
  listId: string;
  /**
   * How many matching entries
   * @example 120
   */
  total?: number;
  /**
   * Current page (0-based index)
   * @example 0
   */
  page?: number;
  /**
   * Total page count
   * @example 24
   */
  pages?: number;
  addresses?: BlocklistListEntries;
}

export interface GatewayResponse {
  /**
   * Gateway ID
   * @maxLength 256
   * @example "example"
   */
  gateway: string;
  /**
   * Display name for the gateway
   * @maxLength 256
   * @example "My Email Gateway"
   */
  name: string;
  /**
   * Count of email deliveries using this gateway
   * @example 100
   */
  deliveries?: number;
  /**
   * Last delivery time
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  lastUse?: string;
  /** @maxLength 1024 */
  user?: string;
  /** @maxLength 1024 */
  pass?: string;
  /**
   * Hostname to connect to
   * @example "smtp.gmail.com"
   */
  host?: string;
  /**
   * Service port number
   * @min 1
   * @max 65536
   * @example 465
   */
  port?: number;
  /**
   * Should connection use TLS. Usually true for port 465
   * @default false
   * @example true
   */
  secure?: boolean;
  lastError?: AccountErrorEntry;
}

export interface ApplicationResponse {
  /**
   * OAuth2 application ID
   * @maxLength 256
   * @example "AAABhaBPHscAAAAH"
   */
  id: string;
  /**
   * Display name for the app
   * @maxLength 256
   * @example "My OAuth2 App"
   */
  name?: string;
  /**
   * OAuth2 application description
   * @maxLength 1024
   * @example "App description"
   */
  description?: string;
  /**
   * Title for the application button
   * @maxLength 256
   * @example "App title"
   */
  title?: string;
  /** OAuth2 provider */
  provider: OAuth2Provider;
  /**
   * Is the application enabled
   * @example true
   */
  enabled?: boolean;
  /**
   * `true` for older OAuth2 apps set via the settings endpoint
   * @example true
   */
  legacy?: boolean;
  /**
   * The time this entry was added
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created: string;
  /**
   * The time this entry was updated
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  updated?: string;
  /**
   * Is the application listed in the hosted authentication form
   * @example true
   */
  includeInListing?: boolean;
  /**
   * Client or Application ID for 3-legged OAuth2 applications
   * @example "4f05f488-d858-4f2c-bd12-1039062612fe"
   */
  clientId?: string;
  /**
   * Client secret for 3-legged OAuth2 applications. Actual value is not revealed.
   * @example "******"
   */
  clientSecret?: string;
  /**
   * Authorization tenant value for Outlook OAuth2 applications
   * @example "common"
   */
  authority?: string;
  /**
   * Redirect URL for 3-legged OAuth2 applications
   * @example "https://myservice.com/oauth"
   */
  redirectUrl?: string;
  /** Google Cloud Project ID */
  googleProjectId?: GoogleProjectId;
  /**
   * Show only Google Workspace accounts on the OAuth2 login page
   * @example false
   */
  googleWorkspaceAccounts?: boolean;
  /**
   * Service Client Email for 2-legged OAuth2 applications
   * @example "name@project-123.iam.gserviceaccount.com"
   */
  serviceClientEmail?: string;
  /**
   * Service client ID for 2-legged OAuth2 applications
   * @example "9103965568215821627203"
   */
  serviceClient?: string;
  /**
   * PEM formatted service secret for 2-legged OAuth2 applications. Actual value is not revealed.
   * @example "******"
   */
  serviceKey?: string;
  /**
   * The number of accounts registered with this application. Not available for legacy apps.
   * @example 12
   */
  accounts?: number;
  lastError?: AccountErrorEntry;
}

export interface AccountTokenResponse {
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /**
   * Username
   * @maxLength 256
   * @example "user@example.com"
   */
  user: string;
  /**
   * Access Token
   * @maxLength 256
   * @example "aGVsbG8gd29ybGQ="
   */
  accessToken: string;
  /** OAuth2 provider */
  provider: OAuth2Provider;
}

/**
 * Special use flag of the mailbox
 * @example "\Sent"
 */
export enum MailboxSpecialUse {
  ValueAll = 'All',
  ValueArchive = 'Archive',
  ValueDrafts = 'Drafts',
  ValueFlagged = 'Flagged',
  ValueJunk = 'Junk',
  ValueSent = 'Sent',
  ValueTrash = 'Trash',
  ValueInbox = 'Inbox'
}

/**
 * Source of the specialUse flag. Can be "user" (set via API), "extension" (provided by the email server), or "name" (determined based on the folder name).
 * @example "extension"
 */
export enum MailboxSpecialUseSource {
  User = 'user',
  Extension = 'extension',
  Name = 'name'
}

/** Optional mailbox status information */
export interface MailboxResponseStatus {
  /**
   * Number of messages in the mailbox as reported by the STATUS command
   * @example 120
   */
  messages?: number;
  /**
   * Number of unseen messages in the mailbox as reported by the STATUS command
   * @example 120
   */
  unseen?: number;
}

export interface MailboxResponseItem {
  /**
   * Full path to the mailbox
   * @example "Kalender/S&APw-nnip&AOQ-evad"
   */
  path: string;
  /**
   * Hierarchy delimiter used in the mailbox path
   * @example "/"
   */
  delimiter?: string;
  /**
   * Full path to the parent mailbox
   * @example "Kalender"
   */
  parentPath: string;
  /**
   * Name of the mailbox
   * @example "Sünnipäevad"
   */
  name: string;
  /**
   * True if the mailbox was returned in response to the LIST command
   * @example true
   */
  listed?: boolean;
  /**
   * True if the mailbox was returned in response to the LSUB command
   * @example true
   */
  subscribed?: boolean;
  /** Special use flag of the mailbox */
  specialUse?: MailboxSpecialUse;
  /** Source of the specialUse flag. Can be "user" (set via API), "extension" (provided by the email server), or "name" (determined based on the folder name). */
  specialUseSource?: MailboxSpecialUseSource;
  /**
   * True if adding subfolders is not allowed
   * @example false
   */
  noInferiors?: boolean;
  /**
   * Number of messages in the mailbox
   * @example 120
   */
  messages?: number;
  /**
   * Next expected UID
   * @example 121
   */
  uidNext?: number;
  /** Optional mailbox status information */
  status?: MailboxResponseStatus;
}

export type MailboxesList = MailboxResponseItem[];

export interface MailboxesFilterResponse {
  mailboxes?: MailboxesList;
}

/**
 * The sender's email address
 * @example {"name":"From Me","address":"sender@example.com"}
 */
export interface FromAddress {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

export interface RcptAddressEntry {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

/** An array of email address entries */
export type AddressList = RcptAddressEntry[];

/** List of IMAP flags associated with the message */
export type FlagList = string[];

/** List of Gmail labels associated with the message */
export type LabelList = string[];

export interface AttachmentEntry {
  /**
   * Unique identifier for the attachment
   * @example "AAAAAgAACrIyLjI"
   */
  id?: string;
  /**
   * MIME type of the attachment
   * @example "image/gif"
   */
  contentType?: string;
  /**
   * Size of the attachment in bytes when encoded (actual file size may be smaller due to encoding)
   * @example 48
   */
  encodedSize?: number;
  /**
   * Indicates if the attachment is an embedded image used in an HTML `<img>` tag
   * @example true
   */
  embedded?: boolean;
  /**
   * Indicates if the attachment should be displayed inline in the message preview
   * @example true
   */
  inline?: boolean;
  /**
   * Content ID used for embedded images
   * @example "<unique-image-id@localhost>"
   */
  contentId?: string;
  /**
   * Filename of the attachment
   * @example "image.png"
   */
  filename?: string;
  /**
   * Calendar event method if the attachment is an iCalendar event
   * @example "REQUEST"
   */
  method?: string;
}

/** Array of attachment objects */
export type AttachmentList = AttachmentEntry[];

/** Size of the encoded message parts in bytes */
export interface EncodedSize {
  /**
   * Size in bytes of the plain text content
   * @example 1013
   */
  plain?: number;
  /**
   * Size in bytes of the HTML content
   * @example 1013
   */
  html?: number;
}

export interface TextInfo {
  /**
   * Identifier for fetching the message text content
   * @example "AAAAAgAACqiTkaExkaEykA"
   */
  id?: string;
  /** Size of the encoded message parts in bytes */
  encodedSize?: EncodedSize;
}

export interface MessageListEntry {
  /**
   * Unique identifier for the message
   * @example "AAAAAgAACrI"
   */
  id?: string;
  /**
   * Unique identifier of the message within the mailbox
   * @example 12345
   */
  uid?: number;
  /**
   * Globally unique email ID assigned by the server (if supported)
   * @example "1694937972638499881"
   */
  emailId?: string;
  /**
   * Unique identifier for the thread to which this message belongs (if supported by the server)
   * @example "1694936993596975454"
   */
  threadId?: string;
  /**
   * Date when the message was added to the mailbox (INTERNALDATE)
   * @format date-time
   * @example "2021-03-22T13:13:31.000Z"
   */
  date?: string;
  /**
   * True if this message is marked as a draft
   * @example false
   */
  draft?: boolean;
  /**
   * True if the message is marked as unseen (unread)
   * @example true
   */
  unseen?: boolean;
  /**
   * True if this message is flagged
   * @example true
   */
  flagged?: boolean;
  /**
   * Size of the message in bytes
   * @example 1040
   */
  size?: number;
  /**
   * Subject of the message, decoded into Unicode
   * @example "What a wonderful message"
   */
  subject?: string;
  /** The sender's email address */
  from?: FromAddress;
  /** An array of email address entries */
  replyTo?: AddressList;
  /** An array of email address entries */
  to?: AddressList;
  /** An array of email address entries */
  cc?: AddressList;
  /** An array of email address entries */
  bcc?: AddressList;
  /**
   * The Message-ID header value of the message
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /**
   * The In-Reply-To header value (Message-ID of the message this is a reply to)
   * @example "<7JBUMt0WOn+_==MOkaCOQ@mail.gmail.com>"
   */
  inReplyTo?: string;
  /** List of IMAP flags associated with the message */
  flags?: FlagList;
  /** List of Gmail labels associated with the message */
  labels?: LabelList;
  /** Array of attachment objects */
  attachments?: AttachmentList;
  text?: TextInfo;
  /** A short text preview of the message content */
  preview?: string;
}

export type PageMessages = MessageListEntry[];

export interface MessageList {
  /**
   * Total number of matching messages. This number is exact for IMAP accounts, but approximate for Gmail API accounts.
   * @example 120
   */
  total?: number;
  /**
   * Current page index (0-based)
   * @example 0
   */
  page?: number;
  /**
   * Total number of pages. This number is exact for IMAP accounts, but approximate for Gmail API accounts.
   * @example 24
   */
  pages?: number;
  /**
   * Paging cursor for the next page. Continue paging until this value is null.
   * @example "imap_kcQIji3UobDDTxc"
   */
  nextPageCursor?: string;
  /**
   * Paging cursor for the previous page
   * @example "imap_kcQIji3UobDDTxc"
   */
  prevPageCursor?: string;
  messages?: PageMessages;
}

/** DKIM results */
export type Dkim = object;

/** SPF results */
export type Spf = object;

/** DMARC results */
export type Dmarc = object;

/** BIMI results */
export type Bimi = object;

/** ARC results */
export type Arc = object;

/** Primary DKIM signature. `status.aligned` should be set, otherwise DKIM check should not be considered as passed. */
export type MainSig = object;

export interface DeliveryCheckResponse {
  /**
   * Was the test completed
   * @example true
   */
  success?: boolean;
  /** DKIM results */
  dkim?: Dkim;
  /** SPF results */
  spf?: Spf;
  /** DMARC results */
  dmarc?: Dmarc;
  /** BIMI results */
  bimi?: Bimi;
  /** ARC results */
  arc?: Arc;
  /** Primary DKIM signature. `status.aligned` should be set, otherwise DKIM check should not be considered as passed. */
  mainSig?: MainSig;
}

/**
 * Queue ID
 * @example "notify"
 */
export enum Queue {
  Notify = 'notify',
  Submit = 'submit',
  Documents = 'documents'
}

export interface QueueJobs {
  /**
   * Jobs that are currently being processed
   * @example 123
   */
  active?: number;
  /**
   * Jobs that are processed in the future
   * @example 123
   */
  delayed?: number;
  /**
   * Jobs that would be processed once queue processing is resumed
   * @example 123
   */
  paused?: number;
  /**
   * Jobs that should be processed, but are waiting until there are any free handlers
   * @example 123
   */
  waiting?: number;
}

export interface SettingsQueueResponse {
  /** Queue ID */
  queue: Queue;
  jobs?: QueueJobs;
  /**
   * Is the queue paused or not
   * @example false
   */
  paused?: boolean;
}

export interface RequestTemplateContent {
  /**
   * Message subject
   * @maxLength 10240
   * @example "What a wonderful message"
   */
  subject?: string;
  /**
   * Plaintext content of the message
   * @maxLength 5242880
   * @example "Hello from myself!"
   */
  text?: string;
  /**
   * HTML content of the message
   * @maxLength 5242880
   * @example "<p>Hello from myself!</p>"
   */
  html?: string;
  /**
   * Preview text that appears in the inbox after the subject line
   * @maxLength 1024
   * @example "Welcome to our newsletter!"
   */
  previewText?: string;
  /** Markup language for HTML ("html" or "markdown") */
  format?: Format;
}

export interface AccountTemplateResponse {
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /**
   * Template ID
   * @maxLength 256
   * @example "AAABgS-UcAYAAAABAA"
   */
  id: string;
  /**
   * Name of the template
   * @maxLength 256
   * @example "Transaction receipt"
   */
  name: string;
  /**
   * Optional description of the template
   * @maxLength 1024
   * @example "Something about the template"
   */
  description?: string;
  /** Markup language for HTML ("html" or "markdown") */
  format?: Format;
  /**
   * The time this template was created
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created?: string;
  /**
   * The time this template was last updated
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  updated?: string;
  content?: RequestTemplateContent;
}

/**
 * HTTP referrer allowlist for API requests
 * @default false
 * @example ["*web.domain.org/*","*.domain.org/*","https://domain.org/*"]
 */
export enum ReferrerAllowlist {
  False = 'false'
}

/**
 * IP address allowlist
 * @default false
 * @example ["1.2.3.4","5.6.7.8","127.0.0.0/8"]
 */
export enum AddressAllowlist {
  False = 'false'
}

/**
 * Rate limits for the token
 * @default false
 * @example {"maxRequests":20,"timeWindow":2}
 */
export enum AddressRateLimit {
  False = 'false'
}

/** Access restrictions */
export enum TokenRestrictions {
  False = 'false'
}

export interface AccountTokensItem {
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /**
   * Token description
   * @maxLength 1024
   * @example "Token description"
   */
  description: string;
  /**
   * Related metadata in JSON format
   * @maxLength 1048576
   * @example "{"example": "value"}"
   */
  metadata?: string;
  /** Access restrictions */
  restrictions?: TokenRestrictions;
  /**
   * IP address of the requester
   * @example "127.0.0.1"
   */
  ip?: string;
}

export type AccountTokensEntries = AccountTokensItem[];

export interface AccountsTokensResponse {
  tokens?: AccountTokensEntries;
}

export interface WebhookRouteContent {
  /**
   * Filter function
   * @example "return true;"
   */
  fn?: string;
  /**
   * Mapping function
   * @example "payload.ts = Date.now(); return payload;"
   */
  map?: string;
}

export interface WebhookRouteResponse {
  /**
   * Webhook ID
   * @maxLength 256
   * @example "AAABgS-UcAYAAAABAA"
   */
  id: string;
  /**
   * Name of the route
   * @maxLength 256
   * @example "Send to Slack"
   */
  name: string;
  /**
   * Optional description of the webhook route
   * @maxLength 1024
   * @example "Something about the route"
   */
  description?: string;
  /**
   * The time this route was created
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  created?: string;
  /**
   * The time this route was last updated
   * @format date-time
   * @example "2021-02-17T13:43:18.860Z"
   */
  updated?: string;
  /**
   * Is the route enabled
   * @example true
   */
  enabled?: boolean;
  /**
   * The URL where webhooks will be sent
   * @example "https://myservice.com/imap/webhooks"
   */
  targetUrl?: string;
  /**
   * How many times this route has been applied
   * @example 123
   */
  tcount?: number;
  content?: WebhookRouteContent;
}

export interface TextResponse {
  /**
   * Plaintext content
   * @example "Hello world"
   */
  plain?: string;
  /**
   * HTML content
   * @example "<p>Hello world</p>"
   */
  html?: string;
  /**
   * Is the current text output capped or not
   * @example false
   */
  hasMore?: boolean;
}

/**
 * An object where each header field name is a key, and the value is an array of header field values. Not available for MS Graph API emails.
 * @example {"from":["From Me <sender@example.com>"],"subject":["What a wonderful message"]}
 */
export type MessageHeaders = object;

export interface TextInfoDetails {
  /**
   * Identifier for fetching the message text content
   * @example "AAAAAgAACqiTkaExkaEykA"
   */
  id?: string;
  /** Size of the encoded message parts in bytes */
  encodedSize?: EncodedSize;
  /**
   * Plaintext content of the message
   * @example "Hello from myself!"
   */
  plain?: string;
  /**
   * HTML content of the message
   * @example "<p>Hello from myself!</p>"
   */
  html?: string;
  /**
   * True if there is more content available beyond what was fetched; false if the entire content is included
   * @example false
   */
  hasMore?: boolean;
}

export interface BounceResponse {
  /**
   * Server response message
   * @example "550 5.1.1 No such user"
   */
  message?: string;
  /**
   * Status code from the server
   * @example "5.1.1"
   */
  status?: string;
}

export interface BounceEntry {
  /**
   * Identifier of the bounce email message
   * @maxLength 256
   * @example "AAAAAQAACnA"
   */
  message: string;
  /**
   * Email address of the recipient that bounced
   * @example "recipient@example.com"
   */
  recipient?: string;
  /**
   * Action taken, e.g., "failed"
   * @example "failed"
   */
  action?: string;
  response?: BounceResponse;
  /**
   * Time the bounce was registered by EmailEngine
   * @format date-time
   * @example "2021-03-22T13:13:31.000Z"
   */
  date?: string;
}

export type BounceList = BounceEntry[];

/**
 * Special use flag of the mailbox containing this message
 * @example "\Sent"
 */
export enum Model1 {
  ValueAll = 'All',
  ValueArchive = 'Archive',
  ValueDrafts = 'Drafts',
  ValueFlagged = 'Flagged',
  ValueJunk = 'Junk',
  ValueSent = 'Sent',
  ValueTrash = 'Trash',
  ValueInbox = 'Inbox'
}

/**
 * Special use flag associated with the message, indicating the mailbox special use
 * @example "\Sent"
 */
export enum MessageSpecialUse {
  ValueDrafts = 'Drafts',
  ValueJunk = 'Junk',
  ValueSent = 'Sent',
  ValueTrash = 'Trash',
  ValueInbox = 'Inbox'
}

export interface MessageDetails {
  /**
   * Unique identifier for the message
   * @example "AAAAAgAACrI"
   */
  id?: string;
  /**
   * Unique identifier of the message within the mailbox
   * @example 12345
   */
  uid?: number;
  /**
   * Globally unique email ID assigned by the server (if supported)
   * @example "1694937972638499881"
   */
  emailId?: string;
  /**
   * Unique identifier for the thread to which this message belongs (if supported by the server)
   * @example "1694936993596975454"
   */
  threadId?: string;
  /**
   * Date when the message was added to the mailbox (INTERNALDATE)
   * @format date-time
   * @example "2021-03-22T13:13:31.000Z"
   */
  date?: string;
  /**
   * True if this message is marked as a draft
   * @example false
   */
  draft?: boolean;
  /**
   * True if the message is marked as unseen (unread)
   * @example true
   */
  unseen?: boolean;
  /**
   * True if this message is flagged
   * @example true
   */
  flagged?: boolean;
  /**
   * Size of the message in bytes
   * @example 1040
   */
  size?: number;
  /**
   * Subject of the message, decoded into Unicode
   * @example "What a wonderful message"
   */
  subject?: string;
  /** The sender's email address */
  from?: FromAddress;
  /** The sender's email address */
  sender?: FromAddress;
  /** An array of email address entries */
  to?: AddressList;
  /** An array of email address entries */
  cc?: AddressList;
  /** An array of email address entries */
  bcc?: AddressList;
  /** An array of email address entries */
  replyTo?: AddressList;
  /**
   * The Message-ID header value of the message
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /**
   * The In-Reply-To header value (Message-ID of the message this is a reply to)
   * @example "<7JBUMt0WOn+_==MOkaCOQ@mail.gmail.com>"
   */
  inReplyTo?: string;
  /** List of IMAP flags associated with the message */
  flags?: FlagList;
  /** List of Gmail labels associated with the message */
  labels?: LabelList;
  /** Array of attachment objects */
  attachments?: AttachmentList;
  /** An object where each header field name is a key, and the value is an array of header field values. Not available for MS Graph API emails. */
  headers?: MessageHeaders;
  text?: TextInfoDetails;
  bounces?: BounceList;
  /**
   * True if this message was detected to be an auto-reply email, like an Out of Office notice
   * @example false
   */
  isAutoReply?: boolean;
  /** Special use flag of the mailbox containing this message */
  specialUse?: Model1;
  /** Special use flag associated with the message, indicating the mailbox special use */
  messageSpecialUse?: MessageSpecialUse;
}

/** IMAP configuration */
export enum ImapConfiguration {
  False = 'false'
}

/** SMTP configuration */
export enum SmtpConfiguration {
  False = 'false'
}

/** OAuth2 configuration */
export enum OAuth2 {
  False = 'false'
}

/** @example {"account":"example","name":"Nyan Cat","email":"nyan.cat@example.com","imap":{"auth":{"user":"nyan.cat","pass":"sercretpass"},"host":"mail.example.com","port":993,"secure":true},"smtp":{"auth":{"user":"nyan.cat","pass":"secretpass"},"host":"mail.example.com","port":465,"secure":true}} */
export interface CreateAccount {
  /**
   * Account ID. If the provided value is `null` then an unique ID will be autogenerated
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Display name for the account
   * @maxLength 256
   * @example "My Email Account"
   */
  name: string;
  /**
   * Default email address of the account
   * @example "user@example.com"
   */
  email?: string;
  /** Check changes only on selected paths. Either a single string path or an array of paths. Can use references like "\Sent" or "\Inbox". Set to `null` to unset. */
  path?: AccountPath;
  /** An array of mailbox paths. If set, then EmailEngine opens additional IMAP connections against these paths to detect changes faster. Note: Connection counts are usually highly limited. */
  subconnections?: SubconnectionPaths;
  /**
   * Account-specific webhook URL
   * @example "https://myservice.com/imap/webhooks"
   */
  webhooks?: string;
  /**
   * Copy submitted messages to Sent folder. Set to `null` to unset and use provider specific default.
   * @example null
   */
  copy?: boolean;
  /**
   * Store recent logs
   * @default false
   * @example false
   */
  logs?: boolean;
  /**
   * Send webhooks for messages starting from provided date. The default is the account creation date. Only applies for IMAP accounts.
   * @format date-time
   * @default "now"
   * @example "2021-07-08T07:06:34.336Z"
   */
  notifyFrom?: string;
  /**
   * Proxy URL
   * @example "socks://proxy.example.com:1080"
   */
  proxy?: string;
  /**
   * Hostname to use for the SMTP EHLO/HELO greeting
   * @example "my.proxy.tld"
   */
  smtpEhloName?: string;
  /** Sets the account-specific IMAP indexing method. Choose "full" to build a complete index that tracks deleted and updated emails, or "fast" to only detect newly received emails. Defaults to global setting. */
  imapIndexer?: IMAPIndexer;
  /** IMAP configuration */
  imap?: ImapConfiguration;
  /** SMTP configuration */
  smtp?: SmtpConfiguration;
  /** OAuth2 configuration */
  oauth2?: OAuth2;
  /** Custom HTTP headers added to webhook requests */
  webhooksCustomHeaders?: AccountWebhooksCustomHeaders;
  /**
   * Optional locale
   * @maxLength 100
   * @example "fr"
   */
  locale?: string;
  /**
   * Optional timezone
   * @maxLength 100
   * @example "Europe/Tallinn"
   */
  tz?: string;
}

/**
 * Is the account new or updated existing
 * @example "new"
 */
export enum CreateAccountState {
  Existing = 'existing',
  New = 'new'
}

export interface CreateAccountResponse {
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /** Is the account new or updated existing */
  state: CreateAccountState;
}

export interface CreateGateway {
  /**
   * Gateway ID
   * @maxLength 256
   * @default null
   * @example "sendgun"
   */
  gateway: string;
  /**
   * Account Name
   * @maxLength 256
   * @example "John Smith"
   */
  name: string;
  /**
   * @maxLength 1024
   * @default null
   */
  user?: string;
  /**
   * @maxLength 1024
   * @default null
   */
  pass?: string;
  /**
   * Hostname to connect to
   * @example "smtp.gmail.com"
   */
  host: string;
  /**
   * Service port number
   * @min 1
   * @max 65536
   * @example 465
   */
  port: number;
  /**
   * Should connection use TLS. Usually true for port 465
   * @default false
   * @example true
   */
  secure?: boolean;
}

/**
 * Is the gateway new or updated existing
 * @example "new"
 */
export enum CreateGatewayState {
  Existing = 'existing',
  New = 'new'
}

export interface CreateGatewayResponse {
  /**
   * Gateway ID
   * @maxLength 256
   * @example "example"
   */
  gateway: string;
  /** Is the gateway new or updated existing */
  state: CreateGatewayState;
}

export interface RegisterLicense {
  /**
   * License file
   * @maxLength 10240
   * @example "-----BEGIN LICENSE-----
   * ..."
   */
  license: string;
}

/**
 * OAuth2 provider
 * @maxLength 256
 * @example "gmail"
 */
export enum Provider {
  Gmail = 'gmail',
  GmailService = 'gmailService',
  Outlook = 'outlook',
  MailRu = 'mailRu'
}

/**
 * Cloud Pub/Sub app for Gmail API webhooks
 * @maxLength 512
 * @example "AAAAAQAACnA"
 */
export enum PubSubApp {
  False = 'false'
}

export type ExtraScopes = string[];

export type SkipScopes = string[];

export interface CreateOAuth2App {
  /**
   * Application name
   * @maxLength 256
   * @example "My Gmail App"
   */
  name: string;
  /**
   * Application description
   * @maxLength 1024
   * @example "My cool app"
   */
  description?: string;
  /**
   * Title for the application button
   * @maxLength 256
   * @example "App title"
   */
  title?: string;
  /** OAuth2 provider */
  provider: Provider;
  /**
   * Enable this app
   * @default false
   * @example true
   */
  enabled?: boolean;
  /**
   * Client or Application ID for 3-legged OAuth2 applications
   * @maxLength 256
   * @example "52422112755-3uov8bjwlrullq122rdm6l8ui25ho7qf.apps.googleusercontent.com"
   */
  clientId?: string;
  /**
   * Client secret for 3-legged OAuth2 applications
   * @maxLength 256
   * @example "boT7Q~dUljnfFdVuqpC11g8nGMjO8kpRAv-ZB"
   */
  clientSecret?: string;
  /** OAuth2 Base Scopes */
  baseScopes?: BaseScopes;
  /** Cloud Pub/Sub app for Gmail API webhooks */
  pubSubApp?: PubSubApp;
  extraScopes?: ExtraScopes;
  skipScopes?: SkipScopes;
  /**
   * Service client ID for 2-legged OAuth2 applications
   * @maxLength 256
   * @example "7103296518315821565203"
   */
  serviceClient: string;
  /** Google Cloud Project ID */
  googleProjectId?: GoogleProjectId;
  /**
   * Show only Google Workspace accounts on the OAuth2 login page
   * @example false
   */
  googleWorkspaceAccounts?: boolean;
  /**
   * Service Client Email for 2-legged OAuth2 applications
   * @example "name@project-123.iam.gserviceaccount.com"
   */
  serviceClientEmail: string;
  /**
   * PEM formatted service secret for 2-legged OAuth2 applications
   * @maxLength 102400
   * @example "-----BEGIN PRIVATE KEY-----
   * MIIEvgIBADANBgk..."
   */
  serviceKey: string;
  /**
   * Authorization tenant value for Outlook OAuth2 applications
   * @maxLength 1024
   * @example "common"
   */
  authority: string;
  /**
   * Azure cloud type for Outlook OAuth2 applications
   * @example "global"
   */
  cloud?: string;
  /**
   * Redirect URL for 3-legged OAuth2 applications
   * @example "https://myservice.com/oauth"
   */
  redirectUrl?: string;
}

export interface CreateAppResponse {
  /**
   * OAuth2 application ID
   * @maxLength 256
   * @example "AAABhaBPHscAAAAH"
   */
  id: string;
  /**
   * Was the app created
   * @default true
   */
  created?: boolean;
}

export interface Settings {
  /** If set to false, webhooks will not be emitted */
  webhooksEnabled?: boolean;
  /**
   * The URL where webhooks will be sent
   * @example "https://myservice.com/imap/webhooks"
   */
  webhooks?: string;
  /** List of webhook events to subscribe to */
  webhookEvents?: WebhookEvents;
  /** Custom HTTP headers added to webhook requests */
  webhooksCustomHeaders?: WebhooksCustomHeaders;
  /** List of additional email headers to include in webhook notifications */
  notifyHeaders?: NotifyHeaders;
  /**
   * The base URL of the EmailEngine service
   * @example "https://emailengine.example.com"
   */
  serviceUrl?: string;
  /** If true, rewrites HTML links in sent emails to track link clicks */
  trackClicks?: boolean;
  /** If true, inserts a tracking beacon image in HTML emails to monitor email opens */
  trackOpens?: boolean;
  /** Sets the indexing method for IMAP accounts. Choose "full" to build a complete index that tracks deleted and updated emails, or "fast" to only detect newly received emails */
  imapIndexer?: ImapIndexer;
  /** Enables Gmail category tab detection for incoming emails when set to true. Applies only to Gmail IMAP accounts; Gmail API accounts automatically include category information */
  resolveGmailCategories?: boolean;
  /** If true, allows connections to servers with insecure or untrusted SSL/TLS certificates. Applies to both IMAP and SMTP connections. Use with caution */
  ignoreMailCertErrors?: boolean;
  /** If true, generates a summary of the email using OpenAI ChatGPT */
  generateEmailSummary?: boolean;
  /**
   * OpenAI API key
   * @example "verysecret"
   */
  openAiAPIKey?: string;
  /**
   * OpenAI API model
   * @example "gpt-3.5-turbo"
   */
  openAiModel?: string;
  /**
   * OpenAI API URL
   * @example "https://api.openai.com"
   */
  openAiAPIUrl?: string;
  /**
   * OpenAI API model for chat
   * @example "gpt-3.5-turbo"
   */
  documentStoreChatModel?: string;
  /**
   * Controls the randomness of the OpenAI API responses. Higher values (up to 2) make output more random, while lower values make it more focused and deterministic
   * @min 0
   * @max 2
   * @example 0.8
   */
  openAiTemperature?: number;
  /**
   * Controls the cumulative probability for nucleus sampling in the OpenAI API. A value between 0 and 1
   * @min 0
   * @max 1
   * @example 0.1
   */
  openAiTopP?: number;
  /**
   * Prompt to send to the LLM for analyzing emails
   * @maxLength 6144
   * @example "You are an assistant scanning incoming emails..."
   */
  openAiPrompt?: string;
  /** If true, generates vector embeddings for the email */
  openAiGenerateEmbeddings?: boolean;
  /** If true, sends "New Email" webhooks for incoming emails only */
  inboxNewOnly?: boolean;
  /**
   * HMAC secret for signing public requests
   * @example "verysecret"
   */
  serviceSecret?: string;
  /**
   * The URL of the authentication server to fetch authentication data from
   * @example "https://myservice.com/authentication"
   */
  authServer?: string;
  /** If true, the global proxy is enabled */
  proxyEnabled?: boolean;
  /**
   * Proxy URL
   * @example "socks://proxy.example.com:1080"
   */
  proxyUrl?: string;
  /**
   * Hostname to use for the SMTP EHLO/HELO greeting
   * @example "my.proxy.tld"
   */
  smtpEhloName?: string;
  /** Include message text in webhook notifications */
  notifyText?: boolean;
  /** Pre-process HTML in webhook notifications to be web-safe */
  notifyWebSafeHtml?: boolean;
  /**
   * Maximum size of message text to include in webhook notifications (in bytes)
   * @min 0
   */
  notifyTextSize?: number;
  /** Include calendar events in webhook notifications */
  notifyCalendarEvents?: boolean;
  logs?: LogSettings;
  /** How to select local IP address for IMAP connections */
  imapStrategy?: ImapStrategy;
  /** How to select local IP address for SMTP connections */
  smtpStrategy?: SmtpStrategy;
  /** A list of pooled local IP addresses that can be used for IMAP and SMTP connections */
  localAddresses?: LocalAddresses;
  /** Enable SMTP interface */
  smtpServerEnabled?: boolean;
  /**
   * SMTP interface port
   * @min 0
   * @max 65536
   */
  smtpServerPort?: number;
  /** SMTP host to bind to */
  smtpServerHost?: string;
  /** Enable PROXY protocol for SMTP server */
  smtpServerProxy?: boolean;
  /** Enable SMTP authentication */
  smtpServerAuthEnabled?: boolean;
  /**
   * SMTP client password. Set to `null` to disable
   * @maxLength 1024
   */
  smtpServerPassword?: string;
  /** Enable TLS for the SMTP interface. Requires a valid certificate */
  smtpServerTLSEnabled?: boolean;
  /** Enable IMAP proxy interface */
  imapProxyServerEnabled?: boolean;
  /**
   * IMAP proxy interface port
   * @min 0
   * @max 65536
   */
  imapProxyServerPort?: number;
  /** IMAP proxy host to bind to */
  imapProxyServerHost?: string;
  /** Enable PROXY protocol for the IMAP proxy server */
  imapProxyServerProxy?: boolean;
  /**
   * IMAP proxy client password. Set to `null` to disable
   * @maxLength 1024
   */
  imapProxyServerPassword?: string;
  /** Enable TLS for the IMAP proxy interface. Requires a valid certificate */
  imapProxyServerTLSEnabled?: boolean;
  /**
   * Number of completed or failed queue entries to keep
   * @min 0
   */
  queueKeep?: number;
  /**
   * Number of times to retry sending an email before it is considered as failed
   * @min 0
   */
  deliveryAttempts?: number;
  /**
   * HTML code displayed at the top of public pages like the hosted authentication form
   * @maxLength 1048576
   */
  templateHeader?: string;
  /**
   * HTML code added to the HEAD tag of public pages like the hosted authentication form
   * @maxLength 1048576
   */
  templateHtmlHead?: string;
  /**
   * JSON object to be used as the `env` variable in pre-processing scripts
   * @maxLength 1048576
   * @example "{"key": "value"}"
   */
  scriptEnv?: string;
  /** Enable support for reverse proxies */
  enableApiProxy?: boolean;
  /** Default locale identifier */
  locale?: Locale;
  /**
   * Default timezone identifier
   * @maxLength 100
   * @example "Europe/Tallinn"
   */
  timezone?: string;
  /**
   * Filter function for LLM pre-processing (JavaScript)
   * @maxLength 524288
   * @example "return true; // passes all emails"
   */
  openAiPreProcessingFn?: string;
}

/** List of updated setting keys */
export type UpdatedSettings = string[];

export interface SettingsUpdatedResponse {
  /** List of updated setting keys */
  updated?: UpdatedSettings;
}

export enum TokenScope {
  Api = 'api',
  Smtp = 'smtp',
  ImapProxy = 'imap-proxy'
}

/** @default ["api"] */
export type Scopes = TokenScope[];

export interface CreateToken {
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /**
   * Token description
   * @maxLength 1024
   * @example "Token description"
   */
  description: string;
  scopes: Scopes;
  /**
   * Related metadata in JSON format
   * @maxLength 1048576
   * @example "{"example": "value"}"
   */
  metadata?: string;
  /** Access restrictions */
  restrictions?: TokenRestrictions;
  /**
   * IP address of the requester
   * @example "127.0.0.1"
   */
  ip?: string;
}

export interface CreateTokenResponse {
  /**
   * Access token
   * @example "123456"
   */
  token: string;
}

export interface VerifyAccount {
  /**
   * Include mailbox listing in response
   * @default false
   * @example false
   */
  mailboxes?: boolean;
  /** IMAP configuration */
  imap?: ImapConfiguration;
  /** SMTP configuration */
  smtp?: SmtpConfiguration;
  /**
   * Proxy URL
   * @example "socks://proxy.example.com:1080"
   */
  proxy?: string;
  /**
   * Hostname to use for the SMTP EHLO/HELO greeting
   * @example "my.proxy.tld"
   */
  smtpEhloName?: string;
}

export interface Imap {
  /**
   * Was IMAP account verified
   * @example true
   */
  success?: boolean;
  /**
   * Error messages for IMAP verification. Only present if success=false
   * @example "Something went wrong"
   */
  error?: string;
  /**
   * Error code. Only present if success=false
   * @example "ERR_SSL_WRONG_VERSION_NUMBER"
   */
  code?: string;
}

export interface Smtp {
  /**
   * Was SMTP account verified
   * @example true
   */
  success?: boolean;
  /**
   * Error messages for SMTP verification. Only present if success=false
   * @example "Something went wrong"
   */
  error?: string;
  /**
   * Error code. Only present if success=false
   * @example "ERR_SSL_WRONG_VERSION_NUMBER"
   */
  code?: string;
}

export interface MailboxShortResponseItem {
  /**
   * Full path to the mailbox
   * @example "Kalender/S&APw-nnip&AOQ-evad"
   */
  path: string;
  /**
   * Hierarchy delimiter used in the mailbox path
   * @example "/"
   */
  delimiter?: string;
  /**
   * Full path to the parent mailbox
   * @example "Kalender"
   */
  parentPath: string;
  /**
   * Name of the mailbox
   * @example "Sünnipäevad"
   */
  name: string;
  /**
   * True if the mailbox was returned in response to the LIST command
   * @example true
   */
  listed?: boolean;
  /**
   * True if the mailbox was returned in response to the LSUB command
   * @example true
   */
  subscribed?: boolean;
  /** Special use flag of the mailbox */
  specialUse?: MailboxSpecialUse;
}

export type Mailboxes = MailboxShortResponseItem[];

export interface VerifyAccountResponse {
  imap?: Imap;
  smtp?: Smtp;
  mailboxes?: Mailboxes;
}

/**
 * Check changes only on selected paths. Either a single string path or an array of paths. Can use references like "\Sent" or "\Inbox". Set to `null` to unset.
 * @example ["*"]
 */
export type AccountFormPath = string[];

/**
 * Display the form for the specified account type (either "imap" or an OAuth2 app ID) instead of allowing the user to choose
 * @default false
 * @example "imap"
 */
export enum DefaultAccountType {
  False = 'false'
}

export interface RequestAuthForm {
  /**
   * Account ID. If the provided value is `null` then an unique ID will be autogenerated. Using an existing account ID will update settings for that existing account.
   * @maxLength 256
   * @default null
   * @example "example"
   */
  account?: string;
  /**
   * Display name for the account
   * @maxLength 256
   * @example "My Email Account"
   */
  name?: string;
  /**
   * Default email address of the account
   * @example "user@example.com"
   */
  email?: string;
  /**
   * If true then acts as a shared mailbox. Available for MS365 OAuth2 accounts.
   * @default false
   */
  delegated?: boolean;
  /**
   * Send webhooks for messages starting from provided date. The default is the account creation date. Only applies for IMAP accounts.
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  notifyFrom?: string;
  /** An array of mailbox paths. If set, then EmailEngine opens additional IMAP connections against these paths to detect changes faster. Note: Connection counts are usually highly limited. */
  subconnections?: SubconnectionPaths;
  /** Check changes only on selected paths. Either a single string path or an array of paths. Can use references like "\Sent" or "\Inbox". Set to `null` to unset. */
  path?: AccountFormPath;
  /**
   * The user will be redirected to this URL after submitting the authentication form
   * @example "https://myapp/account/settings.php"
   */
  redirectUrl: string;
  /** Display the form for the specified account type (either "imap" or an OAuth2 app ID) instead of allowing the user to choose */
  type?: DefaultAccountType;
}

export interface RequestAuthFormResponse {
  /**
   * Generated URL to the hosted authentication form
   * @example "https://ee.example.com/accounts/new?data=eyJhY2NvdW50IjoiZXhh...L0W_BkFH5HW6Krwmr7c&type=imap"
   */
  url: string;
}

export interface BlocklistListAddPayload {
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /**
   * Email address to add to the list
   * @example "user@example.com"
   */
  recipient: string;
  /**
   * Identifier for the blocking reason
   * @default "block"
   */
  reason?: string;
}

export interface BlocklistListAddResponse {
  /**
   * Was the request successful
   * @example true
   */
  success?: boolean;
  /**
   * Was the address added to the list
   * @example true
   */
  added?: boolean;
}

export interface CreateTemplateContent {
  /**
   * Message subject
   * @maxLength 10240
   * @example "What a wonderful message"
   */
  subject?: string;
  /**
   * Plaintext content of the message
   * @maxLength 5242880
   * @example "Hello from myself!"
   */
  text?: string;
  /**
   * HTML content of the message
   * @maxLength 5242880
   * @example "<p>Hello from myself!</p>"
   */
  html?: string;
  /**
   * Preview text that appears in the inbox after the subject line
   * @maxLength 1024
   * @example "Welcome to our newsletter!"
   */
  previewText?: string;
}

export interface CreateTemplate {
  /**
   * Account ID. Use `null` for public templates.
   * @maxLength 256
   * @example "example"
   */
  account: string;
  /**
   * Name of the template
   * @maxLength 256
   * @example "Transaction receipt"
   */
  name: string;
  /**
   * Optional description of the template
   * @maxLength 1024
   * @example "Something about the template"
   */
  description?: string;
  /** Markup language for HTML ("html" or "markdown") */
  format?: Format;
  content: CreateTemplateContent;
}

export interface CreateTemplateResponse {
  /** Was the template created or not */
  created?: boolean;
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /**
   * Template ID
   * @maxLength 256
   * @example "example"
   */
  id: string;
}

/**
 * Message flags
 * @example ["\\Seen","\\Draft"]
 */
export type Flags = string[];

/**
 * Specifies the action to perform on the referenced message. Possible values are 'reply' or 'forward'.
 * @default "reply"
 * @example "reply"
 */
export enum Action {
  Forward = 'forward',
  Reply = 'reply'
}

/** Contains information needed when replying to or forwarding an email */
export interface MessageReference {
  /**
   * The EmailEngine-specific ID of the referenced message. Note: This is not the Message-ID found in the email's headers.
   * @maxLength 256
   * @example "AAAAAQAACnA"
   */
  message: string;
  /** Specifies the action to perform on the referenced message. Possible values are 'reply' or 'forward'. */
  action?: Action;
  /**
   * When set to `true`, includes the original email content as blockquoted text in the reply.
   * @default false
   */
  inline?: boolean;
  /**
   * When set to `true`, includes attachments from the original email in the forwarded message.
   * @default false
   */
  forwardAttachments?: boolean;
  /**
   * When set to `true`, processes the email even if the original referenced message is no longer available.
   * @default false
   */
  ignoreMissing?: boolean;
  /**
   * Specifies the expected Message-ID of the referenced email. When set, the reference action (such as 'reply' or 'forward') will only proceed if the Message-ID of the referenced email matches this value. If the Message-IDs do not match, an error is returned. This ensures that the action is applied to the correct email.
   * @maxLength 996
   * @example "<test123@example.com>"
   */
  messageId?: string;
    /**
   * If enabled then fetch the data from the Document Store instead of IMAP
   * @default false
   */
  documentStore?: boolean;
}

export interface Model2 {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

/**
 * List of addresses
 * @example [{"address":"recipient@example.com"}]
 */
export type Model3 = Model2[];

/** List of addresses */
export type Model4 = Model2[];

/** List of addresses */
export type Model5 = Model2[];

export enum ContentDisposition {
  Inline = 'inline',
  Attachment = 'attachment'
}

/** @default "base64" */
export enum Encoding {
  Base64 = 'base64'
}

/**
 * References an existing attachment by its ID instead of providing new attachment content. If this field is set, the `content` field must not be included. If not set, the `content` field is required.
 * @maxLength 256
 * @example "AAAAAQAACnAcde"
 */
export enum Reference {
  False = 'false'
}

export interface UploadAttachment {
  /**
   * @maxLength 256
   * @example "transparent.gif"
   */
  filename?: string;
  /**
   * Base64 formatted attachment file
   * @maxLength 5242880
   * @example "R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs="
   */
  content: string;
  /**
   * @maxLength 256
   * @example "image/gif"
   */
  contentType?: string;
  contentDisposition?: ContentDisposition;
  /**
   * Content-ID value for embedded images
   * @maxLength 256
   * @example "unique-image-id@localhost"
   */
  cid?: string;
  encoding?: Encoding;
  /** References an existing attachment by its ID instead of providing new attachment content. If this field is set, the `content` field must not be included. If not set, the `content` field is required. */
  reference?: Reference;
}

/** List of attachments */
export type UploadAttachmentList = UploadAttachment[];

/**
 * Custom Headers
 * @example {"X-My-Custom-Header":"Custom header value"}
 */
export type CustomHeaders = object;

export interface MessageUpload {
  /**
   * Target mailbox folder path
   * @example "INBOX"
   */
  path: string;
  /** Message flags */
  flags?: Flags;
  /**
   * Sets the internal date for this message
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  internalDate?: string;
  /** Contains information needed when replying to or forwarding an email */
  reference?: MessageReference;
  /**
   * A Base64-encoded email message in RFC 822 format. If you provide other fields along with raw, those fields will override the corresponding values in the raw message.
   * @maxLength 5242880
   * @example "TUlNRS1WZXJzaW9uOiAxLjANClN1YmplY3Q6IGhlbGxvIHdvcmxkDQoNCkhlbGxvIQ0K"
   */
  raw?: string;
  /** The sender's email address */
  from?: FromAddress;
  /** List of addresses */
  to?: Model3;
  /** List of addresses */
  cc?: Model4;
  /** List of addresses */
  bcc?: Model5;
  /**
   * Message subject
   * @maxLength 10240
   * @example "What a wonderful message"
   */
  subject?: string;
  /**
   * Message Text
   * @maxLength 5242880
   * @example "Hello from myself!"
   */
  text?: string;
  /**
   * Message HTML
   * @maxLength 5242880
   * @example "<p>Hello from myself!</p>"
   */
  html?: string;
  /** List of attachments */
  attachments?: UploadAttachmentList;
  /**
   * Message ID
   * @maxLength 996
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /** Custom Headers */
  headers?: CustomHeaders;
  /**
   * Optional locale
   * @maxLength 100
   * @example "fr"
   */
  locale?: string;
  /**
   * Optional timezone
   * @maxLength 100
   * @example "Europe/Tallinn"
   */
  tz?: string;
}

/** Reference info if referencing was requested */
export interface ResponseReference {
  /**
   * Referenced message ID
   * @maxLength 256
   * @example "AAAAAQAACnA"
   */
  message: string;
  /**
   * Was the referenced message processed
   * @example true
   */
  success?: boolean;
  /**
   * An error message if referenced message processing failed
   * @example "Referenced message was not found"
   */
  error?: string;
  /**
   * Was the message dat aloaded from the document store
   * @example true
   */
  documentStore?: boolean;
}

export interface MessageUploadResponse {
  /**
   * Unique identifier for the message. NB! This and other fields might not be present if server did not provide enough information
   * @example "AAAAAgAACrI"
   */
  id?: string;
  /**
   * Folder this message was uploaded to
   * @example "INBOX"
   */
  path?: string;
  /**
   * UID of uploaded message
   * @example 12345
   */
  uid?: number;
  /**
   * UIDVALIDTITY of the target folder. Numeric value cast as string.
   * @example "12345"
   */
  uidValidity?: string;
  /**
   * Sequence number of uploaded message
   * @example 12345
   */
  seq?: number;
  /**
   * Message ID
   * @maxLength 996
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /** Reference info if referencing was requested */
  reference?: ResponseReference;
}

/**
 * Mailbox path as an array or a string. If account is namespaced then namespace prefix is added by default.
 * @example ["Parent folder","Subfolder"]
 */
export type MailboxPath = string[];

export interface CreateMailbox {
  /** Mailbox path as an array or a string. If account is namespaced then namespace prefix is added by default. */
  path?: MailboxPath;
}

export interface CreateMailboxResponse {
  /**
   * Full path to mailbox
   * @example "Kalender/S&APw-nnip&AOQ-evad"
   */
  path: string;
  /**
   * Mailbox ID (if server has support)
   * @example "1439876283476"
   */
  mailboxId?: string;
  /**
   * Was the mailbox created
   * @example true
   */
  created?: boolean;
}

/**
 * Headers to match against
 * @example {"Message-ID":"<1DAF52A51E674A2@example.com>"}
 */
export type Headers = object;

/** Search query to filter messages */
export interface SearchQuery {
  /**
   * Sequence number range. Only supported for IMAP accounts.
   * @maxLength 8192
   */
  seq?: string;
  /** Check if message is answered or not. Only supported for IMAP accounts. */
  answered?: boolean;
  /** Check if message is marked for deletion or not. Only supported for IMAP accounts. */
  deleted?: boolean;
  /** Check if message is a draft. */
  draft?: boolean;
  /** Check if message is marked as unseen. */
  unseen?: boolean;
  /** Check if message is flagged or not. */
  flagged?: boolean;
  /** Check if message is marked as seen. */
  seen?: boolean;
  /**
   * Match From: header
   * @maxLength 256
   */
  from?: string;
  /**
   * Match To: header. Not supported for MS Graph API accounts.
   * @maxLength 256
   */
  to?: string;
  /**
   * Match Cc: header. Not supported for MS Graph API accounts.
   * @maxLength 256
   */
  cc?: string;
  /**
   * Match Bcc: header. Not supported for MS Graph API accounts.
   * @maxLength 256
   */
  bcc?: string;
  /**
   * Match text body
   * @maxLength 256
   */
  body?: string;
  /**
   * Match message subject
   * @maxLength 2560
   * @example "Hello world"
   */
  subject?: string;
  /**
   * Matches messages larger than value in bytes. Not supported for MS Graph API accounts.
   * @min 0
   * @max 1073741824
   */
  larger?: number;
  /**
   * Matches messages smaller than value in bytes. Not supported for MS Graph API accounts.
   * @min 0
   * @max 1073741824
   */
  smaller?: number;
  /**
   * UID range. Only supported for IMAP accounts.
   * @maxLength 8192
   */
  uid?: string;
  /**
   * Matches messages with modseq higher than value. Only supported for IMAP accounts.
   * @min 0
   */
  modseq?: number;
  /**
   * Matches messages received before date
   * @format date
   */
  before?: string;
  /**
   * Matches messages received after date
   * @format date
   */
  since?: string;
  /**
   * Matches messages sent before date
   * @format date
   */
  sentBefore?: string;
  /**
   * Matches messages sent after date
   * @format date
   */
  sentSince?: string;
  /**
   * Match specific Gmail unique email ID
   * @maxLength 256
   * @example "1278455344230334865"
   */
  emailId?: string;
  /**
   * Match specific Gmail unique thread ID
   * @maxLength 256
   * @example "1278455344230334865"
   */
  threadId?: string;
  /** Headers to match against */
  header?: Headers;
  /**
   * Raw Gmail search string. Will return an error if used for other account types.
   * @maxLength 1024
   * @example "has:attachment in:unread"
   */
  gmailRaw?: string;
}

/** Document Store query. Only allowed with `documentStore`. */
export type Model21 = object;

/** @example {"search":{"unseen":true,"flagged":true,"from":"nyan.cat@example.com","body":"Hello world","subject":"Hello world","sentBefore":"2024-08-09","sentSince":"2022-08-09","emailId":"1278455344230334865","threadId":"1266894439832287888","header":{"Message-ID":"<12345@example.com>"},"gmailRaw":"has:attachment in:unread"}} */
export interface Model6 {
  /** Search query to filter messages */
  search: SearchQuery;
  /** Document Store query. Only allowed with `documentStore`. */
  documentQuery?: Model21;
}

export type Model7 = string[];

/** An optional object specifying the SMTP envelope used during email transmission. If not provided, the envelope is automatically derived from the email's message headers. This is useful when you need the envelope addresses to differ from those in the email headers. */
export interface SMTPEnvelope {
  /** @example "sender@example.com" */
  from?: string;
  to?: Model7;
}

export interface ReplyToAddress {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

/**
 * List of Reply-To addresses
 * @example [{"name":"From Me","address":"sender@example.com"}]
 */
export type ReplyTo = ReplyToAddress[];

export interface ToAddress {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

/**
 * List of recipient addresses
 * @example [{"address":"recipient@example.com"}]
 */
export type ToAddressList = ToAddress[];

export interface CcAddress {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

/** List of CC addresses */
export type CcAddressList = CcAddress[];

export interface BccAddress {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

/** List of BCC addresses */
export type BccAddressList = BccAddress[];

/** An object of variables for the template renderer */
export type RenderValues = object;

/** Template rendering options */
export enum TemplateRender {
  False = 'false'
}

export interface MailMergeListEntry {
  to: ToAddress;
  /**
   * Message ID
   * @maxLength 996
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /** An object of variables for the template renderer */
  params?: RenderValues;
  /**
   * Send message at specified time. Overrides message level `sendAt` value.
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  sendAt?: string;
}

/**
 * Mail merge options. A separate email is generated for each recipient. Using mail merge disables `messageId`, `envelope`, `to`, `cc`, `bcc`, `render` keys for the message root.
 * @minItems 1
 */
export type MailMergeList = MailMergeListEntry[];

/** Specifies if only headers or the entire body of the message should be included in the response (RET) */
export enum Return {
  Headers = 'headers',
  Full = 'full'
}

export enum NotifyEntry {
  Never = 'never',
  Success = 'success',
  Failure = 'failure',
  Delay = 'delay'
}

/** Defines the conditions under which a DSN response should be sent */
export type Notify = NotifyEntry[];

/** Request DSN notifications */
export interface DSN {
  /**
   * The envelope identifier that would be included in the response (ENVID)
   * @maxLength 256
   */
  id?: string;
  /** Specifies if only headers or the entire body of the message should be included in the response (RET) */
  return: Return;
  /** Defines the conditions under which a DSN response should be sent */
  notify?: Notify;
  /** The email address the DSN should be sent (ORCPT) */
  recipient?: string;
}

/** @example {"to":[{"name":"Nyan Cat","address":"nyan.cat@example.com"}],"subject":"What a wonderful message!","text":"Hello from myself!","html":"<p>Hello from myself!</p>","attachments":[{"filename":"transparent.gif","content":"R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs=","contentType":"image/gif"}]} */
export interface SubmitMessage {
  /** Contains information needed when replying to or forwarding an email */
  reference?: MessageReference;
  /** An optional object specifying the SMTP envelope used during email transmission. If not provided, the envelope is automatically derived from the email's message headers. This is useful when you need the envelope addresses to differ from those in the email headers. */
  envelope?: SMTPEnvelope;
  /**
   * A Base64-encoded email message in RFC 822 format. If you provide other fields along with raw, those fields will override the corresponding values in the raw message.
   * @maxLength 5242880
   * @example "TUlNRS1WZXJzaW9uOiAxLjANClN1YmplY3Q6IGhlbGxvIHdvcmxkDQoNCkhlbGxvIQ0K"
   */
  raw?: string;
  /** The sender's email address */
  from?: FromAddress;
  /** List of Reply-To addresses */
  replyTo?: ReplyTo;
  /** List of recipient addresses */
  to?: ToAddressList;
  /** List of CC addresses */
  cc?: CcAddressList;
  /** List of BCC addresses */
  bcc?: BccAddressList;
  /**
   * Message subject
   * @maxLength 10240
   * @example "What a wonderful message"
   */
  subject?: string;
  /**
   * Plaintext content of the message
   * @maxLength 5242880
   * @example "Hello from myself!"
   */
  text?: string;
  /**
   * HTML content of the message
   * @maxLength 5242880
   * @example "<p>Hello from myself!</p>"
   */
  html?: string;
  /**
   * Preview text that appears in the inbox after the subject line
   * @maxLength 1024
   * @example "Welcome to our newsletter!"
   */
  previewText?: string;
  /**
   * Stored template ID to load the email content from
   * @maxLength 256
   * @example "example"
   */
  template?: string;
  /** Template rendering options */
  render?: TemplateRender;
  /** Mail merge options. A separate email is generated for each recipient. Using mail merge disables `messageId`, `envelope`, `to`, `cc`, `bcc`, `render` keys for the message root. */
  mailMerge?: MailMergeList;
  /** List of attachments */
  attachments?: UploadAttachmentList;
  /**
   * Message ID
   * @maxLength 996
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /** Custom Headers */
  headers?: CustomHeaders;
  /**
   * Should EmailEngine track opens for this message
   * @example false
   */
  trackOpens?: boolean;
  /**
   * Should EmailEngine track clicks for this message
   * @example false
   */
  trackClicks?: boolean;
  /**
   * If set then either copies the message to the Sent Mail folder or not. If not set then uses the account's default setting.
   * @example null
   */
  copy?: boolean;
  /**
   * Upload sent message to this folder. By default the account's Sent Mail folder is used.
   * @maxLength 1024
   * @example "Sent Mail"
   */
  sentMailPath?: string;
  /**
   * Optional locale
   * @maxLength 100
   * @example "fr"
   */
  locale?: string;
  /**
   * Optional timezone
   * @maxLength 100
   * @example "Europe/Tallinn"
   */
  tz?: string;
  /**
   * Send message at specified time
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  sendAt?: string;
  /**
   * How many delivery attempts to make until message is considered as failed
   * @example 10
   */
  deliveryAttempts?: number;
  /**
   * Optional SMTP gateway ID for message routing
   * @maxLength 256
   * @example "example"
   */
  gateway?: string;
  /**
   * List ID for Mail Merge. Must use a subdomain name format. Lists are registered ad-hoc, so a new identifier defines a new list.
   * @example "test-list"
   */
  listId?: string;
  /** Request DSN notifications */
  dsn?: DSN;
  /**
   * Optional base URL for trackers. This URL must point to your EmailEngine instance.
   * @example "https://customer123.myservice.com"
   */
  baseUrl?: string;
  /**
   * Optional proxy URL to use when connecting to the SMTP server
   * @example "socks://proxy.example.com:1080"
   */
  proxy?: string;
  /**
   * Optional local IP address to bind to when connecting to the SMTP server
   * @example "127.0.0.1"
   */
  localAddress?: string;
  /**
   * If true, then EmailEngine does not send the email and returns an RFC822 formatted email file. Tracking information is not added to the email.
   * @default false
   */
  dryRun?: boolean;
}

/** Reference info if referencing was requested */
export interface Model8 {
  /**
   * Referenced message ID
   * @maxLength 256
   * @example "AAAAAQAACnA"
   */
  message: string;
  /**
   * Was the message dat aloaded from the document store
   * @example true
   */
  documentStore?: boolean;
  /**
   * Was the referenced message processed successfully
   * @example true
   */
  success?: boolean;
  /**
   * An error message if referenced message processing failed
   * @example "Referenced message was not found"
   */
  error?: string;
}

export interface ToAddressSingle {
  /**
   * @maxLength 256
   * @example "Some Name"
   */
  name?: string;
  /** @example "user@example.com" */
  address: string;
}

/** Reference info if referencing was requested */
export interface Model9 {
  /**
   * Referenced message ID
   * @maxLength 256
   * @example "AAAAAQAACnA"
   */
  message: string;
  /**
   * Was the referenced message processed successfully
   * @example true
   */
  success?: boolean;
  /**
   * An error message if referenced message processing failed
   * @example "Referenced message was not found"
   */
  error?: string;
}

/** Info about skipped message. If this value is set, then the message was not sent */
export interface Skipped {
  /**
   * Why this message was skipped
   * @example "unsubscribe"
   */
  reason?: string;
  /** @example "test-list" */
  listId?: string;
}

/** @example {"success":true,"to":{"name":"Andris 2","address":"andris@ethereal.email"},"messageId":"<19b9c433-d428-f6d8-1d00-d666ebcadfc4@ekiri.ee>","queueId":"1812477338914c8372a","reference":{"message":"AAAAAQAACnA","success":true},"sendAt":"2021-07-08T07:06:34.336Z"} */
export interface BulkResponseEntry {
  /**
   * Was the referenced message processed successfully
   * @example true
   */
  success?: boolean;
  to?: ToAddressSingle;
  /**
   * Message ID
   * @maxLength 996
   * @example "<test123@example.com>"
   */
  messageId?: string;
  /**
   * Queue identifier for scheduled email. Not present for bulk messages.
   * @example "d41f0423195f271f"
   */
  queueId?: string;
  /** Reference info if referencing was requested */
  reference?: Model9;
  /**
   * Send message at specified time. Overrides message level `sendAt` value.
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  sendAt?: string;
  /** Info about skipped message. If this value is set, then the message was not sent */
  skipped?: Skipped;
}

/** Bulk message responses */
export type BulkResponseList = BulkResponseEntry[];

export interface SubmitMessageResponse {
  /** @example "Queued for delivery" */
  response?: string;
  /**
   * Message-ID header value. Not present for bulk messages.
   * @example "<a2184d08-a470-fec6-a493-fa211a3756e9@example.com>"
   */
  messageId?: string;
  /**
   * Queue identifier for scheduled email. Not present for bulk messages.
   * @example "d41f0423195f271f"
   */
  queueId?: string;
  /**
   * Scheduled send time
   * @format date
   * @example "2021-07-08T07:06:34.336Z"
   */
  sendAt?: string;
  /** Reference info if referencing was requested */
  reference?: Model8;
  /**
   * Base64 encoded RFC822 email if a preview was requested
   * @example "Q29udGVudC1UeXBlOiBtdWx0aX..."
   */
  preview?: string;
  /** Bulk message responses */
  mailMerge?: BulkResponseList;
}

/**
 * Optional gateway ID
 * @maxLength 256
 * @example false
 */
export enum Gateway {
  False = 'false'
}

export interface DeliveryStartRequest {
  /** Optional gateway ID */
  gateway?: Gateway;
}

export interface DeliveryStartResponse {
  /**
   * Was the test started
   * @example true
   */
  success?: boolean;
  /**
   * Test ID
   * @example "6420a6ad-7f82-4e4f-8112-82a9dad1f34d"
   */
  deliveryTest?: string;
}

export interface EmptyLicenseResponse {
  /** @example false */
  active?: boolean;
  /** @example false */
  details?: boolean;
  /** @example "SSPL-1.0-or-later" */
  type?: string;
}

export interface DeleteRequestResponse {
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /**
   * Was the account deleted
   * @default true
   */
  deleted?: boolean;
}

export interface DeleteBlocklistResponse {
  /**
   * Was the address removed from the list
   * @default true
   */
  deleted?: boolean;
}

export interface Model10 {
  /**
   * Gateway ID
   * @maxLength 256
   * @example "example"
   */
  gateway: string;
  /**
   * Was the gateway deleted
   * @default true
   */
  deleted?: boolean;
}

export interface DeleteAppRequestResponse {
  /**
   * OAuth2 application ID
   * @maxLength 256
   * @example "AAABhaBPHscAAAAH"
   */
  id: string;
  /**
   * Was the gateway deleted
   * @default true
   */
  deleted?: boolean;
  /**
   * The number of accounts registered with this application. Not available for legacy apps.
   * @example 12
   */
  accounts?: number;
}

export interface DeleteOutboxEntryResponse {
  /**
   * Was the message deleted
   * @default true
   */
  deleted?: boolean;
}

export interface DeleteTokenRequestResponse {
  /**
   * Was the account deleted
   * @default true
   */
  deleted?: boolean;
}

export interface DeleteMailboxResponse {
  /**
   * Full path to mailbox
   * @example "Kalender/S&APw-nnip&AOQ-evad"
   */
  path: string;
  /**
   * Was the mailbox deleted
   * @example true
   */
  deleted?: boolean;
}

export interface DeleteTemplateRequestResponse {
  /**
   * Was the account flushed
   * @default true
   */
  deleted?: boolean;
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
}

export interface Model11 {
  /**
   * Was the template deleted
   * @default true
   */
  deleted?: boolean;
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /**
   * Template ID
   * @maxLength 256
   * @example "AAABgS-UcAYAAAABAA"
   */
  id: string;
}

/** Present if message was moved to Trash */
export interface Moved {
  /**
   * Trash folder path
   * @example "Trash"
   */
  destination: string;
  /**
   * Message ID in Trash
   * @example "AAAAAwAAAWg"
   */
  message: string;
}

export interface MessageDeleteResponse {
  /**
   * Was the delete action executed
   * @example false
   */
  deleted?: boolean;
  /** Present if message was moved to Trash */
  moved?: Moved;
}

/** Optional TLS configuration settings */
export interface Model12 {
  /**
   * Whether to reject invalid TLS certificates
   * @example true
   */
  rejectUnauthorized?: boolean;
  /**
   * The minimum TLS version to allow
   * @maxLength 256
   * @example "TLSv1.2"
   */
  minVersion?: string;
}

/** IMAP configuration */
export enum IMAPUpdate {
  False = 'false'
}

/** Optional TLS configuration settings */
export interface Model13 {
  /**
   * Whether to reject invalid TLS certificates
   * @example true
   */
  rejectUnauthorized?: boolean;
  /**
   * The minimum TLS version to allow
   * @maxLength 256
   * @example "TLSv1.2"
   */
  minVersion?: string;
}

/** SMTP configuration */
export enum SMTPUpdate {
  False = 'false'
}

/** OAuth2 configuration */
export enum OAuth2Update {
  False = 'false'
}

/** @example {"name":"Nyan Cat","email":"nyan.cat@example.com","imap":{"partial":true,"disabled":true},"smtp":{"partial":true,"host":"mail.example.com"}} */
export interface UpdateAccount {
  /**
   * Display name for the account
   * @maxLength 256
   * @example "My Email Account"
   */
  name?: string;
  /**
   * Default email address of the account
   * @example "user@example.com"
   */
  email?: string;
  /** Check changes only on selected paths. Either a single string path or an array of paths. Can use references like "\Sent" or "\Inbox". Set to `null` to unset. */
  path?: AccountPath;
  /** An array of mailbox paths. If set, then EmailEngine opens additional IMAP connections against these paths to detect changes faster. Note: Connection counts are usually highly limited. */
  subconnections?: SubconnectionPaths;
  /**
   * Account-specific webhook URL
   * @example "https://myservice.com/imap/webhooks"
   */
  webhooks?: string;
  /**
   * Copy submitted messages to Sent folder. Set to `null` to unset and use provider specific default.
   * @example null
   */
  copy?: boolean;
  /**
   * Store recent logs
   * @example false
   */
  logs?: boolean;
  /**
   * Send webhooks for messages starting from provided date. The default is the account creation date. Only applies for IMAP accounts.
   * @format date-time
   * @example "2021-07-08T07:06:34.336Z"
   */
  notifyFrom?: string;
  /**
   * Proxy URL
   * @example "socks://proxy.example.com:1080"
   */
  proxy?: string;
  /**
   * Hostname to use for the SMTP EHLO/HELO greeting
   * @example "my.proxy.tld"
   */
  smtpEhloName?: string;
  /** IMAP configuration */
  imap?: IMAPUpdate;
  /** SMTP configuration */
  smtp?: SMTPUpdate;
  /** OAuth2 configuration */
  oauth2?: OAuth2Update;
  /** Custom HTTP headers added to webhook requests */
  webhooksCustomHeaders?: AccountWebhooksCustomHeaders;
  /**
   * Optional locale
   * @maxLength 100
   * @example "fr"
   */
  locale?: string;
  /**
   * Optional timezone
   * @maxLength 100
   * @example "Europe/Tallinn"
   */
  tz?: string;
}

export interface Model14 {
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
}

/**
 * Client or Application ID for 3-legged OAuth2 applications
 * @maxLength 256
 * @example "52422112755-3uov8bjwlrullq122rdm6l8ui25ho7qf.apps.googleusercontent.com"
 */
export enum ClientId {
  False = 'false'
}

/** OAuth2 Extra Scopes */
export type Model15 = string[];

/** OAuth2 scopes to skip from the base set */
export type Model16 = string[];

/**
 * Service client ID for 2-legged OAuth2 applications
 * @maxLength 256
 * @example "7103296518315821565203"
 */
export enum ServiceClient {
  False = 'false'
}

/**
 * Azure cloud type for Outlook OAuth2 applications
 * @example "global"
 */
export enum AzureCloud {
  Global = 'global',
  GccHigh = 'gcc-high',
  Dod = 'dod',
  China = 'china'
}

/**
 * Redirect URL for 3-legged OAuth2 applications
 * @example "https://myservice.com/oauth"
 */
export enum RedirectUrl {
  False = 'false'
}

export interface UpdateOAuthApp {
  /**
   * Application name
   * @maxLength 256
   * @example "My Gmail App"
   */
  name?: string;
  /**
   * Application description
   * @maxLength 1024
   * @example "My cool app"
   */
  description?: string;
  /**
   * Title for the application button
   * @maxLength 256
   * @example "App title"
   */
  title?: string;
  /**
   * Enable this app
   * @example true
   */
  enabled?: boolean;
  /** Client or Application ID for 3-legged OAuth2 applications */
  clientId?: ClientId;
  /**
   * Client secret for 3-legged OAuth2 applications
   * @maxLength 256
   * @example "boT7Q~dUljnfFdVuqpC11g8nGMjO8kpRAv-ZB"
   */
  clientSecret?: string;
  /**
   * Cloud Pub/Sub app for Gmail API webhooks
   * @maxLength 512
   * @example "AAAAAQAACnA"
   */
  pubSubApp?: string;
  /** OAuth2 Extra Scopes */
  extraScopes?: Model15;
  /** OAuth2 scopes to skip from the base set */
  skipScopes?: Model16;
  /** Service client ID for 2-legged OAuth2 applications */
  serviceClient?: ServiceClient;
  /** Google Cloud Project ID */
  googleProjectId?: GoogleProjectId;
  /**
   * Show only Google Workspace accounts on the OAuth2 login page
   * @example false
   */
  googleWorkspaceAccounts?: boolean;
  /**
   * Service Client Email for 2-legged OAuth2 applications
   * @example "name@project-123.iam.gserviceaccount.com"
   */
  serviceClientEmail?: string;
  /**
   * PEM formatted service secret for 2-legged OAuth2 applications
   * @maxLength 102400
   * @example "-----BEGIN PRIVATE KEY-----
   * MIIEvgIBADANBgk..."
   */
  serviceKey?: string;
  /**
   * Authorization tenant value for Outlook OAuth2 applications
   * @maxLength 1024
   * @example "common"
   */
  authority?: string;
  /** Azure cloud type for Outlook OAuth2 applications */
  cloud?: AzureCloud;
  /**
   * @maxLength 1024
   * @example "f8cdef31-a31e-4b4a-93e4-5f571e91255a"
   */
  tenant?: string;
  /** Redirect URL for 3-legged OAuth2 applications */
  redirectUrl?: RedirectUrl;
}

export interface UpdateOAuthAppResponse {
  /**
   * OAuth2 app ID
   * @maxLength 256
   * @example "example"
   */
  id: string;
}

/**
 * Add new flags
 * @example ["\\Seen"]
 */
export type AddFlags = string[];

/**
 * Delete specific flags
 * @example ["\\Flagged"]
 */
export type DeleteFlags = string[];

/**
 * Override all flags
 * @example ["\\Seen","\\Flagged"]
 */
export type SetFlags = string[];

/** Flag updates */
export interface FlagUpdate {
  /** Add new flags */
  add?: AddFlags;
  /** Delete specific flags */
  delete?: DeleteFlags;
  /** Override all flags */
  set?: SetFlags;
}

/**
 * Add new labels
 * @example ["Some label"]
 */
export type AddLabels = string[];

/**
 * Delete specific labels
 * @example ["Some label"]
 */
export type DeleteLabels = string[];

/**
 * Override all labels
 * @example ["First label","Second label"]
 */
export type SetLabels = string[];

/** Label updates. Labels can only be used with Gmail IMAP and Gmail API accounts. */
export interface LabelUpdate {
  /** Add new labels */
  add?: AddLabels;
  /** Delete specific labels */
  delete?: DeleteLabels;
  /** Override all labels */
  set?: SetLabels;
}

export interface MessageUpdate {
  /** Flag updates */
  flags?: FlagUpdate;
  /** Label updates. Labels can only be used with Gmail IMAP and Gmail API accounts. */
  labels?: LabelUpdate;
}

export interface MessagesUpdateRequest {
  /** Search query to filter messages */
  search: SearchQuery;
  update?: MessageUpdate;
}

export interface FlagResponse {
  /** @example true */
  add?: boolean;
  /** @example false */
  delete?: boolean;
  /** @example false */
  set?: boolean;
}

export interface MessageUpdateResponse {
  flags?: FlagResponse;
  labels?: FlagResponse;
}

/**
 * New mailbox path as an array or a string. If account is namespaced then namespace prefix is added by default.
 * @example ["Parent folder","Subfolder"]
 */
export type TargetMailboxPath = string[];

export interface RenameMailbox {
  /**
   * Mailbox folder path to rename
   * @example "Previous Folder Name"
   */
  path: string;
  /** New mailbox path as an array or a string. If account is namespaced then namespace prefix is added by default. */
  newPath?: TargetMailboxPath;
}

export interface RenameMailboxResponse {
  /**
   * Mailbox folder path to rename
   * @example "Previous Mail"
   */
  path: string;
  /**
   * Full path to mailbox
   * @example "Kalender/S&APw-nnip&AOQ-evad"
   */
  newPath: string;
  /**
   * Was the mailbox renamed
   * @example true
   */
  renamed?: boolean;
}

export interface RequestSync {
  /**
   * Only sync if true
   * @default false
   */
  sync?: boolean;
}

export interface RequestSyncResponse {
  /**
   * Sync status
   * @default false
   */
  sync?: boolean;
}

export interface RequestReconnect {
  /**
   * Only reconnect if true
   * @default false
   */
  reconnect?: boolean;
}

export interface RequestReconnectResponse {
  /**
   * Reconnection status
   * @default false
   */
  reconnect?: boolean;
}

export interface RequestFlush {
  /**
   * Only flush the account if true
   * @default false
   */
  flush?: boolean;
  /**
   * Send webhooks for messages starting from provided date. The default is the account creation date. Only applies for IMAP accounts.
   * @format date-time
   * @default "now"
   * @example "2021-07-08T07:06:34.336Z"
   */
  notifyFrom?: string;
  /** Sets the account-specific IMAP indexing method. Choose "full" to build a complete index that tracks deleted and updated emails, or "fast" to only detect newly received emails. Defaults to global setting. */
  imapIndexer?: IMAPIndexer;
}

export interface RequestFlushResponse {
  /**
   * Flush status
   * @default false
   */
  flush?: boolean;
}

export interface UpdateGateway {
  /**
   * Account Name
   * @maxLength 256
   * @example "John Smith"
   */
  name?: string;
  /** @maxLength 1024 */
  user?: string;
  /** @maxLength 1024 */
  pass?: string;
  /**
   * Hostname to connect to
   * @example "smtp.gmail.com"
   */
  host?: string;
  /**
   * Service port number
   * @min 1
   * @max 65536
   * @example 465
   */
  port?: number;
  /**
   * Should connection use TLS. Usually true for port 465
   * @example true
   */
  secure?: boolean;
}

export interface UpdateGatewayResponse {
  /**
   * Gateway ID
   * @maxLength 256
   * @example "example"
   */
  gateway: string;
}

export interface SettingsPutQueuePayload {
  /**
   * Set queue state to paused
   * @example false
   */
  paused?: boolean;
}

export interface SettingsPutQueueResponse {
  /** Queue ID */
  queue: Queue;
  /**
   * Is the queue paused or not
   * @example false
   */
  paused?: boolean;
}

export interface UpdateTemplateContent {
  /**
   * Message subject
   * @maxLength 10240
   * @example "What a wonderful message"
   */
  subject?: string;
  /**
   * Plaintext content of the message
   * @maxLength 5242880
   * @example "Hello from myself!"
   */
  text?: string;
  /**
   * HTML content of the message
   * @maxLength 5242880
   * @example "<p>Hello from myself!</p>"
   */
  html?: string;
  /**
   * Preview text that appears in the inbox after the subject line
   * @maxLength 1024
   * @example "Welcome to our newsletter!"
   */
  previewText?: string;
}

export interface UpdateTemplate {
  /**
   * Name of the template
   * @maxLength 256
   * @example "Transaction receipt"
   */
  name?: string;
  /**
   * Optional description of the template
   * @maxLength 1024
   * @example "Something about the template"
   */
  description?: string;
  /** Markup language for HTML ("html" or "markdown") */
  format?: Format;
  content?: UpdateTemplateContent;
}

export interface UpdateTemplateResponse {
  /** Was the template updated or not */
  updated?: boolean;
  /**
   * Account ID
   * @maxLength 256
   * @example "user123"
   */
  account: string;
  /**
   * Template ID
   * @maxLength 256
   * @example "example"
   */
  id: string;
}

export interface MessagesMoveRequest {
  /** Search query to filter messages */
  search: SearchQuery;
  /**
   * Target mailbox folder path
   * @example "INBOX"
   */
  path: string;
}

export type IdMapTuple = string[];

/**
 * An optional map of source and target ID values, if the server provided this info
 * @example [["AAAAAQAACnA","AAAAAwAAAD4"]]
 */
export type IdMapArray = IdMapTuple[];

/** An optional list of emailId values, if the server supports unique email IDs */
export type EmailIdsArray = string[];

export interface MessagesMoveResponse {
  /**
   * Target mailbox folder path
   * @example "INBOX"
   */
  path: string;
  /** An optional map of source and target ID values, if the server provided this info */
  idMap?: IdMapArray;
  /** An optional list of emailId values, if the server supports unique email IDs */
  emailIds?: EmailIdsArray;
}

export interface MessagesDeleteRequest {
  /** Search query to filter messages */
  search: SearchQuery;
}

/** Value is present if messages were moved to Trash */
export interface MessagesMovedToTrash {
  /**
   * Trash folder path
   * @example "Trash"
   */
  destination: string;
  /** An optional map of source and target ID values, if the server provided this info */
  idMap?: IdMapArray;
  /** An optional list of emailId values, if the server supports unique email IDs */
  emailIds?: EmailIdsArray;
}

export interface MessagesDeleteResponse {
  /**
   * Was the delete action executed
   * @example false
   */
  deleted?: boolean;
  /** Value is present if messages were moved to Trash */
  moved?: MessagesMovedToTrash;
}

export interface MessageMove {
  /**
   * Target mailbox folder path
   * @example "INBOX"
   */
  path: string;
}

export interface MessageMoveResponse {
  /**
   * Target mailbox folder path
   * @example "INBOX"
   */
  path: string;
  /**
   * Message ID
   * @maxLength 256
   * @example "AAAAAQAACnA"
   */
  id: string;
  /**
   * UID of moved message
   * @example 12345
   */
  uid?: number;
}
