import React from 'react';
import { Button, Flex, Form, InputNumber, Tooltip, Typography } from 'antd';
import { CurrencyInput } from 'components/CurrencyInput/CurrencyInput';
import { CONTROL_SIZE } from 'consts/common';
import { DeleteOutlined } from '@ant-design/icons';
import { OrderService } from 'services/api/data-contracts';
import { FormInstance } from 'antd/es/form';
import { useWatch } from 'antd/es/form/Form';

interface IProps {
  form: FormInstance<OrderService>;
}

export const OwnPriceForm = ({ form }: IProps) => {
  const price = useWatch('price', form);
  const currency = useWatch('currency', form);

  const handleDeleteOwnPrice = () => {
    form.setFieldsValue({ price: undefined, currency: undefined });
  };

  return (
    <>
      <Typography
        style={{
          fontSize: 14,
          fontWeight: 700,
          lineHeight: '22px',
          marginTop: '24px'
        }}>
        Own price
      </Typography>

      <Flex gap={8}>
        <CurrencyInput required={!!price} />

        <Form.Item name="price" label="Price" rules={[{ required: !!currency }]}>
          <InputNumber precision={2} size={CONTROL_SIZE} style={{ width: 188 }} />
        </Form.Item>

        <Tooltip mouseEnterDelay={1} title="Will be also deleted from the Dictionary">
          <Button
            size={CONTROL_SIZE}
            shape="circle"
            icon={<DeleteOutlined />}
            style={{ marginTop: '24px' }}
            type="text"
            onClick={handleDeleteOwnPrice}
            disabled={!price && !currency}
          />
        </Tooltip>
      </Flex>
    </>
  );
};
