import React from 'react';
import { Col, Row, Space } from 'antd';
import { Balance } from 'services/api/data-contracts';
import { CONTROL_SIZE } from 'consts/common';
import { getAmountValue } from 'helpers';
import { red } from '@ant-design/colors';

interface IProps {
  balance?: Balance;
}

const getBalanceColor = (n: number) => {
  if (n >= 0) {
    return '#52C41A';
  }

  return red[5];
};

export const BalanceCalculationView = ({ balance }: IProps) => {
  if (!balance) {
    return null;
  }

  return (
    <Row>
      {Object.entries(balance)
        .sort(([a], [b]) => a.localeCompare(b))
        .map(([key, value]) => (
          <Col key={key} style={{ whiteSpace: 'nowrap', marginRight: '24px' }}>
            <Space size={CONTROL_SIZE}>
              <Space
                style={{
                  color: getBalanceColor(value.current),
                  fontWeight: '700'
                }}>
                <span>{key.toUpperCase()} </span>
                <span>{getAmountValue(value.current)}</span>
              </Space>
              {value.opening != null && (
                <Space size={4} style={{ fontSize: '12px' }}>
                  <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Start</span>
                  <span>{getAmountValue(value.opening)}</span>
                </Space>
              )}
              {value.closing != null && (
                <Space size={4} style={{ fontSize: '12px' }}>
                  <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>End</span>
                  <span>{getAmountValue(value.closing)}</span>
                </Space>
              )}
            </Space>
          </Col>
        ))}
    </Row>
  );
};
