import React, { useEffect, useMemo, useState } from 'react';
import { Button, Divider, Flex, Popover, Space } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { CONTROL_SIZE } from 'consts/common';
import { FlightFilters } from './FlightFilters';
import { difference, isEqual, omit } from 'lodash';
import { FlightsFilter, useFlightsDashboardStore } from 'store/flightsDashboardStore';
import { OrdersFilter, useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { OrderWidgetFilters } from './OrderWidgetFilters';
import { Airport, OrderType } from 'services/api/data-contracts';
import { defaultFlightsFilter } from 'consts/flight';
import { defaultOrdersFilterForDispatcher, orderTypes } from 'consts/order';
import { AppliedDispatcherFilters } from './AppliedDispatcherFilters';
import { useDashboardStore } from 'store/dashboardStore';
import { MessagesFilter, useMessageStore } from 'store/messageStore';
import { MessageFilters } from '../../components/MessageFilters/MessageFilters';
import { RangeValue } from 'store/helpers/types';
import { useSearchParams } from 'react-router-dom';
import { useCtrlEnterShortcut } from 'helpers';

type Filter = FlightsFilter & OrdersFilter & MessagesFilter;

export const DispatcherFilters = () => {
  const [isOpen, setOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const { setLinkFilters } = useDashboardStore(({ setLinkFilters }) => ({ setLinkFilters }));
  const { setSelectedFlights, flightsFilter, setFlightsFilter, setFlightsPageNumber } =
    useFlightsDashboardStore(
      ({ setSelectedFlights, flightsFilter, setFlightsFilter, setFlightsPageNumber }) => ({
        setSelectedFlights,
        flightsFilter,
        setFlightsFilter,
        setFlightsPageNumber
      })
    );
  const { ordersFilter, setOrdersFilter, setSelectedOrders, widgetMode } = useOrdersDashboardStore(
    ({ ordersFilter, setOrdersFilter, setSelectedOrders, widgetMode }) => ({
      ordersFilter,
      setOrdersFilter,
      setSelectedOrders,
      widgetMode
    })
  );
  const { setSelectedMessages, messagesFilter, setMessagesFilter } = useMessageStore(
    ({ setSelectedMessages, messagesFilter, setMessagesFilter }) => ({
      setSelectedMessages,
      messagesFilter,
      setMessagesFilter
    })
  );

  const [filter, setFilter] = useState<Filter>({
    ...flightsFilter,
    ...ordersFilter,
    ...messagesFilter
  });

  useEffect(() => {
    setOrdersFilter(defaultOrdersFilterForDispatcher);
  }, []);

  useEffect(() => {
    setFilter({ ...flightsFilter, ...ordersFilter, ...messagesFilter });
  }, [flightsFilter, ordersFilter, messagesFilter]);

  useEffect(() => {
    const orderNumbersFilter = searchParams.has('orderNumbers')
      ? searchParams.get('orderNumbers')
      : undefined;
    const orderTypesFilter =
      searchParams.has('orderTypes') && (searchParams.get('orderTypes')?.split(',') as OrderType[]);

    if (orderNumbersFilter || orderTypesFilter) {
      setOrdersFilter({
        ...(orderNumbersFilter ? { orderNumbers: orderNumbersFilter } : {}),
        ...(orderTypesFilter ? { orderTypes: orderTypesFilter } : {})
      });
    }
  }, []);

  useEffect(() => {
    const isOrderTypesFilterSet =
      ordersFilter.orderTypes && ordersFilter.orderTypes.length !== orderTypes.length;

    if (ordersFilter.orderNumbers || isOrderTypesFilterSet) {
      setSearchParams({
        ...(ordersFilter.orderNumbers && { orderNumbers: ordersFilter.orderNumbers }),
        ...(ordersFilter.orderTypes && { orderTypes: ordersFilter.orderTypes.join(',') })
      });
    } else {
      setSearchParams({});
    }
  }, [ordersFilter]);

  const handleChangeFilter = (
    name: string,
    value:
      | string
      | RangeValue
      | OrderType[]
      | boolean
      | string[]
      | Airport
      | { label: string; value: number | string }[]
      | undefined
  ) => {
    if (value) {
      setFilter((filter) => ({ ...filter, [name]: value }));
    } else {
      setFilter((filter) => omit(filter, name));
    }
  };

  const handleApplyFilter = () => {
    const isFilterUpdated = (prev: Partial<Filter>, curr: Partial<Filter>) => {
      const prevValues = Object.values(prev).filter((i) => !!i);
      const currValues = Object.values(curr).filter((i) => !!i);

      return (
        difference(currValues, prevValues).length > 0 ||
        difference(prevValues, currValues).length > 0
      );
    };

    const updatedFlightsFilter = {
      estimatedTimeDeparture: filter.estimatedTimeDeparture,
      flightNumber: filter.flightNumber,
      flightsAircrafts: filter.flightsAircrafts,
      operators: filter.operators,
      airport: filter.airport
    };

    if (isFilterUpdated(flightsFilter, updatedFlightsFilter)) {
      setFlightsFilter(updatedFlightsFilter);
      setSelectedFlights([]);
      setFlightsPageNumber(1);
    }

    const updatedOrdersFilter = {
      orderStartDate: filter.orderStartDate,
      orderEndDate: filter.orderEndDate,
      orderTypes: filter.orderTypes,
      customers: filter.customers,
      locations: filter.locations,
      payers: filter.payers,
      providers: filter.providers,
      suppliers: filter.suppliers,
      orderNumbers: filter.orderNumbers,
      orderStatuses: filter.orderStatuses,
      ordersAircrafts: filter.ordersAircrafts
    };

    if (isFilterUpdated(ordersFilter, updatedOrdersFilter)) {
      setOrdersFilter(updatedOrdersFilter);
      setSelectedOrders([]);
    }

    const updatedMessagesFilter = {
      recipientOrSender: filter.recipientOrSender,
      emailSubject: filter.emailSubject,
      emailBody: filter.emailBody,
      messageSentDate: filter.messageSentDate,
      withAttachment: filter.withAttachment,
      flags: filter.flags,
      links: filter.links
    };

    if (isFilterUpdated(messagesFilter, updatedMessagesFilter)) {
      setMessagesFilter({ showDone: messagesFilter.showDone, ...updatedMessagesFilter });
      setSelectedMessages([]);
    }

    setOpen(false);
  };

  useCtrlEnterShortcut(handleApplyFilter, isOpen);

  const handleResetFilter = () => {
    setFilter({
      ...defaultFlightsFilter,
      ...defaultOrdersFilterForDispatcher,
      showDone: messagesFilter.showDone
    });

    setLinkFilters([]);

    setMessagesFilter({
      showDone: messagesFilter.showDone
    });
    setSelectedMessages([]);

    setFlightsFilter(defaultFlightsFilter);
    setSelectedFlights([]);
    setFlightsPageNumber(1);

    setOrdersFilter(defaultOrdersFilterForDispatcher);
    setSelectedOrders([]);
  };

  const areFiltersEqualToDefault = useMemo(
    () =>
      isEqual(filter, {
        ...defaultFlightsFilter,
        ...defaultOrdersFilterForDispatcher,
        showDone: messagesFilter.showDone
      }),
    [filter, messagesFilter]
  );

  const popoverContent = (
    <div style={{ marginBottom: '18px' }} data-testid="filters-popover">
      <Flex justify="flex-end" align="center">
        <Space>
          <Button size={CONTROL_SIZE} type="primary" onClick={handleApplyFilter}>
            Apply
          </Button>
        </Space>
      </Flex>

      <Divider orientation="left">Messages</Divider>
      <MessageFilters onChange={handleChangeFilter} filter={filter} />

      <Divider orientation="left">Flights</Divider>
      <FlightFilters onChange={handleChangeFilter} filter={filter} />

      <Divider orientation="left">Orders and Programs</Divider>
      <OrderWidgetFilters onChange={handleChangeFilter} filter={filter} />
    </div>
  );

  return (
    <Flex style={{ margin: '12px 0' }} justify="flex-start" align="center" gap={8}>
      <Popover
        mouseEnterDelay={1}
        content={popoverContent}
        placement="bottomRight"
        open={isOpen}
        trigger="click"
        onOpenChange={() => setOpen(false)}
        overlayInnerStyle={{
          width: '1134px'
        }}>
        <Button
          icon={<FilterOutlined />}
          size={CONTROL_SIZE}
          title="DispatcherFilters"
          onClick={() => setOpen((isOpen) => !isOpen)}
        />
      </Popover>

      <AppliedDispatcherFilters
        filter={filter}
        setFilter={setFilter}
        ordersWidgetMode={widgetMode}
      />

      {!areFiltersEqualToDefault && (
        <Button ghost type="primary" size={CONTROL_SIZE} onClick={handleResetFilter}>
          Reset all
        </Button>
      )}
    </Flex>
  );
};
