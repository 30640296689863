import React from 'react';
import { ColumnsType } from 'antd/es/table';
import {
  CustomerStatus,
  OrderService,
  PaymentType,
  SupplierStatus
} from 'services/api/data-contracts';
import { Popover, Tooltip } from 'antd';
import { ProfileOutlined } from '@ant-design/icons';
import { getOrderTerm, getUTCDate } from 'helpers';
import {
  CustomerStatusTag,
  PaymentTypeIcon,
  SupplierStatusTag
} from '../../../../components/OrderServiceDrawer';
import { OrderStatusTag } from '../../OrderStatusTag';

export const getColumns = (): ColumnsType<OrderService> => [
  {
    dataIndex: 'order',
    key: 'order',
    width: 30,
    render: (_, record) => {
      const order = record.order;

      if (!order) {
        return null;
      }

      const title = (
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <OrderStatusTag status={order.status} />
          {order.type}
        </div>
      );

      const content = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>
            {[
              order.number,
              order.location && 'code' in order.location
                ? order.location.code
                : order.location?.name,
              getOrderTerm(getUTCDate(order.startDatetime), getUTCDate(order.endDatetime))
            ].join(' | ')}
          </span>
          <span>{order.customer?.name}</span>
        </div>
      );

      return (
        <Popover mouseEnterDelay={1} content={content} title={title} placement="leftTop">
          <ProfileOutlined style={{ color: '#878787' }} />
        </Popover>
      );
    }
  },
  {
    title: 'CusSt',
    dataIndex: 'customerStatus',
    key: 'customerStatus',
    width: 60,
    render: (value) => value && <CustomerStatusTag status={value as CustomerStatus} />
  },
  {
    title: 'Service',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    render: (_, record) => (
      <Tooltip mouseEnterDelay={1} title={record.service?.name}>
        {record.service?.name}
      </Tooltip>
    )
  },
  {
    title: 'Supplier',
    dataIndex: 'supplier',
    key: 'supplier',
    ellipsis: true,
    render: (_, record) => (
      <Tooltip mouseEnterDelay={1} title={record.supplier?.name}>
        {record.supplier?.name}
      </Tooltip>
    )
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
    ellipsis: true,
    render: (_, record) => (
      <Tooltip mouseEnterDelay={1} title={record.provider?.name}>
        {record.provider?.name}
      </Tooltip>
    )
  },
  {
    title: 'SupSt',
    dataIndex: 'supplierStatus',
    key: 'supplierStatus',
    width: 60,
    render: (value) => value && <SupplierStatusTag status={value as SupplierStatus} />
  },
  {
    title: '$',
    dataIndex: 'paymentType',
    key: 'paymentType',
    width: 32,
    render: (value) => <PaymentTypeIcon paymentType={value as PaymentType} />,
    align: 'center'
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    render: (value) => (
      <Tooltip mouseEnterDelay={1} title={value}>
        <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
          {value}
        </span>
      </Tooltip>
    )
  }
];
