import React from 'react';
import { Flex } from 'antd';
import {
  InvoiceDetailsBlock,
  InvoiceItemsBlock,
  PaymentPurposeBlock,
  CreditNotesBlock,
  InvoiceServicesBlock
} from './accordions';
import { useInvoiceStore } from 'store/invoiceStore';
import { UploadFileButton } from 'pages/widgets/components/UploadFileButton/UploadFileButton';
import { InvoiceType } from 'services/api/data-contracts';

interface IProps {
  setAddServiceDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InvoiceDetails = ({ setAddServiceDrawerOpen }: IProps) => {
  const { isInvoiceEditMode, current } = useInvoiceStore(({ isInvoiceEditMode, current }) => ({
    isInvoiceEditMode,
    current
  }));

  return (
    <Flex gap={8} vertical>
      {!isInvoiceEditMode && (
        <div style={{ marginTop: '8px' }}>
          <UploadFileButton
            relatedId={current?.id}
            counterpartyId={
              current?.type === InvoiceType.Issued ? current?.payerId : current?.supplierId
            }
            entityName="Invoice"
          />
        </div>
      )}

      <InvoiceDetailsBlock />

      {!isInvoiceEditMode && (
        <>
          <InvoiceItemsBlock />
          <InvoiceServicesBlock setAddServiceDrawerOpen={setAddServiceDrawerOpen} />
          <PaymentPurposeBlock />
          <CreditNotesBlock />
        </>
      )}
    </Flex>
  );
};
