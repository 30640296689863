import React from 'react';
import { Flex, Input, Tooltip } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { FlightsFilter } from 'store/flightsDashboardStore';
import { StyledDateRangePicker } from 'components/common/StyledDatePicker';
import { RangeValue } from 'store/helpers/types';
import { Airport } from 'services/api/data-contracts';
import { AirportSelect } from 'components/AirportsInput/AirportSelect';
import { AircraftMultipleInput } from 'components/AircraftInput/AircraftMultipleInput';
import { CounterpartyMultipleInput } from 'components/CounterpartyInput/CounterpartyMultipleInput';

interface IProps {
  onChange: (
    name: string,
    value:
      | string
      | RangeValue
      | Airport
      | {
          value: number;
          label: string;
        }[]
      | undefined
  ) => void;
  filter: FlightsFilter;
}

export const FlightFilters = ({ onChange, filter }: IProps) => (
  <Flex vertical gap={8}>
    <Flex gap={20} justify="space-between" style={{ width: '100%' }}>
      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Operator">
        <div style={{ minWidth: '220px', width: '220px' }}>
          <CounterpartyMultipleInput
            isOperator
            listHeight={180}
            placeholder="Select operators"
            values={filter.operators}
            onChange={(operators) => onChange('operators', operators)}
          />
        </div>
      </Tooltip>

      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Aircraft">
        <div style={{ minWidth: '220px', width: '220px' }}>
          <AircraftMultipleInput
            values={filter.flightsAircrafts}
            onChange={(aircrafts) => onChange('flightsAircrafts', aircrafts)}
          />
        </div>
      </Tooltip>

      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Flight number">
        <Input
          size={CONTROL_SIZE}
          placeholder="All flight numbers"
          name="flightNumber"
          onChange={({ target: { name, value } }) => onChange(name, value)}
          value={filter.flightNumber}
          allowClear
          autoComplete="off"
          style={{ minWidth: '220px', width: '220px' }}
        />
      </Tooltip>

      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Estimated time departure">
        <StyledDateRangePicker
          placeholder={['Est Dep from', 'To']}
          value={filter.estimatedTimeDeparture}
          name="estimatedTimeDeparture"
          onChange={(value) => {
            onChange('estimatedTimeDeparture', value);
          }}
          format={DATE_FORMAT}
          onSetDate={(value) => {
            onChange('estimatedTimeDeparture', value);
          }}
        />
      </Tooltip>
    </Flex>
    <div style={{ minWidth: '220px', width: '220px' }}>
      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Airport">
        <AirportSelect
          placeholder="All airports"
          onSetAirport={(value) => onChange('airport', value)}
          value={filter.airport?.id}
        />
      </Tooltip>
    </div>
  </Flex>
);
