import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { MESSAGES_QUERY_KEY } from './consts';
import { SubmitMessage, SubmitMessageResponse } from 'services/emailEngineApi/data-contracts';
import { messagesApi } from 'services/emailEngineApi';
import { settingsStore } from 'services/settings/SettingsStore';
import { addQueuedMessageToStorage } from './helpers/localStorage';
import { fetchLinks } from 'queries/links/useGetLinks';
import { getSourceLinks } from '../../pages/widgets/MessagesWidget/components/ActionsDropdown/helpers/getSourceLinks';
import { map } from 'lodash';
import { NewMessageMode, useMessageStore } from 'store/messageStore';
import { ACTION } from 'pages/widgets/MessagesWidget/components/NewMessage/constants';
import { LinkEntityItem } from 'services/api/data-contracts';

const addMessageToStorage = async (
  response: SubmitMessageResponse,
  queryClient: QueryClient,
  newMessageMode: NewMessageMode,
  pendingEntitiesToLinkFromTemplate?: Array<LinkEntityItem>
) => {
  const addMessage = (source: LinkEntityItem[]) => {
    addQueuedMessageToStorage({
      messageId: `${response.messageId}`,
      referenceId: response.reference?.message,
      source
    });
  };

  if (newMessageMode === ACTION.FROM_ORDER && pendingEntitiesToLinkFromTemplate?.length) {
    addMessage(
      getSourceLinks({
        orderNumbers: pendingEntitiesToLinkFromTemplate.map((item) => item.entityId as string)
      })
    );
  } else if (
    newMessageMode === ACTION.WITH_INVOICE_ATTACHMENT &&
    pendingEntitiesToLinkFromTemplate?.length
  ) {
    addMessage(
      getSourceLinks({
        invoiceIds: pendingEntitiesToLinkFromTemplate.map((item) => item.entityId as number)
      })
    );
  } else if (newMessageMode === ACTION.REPLY || newMessageMode === ACTION.REPLY_ALL) {
    const data = await queryClient.fetchQuery(
      ['getLinks', { messageIds: response.reference?.message }],
      () => fetchLinks({ messageIds: response.reference?.message })
    );

    if (data?.data) {
      addMessage(
        getSourceLinks({
          flightIds: map(data.data.flights, 'id'),
          invoiceIds: map(data.data.invoices, 'id'),
          orderNumbers: map(data.data.orders, 'number')
        })
      );
    }
  }
};

const createItem = async (data: SubmitMessage) => {
  const account = settingsStore.getMailboxSettings()?.mailbox_name || '';
  const response = await messagesApi.postV1AccountAccountSubmit(account, {
    ...data,
    from: {
      name: account,
      address: account
    },
    copy: true
  });

  return response.data;
};

export const useCreateMessage = (shouldAddLinks = false, onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  const { newMessageMode, pendingEntitiesToLinkFromTemplate } = useMessageStore(
    ({ newMessageMode, pendingEntitiesToLinkFromTemplate }) => ({
      newMessageMode,
      pendingEntitiesToLinkFromTemplate
    })
  );

  return useMutation({
    mutationFn: createItem,
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: [MESSAGES_QUERY_KEY] });

      if ((shouldAddLinks || pendingEntitiesToLinkFromTemplate?.length) && response.messageId) {
        await addMessageToStorage(
          response,
          queryClient,
          newMessageMode,
          pendingEntitiesToLinkFromTemplate
        );
      }

      onSuccess && onSuccess();
    }
  });
};
