import React from 'react';
import { CreditNoteStatus, InvoiceStatus } from 'services/api/data-contracts';
import { Tooltip } from 'antd';
import { geekblue, gold, gray, green, purple, red } from '@ant-design/colors';
import { SmallTag } from 'components/common/SmallTag';

export type DocumentStatus = InvoiceStatus | CreditNoteStatus | 'P' | 'NP';

const documentStatusColors: Record<DocumentStatus, { tag: string; border: string; font: string }> =
  {
    [InvoiceStatus.RFSN]: { tag: purple[0], border: purple[4], font: purple[4] },
    [InvoiceStatus.SENT]: { tag: green[6], border: green[7], font: '#FFF' },
    [InvoiceStatus.CNLD]: { tag: red[0], border: red[6], font: red[6] },
    [InvoiceStatus.RINV]: { tag: gold[3], border: gold[5], font: 'inherit' },
    P: { tag: geekblue[0], border: geekblue[4], font: geekblue[4] },
    NP: { tag: gray[1], border: gray[6], font: gray[6] }
  };

export const documentStatusTitles: Record<DocumentStatus, string> = {
  [InvoiceStatus.RFSN]: 'Ready for sending',
  [InvoiceStatus.SENT]: 'Is sent',
  [InvoiceStatus.CNLD]: 'Canceled',
  [InvoiceStatus.RINV]: 'Reinvoiced',
  P: 'Posted',
  NP: 'Not posted'
};

interface IProps {
  status: DocumentStatus;
}

export const DocumentStatusTag = ({ status }: IProps) => (
  <Tooltip title={documentStatusTitles[status]}>
    <SmallTag
      color={documentStatusColors[status]?.tag}
      style={{
        color: documentStatusColors[status]?.font,
        borderColor: documentStatusColors[status]?.border
      }}>
      {status === InvoiceStatus.RFSN ? 'RFS' : status === InvoiceStatus.SENT ? 'IS' : status}
    </SmallTag>
  </Tooltip>
);
