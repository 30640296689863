import { ColumnsType } from 'antd/es/table';
import { Counterparty } from 'services/api/data-contracts';
import { stringSorter } from '../utils/tableSorters';
import React from 'react';
import { DecoratedText } from 'pages/components/DecoratedText';

export const getCounterpartyColumns = (): ColumnsType<Counterparty> => [
  {
    title: 'Short name',
    dataIndex: 'name',
    key: 'name',
    render: (value, record) => <DecoratedText isActive={record.isActive}>{value}</DecoratedText>,
    sorter: (a, b) => stringSorter(a.name, b.name),
    defaultSortOrder: 'ascend',
    ellipsis: true
  },
  {
    title: 'Full name',
    dataIndex: 'fullName',
    key: 'fullName',
    render: (value, record) => <DecoratedText isActive={record.isActive}>{value}</DecoratedText>,
    sorter: (a, b) => stringSorter(a.fullName, b.fullName),
    ellipsis: true
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    render: (_, record) => (
      <DecoratedText isActive={record.isActive}>{record.country?.name || '-'}</DecoratedText>
    ),
    sorter: (a, b) => stringSorter(a.country?.name || '', b.country?.name || '')
  },
  {
    title: 'Registration №',
    dataIndex: 'registrationNumber',
    key: 'registrationNumber',
    sorter: (a, b) => stringSorter(a.registrationNumber || '', b.registrationNumber || ''),
    render: (value, record) => <DecoratedText isActive={record.isActive}>{value}</DecoratedText>,
    ellipsis: true
  }
];
