import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useHighlightRow = () => {
  const [highlightedId, setHighlightedId] = useState<number | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const invoiceItemId = searchParams.get('invoiceItemId');

    if (invoiceItemId && !isNaN(Number(invoiceItemId))) {
      setHighlightedId(Number(invoiceItemId));

      const timer = setTimeout(() => {
        setHighlightedId(null);
        searchParams.delete('invoiceItemId');
        setSearchParams(searchParams);
      }, 120000); // 120 seconds

      return () => {
        searchParams.delete('invoiceItemId');
        setSearchParams(searchParams);
        clearTimeout(timer);
      };
    }
  }, [searchParams, setSearchParams]);

  return highlightedId;
};
