import { Contract, Payment } from 'services/api/data-contracts';
import { BalanceFilter } from 'store/balanceReportStore';
import { paymentInitialData } from 'consts/payment';

export const getPaymentData = (data: { filter?: BalanceFilter; contracts: Contract[] }) =>
  ({
    ...paymentInitialData,
    payerId: data.filter?.counterpartyId,
    supplierId: data.filter?.counterpartyId
  }) as unknown as Payment;
