import { BalanceViewItems, Invoice, Payment } from 'services/api/data-contracts';
import { InvoiceStatus, PaymentStatus } from 'store/balanceReportStore';
import dayjs from 'dayjs';

export const isInvoice = (entity: BalanceViewItems[number]) =>
  entity.hasOwnProperty('invoiceNumber');

export const areInvoicesInSameCurrency = (invoices: Invoice[]): boolean => {
  if (invoices.length === 0) return true;
  const currency = invoices[0].currency;
  return invoices.every((invoice) => invoice.currency === currency);
};

export const arePaymentsInCurrency = (payments: Payment[], currency?: string): boolean => {
  if (!currency) return true;

  return payments.every((payment) => payment.currency === currency);
};

export const sortInvoicesByDateAndAmountDue = (invoices: Invoice[], advance: number) =>
  invoices.sort((a, b) => {
    if (a.amountDue === advance && b.amountDue !== advance) {
      return -1;
    } else if (a.amountDue !== advance && b.amountDue === advance) {
      return 1;
    } else {
      return new Date(a.invoiceDate).getTime() - new Date(b.invoiceDate).getTime();
    }
  });

export const filterInvoices = (items: BalanceViewItems, invoiceStatus: InvoiceStatus[]) =>
  items.filter((item) => {
    if (!isInvoice(item)) return true;

    const invoice = item as Invoice;

    const isPaid = invoice.amountDue === 0;
    const isExpired = dayjs(invoice.dueDate).isBefore(dayjs());
    const isPartiallyPaid = invoice.amountDue > 0 && invoice.amountDue < invoice.invoiceTotal;
    const isUnpaid = invoice.amountDue === invoice.invoiceTotal;

    return (
      (invoiceStatus.includes(InvoiceStatus.Paid) && isPaid) ||
      (invoiceStatus.includes(InvoiceStatus.Expired) && isExpired) ||
      (invoiceStatus.includes(InvoiceStatus.Unpaid) && isUnpaid) ||
      (invoiceStatus.includes(InvoiceStatus.PartlyPaid) && isPartiallyPaid)
    );
  });

export const filterPayments = (items: BalanceViewItems, paymentStatus: PaymentStatus[]) =>
  items.filter((item) => {
    if (isInvoice(item)) return true;

    const payment = item as Payment & { advance: number };

    const isLinked = payment.advance === 0;
    const isPartiallyLinked = payment.advance > 0 && payment.advance < payment.amount;
    const isNotLinked = payment.advance === payment.amount;

    return (
      (paymentStatus.includes(PaymentStatus.Linked) && isLinked) ||
      (paymentStatus.includes(PaymentStatus.PartiallyLinked) && isPartiallyLinked) ||
      (paymentStatus.includes(PaymentStatus.NotLinked) && isNotLinked)
    );
  });
