import React from 'react';
import { RebateReportCurrencyGroup } from 'services/api/data-contracts';
import { CONTROL_SIZE } from 'consts/common';
import { Table } from 'antd';
import { getAmountValue } from 'helpers';
import { RebatesExpandableTable } from './RebatesExpandableTable';

interface IProps {
  data: RebateReportCurrencyGroup[];
  customerId?: number;
  customerName?: string;
}

export const CurrenciesExpandableTable = ({ data, customerId, customerName }: IProps) => {
  const dataSource = data.map((item) => ({
    ...item,
    key: `${customerId}-${item.currency}`
  }));

  return (
    <Table
      dataSource={dataSource}
      key={customerName}
      columns={[
        {
          key: 'currency',
          render: (_, record) => `${record.currency} ${getAmountValue(record.total)}`
        }
      ]}
      showHeader={false}
      pagination={false}
      size={CONTROL_SIZE}
      expandable={{
        defaultExpandAllRows: true,
        expandedRowRender: (record) => (
          <RebatesExpandableTable
            items={record.invoiceItems}
            key={record.key}
            currency={record.currency}
            customerId={customerId}
            customerName={customerName}
          />
        ),
        rowExpandable: (record) => !!record.invoiceItems && record.invoiceItems?.length > 0,
        columnWidth: 32
      }}
    />
  );
};
