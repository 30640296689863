import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { counterpartiesApi } from 'services/api';
import { Contact } from 'services/api/data-contracts';
import { CONTACTS_QUERY_KEY } from './consts';
import { MESSAGE_DURATION } from 'consts/common';

const updateItem = async (counterpartyId: number, { id, ...data }: Contact) =>
  await counterpartiesApi.updateContact(counterpartyId, id, data);

export const useUpdateCounterpartyContact = (counterpartyId: number, onSubmit: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Contact) => updateItem(counterpartyId, data),
    onSuccess: async (response) => {
      await queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEY, counterpartyId] });

      message.success(
        `Contact "${response.data.emailAddress}" has been successfully updated`,
        MESSAGE_DURATION
      );

      onSubmit();
    }
  });
};
