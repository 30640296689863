import React from 'react';
import { Divider, Flex } from 'antd';
import { MessageDetails as Message } from 'services/emailEngineApi/data-contracts';
import { ShadowRenderedMessage } from '../../../MessageView/ShadowRenderedMessage';
import styled from 'styled-components';
import { transformDate } from './helpers/transformDate';
import { NewMessageMode } from 'store/messageStore';
import { ACTION } from '../../constants';

interface IProps {
  mode: Extract<NewMessageMode, 'reply' | 'forward' | 'replyAll'>;
  message?: Message;
}

const StyledBlockquote = styled.blockquote`
  border-left: 1px solid grey;
  padding-left: 6px;
  margin-inline-start: 12px;
`;

const StyledFlex = styled(Flex)`
  color: rgba(0, 0, 0, 0.45);
  font-weight: 500;
`;

const StyledAddress = styled.span`
  color: #0000ee;
`;

export const AnsweredMessage = ({ message, mode }: IProps) => {
  const htmlString = message?.text?.html || message?.text?.plain || '';
  const name = message?.from?.name ?? '';
  const address = message?.from?.address ?? '';
  const subject = message?.subject ?? '';
  const createdAt = transformDate(message?.date as string);

  const header =
    mode === ACTION.REPLY || mode === ACTION.REPLY_ALL ? (
      <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontWeight: '500' }}>
        {`On ${createdAt} ${name} `}
        <StyledAddress>{`<${address}>`}</StyledAddress>
        {`wrote:`}
      </span>
    ) : (
      <StyledFlex vertical>
        <Flex>&gt; Begin forwarded message:</Flex>
        <Flex>&gt;</Flex>
        <Flex>
          {`From: ${name} `}
          <StyledAddress>{`<${address}>`}</StyledAddress>
        </Flex>
        <Flex>{`Subject: ${subject}`}</Flex>
        <Flex>{`Date: ${createdAt}`}</Flex>
        <Flex>
          {'To: '}
          {message?.to?.map((email, index, arr) => [
            <StyledAddress key={index}>{`<${email.address}>`}</StyledAddress>,
            index < arr.length - 1 && ', '
          ])}
        </Flex>
        {message?.cc && message?.cc.length > 0 && (
          <Flex>{`CC: ${message.cc?.map((email) => '<' + email.address + '>').join(', ')}`}</Flex>
        )}
      </StyledFlex>
    );

  return (
    <Flex vertical style={{ margin: '0 12px 12px 12px' }}>
      <Divider style={{ margin: '6px' }} />

      <Flex>{header}</Flex>
      {message && htmlString && (
        <StyledBlockquote>
          <ShadowRenderedMessage dangerousHTML={htmlString} isLoading={false} />
        </StyledBlockquote>
      )}
    </Flex>
  );
};
