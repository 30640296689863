import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Flex, Select } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { difference, isEqual, omit } from 'lodash';
import { RangeValue } from 'store/helpers/types';
import { StyledDateRangePicker } from 'components/common/StyledDatePicker';
import { settingsStore } from 'services/settings/SettingsStore';
import { getSelectOptions, useCtrlEnterShortcut } from 'helpers';
import { RebateReportFilter, useRebateReportStore } from 'store/rebateReportStore';
import { defaultRebateReportFilter } from 'consts/rebateReport';
import { CounterpartyMultipleInput } from 'components/CounterpartyInput/CounterpartyMultipleInput';
import { ServiceNameMultipleInput } from 'components/ServiceNameInput/ServiceNameMultipleInput';
import { ContractType } from 'services/api/data-contracts';

export const RebateReportFilters = () => {
  const [filter, setFilter] = useState<RebateReportFilter>(defaultRebateReportFilter);

  const { allowedCurrencies = [] } = settingsStore.getCurrentTenant();

  const { filter: rebateFilter, setFilter: setRebateFilter } = useRebateReportStore();

  useEffect(
    () => () => {
      setRebateFilter({});
    },
    []
  );

  const handleChangeFilter = (
    name: string,
    value: string | string[] | number[] | RangeValue | undefined
  ) => {
    if (value) {
      setFilter((filter) => ({ ...filter, [name]: value }));
    } else {
      setFilter((filter) => omit(filter, name));
    }
  };

  const isFilterUpdated = useCallback(
    (prev: Partial<RebateReportFilter>, curr: Partial<RebateReportFilter>) => {
      const prevValues = Object.values(prev).filter((i) => !!i);
      const currValues = Object.values(curr).filter((i) => !!i);

      return (
        difference(currValues, prevValues).length > 0 ||
        difference(prevValues, currValues).length > 0
      );
    },
    []
  );

  const isApplyAvailable = useMemo(
    () => isFilterUpdated(rebateFilter, filter),
    [filter, rebateFilter]
  );

  const isResetAvailable = useMemo(() => !isEqual(filter, defaultRebateReportFilter), [filter]);

  const handleApplyFilter = () => {
    const updatedFilter = { ...filter };

    const isUpdated = isFilterUpdated(rebateFilter, updatedFilter);

    if (isUpdated) {
      setRebateFilter(updatedFilter);
    }
  };

  useCtrlEnterShortcut(handleApplyFilter, isApplyAvailable);

  const handleResetFilter = () => {
    setFilter(defaultRebateReportFilter);
    setRebateFilter(defaultRebateReportFilter);
  };

  return (
    <Flex gap={8}>
      <Select
        mode="multiple"
        maxTagCount="responsive"
        placeholder="All currencies"
        data-testid="currency-select"
        value={filter.currencies}
        options={getSelectOptions(allowedCurrencies)}
        onChange={(value) => handleChangeFilter('currencies', value)}
        allowClear
        style={{ width: '120px' }}
        size={CONTROL_SIZE}
        showSearch
      />

      <div style={{ width: 260 }}>
        <StyledDateRangePicker
          placeholder={['From', 'To']}
          value={filter.date}
          status={!filter.date ? 'error' : ''}
          onChange={(value) => {
            handleChangeFilter('date', value);
          }}
          format={DATE_FORMAT}
          onSetDate={(value) => {
            handleChangeFilter('date', value);
          }}
        />
      </div>

      <div style={{ width: 240 }}>
        <CounterpartyMultipleInput
          onChange={(values) =>
            handleChangeFilter(
              'customerIds',
              values?.map(({ value }) => value)
            )
          }
          placeholder="All customers"
          isSelf={false}
          contractType={ContractType.WithCustomer}
        />
      </div>

      <div style={{ width: 240 }}>
        <ServiceNameMultipleInput
          onChange={(values) => handleChangeFilter('serviceNameIds', values)}
        />
      </div>

      {isResetAvailable && (
        <Button ghost type="primary" size={CONTROL_SIZE} onClick={handleResetFilter}>
          Reset all
        </Button>
      )}

      {isApplyAvailable && (
        <Button size={CONTROL_SIZE} type="primary" onClick={handleApplyFilter}>
          Apply
        </Button>
      )}
    </Flex>
  );
};
