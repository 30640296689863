import { ConfigProvider, Empty, Form, Popover, Select } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import React, { useMemo } from 'react';
import { useGetRebatesForInvoiceItem } from 'queries/rebate/useGetRebatesForInvoiceItem';
import { getRebateSign, isAgentFeeRebate, RebateTypeMap } from 'store/rebateStore';
import { getAmountValue, getUTCDate } from 'helpers';
import { InfoCircleOutlined } from '@ant-design/icons';
import { RebateTooltip } from './RebateTooltip';
import { useInvoiceStore } from 'store/invoiceStore';
import { InvoiceType } from 'services/api/data-contracts';

interface IProps {
  name: string | (string | number)[];
  label?: string;
  serviceNameId: number;
  orderServiceId?: number;
}

export const RebateInput = ({ label, name, serviceNameId, orderServiceId }: IProps) => {
  const { isIssuedInvoice, invoicePayerId, invoiceSupplyDate, invoiceCurrency } = useInvoiceStore(
    ({ current }) => ({
      isIssuedInvoice: current?.type === InvoiceType.Issued,
      invoiceSupplyDate: current?.supplyDate,
      invoicePayerId: Number(current?.payerId),
      invoiceCurrency: current?.currency || ''
    })
  );

  const { isLoading, data } = useGetRebatesForInvoiceItem(
    {
      serviceNameId,
      orderServiceId,
      invoiceSupplyDate,
      invoicePayerId,
      invoiceCurrency
    },
    !!(isIssuedInvoice && serviceNameId && invoicePayerId && invoiceCurrency)
  );

  const options = useMemo(
    () =>
      (data?.data.items || []).map(
        ({
          id,
          isActive,
          type,
          value,
          currency,
          unitOfMeasure,
          startDate,
          endDate,
          priceInInvoiceCurrency
        }) => {
          const rebateSign = getRebateSign(type);
          const rebateValue = isAgentFeeRebate(type)
            ? `${rebateSign}${value}%`
            : `${rebateSign}${getAmountValue(priceInInvoiceCurrency || value)}`;

          return {
            label: rebateValue,
            dropdownLabel:
              (isAgentFeeRebate(type)
                ? `${rebateSign}${value}%`
                : `${currency} ${rebateSign}${value} per ${unitOfMeasure.shortName}`) +
              ` ${RebateTypeMap[type].toLowerCase()} ${getUTCDate(startDate).format(DATE_FORMAT)} - ${
                endDate ? getUTCDate(endDate).format(DATE_FORMAT) : '∞'
              }`,
            extraLabel:
              priceInInvoiceCurrency && currency !== invoiceCurrency
                ? `${invoiceCurrency} ${rebateSign}${getAmountValue(priceInInvoiceCurrency)}`
                : null,
            value: id,
            disabled: !isActive
          };
        }
      ),
    [data?.data.items]
  );

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="No data" image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
      <Form.Item
        name={name}
        label={
          label ? (
            <>
              <span style={{ marginRight: 4 }}>{label}</span>
              <Popover
                mouseEnterDelay={1}
                content={<RebateTooltip />}
                trigger="hover"
                placement="topLeft">
                <InfoCircleOutlined style={{ color: '#00000073' }} />
              </Popover>
            </>
          ) : null
        }
        style={{ margin: 0 }}>
        <Select
          allowClear
          style={{ width: '100%' }}
          placeholder="0.00"
          options={options}
          size={CONTROL_SIZE}
          showSearch
          optionFilterProp="dropdownLabel"
          optionRender={(option) => (
            <div aria-label={option.data.label?.toString()}>
              <span>{option.data.dropdownLabel}</span>
              {option.data.extraLabel && (
                <>
                  <br />
                  <span style={{ color: '#00000073' }}>{option.data.extraLabel}</span>
                </>
              )}
            </div>
          )}
          loading={isLoading}
          popupMatchSelectWidth={false}
          disabled={!data?.data.items?.length}
        />
      </Form.Item>
    </ConfigProvider>
  );
};
