import { getUTCDate } from 'helpers';
import {
  ContractPaymentType,
  CreditNoteItemInput,
  InvoiceHydrated,
  InvoiceType,
  PaymentHydrated,
  PaymentStatus,
  TransactionType
} from 'services/api/data-contracts';

export const transformForRequest = (
  formData: Pick<PaymentHydrated, 'notes'> & { creditNoteItems: CreditNoteItemInput[] },
  invoice: InvoiceHydrated
) => {
  const today = getUTCDate().format();

  return {
    transactionType: TransactionType.CreditNote,
    items: formData.creditNoteItems,
    amount: formData.creditNoteItems.reduce(
      (acc: number, item: CreditNoteItemInput) => acc + item.amount,
      0
    ),
    notes: formData.notes,
    status: PaymentStatus.DONE,
    plannedDate: today,
    sentDate: today,
    receivedDate: today,
    currency: invoice.currency,
    posted: true,
    correctedInvoiceId: invoice.id,
    supplierId: invoice.type === InvoiceType.Issued ? invoice.supplierId : undefined,
    payerId: invoice.type === InvoiceType.Received ? invoice.payerId : undefined,
    type:
      invoice.type === InvoiceType.Received
        ? ContractPaymentType.Incoming
        : ContractPaymentType.Outgoing,
    contractId: invoice.contractId
  };
};
