import React from 'react';
import {
  LinkedEntityTypes,
  LinkedFlight,
  LinkedInvoice,
  LinkedMessage,
  LinkedOrder,
  LinkedProgram,
  Payment
} from 'services/api/data-contracts';
import { MailOutlined, SendOutlined } from '@ant-design/icons';
import { getOrderIcon, getOrderTerm, getUTCDate } from 'helpers';
import { orange } from '@ant-design/colors';
import { BsCalendarWeek } from 'react-icons/bs';
import { DATE_FORMAT_SHORT } from 'consts/common';
import { IoLogoUsd as UsdIcon } from 'react-icons/io5';
import styled from 'styled-components';
import { Tag } from 'antd';

const StyledTag = styled(Tag)`
  margin-bottom: 8px;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  gap: 6px;

  span:has(.text) {
    flex-grow: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    margin-inline-start: 0 !important;
  }

  .ant-tag-close-icon {
    display: inline-flex;
    align-self: center;
    margin: 0;
    font-size: 16px;
  }
`;

interface IArgs {
  links: (LinkedFlight | LinkedOrder | LinkedProgram | LinkedMessage | LinkedInvoice | Payment)[];
  linkedEntityType: LinkedEntityTypes;
  onUnlink: (source: { entityId: string | number; entityType: LinkedEntityTypes }) => Promise<void>;
  renderUnlinkIcon: (isRemovable?: boolean) => React.ReactNode | null;
  targetEntityId?: string | number;
}

export const renderLinkTags = ({
  links,
  linkedEntityType,
  targetEntityId,
  renderUnlinkIcon,
  onUnlink
}: IArgs): JSX.Element[] =>
  links.map((link) => {
    let label = '';
    let color = '';
    let icon: React.ReactNode = null;
    let isRemovable = false;

    switch (linkedEntityType) {
      case LinkedEntityTypes.Flight:
        label = (link as LinkedFlight).flightNumber;
        color = 'blue';
        icon = <SendOutlined />;
        break;
      case LinkedEntityTypes.Order:
        const { location, orderType, startDatetime, endDatetime } = link as LinkedOrder;
        const orderLocation = location
          ? 'code' in location
            ? location.code
            : location.name
          : undefined;

        label = [
          orderType,
          orderLocation,
          getOrderTerm(getUTCDate(startDatetime), getUTCDate(endDatetime))
        ]
          .filter((i) => !!i)
          .join(' | ');
        color = 'orange';
        icon = getOrderIcon(orderType, orange[6]);
        break;
      case LinkedEntityTypes.Program:
        label = (link as LinkedProgram).programNumber;
        color = 'green';
        icon = <BsCalendarWeek />;
        break;
      case LinkedEntityTypes.Invoice:
        const { invoiceNumber, type, receivingOrSendingDate } = link as LinkedInvoice;
        label = [invoiceNumber, type, getUTCDate(receivingOrSendingDate).format(DATE_FORMAT_SHORT)]
          .filter((i) => !!i)
          .join(' | ');
        color = 'purple';
        icon = <UsdIcon />;
        break;
      case LinkedEntityTypes.CreditNote:
        const { creditNoteNumber, createdAt } = link as Payment;
        label = [creditNoteNumber, getUTCDate(createdAt).format(DATE_FORMAT_SHORT)]
          .filter((i) => !!i)
          .join(' | ');
        color = 'red';
        icon = <UsdIcon />;
        break;
      case LinkedEntityTypes.Message:
        const {
          programIds = [],
          flightIds = [],
          invoiceIds = [],
          orderNumbers = []
        } = link as LinkedMessage;

        isRemovable =
          !!targetEntityId &&
          [...programIds, ...flightIds, ...invoiceIds, ...orderNumbers]
            .map((i) => i.toString())
            .includes(targetEntityId.toString());
        label = (link as LinkedMessage).messageId;
        color = 'magenta';
        icon = <MailOutlined />;
    }

    const unlinkIcon = renderUnlinkIcon(isRemovable);

    return (
      <StyledTag
        color={color}
        icon={icon}
        key={'messageId' in link ? link.messageId : link.id}
        closeIcon={unlinkIcon}
        onClose={() =>
          onUnlink({
            entityId: 'messageId' in link ? link.messageId : link.id,
            entityType: linkedEntityType
          })
        }>
        <span className="text">{label}</span>
      </StyledTag>
    );
  });
