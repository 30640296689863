import React from 'react';
import { Checkbox, Flex, Input, Select, Tooltip } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { RangeValue } from 'store/helpers/types';
import { OrdersFilter } from 'store/ordersDashboardStore';
import { ContractType, OrderType } from 'services/api/data-contracts';
import { StyledDateRangePicker } from 'components/common/StyledDatePicker';
import { orderStatuses, orderTypes } from 'consts/order';
import { getSelectOptions } from 'helpers';
import { ServicesMultipleInput } from 'components/ServicesMultipleInput/ServicesMultipleInput';
import { LocationMultipleInput } from 'components/LocationInput/LocationMultipleInput';
import { CounterpartyMultipleInput } from 'components/CounterpartyInput/CounterpartyMultipleInput';
import { AircraftMultipleInput } from 'components/AircraftInput/AircraftMultipleInput';

interface IProps {
  onChange: (
    name: string,
    value:
      | string
      | string[]
      | RangeValue
      | OrderType[]
      | { value: number | string; label: string }[]
      | boolean
      | undefined
  ) => void;
  filter: OrdersFilter;
}

export const OrderFilters = ({ onChange, filter }: IProps) => (
  <>
    <Flex gap={20} justify="space-between" style={{ width: '100%', marginBottom: '12px' }}>
      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Locations">
        <div style={{ flexBasis: '220px' }}>
          <LocationMultipleInput
            values={filter.locations}
            onChange={(locations) => onChange('locations', locations)}
          />
        </div>
      </Tooltip>

      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Payers">
        <div style={{ flex: 1 }}>
          <CounterpartyMultipleInput
            contractType={ContractType.WithCustomer}
            listHeight={180}
            placeholder="Select payers"
            values={filter.payers}
            onChange={(payers) => onChange('payers', payers)}
            dataTestId={'payer-multiselect'}
          />
        </div>
      </Tooltip>

      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Suppliers">
        <div style={{ flexBasis: '220px' }}>
          <CounterpartyMultipleInput
            contractType={ContractType.WithSupplier}
            listHeight={180}
            placeholder="Select suppliers"
            values={filter.suppliers}
            onChange={(suppliers) => onChange('suppliers', suppliers)}
            dataTestId={'supplier-multiselect'}
          />
        </div>
      </Tooltip>

      <div style={{ flex: 1 }}>
        <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="End date">
          <StyledDateRangePicker
            placeholder={['End from', 'To']}
            value={filter.orderEndDate}
            name="orderEndDate"
            testid="end-date-input"
            onChange={(value) => {
              onChange('orderEndDate', value);
            }}
            format={DATE_FORMAT}
            onSetDate={(value) => {
              onChange('orderEndDate', value);
            }}
          />
        </Tooltip>
      </div>
    </Flex>

    <Flex gap={20} justify="space-between" style={{ width: '100%', marginTop: 12 }}>
      <div style={{ flexBasis: '220px' }}>
        <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Order number">
          <Input
            size={CONTROL_SIZE}
            placeholder="All order numbers"
            name="orderNumbers"
            data-testid="order-number-input"
            onChange={({ target: { name, value } }) => onChange(name, value)}
            value={filter.orderNumbers}
            allowClear
            autoComplete="off"
          />
        </Tooltip>
      </div>

      <div style={{ flex: 1 }}>
        <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Statuses">
          <Select
            size={CONTROL_SIZE}
            placeholder="Select statuses"
            style={{ width: '100%' }}
            options={orderStatuses.map((status) => ({
              value: status,
              label: status
            }))}
            optionFilterProp="label"
            mode="multiple"
            maxTagCount="responsive"
            listHeight={200}
            value={filter.orderStatuses}
            onChange={(value) => {
              onChange('orderStatuses', value);
            }}
            allowClear
          />
        </Tooltip>
      </div>

      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Aircrafts">
        <div style={{ flexBasis: '220px' }}>
          <AircraftMultipleInput
            values={filter.ordersAircrafts}
            onChange={(aircrafts) => onChange('ordersAircrafts', aircrafts)}
          />
        </div>
      </Tooltip>

      <div style={{ flex: 1 }}>
        <Tooltip
          mouseEnterDelay={1}
          trigger={['focus', 'hover']}
          title="Arrival or departure flight numbers">
          <Input
            size={CONTROL_SIZE}
            placeholder="All flight numbers"
            name="flightNumbers"
            data-testid="flight-number-input"
            onChange={({ target: { name, value } }) => onChange(name, value)}
            value={filter.flightNumbers}
            style={{ width: '100%' }}
            allowClear
            autoComplete="off"
          />
        </Tooltip>
      </div>
    </Flex>

    <Flex gap={20} style={{ marginTop: 12 }}>
      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Services">
        <div style={{ width: '220px' }}>
          <ServicesMultipleInput
            value={filter.services}
            onChange={(value) => {
              onChange('services', value);
            }}
          />
        </div>
      </Tooltip>

      <Checkbox.Group
        options={getSelectOptions(orderTypes)}
        data-testid="order-types-select"
        value={filter.orderTypes}
        onChange={(value) => {
          value.length > 0 && onChange('orderTypes', value);
        }}
      />

      <Checkbox
        value={filter.isNotInProgram}
        checked={filter.isNotInProgram}
        onChange={({ target: { checked } }) => onChange('isNotInProgram', checked)}
        data-testid="order-isNotInProgram-checkbox">
        Hide program orders
      </Checkbox>
    </Flex>
  </>
);
