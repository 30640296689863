import React, { useEffect } from 'react';
import { DashboardContext, DashboardType } from '../consts';
import { settingsStore } from 'services/settings/SettingsStore';
import { Empty, Flex, Table } from 'antd';
import { useGetRebateReport } from 'queries/rebate/useGetRebateReport';
import { RebateReportFilters } from './filters/RebateReportFilters';
import { useRebateReportStore } from 'store/rebateReportStore';
import { CONTROL_SIZE } from 'consts/common';
import { CurrenciesExpandableTable } from './CurrenciesExpandableTable';
import { CreditNoteCreationPopover } from './CreditNoteCreationPopover';

const dashboardType = {
  type: DashboardType.Rebate
};

export const RebateReport = () => {
  const { data } = useRebateReportStore();

  useGetRebateReport();

  useEffect(() => {
    settingsStore.setCurrentDashboard(DashboardType.Rebate);
  }, []);

  const dataSource = (data?.items || []).map((item) => ({ ...item, key: item.id }));

  return (
    <DashboardContext.Provider value={dashboardType}>
      <CreditNoteCreationPopover />

      <Flex vertical gap={20}>
        <RebateReportFilters />

        {data ? (
          <Table
            dataSource={dataSource}
            columns={[{ key: 'customer', render: (_, record) => record.name }]}
            showHeader={false}
            pagination={false}
            key="rebate-report-table"
            expandable={{
              defaultExpandAllRows: true,
              expandedRowRender: (record) => (
                <CurrenciesExpandableTable
                  key={record.id}
                  data={record.currencies || []}
                  customerId={record.id}
                  customerName={record.name}
                />
              ),
              rowExpandable: (record) => !!record.currencies && record.currencies?.length > 0,
              columnWidth: 32
            }}
            size={CONTROL_SIZE}
            footer={
              !!data?.total && data.total > 0 ? () => <span>Total: {data.total}</span> : undefined
            }
          />
        ) : (
          <Flex align="center" justify="center" style={{ width: '100%', height: 500 }}>
            <Empty description="Select a customer to see the report" />
          </Flex>
        )}
      </Flex>
    </DashboardContext.Provider>
  );
};
