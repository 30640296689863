import React, { useEffect, useState } from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { ConfigProvider, Empty, Form, notification, Table, TableProps } from 'antd';
import {
  InvoiceType,
  LinkActionTypes,
  LinkedEntityTypes,
  SettlementInvoiceItem
} from 'services/api/data-contracts';
import { getColumns, SettlementCreditNoteItem } from './columns';
import { useInvoiceStore } from 'store/invoiceStore';
import { useMessageStore } from 'store/messageStore';
import { useGetLinks } from 'queries/links/useGetLinks';
import { LINK_STATUS } from './helpers/constants';
import { validateLinkingStatus } from './helpers/validateLinkingStatus';
import styled, { css } from 'styled-components';
import { ColumnType } from 'antd/es/table/interface';
import { useForm } from 'antd/es/form/Form';
import { EditableCell } from './EditableCell';
import { useUpdateInvoiceItems } from 'queries/invoiceItems';
import { useUpdateMessageLinks } from 'queries/links/useUpdateMessageLinks';

interface StyledTableProps {
  $isEditMode?: boolean;
}

const StyledTable = styled(
  Table<SettlementInvoiceItem | SettlementCreditNoteItem>
)<StyledTableProps>`
  table {
    table-layout: auto !important;
  }

  .unposted {
    color: rgba(0, 0, 0, 0.45);
  }

  .ant-spin-nested-loading {
    padding-bottom: 8px;
    overflow-x: hidden;
  }

  .ant-table-cell {
    align-content: flex-start;

    ${(props) =>
      props.$isEditMode &&
      css`
        & > span {
          margin-top: 10px !important;
          transition: margin 200ms;
        }
      `}
  }
`;

export interface InvoiceProps {
  isPosted: boolean;
  type: InvoiceType;
  invoiceNumber: string;
  currency: string;
  contractId: number;
}

interface IProps {
  invoiceItems: (SettlementInvoiceItem & InvoiceProps)[];
  isFullScreenMode: boolean;
  orderNumber: string;
  serviceId: number;
}

export const InvoiceItemsExpandableTable = ({
  invoiceItems,
  isFullScreenMode,
  orderNumber,
  serviceId
}: IProps) => {
  const [invoiceId, setInvoiceId] = useState<null | number>(null);
  const [api, contextHolder] = notification.useNotification();

  const [form] = useForm<SettlementInvoiceItem>();

  const { editingInvoiceItemId, setEditingInvoiceItemId } = useInvoiceStore(
    ({ editingInvoiceItemId, setEditingInvoiceItemId }) => ({
      editingInvoiceItemId,
      setEditingInvoiceItemId
    })
  );
  const { selectedMessages } = useMessageStore(({ selectedMessages }) => ({
    selectedMessages
  }));

  useEffect(() => {
    !isFullScreenMode && setEditingInvoiceItemId(undefined);
  }, [isFullScreenMode]);

  const { data: invoiceLinks, isFetching } = useGetLinks(
    { invoiceIds: invoiceId?.toString() },
    selectedMessages.length > 0 && !!invoiceId
  );
  const linkMutation = useUpdateMessageLinks();

  const handleEdit = (record?: SettlementInvoiceItem) => {
    form.setFieldsValue(record || {});
    setEditingInvoiceItemId(record?.id || undefined);
  };

  const updateMutation = useUpdateInvoiceItems({
    orderNumber,
    serviceId,
    invoiceId: invoiceId || undefined
  });

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      const invoiceItem = invoiceItems.find((item) => item.id === editingInvoiceItemId);
      const updatedProps = form.getFieldsValue();

      if (invoiceItem && updatedProps) {
        updateMutation.mutate([{ ...invoiceItem, ...updatedProps }]);
      }

      setEditingInvoiceItemId(undefined);
    } catch (err) {
      console.log(err);
    }
  };

  const handleLinkInvoice = async (invoiceItem: SettlementInvoiceItem, status: string) => {
    try {
      validateLinkingStatus(status);

      if (
        invoiceItem.invoiceId &&
        (status === LINK_STATUS.LINK_INVOICE || status === LINK_STATUS.UNLINK_INVOICE)
      ) {
        await linkMutation.mutateAsync({
          action:
            status === LINK_STATUS.LINK_INVOICE ? LinkActionTypes.Link : LinkActionTypes.Unlink,
          target: selectedMessages.map(({ id }) => ({
            entityType: LinkedEntityTypes.Message,
            entityId: id
          })),
          source: [
            {
              entityType: LinkedEntityTypes.Invoice,
              entityId: invoiceItem.invoiceId
            }
          ]
        });
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        api.warning({
          message: 'Link validation',
          description: e.message,
          placement: 'topLeft',
          duration: 10,
          style: {
            width: 320
          }
        });
      }
    }
  };

  const dataSource = invoiceItems.flatMap((invoiceItem) => {
    const creditNoteItems =
      invoiceItem.creditNoteItems?.map(
        (creditNoteItem) =>
          ({
            ...creditNoteItem,
            serviceName: invoiceItem.serviceName,
            unitOfMeasure: invoiceItem.unitOfMeasure,
            vatRate: invoiceItem.vatRate,
            key: `${invoiceItem.id}-${creditNoteItem.id}`
          }) as SettlementCreditNoteItem
      ) || [];

    return [{ ...invoiceItem, key: invoiceItem.id }, ...creditNoteItems];
  });

  const columns: TableProps<
    (SettlementInvoiceItem & InvoiceProps) | SettlementCreditNoteItem
  >['columns'] = getColumns(
    isFullScreenMode,
    editingInvoiceItemId,
    handleEdit,
    handleSubmit,
    handleLinkInvoice,
    setInvoiceId,
    {
      data: invoiceLinks?.data,
      isFetching
    },
    selectedMessages
  ).map((col) => {
    if ('isEditable' in col && !col.isEditable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: SettlementInvoiceItem | SettlementCreditNoteItem) => ({
        record,
        dataIndex: (col as ColumnType<SettlementInvoiceItem>).dataIndex,
        isEditMode: record.id === editingInvoiceItemId,
        title: (col as ColumnType<SettlementInvoiceItem>).title?.toString() || undefined,
        form
      })
    };
  });

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty description="Invoice items not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}>
      {contextHolder}
      <Form form={form}>
        <StyledTable
          dataSource={dataSource}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={columns}
          size={CONTROL_SIZE}
          pagination={false}
          rowClassName={(row) =>
            ('isPosted' in row ? row.isPosted : (row as SettlementCreditNoteItem).payment?.posted)
              ? ''
              : 'unposted'
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          $isEditMode={!!editingInvoiceItemId}
          components={{
            body: {
              cell: EditableCell
            }
          }}
        />
      </Form>
    </ConfigProvider>
  );
};
