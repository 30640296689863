import React from 'react';
import { Checkbox, Flex, Input, Select, Tooltip } from 'antd';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { FlightsFilter } from 'store/flightsDashboardStore';
import { OrdersFilter } from 'store/ordersDashboardStore';
import { ContractType, OrderType } from 'services/api/data-contracts';
import { getSelectOptions } from 'helpers';
import { orderStatuses, orderTypes } from 'consts/order';
import { StyledDateRangePicker } from 'components/common/StyledDatePicker';
import { RangeValue } from 'store/helpers/types';
import { AircraftMultipleInput } from 'components/AircraftInput/AircraftMultipleInput';
import { LocationMultipleInput } from 'components/LocationInput/LocationMultipleInput';
import { CounterpartyMultipleInput } from 'components/CounterpartyInput/CounterpartyMultipleInput';

interface IProps {
  onChange: (
    name: string,
    value:
      | string
      | string[]
      | RangeValue
      | OrderType[]
      | boolean
      | {
          value: number | string;
          label: string;
        }[]
      | undefined
  ) => void;
  filter: FlightsFilter & OrdersFilter;
}

export const OrderWidgetFilters = ({ onChange, filter }: IProps) => (
  <>
    <Flex gap={20} justify="space-between" style={{ marginBottom: '12px', width: '100%' }}>
      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Customer">
        <div style={{ minWidth: '220px', width: '220px' }}>
          <CounterpartyMultipleInput
            contractType={ContractType.WithCustomer}
            listHeight={180}
            placeholder="Select customers"
            values={filter.customers}
            onChange={(customers) => onChange('customers', customers)}
          />
        </div>
      </Tooltip>

      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Payer">
        <div style={{ minWidth: '220px', width: '220px' }}>
          <CounterpartyMultipleInput
            contractType={ContractType.WithCustomer}
            listHeight={180}
            placeholder="Select payers"
            values={filter.payers}
            onChange={(payers) => onChange('payers', payers)}
          />
        </div>
      </Tooltip>

      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Location">
        <div style={{ minWidth: '220px', width: '220px' }}>
          <LocationMultipleInput
            values={filter.locations}
            onChange={(locations) => onChange('locations', locations)}
          />
        </div>
      </Tooltip>

      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Start date">
        <StyledDateRangePicker
          placeholder={['Start date from', 'To']}
          value={filter.orderStartDate}
          name="orderStartDate"
          onChange={(value) => {
            onChange('orderStartDate', value);
          }}
          format={DATE_FORMAT}
          onSetDate={(value) => {
            onChange('orderStartDate', value);
          }}
        />
      </Tooltip>
    </Flex>

    <Flex gap={20} justify="space-between" style={{ width: '100%' }}>
      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Supplier">
        <div style={{ minWidth: '220px', width: '220px' }}>
          <CounterpartyMultipleInput
            contractType={ContractType.WithSupplier}
            listHeight={180}
            placeholder="Select suppliers"
            values={filter.suppliers}
            onChange={(suppliers) => onChange('suppliers', suppliers)}
          />
        </div>
      </Tooltip>

      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Provider">
        <div style={{ minWidth: '220px', width: '220px' }}>
          <CounterpartyMultipleInput
            contractType={ContractType.WithSupplier}
            listHeight={180}
            placeholder="Select providers"
            values={filter.providers}
            onChange={(providers) => onChange('providers', providers)}
          />
        </div>
      </Tooltip>

      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Order number">
        <Input
          size={CONTROL_SIZE}
          placeholder="All numbers"
          name="orderNumbers"
          onChange={({ target: { name, value } }) => onChange(name, value)}
          value={filter.orderNumbers}
          allowClear
          style={{ minWidth: '220px', width: 220 }}
          autoComplete="off"
        />
      </Tooltip>

      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="End date">
        <StyledDateRangePicker
          placeholder={['End date from', 'To']}
          value={filter.orderEndDate}
          name="orderStartDate"
          onChange={(value) => {
            onChange('orderEndDate', value);
          }}
          format={DATE_FORMAT}
          onSetDate={(value) => {
            onChange('orderEndDate', value);
          }}
        />
      </Tooltip>
    </Flex>

    <Flex gap={20} justify="start" style={{ marginTop: 12 }}>
      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Status">
        <Select
          size={CONTROL_SIZE}
          placeholder="All statuses"
          style={{ minWidth: '220px', width: '220px' }}
          options={orderStatuses.map((status) => ({
            value: status,
            label: status
          }))}
          optionFilterProp="label"
          mode="multiple"
          maxTagCount="responsive"
          listHeight={200}
          value={filter.orderStatuses}
          onChange={(value) => {
            onChange('orderStatuses', value);
          }}
          allowClear
        />
      </Tooltip>

      <Tooltip mouseEnterDelay={1} trigger={['focus', 'hover']} title="Aircraft">
        <div style={{ minWidth: '220px', width: '220px' }}>
          <AircraftMultipleInput
            values={filter.ordersAircrafts}
            onChange={(aircrafts) => onChange('ordersAircrafts', aircrafts)}
          />
        </div>
      </Tooltip>

      <Flex gap={8}>
        <Checkbox.Group
          options={getSelectOptions(orderTypes)}
          data-testid="order-types-select"
          value={filter.orderTypes}
          onChange={(value) => onChange('orderTypes', value as OrderType[])}
        />
      </Flex>
    </Flex>
  </>
);
