/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Error,
  InvoiceHydrated,
  InvoiceHydratedListResponse,
  InvoiceInput,
  InvoiceListResponse,
  InvoiceTemplatesListResponse,
  InvoicesReportsListResponse,
  ReportFormat,
  UploadFileRequestBody,
  UploadFileResponse,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Invoices<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of Invoices
   *
   * @tags invoices
   * @name GetAllInvoices
   * @summary Get a list of Invoices
   * @request GET:/invoices
   * @secure
   */
  getAllInvoices = (params: RequestParams = {}) =>
    this.http.request<InvoiceHydratedListResponse, Error>({
      path: `/invoices`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new Invoice
   *
   * @tags invoices
   * @name AddInvoice
   * @summary Create a new Invoice
   * @request POST:/invoices
   * @secure
   */
  addInvoice = (data: InvoiceInput, params: RequestParams = {}) =>
    this.http.request<InvoiceHydrated, ValidationError | Error>({
      path: `/invoices`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific Invoice by id
   *
   * @tags invoices
   * @name GetInvoiceById
   * @summary Get a specific Invoice
   * @request GET:/invoices/{invoiceId}
   * @secure
   */
  getInvoiceById = (invoiceId: number, params: RequestParams = {}) =>
    this.http.request<InvoiceHydrated, Error>({
      path: `/invoices/${invoiceId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific Invoice by id
   *
   * @tags invoices
   * @name UpdateInvoice
   * @summary Update a specific Invoice
   * @request PUT:/invoices/{invoiceId}
   * @secure
   */
  updateInvoice = (invoiceId: number, data: InvoiceInput, params: RequestParams = {}) =>
    this.http.request<InvoiceHydrated, ValidationError | Error>({
      path: `/invoices/${invoiceId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific Invoice by id
   *
   * @tags invoices
   * @name DeleteInvoice
   * @summary Delete a specific Invoice
   * @request DELETE:/invoices/{invoiceId}
   * @secure
   */
  deleteInvoice = (invoiceId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/invoices/${invoiceId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
  /**
   * @description Get a list of linkable invoices
   *
   * @tags invoices
   * @name GetLinkableInvoices
   * @summary Get a list of linkable invoices
   * @request GET:/invoices/linkable
   * @secure
   */
  getLinkableInvoices = (
    query?: {
      /**
       * Filter by id of a Payment
       * @format int32
       * @min 1
       * @max 1000000
       */
      paymentId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<InvoiceListResponse, Error>({
      path: `/invoices/linkable`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Get a list of invoices templates
   *
   * @tags invoices
   * @name GetInvoiceTemplates
   * @summary Get a list of invoices templates
   * @request GET:/invoices/templates
   * @secure
   */
  getInvoiceTemplates = (params: RequestParams = {}) =>
    this.http.request<InvoiceTemplatesListResponse, Error>({
      path: `/invoices/templates`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Add new order services to invoice
   *
   * @tags invoices
   * @name AddOrderServicesToInvoice
   * @summary Add new order services to invoice
   * @request POST:/invoices/{invoiceId}/order-services
   * @secure
   */
  addOrderServicesToInvoice = (
    invoiceId: number,
    data: {
      orderServiceIds?: number[];
    },
    params: RequestParams = {}
  ) =>
    this.http.request<InvoiceHydrated, ValidationError | Error>({
      path: `/invoices/${invoiceId}/order-services`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Generate report by specific Invoice
   *
   * @tags invoices
   * @name GenerateInvoiceReport
   * @summary Generate report by specific Invoice
   * @request POST:/invoices/{invoiceId}/generate-report
   * @secure
   */
  generateInvoiceReport = (
    invoiceId: number,
    data: {
      templateId?: string;
      format?: ReportFormat;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, ValidationError | Error>({
      path: `/invoices/${invoiceId}/generate-report`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Generate file for upload data
   *
   * @tags invoices
   * @name PrepareUploadFile
   * @summary Generate file
   * @request POST:/invoices/reports/upload
   * @secure
   */
  prepareUploadFile = (data: UploadFileRequestBody, params: RequestParams = {}) =>
    this.http.request<UploadFileResponse, ValidationError | Error>({
      path: `/invoices/reports/upload`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Download specific report by id
   *
   * @tags invoices
   * @name DownloadReportById
   * @summary Download specific report
   * @request GET:/invoices/reports/{fileId}
   * @secure
   */
  downloadReportById = (
    fileId: string,
    query: {
      format: ReportFormat;
      /** @default false */
      isUserFile?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, ValidationError | Error>({
      path: `/invoices/reports/${fileId}`,
      method: 'GET',
      query: query,
      secure: true,
      ...params
    });
  /**
   * @description Get reports list
   *
   * @tags invoices
   * @name InvoiceReportsList
   * @summary Get reports list
   * @request GET:/invoices/reports
   * @secure
   */
  invoiceReportsList = (
    query: {
      counterpartyId: number;
      /**
       * The limit on number of items to return
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      limit?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<InvoicesReportsListResponse, ValidationError | Error>({
      path: `/invoices/reports`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
}
