import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { RebateReportInvoiceItem, RebateReportResponse } from 'services/api/data-contracts';
import { RangeValue } from 'store/helpers/types';
import { defaultRebateReportFilter } from 'consts/rebateReport';

export interface RebateReportFilter {
  date?: RangeValue;
  customerIds?: number[];
  serviceNameIds?: number[];
  currencies?: string[];
}

export type RebateReportItem = RebateReportInvoiceItem & {
  currency: string;
  customerId: number;
  customerName: string;
};

type RebateReportStore = {
  data?: RebateReportResponse;
  setData: (data?: RebateReportResponse) => void;
  filter: RebateReportFilter;
  setFilter: (filter: RebateReportFilter) => void;
  selectedItems: RebateReportItem[];
  setSelectedItems: (selectedItems: RebateReportItem[]) => void;
};

export const useRebateReportStore = create<RebateReportStore>()(
  devtools((set) => ({
    data: undefined,
    setData: (data) => {
      set({ data });
    },
    filter: defaultRebateReportFilter,
    setFilter: (filter) => {
      set({ filter });
    },
    selectedItems: [],
    setSelectedItems: (selectedItems) => {
      set({ selectedItems });
    }
  }))
);
