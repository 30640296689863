import React, { useCallback, useMemo } from 'react';
import { ConfigProvider, Empty, Flex, List, Table } from 'antd';
import styled from 'styled-components';
import { getColumns } from './utils/columns';
import { CONTROL_SIZE } from 'consts/common';
import { useBalanceReportStore } from 'store/balanceReportStore';
import {
  BalanceReportName,
  BalanceViewContract,
  BalanceViewItems,
  BalanceViewResponse,
  InvoiceHydrated,
  PaymentHydrated
} from 'services/api/data-contracts';
import { BalanceCalculationView } from 'pages/BalanceReport/components/BalanceCalculationView/BalanceCalculationView';
import { useInvoiceStore } from 'store/invoiceStore';
import { usePaymentStore } from 'store/paymentStore';
import { filterInvoices, filterPayments, isInvoice } from './utils/helpers';
import { useNavigate } from 'react-router-dom';
import { useInvoiceDrawerRouting, usePaymentDrawerRouting } from 'routing';
import { useDrawerStore } from 'store/drawerStore';
import { BalanceFooter } from './components/BalanceFooter';
import { ExportPopover } from './components/ExportPopover';
import { ExportPopoverErrorBoundary } from './components/ExportPopoverErrorBoundary';

interface IProps {
  entitiesList?: BalanceViewResponse;
  contract?: BalanceViewContract;
  isLoading?: boolean;
}

export const StyledList = styled(List)`
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  width: 100%;

  .ant-table-wrapper,
  .ant-spin-container {
    height: 100%;
  }

  .ant-table {
    width: 100%;
    overflow-x: auto;
  }

  .ant-spin-nested-loading {
    flex-grow: 1;
    width: 100%;
    overflow-y: scroll;
  }

  .ant-spin-container.ant-spin-blur > div {
    min-height: 0 !important;
  }

  .ant-list-header {
    padding: 8px 16px !important;
  }

  .ant-list-footer {
    border-top: 1px solid #d9d9d9;
    z-index: 100;
    padding: 8px 16px;
  }

  .bold {
    font-weight: 600;
  }
`;

export const BalanceWidget = ({ entitiesList, contract, isLoading = false }: IProps) => {
  const navigate = useNavigate();

  useInvoiceDrawerRouting();
  usePaymentDrawerRouting();

  const {
    selectedEntities,
    setSelectedEntities,
    data,
    setShouldUpdateBalanceView,
    isContractViewMode,
    uiFilter
  } = useBalanceReportStore();
  const { setPayment } = usePaymentStore(({ setCurrent }) => ({ setPayment: setCurrent }));
  const { setInvoice } = useInvoiceStore(({ setCurrent }) => ({ setInvoice: setCurrent }));
  const { setDrawerOpen } = useDrawerStore();

  const handleNavigate = useCallback(
    (record: BalanceViewItems[number]) => {
      if ('invoiceNumber' in record) {
        setInvoice(record as unknown as InvoiceHydrated);
        setDrawerOpen('invoice');
        navigate(`/report/balance/invoice/${record.id}${window.location.search}`);
      } else {
        setPayment(record as unknown as PaymentHydrated);
        setDrawerOpen('payment');
        setShouldUpdateBalanceView(true);
        navigate(`/report/balance/payment/${record.id}${window.location.search}`);
      }
    },
    [navigate, setDrawerOpen, setInvoice, setPayment, setShouldUpdateBalanceView]
  );

  const columns = useMemo(getColumns, []);

  const dataSource = useMemo(() => {
    let items = entitiesList?.items || contract?.items || [];

    if (uiFilter.invoiceStatus && uiFilter.invoiceStatus.length > 0) {
      items = filterInvoices(items, uiFilter.invoiceStatus);
    }

    if (uiFilter.paymentStatus && uiFilter.paymentStatus.length > 0) {
      items = filterPayments(items, uiFilter.paymentStatus);
    }

    return items.map((item) => ({
      ...item,
      key: `${isInvoice(item) ? 'invoice' : 'payment'}-${item.id}`
    }));
  }, [contract, entitiesList, uiFilter.paymentStatus, uiFilter.invoiceStatus]);

  const renderExportPopoverWithBoundary = (reportType: BalanceReportName, tooltip: string) => (
    <ExportPopoverErrorBoundary>
      <ExportPopover
        isLoading={isLoading}
        reportType={reportType}
        contract={contract}
        tooltip={tooltip}
      />
    </ExportPopoverErrorBoundary>
  );

  const contractHeader = () => (
    <Flex justify="space-between" style={{ width: '100%' }}>
      <Flex gap={10}>
        {contract?.contractNumber}
        <BalanceCalculationView balance={contract?.balance} />
      </Flex>

      <Flex gap={10}>
        {renderExportPopoverWithBoundary(
          BalanceReportName.UnpaidInvoicesReports,
          'Unpaid invoices report'
        )}
        {renderExportPopoverWithBoundary(
          BalanceReportName.ReconciliationReports,
          'Reconciliation report'
        )}
      </Flex>
    </Flex>
  );

  const title = useMemo(
    () => (
      <Flex gap={6} justify="space-between" style={{ width: '100%' }}>
        {isContractViewMode ? (
          contractHeader()
        ) : (
          <>
            <Flex gap={10}>
              All contracts
              <BalanceCalculationView balance={entitiesList?.balance} />
            </Flex>

            {entitiesList?.items && entitiesList?.items?.length > 0 && (
              <>
                {renderExportPopoverWithBoundary(
                  BalanceReportName.ReconciliationReports,
                  'Reconciliation report'
                )}
              </>
            )}
          </>
        )}
      </Flex>
    ),
    [isContractViewMode, entitiesList, contractHeader]
  );

  const selectedRowKeys = useMemo(
    () => selectedEntities.map((i) => `${isInvoice(i) ? 'invoice' : 'payment'}-${i.id}`),
    [selectedEntities]
  );

  if (!entitiesList && !contract && !isLoading) {
    return (
      <Flex align="center" justify="center" style={{ width: '100%', height: 500 }}>
        <Empty description="Select сustomer or supplier to see the balance" />
      </Flex>
    );
  }

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty description="Items not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}>
      <StyledList
        header={title}
        footer={<BalanceFooter items={entitiesList?.items || contract?.items || []} />}
        bordered
        size={CONTROL_SIZE}
        style={{
          maxHeight:
            (data?.contracts?.length || 1) > 1 ? 'calc((100vh - 186px)/2' : 'calc((100vh - 116px)'
        }}>
        <Table
          dataSource={dataSource}
          columns={columns}
          loading={isLoading}
          pagination={false}
          size={CONTROL_SIZE}
          rowClassName={(record) => {
            if (isInvoice(record)) {
              return (record as unknown as InvoiceHydrated).amountDue > 0 ? 'bold' : '';
            }

            return (record as unknown as PaymentHydrated).advance > 0 ? 'bold' : '';
          }}
          rowSelection={{
            onChange: (_, selectedEntities) => {
              setSelectedEntities(selectedEntities);
            },
            hideSelectAll: true,
            selectedRowKeys
          }}
          onRow={(record) => ({
            onClick: ({ target }) => {
              const isCheckbox = 'checked' in target;
              const isButton = (target as HTMLElement).closest('button');
              const isDropdown = (target as HTMLElement).closest('.ant-dropdown');
              const isInteractiveElement = isCheckbox || isButton || isDropdown;

              if (isInteractiveElement) return;

              handleNavigate(record);
            }
          })}
        />
      </StyledList>
    </ConfigProvider>
  );
};
