import { useQuery } from '@tanstack/react-query';
import { counterpartiesApi } from 'services/api';
import { CONTACTS_QUERY_KEY } from './consts';

interface IFilter {
  counterpartyId: number;
  recipientTag?: string;
}

const fetchList = async (filter: IFilter) =>
  await counterpartiesApi.getContacts(filter.counterpartyId, {
    recipientTag: filter.recipientTag
  });

export const useGetCounterpartyContacts = (filter: IFilter, enabled: boolean) =>
  useQuery({
    queryKey: [CONTACTS_QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    enabled
  });
