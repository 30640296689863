import React, { useEffect, useMemo, useState } from 'react';
import { CloseOutlined, FolderOpenOutlined, ReloadOutlined } from '@ant-design/icons';
import { Alert, Button, ConfigProvider, Empty, Flex, message, Table, Tooltip } from 'antd';
import { DraggerFileUpload } from 'pages/widgets/components/DraggerFileUpload/DraggerFileUpload';
import { useGetInvoiceDocumentsList } from 'queries/documents/useGetInvoiceDocumentsList';
import { InvoiceType, ReportFormat } from 'services/api/data-contracts';
import { useInvoiceStore } from 'store/invoiceStore';
import { DocumentItem, Documents, getColumns } from './columns';
import { chain } from 'lodash';
import { CONTROL_SIZE } from 'consts/common';
import { useDrawerStore } from 'store/drawerStore';
import { handleError } from 'helpers';
import { AxiosError, AxiosResponse } from 'axios';
import { invoicesApi } from 'services/api';
import styled from 'styled-components';

const StyledTable = styled(Table<Documents[number]>)`
  #actions {
    display: none;
    position: absolute;
    right: 0;
  }

  #actions > button {
    margin-right: 10px;
  }

  .ant-table-cell-row-hover #actions {
    display: block;
  }
`;

export const DocumentDetails = () => {
  const [fileUploadInfo, setFileUploadInfo] = useState({
    isError: false,
    isSuccess: false,
    filename: ''
  });

  useEffect(() => {
    if (fileUploadInfo.isSuccess) {
      refetch();
    }
  }, [fileUploadInfo]);

  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({
    setDrawerOpen
  }));
  const { invoiceCounterpartyName, invoice } = useInvoiceStore(({ current }) => ({
    invoice: current,
    invoiceCounterpartyName:
      current?.type === InvoiceType.Issued ? current?.payer.fullName : current?.supplier.fullName
  }));

  const { data, isFetching, refetch } = useGetInvoiceDocumentsList(invoice?.id);

  const catchReportError = async (err: AxiosError | TypeError | unknown) => {
    if (err instanceof AxiosError && err.response) {
      const decodedResponse = new TextDecoder('utf-8').decode(
        (err.response as AxiosResponse<BufferSource>).data
      );

      await handleError(
        new AxiosError(err.message, err.code, err.config, err.request, {
          data: JSON.parse(decodedResponse)
        } as AxiosResponse<BufferSource>)
      );
    }
  };

  const fetchReport = async (file: DocumentItem) => {
    const response = await invoicesApi.downloadReportById(
      file.fileId as string,
      {
        format: ReportFormat.Pdf, // TODO: do we really need to pass format? see ALLOWED_MIME_TYPES
        isUserFile: file.isUserFile
      },
      {
        format: 'arraybuffer',
        headers: {
          Accept: ReportFormat.Pdf
            ? 'application/pdf, application/json, application/text'
            : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        }
      }
    );

    const contentType = response.headers['content-type'];
    const blob = new Blob([response.data], { type: contentType });

    return { blob, filename: file.filename, contentType };
  };

  const handleDownload = async (file: DocumentItem) => {
    try {
      if (!file.fileId) {
        message.error(`File id for document is not found.`);
        return;
      }
      const { blob, filename } = await fetchReport(file);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      await catchReportError(err);
    }
  };

  const columns = useMemo(
    () => getColumns(handleDownload, invoiceCounterpartyName),
    [data?.data.items.length]
  );

  const rows = useMemo(
    () =>
      chain(data?.data.items)
        .filter((item) => Boolean(item.fileSize))
        .value(),
    [data?.data.items]
  );

  return (
    <div>
      {(fileUploadInfo.isError || fileUploadInfo.isSuccess) && (
        <Alert
          style={{ padding: '9px 16px' }}
          description={
            <Flex vertical>
              <div>
                File {fileUploadInfo.filename}{' '}
                {fileUploadInfo.isSuccess ? 'has been loaded.' : 'failed to load.'}
              </div>
              {fileUploadInfo.isSuccess && (
                <div>
                  Don&apos;t worry if you can&apos;t see the file. It may take up to 2 minutes. You
                  can close the window or upload another file.
                </div>
              )}
            </Flex>
          }
          type={fileUploadInfo.isSuccess ? 'success' : 'error'}
          closable
          closeIcon={
            <Tooltip mouseEnterDelay={1} title="Close alert">
              <CloseOutlined />
            </Tooltip>
          }
        />
      )}
      <Flex justify="space-between" style={{ margin: '8px 0' }}>
        <Button
          icon={<ReloadOutlined />}
          onClick={() => refetch()}
          style={{ border: 'none', boxShadow: 'none', alignSelf: 'center', color: '#1890ff' }}>
          Refresh the file table
        </Button>
        <Button
          icon={<FolderOpenOutlined />}
          onClick={() => setDrawerOpen('documents')}
          style={{ border: 'none', boxShadow: 'none', alignSelf: 'center', color: '#1890ff' }}>
          {invoiceCounterpartyName} files
        </Button>
      </Flex>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description="Uploaded documents for current invoice are not found"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}>
        <StyledTable
          columns={columns}
          dataSource={rows}
          rowKey="fileId"
          size={CONTROL_SIZE}
          loading={isFetching}
          pagination={false}
        />
      </ConfigProvider>
      <div style={{ marginTop: '12px' }}>
        <DraggerFileUpload
          relatedId={invoice?.id}
          counterpartyId={
            invoice?.type === InvoiceType.Issued ? invoice?.payerId : invoice?.supplierId
          }
          entityName="Invoices"
          onSetFileUploadStatus={setFileUploadInfo}
        />
      </div>
    </div>
  );
};
