import { Menu } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { hasCurrentUserRole, UserRole } from 'services/auth/hasUserRole';
import { useLocation, useNavigate } from 'react-router-dom';
import { settingsStore } from 'services/settings/SettingsStore';
import { ItemType } from 'antd/es/menu/interface';
import { capitalize } from 'lodash';

export const AppMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentTenant = settingsStore.getCurrentTenant();

  const getDashboardMenuItem = useCallback((): ItemType | undefined => {
    const availableDashboards = [];

    if (hasCurrentUserRole([UserRole.Dispatcher])) {
      availableDashboards.push('Dispatcher');
    }

    if (hasCurrentUserRole([UserRole.Settlement])) {
      availableDashboards.push('Settlement');
    }

    if (availableDashboards.length === 1) {
      return {
        key: `/dashboard/${availableDashboards[0].toLowerCase()}`,
        label: 'Dashboard'
      };
    } else if (availableDashboards.length > 1) {
      return {
        key: '/dashboard',
        label: location.pathname.startsWith('/dashboard')
          ? `${capitalize(location.pathname.split('/')[2])} dashboard`
          : 'Dashboard',
        children: availableDashboards.map((label) => ({
          key: `/dashboard/${label.toLowerCase()}`,
          label
        }))
      };
    }
  }, [currentTenant]);

  const menuItems: ItemType[] = useMemo(
    () =>
      [
        getDashboardMenuItem(),
        ...(hasCurrentUserRole([UserRole.Settlement])
          ? [
              {
                key: '/report',
                label: location.pathname.startsWith('/report')
                  ? `${capitalize(location.pathname.split('/')[2])} report`
                  : 'Report',
                children: [
                  {
                    key: '/report/balance',
                    label: 'Balance'
                  },
                  {
                    key: '/report/rebate',
                    label: 'Rebate'
                  }
                ]
              }
            ]
          : []),
        {
          key: '/dictionaries',
          label: 'Dictionaries'
        }
      ].filter(Boolean) as ItemType[],
    [currentTenant]
  );

  const selectedKeys = useMemo(() => {
    if (location.pathname.startsWith('/dashboard')) {
      const locationParts = location.pathname.split('/');
      return [locationParts[0] + '/' + locationParts[1]];
    } else if (location.pathname.startsWith('/dictionaries')) {
      return ['/dictionaries'];
    }

    return [location.pathname];
  }, [location.pathname]);

  return (
    <Menu
      theme="dark"
      mode="horizontal"
      selectedKeys={selectedKeys}
      style={{ width: 400 }}
      items={menuItems}
      onClick={({ key }) => {
        if (key.startsWith('/dictionaries') || key.startsWith('/report')) {
          window.open(`${window.location.origin}${key}`, '_blank');
        } else {
          navigate(key);
        }
      }}
    />
  );
};
