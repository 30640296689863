/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Error,
  OwnPriceHydrated,
  OwnPriceHydratedExtended,
  OwnPriceInput,
  OwnPriceListResponse,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class OwnPrices<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of OwnPrice records
   *
   * @tags own prices
   * @name GetAllOwnPrices
   * @summary Get a list of OwnPrice records
   * @request GET:/own-prices
   * @secure
   */
  getAllOwnPrices = (
    query?: {
      /**
       * The current page number
       * @format int32
       * @min 1
       * @max 10000
       * @default 1
       */
      page?: number;
      /**
       * The number of items to return per page
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      pageSize?: number;
      /** Filter by ownPrice name. */
      name?: string;
      /**
       * Filter own price by isSpecialPrice property
       * @example false
       */
      isSpecialPrice?: boolean;
      /**
       * Filter by ownPrice active date checked against a range between own price startDate and endDate
       * @format date
       * @example "2023-10-26"
       */
      activeDate?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<OwnPriceListResponse, Error>({
      path: `/own-prices`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new OwnPrice record
   *
   * @tags own prices
   * @name AddOwnPrice
   * @summary Create a new OwnPrice record
   * @request POST:/own-prices
   * @secure
   */
  addOwnPrice = (data: OwnPriceInput, params: RequestParams = {}) =>
    this.http.request<OwnPriceHydrated, ValidationError | Error>({
      path: `/own-prices`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific OwnPrice record by id
   *
   * @tags own prices
   * @name GetOwnPriceById
   * @summary Get a specific OwnPrice record
   * @request GET:/own-prices/{ownPriceId}
   * @secure
   */
  getOwnPriceById = (ownPriceId: number, params: RequestParams = {}) =>
    this.http.request<OwnPriceHydrated, Error>({
      path: `/own-prices/${ownPriceId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific OwnPrice document by id
   *
   * @tags own prices
   * @name UpdateOwnPrice
   * @summary Update a specific OwnPrice document
   * @request PUT:/own-prices/{ownPriceId}
   * @secure
   */
  updateOwnPrice = (ownPriceId: number, data: OwnPriceInput, params: RequestParams = {}) =>
    this.http.request<OwnPriceHydrated, ValidationError | Error>({
      path: `/own-prices/${ownPriceId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific OwnPrice record by id
   *
   * @tags own prices
   * @name DeleteOwnPrice
   * @summary Delete a specific OwnPrice record
   * @request DELETE:/own-prices/{ownPriceId}
   * @secure
   */
  deleteOwnPrice = (ownPriceId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/own-prices/${ownPriceId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
  /**
   * @description Get a list of OwnPrice records for invoiceItem
   *
   * @tags own prices
   * @name GetAllOwnPricesForInvoiceItem
   * @summary Get a list of OwnPrice records for invoiceItem
   * @request GET:/own-prices/invoice-item
   * @secure
   */
  getAllOwnPricesForInvoiceItem = (
    query: {
      /** Id of the serviceName */
      serviceNameId: number;
      /** Id of the orderService */
      orderServiceId?: number;
      /** @format date */
      invoiceSupplyDate?: string;
      invoiceCurrency: string;
      /** Payer contract id */
      payerContractId: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<
      {
        items?: OwnPriceHydratedExtended[];
      },
      Error
    >({
      path: `/own-prices/invoice-item`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
}
