import React, { useContext, useEffect, useState } from 'react';
import { Drawer, notification } from 'antd';
import styled from 'styled-components';
import { HeaderButtons } from './HeaderButtons';
import { useInvoiceStore } from 'store/invoiceStore';
import { IFilter, useGetOrderServices } from 'queries/orderServices';
import { Filters } from './Filters';
import { orderTypes } from 'consts/order';
import { ServicesList } from './ServicesList';
import { OrderService } from 'services/api/data-contracts';
import { useAddServicesToInvoice } from 'queries/invoice/useAddServicesToInvoice';
import { DashboardContext, DashboardType } from '../../../consts';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header,
  .ant-drawer-body {
    padding: 8px;
  }

  .ant-drawer-header {
    border: none;
  }
`;

interface IProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  isFullScreenMode?: boolean;
}

export const ServicesDrawer = ({ isOpen, setOpen, isFullScreenMode }: IProps) => {
  const [filter, setFilter] = useState<IFilter>({});
  const [page, setPage] = useState(1);
  const [api, contextHolder] = notification.useNotification();

  const { type: dashboardType } = useContext(DashboardContext);

  const [selectedServices, setSelectedServices] = useState<OrderService[]>([]);
  const { invoice } = useInvoiceStore(({ current }) => ({
    invoice: current
  }));

  useEffect(() => {
    invoice && setFilter({ invoiceId: invoice?.id, types: orderTypes });
  }, [invoice]);

  const { data, isFetching } = useGetOrderServices({ ...filter, page }, !!filter.invoiceId);

  const createMutation = useAddServicesToInvoice(() => {
    setOpen(false);
  });

  const handleSubmit = () => {
    try {
      if (selectedServices.length === 0) {
        throw new Error('No services are selected');
      }

      const data = {
        orderServiceIds: selectedServices.map((service) => service.id)
      };

      if (invoice?.id) {
        createMutation.mutate([invoice.id, data]);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        api.warning({
          message: 'Service validation',
          description: e.message,
          placement: 'topLeft',
          duration: 10,
          style: {
            width: 320
          }
        });
      }
    }
  };

  const isRenderInContainer = dashboardType === DashboardType.Settlement && !isFullScreenMode;

  return (
    <>
      {contextHolder}
      <StyledDrawer
        open={isOpen}
        onClose={() => setOpen(false)}
        width={660}
        title="Adding a service"
        closable={false}
        extra={
          <HeaderButtons
            onCancel={() => setOpen(false)}
            onSubmit={handleSubmit}
            isLoading={isFetching || createMutation.isLoading}
          />
        }
        {...(isRenderInContainer ? { getContainer: false } : {})}>
        <Filters appliedFilter={filter} applyFilter={setFilter} isLoading={isFetching} />

        <ServicesList
          data={data?.data}
          isLoading={isFetching}
          onSelectServices={setSelectedServices}
          page={page}
          setPage={setPage}
        />
      </StyledDrawer>
    </>
  );
};
