import { Button, Flex, Popover } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useOrderStore } from 'store/orderStore';
import { useForm } from 'antd/es/form/Form';
import { OrderService } from 'services/api/data-contracts';
import { CONTROL_SIZE } from 'consts/common';
import { useUpdateOrderService } from 'queries/orderServices';
import { isEmpty, omitBy } from 'lodash';
import { TooltipPlacement } from 'antd/es/tooltip';
import { useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { DashboardContext } from '../../../consts';
import { ServicesBulkUpdateForm } from './ServicesBulkUpdateForm';

interface IProps {
  placement?: TooltipPlacement;
  align?: { offset?: [number, number] };
}

export const OrderServicesBulkOperationsPopover = (popoverProps: IProps) => {
  const [isOpen, setOpen] = useState(false);

  const [form] = useForm<OrderService>();

  const { type: dashboardType } = useContext(DashboardContext);

  const { selectedServices, setSelectedServices } = useOrderStore(
    ({ selectedServices, setSelectedServices }) => ({
      selectedServices,
      setSelectedServices
    })
  );
  const { clearOrderRowsState } = useSettlementDashboardStore(({ clearOrderRowsState }) => ({
    clearOrderRowsState
  }));

  useEffect(() => {
    setOpen(selectedServices.length > 1);
  }, [selectedServices]);

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      form.setFieldsValue({});
    }
  }, [isOpen]);

  const handleCancel = () => {
    setOpen(false);
  };

  const updateMutation = useUpdateOrderService(dashboardType, handleCancel);

  const handleSubmit = async () => {
    try {
      const data = omitBy(form.getFieldsValue(), (value) => value === undefined);

      if (isEmpty(data)) {
        return handleCancel();
      }

      for (const serviceId of selectedServices) {
        await updateMutation.mutateAsync({
          ...data,
          id: serviceId
        } as OrderService);
      }
    } finally {
      setSelectedServices([]);
      clearOrderRowsState();
    }
  };

  return (
    <Popover
      mouseEnterDelay={1}
      open={isOpen}
      title={`Change for ${selectedServices.length} selected...`}
      arrow={false}
      getPopupContainer={(node) => node}
      {...popoverProps}
      content={
        <>
          <ServicesBulkUpdateForm form={form} selectedServices={selectedServices} />

          <Flex justify="end" gap={8}>
            <Button size={CONTROL_SIZE} onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" size={CONTROL_SIZE} onClick={handleSubmit}>
              Change
            </Button>
          </Flex>
        </>
      }
    />
  );
};
