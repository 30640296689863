import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Flex, Select } from 'antd';
import { BalanceCounterpartyInput } from 'components/CounterpartyInput/BalanceCounterpartyInput';
import { defaultBalanceFilter } from 'consts/balance';
import { CONTROL_SIZE, DATE_FORMAT, SERVER_DATE_FORMAT } from 'consts/common';
import { difference, isEmpty, isEqual, omit } from 'lodash';
import { BalanceFilter, useBalanceReportStore } from 'store/balanceReportStore';
import { RangeValue } from 'store/helpers/types';
import { BalanceContractInput } from 'components/ContractInput/BalanceContractInput';
import { StyledDateRangePicker } from 'components/common/StyledDatePicker';
import { useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { settingsStore } from 'services/settings/SettingsStore';
import { useCtrlEnterShortcut } from 'helpers';
import { BalanceUIFilters } from './BalanceUIFilters';

export interface Filter {
  currency?: string;
  contractDate?: RangeValue;
  counterpartyId?: number;
  contractIds?: string;
}

export const BalanceFilters = () => {
  const [filter, setFilter] = useState<Filter>(defaultBalanceFilter);

  const { allowedCurrencies = [] } = settingsStore.getCurrentTenant();

  const [searchParams, setSearchParams] = useSearchParams();

  const { balanceFilter, setBalanceFilter, setContractViewMode, setBalanceView } =
    useBalanceReportStore(({ filter, setFilter, setContractViewMode, setData }) => ({
      balanceFilter: filter,
      setBalanceFilter: setFilter,
      setContractViewMode,
      setBalanceView: setData
    }));

  useEffect(
    () => () => {
      setBalanceFilter({});
    },
    []
  );

  useEffect(() => {
    setContractViewMode(!!balanceFilter.contractIds);
  }, [balanceFilter]);

  useEffect(() => {
    const getParam = (param: string) => searchParams.get(param) || undefined;

    const currency = getParam('currency');
    const contractDate = getParam('contractDate')
      ?.split(',')
      .map((date) => dayjs(date, SERVER_DATE_FORMAT)) as [Dayjs, Dayjs];
    const counterpartyId = getParam('counterpartyId');
    const contractIds = getParam('contractIds');

    const searchParamsFilter = {
      ...(currency && { currency }),
      ...(contractDate && { contractDate }),
      ...(counterpartyId && { counterpartyId: parseInt(counterpartyId) }),
      ...(contractIds && { contractIds })
    };

    if (!isEmpty(searchParamsFilter)) {
      const filter = {
        ...searchParamsFilter,
        contractDate:
          searchParamsFilter.contractDate ||
          ([dayjs().set('date', 1), dayjs().endOf('month')] as RangeValue)
      };

      setBalanceFilter(filter);
      setFilter(filter);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(balanceFilter)) {
      setSearchParams({
        ...(balanceFilter.currency && { currency: balanceFilter.currency }),
        ...(balanceFilter.contractDate && {
          contractDate: balanceFilter.contractDate
            .map((date) => date?.format(SERVER_DATE_FORMAT))
            .join(',')
        }),
        ...(balanceFilter.counterpartyId && {
          counterpartyId: balanceFilter.counterpartyId.toString()
        }),
        ...(balanceFilter.contractIds && { contractIds: balanceFilter.contractIds })
      });
    } else {
      setSearchParams({});
    }
  }, [balanceFilter]);

  const handleChangeFilter = (name: string, value: string | number | RangeValue | undefined) => {
    if (value) {
      setFilter((filter) => ({ ...filter, [name]: value }));
    } else {
      setFilter((filter) => omit(filter, name));
    }
  };

  const isFilterUpdated = useCallback(
    (prev: Partial<BalanceFilter>, curr: Partial<BalanceFilter>) => {
      const prevValues = Object.values(prev).filter((i) => !!i);
      const currValues = Object.values(curr).filter((i) => !!i);

      return (
        difference(currValues, prevValues).length > 0 ||
        difference(prevValues, currValues).length > 0
      );
    },
    []
  );

  const isApplyAvailable = useMemo(
    () =>
      isFilterUpdated(balanceFilter, filter) && !!filter.counterpartyId && !!filter.contractDate,
    [filter]
  );

  const isResetAvailable = useMemo(() => !isEqual(filter, defaultBalanceFilter), [filter]);

  const handleApplyFilter = () => {
    const updatedFilter = { ...filter };

    const isUpdated = isFilterUpdated(balanceFilter, updatedFilter);

    if (isUpdated) {
      setBalanceFilter(updatedFilter);
    }
  };

  useCtrlEnterShortcut(handleApplyFilter, isApplyAvailable);

  const handleResetFilter = () => {
    setFilter(defaultBalanceFilter);
    setBalanceFilter({});
    setBalanceView(undefined);
  };

  return (
    <Flex gap={8}>
      <BalanceUIFilters />

      <Select
        placeholder="All currencies"
        data-testid="currency-select"
        value={filter.currency}
        options={allowedCurrencies.map((curr) => ({
          label: curr,
          value: curr
        }))}
        onChange={(value) => handleChangeFilter('currency', value)}
        allowClear
        style={{ width: '90px' }}
        size={CONTROL_SIZE}
        showSearch
      />

      <div style={{ width: 260 }}>
        <StyledDateRangePicker
          placeholder={['From', 'To']}
          value={filter.contractDate}
          status={!filter.contractDate ? 'error' : ''}
          onChange={(value) => {
            handleChangeFilter('contractDate', value);
          }}
          format={DATE_FORMAT}
          onSetDate={(value) => {
            handleChangeFilter('contractDate', value);
          }}
        />
      </div>

      <BalanceCounterpartyInput
        placeholder="Customer or supplier"
        value={filter?.counterpartyId}
        onChange={(value) => {
          handleChangeFilter('counterpartyId', value);
          handleChangeFilter('contractIds', undefined);
        }}
      />

      <BalanceContractInput
        counterpartyId={filter.counterpartyId}
        onChange={(contractIds) => handleChangeFilter('contractIds', contractIds)}
        disabled={!filter.counterpartyId}
        value={filter.contractIds}
      />

      {isResetAvailable && (
        <Button ghost type="primary" size={CONTROL_SIZE} onClick={handleResetFilter}>
          Reset all
        </Button>
      )}

      {isApplyAvailable && (
        <Button size={CONTROL_SIZE} type="primary" onClick={handleApplyFilter}>
          Apply
        </Button>
      )}
    </Flex>
  );
};
