import { Flex, Form, InputNumber } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import React from 'react';
import { KG_PRECISION, LTR_PRECISION, MT_PRECISION, USG_PRECISION } from 'consts/order';

export const FuelQuantityFields = () => (
  <>
    <Flex gap={8}>
      <Form.Item
        name={['additionalData', 'density']}
        style={{ width: '150px' }}
        rules={[
          {
            validator: async (_, value) => {
              if (value <= 0) {
                return Promise.reject('Density should be more than zero');
              }
            }
          }
        ]}>
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          style={{ width: '100%' }}
          placeholder="0.00"
          addonBefore="Density"
          data-testid="fuel-density"
        />
      </Form.Item>
    </Flex>

    <Flex gap={8}>
      <Form.Item name="quantityGallons" style={{ flexBasis: '50%' }}>
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          style={{ width: '100%' }}
          placeholder="0.00"
          addonBefore="Gal"
          precision={USG_PRECISION}
          data-testid="fuel-quantity-gallons"
        />
      </Form.Item>

      <Form.Item name="quantityLitres" style={{ flexBasis: '50%' }}>
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          style={{ width: '100%' }}
          placeholder="0.00"
          addonBefore="L"
          precision={LTR_PRECISION}
          data-testid="fuel-quantity-litres"
        />
      </Form.Item>
    </Flex>

    <Flex gap={8}>
      <Form.Item name="quantity" style={{ flexBasis: '50%' }}>
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          style={{ width: '100%' }}
          placeholder="0.00"
          addonBefore="kg"
          data-testid="fuel-quantity"
          precision={KG_PRECISION}
        />
      </Form.Item>

      <Form.Item name="quantityTonnes" style={{ flexBasis: '50%' }}>
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          style={{ width: '100%' }}
          placeholder="0.000"
          addonBefore="ton"
          data-testid="fuel-quantity-tonnes"
          precision={MT_PRECISION}
        />
      </Form.Item>
    </Flex>
  </>
);
