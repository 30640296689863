import React, { useEffect, useMemo, useState } from 'react';
import { Button, ConfigProvider, Empty, Form, Row, Select, Tooltip } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useFlightStore } from 'store/flightStore';
import { FlightsApiFilter, useGetFlights } from 'queries/flight';
import { Flight, FlightStatus } from 'services/api/data-contracts';
import { MinusCircleOutlined } from '@ant-design/icons';
import { getFlightLabel } from 'helpers';

interface ICommonProps {
  name: string;
  label?: string;
  onFilter?: (flights: Flight[]) => Flight[];
  initialFlightId?: number;
  isError?: boolean;
  errorMessage?: string;
  labelSpan?: number;
  onChange: (id: number | null) => void;
  apiFilter?: FlightsApiFilter;
}

interface INonMultipleFlightsProps extends ICommonProps {
  multiple: false;
  onSetFlight?: (id: number) => void;
}

interface IMultipleFlightsProps extends ICommonProps {
  multiple: true;
  onRemove: () => void;
  existingFlights: number[];
  selectedFlights: Array<number | undefined>;
}

type IProps = INonMultipleFlightsProps | IMultipleFlightsProps;

export const FlightInput = (props: IProps) => {
  const {
    onFilter,
    name,
    label,
    initialFlightId,
    isError,
    errorMessage,
    multiple,
    labelSpan = 24,
    onChange,
    apiFilter = {}
  } = props;

  const [filter, setFilter] = useState('');
  const [flightId, setFlightId] = useState<number | undefined>(undefined);
  const [flight, setFlight] = useState<Flight | undefined>(undefined);

  const { list } = useFlightStore(({ list }) => ({
    list: list.sort(
      (a, b) =>
        new Date(b.actualTimeDeparture || b.estimatedTimeDeparture).getTime() -
        new Date(a.actualTimeDeparture || a.estimatedTimeDeparture).getTime()
    )
  }));

  useEffect(() => {
    initialFlightId && setFlightId(initialFlightId);
  }, [initialFlightId]);

  useEffect(() => {
    if (flightId && list.length > 0) {
      const newFlight = list.find((flight) => flight.id === flightId);
      setFlight(newFlight);
    }
  }, [flightId, list]);

  const flights = onFilter ? onFilter(list) : list;

  const chosenFlight = useMemo(() => flights.find(({ id }) => id === flightId), [flightId]);
  const currentFlight = chosenFlight || flight;

  // The flight can be unlinked if it is already linked (in DB) and it's not DONE
  let isFlightRemovable = true;

  if (currentFlight && multiple && props.existingFlights?.includes(currentFlight.id)) {
    isFlightRemovable = currentFlight.status !== FlightStatus.DONE;
  }

  const { isLoading } = useGetFlights(apiFilter);

  const options = useMemo(
    () =>
      flights.map((flight) => ({
        label: getFlightLabel(flight),
        value: flight.id,
        disabled: multiple && props.selectedFlights?.includes(flight.id)
      })),
    [flights, multiple, 'selectedFlights' in props && props.selectedFlights]
  );

  const handleBlur = () => {
    const filteredOptions = options.filter((o) =>
      o.label.toLowerCase().includes(filter.toLowerCase())
    );

    if (filteredOptions.length === 1 && onChange) {
      onChange(filteredOptions[0].value);
    }
  };

  return (
    <Row
      justify="space-between"
      align="middle"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap'
      }}>
      <ConfigProvider
        renderEmpty={() => (
          <Empty description="Flights not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}>
        <Form.Item
          label={label}
          labelCol={{ span: labelSpan }}
          name={name}
          rules={[
            {
              validator: async () => {
                if (isError) {
                  return Promise.reject(new Error(errorMessage));
                }
              }
            }
          ]}
          style={{ flexGrow: 1 }}>
          <Select
            loading={isLoading}
            size={CONTROL_SIZE}
            options={options}
            onChange={(value) => {
              setFlightId(value);

              if (!value) {
                !multiple && onChange(null);
                setFlightId(undefined);
                setFlight(undefined);
              }

              if (!multiple && props.onSetFlight) {
                props.onSetFlight(value);
              }
            }}
            placeholder="Select flight"
            showSearch
            optionFilterProp="label"
            onSearch={setFilter}
            onBlur={handleBlur}
            allowClear
            disabled={!isFlightRemovable}
          />
        </Form.Item>
      </ConfigProvider>

      {multiple && (
        <Tooltip
          mouseEnterDelay={1}
          title={isFlightRemovable ? 'Unlink flight' : "The completed flight can't be unlinked"}>
          <Button
            type="link"
            shape="circle"
            danger
            size={CONTROL_SIZE}
            icon={<MinusCircleOutlined />}
            style={{ marginBottom: 8 }}
            disabled={!isFlightRemovable}
            onClick={props.onRemove}
            data-testid="delete-flight-btn"
          />
        </Tooltip>
      )}
    </Row>
  );
};
