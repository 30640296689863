import { ColumnsType } from 'antd/es/table';
import { Flight } from 'services/api/data-contracts';
import { CONTROL_SIZE, DATE_TIME_FORMAT } from 'consts/common';
import { getUTCDate } from 'helpers';
import React from 'react';
import { Button, Flex, Tooltip } from 'antd';
import { SendOutlined } from '@ant-design/icons';

export const getColumns = (onCreateNextFlight: (flight: Flight) => void): ColumnsType<Flight> => [
  {
    title: 'Flt №',
    dataIndex: 'flightNumber',
    key: 'flightNumber'
  },
  {
    title: 'Dep',
    dataIndex: 'departureAirport',
    key: 'name',
    render: (_, record) => record.departureAirport?.code
  },
  {
    title: 'Etd',
    dataIndex: 'etd',
    key: 'etd',
    render: (_, record) =>
      getUTCDate(record.actualTimeDeparture || record.estimatedTimeDeparture).format(
        DATE_TIME_FORMAT
      )
  },
  {
    title: 'Arr',
    dataIndex: 'arrivalAirport',
    key: 'arrivalAirport',
    render: (_, record) => record.arrivalAirport?.code
  },
  {
    title: 'Eta',
    dataIndex: 'eta',
    key: 'eta',
    width: 150,
    render: (_, record) => (
      <Flex align="center" justify="space-between">
        {getUTCDate(record.actualTimeArrival || record.estimatedTimeArrival).format(
          DATE_TIME_FORMAT
        )}

        <Tooltip mouseEnterDelay={1} title="Create next flight">
          <Button
            style={{ position: 'absolute', right: 4 }}
            size={CONTROL_SIZE}
            icon={<SendOutlined />}
            shape="circle"
            id="actions"
            onClick={() => onCreateNextFlight(record)}
          />
        </Tooltip>
      </Flex>
    )
  }
];
