import React from 'react';
import { usePaymentStore } from 'store/paymentStore';
import {
  ContractPaymentType,
  PaymentFullyHydrated,
  TransactionType
} from 'services/api/data-contracts';
import { Flex } from 'antd';
import { PaymentView } from './PaymentView/PaymentView';
import { PaymentForm } from './PaymentForm/PaymentForm';
import { PaymentPurposeBlock } from './PaymentPurposeBlock/PaymentPurposeBlock';
import { CreditNoteItemsBlock } from './CreditNoteItemsBlock/CreditNoteItemsBlock';
import { UploadFileButton } from '../../UploadFileButton/UploadFileButton';

interface IProps {
  payment?: PaymentFullyHydrated;
}

export const PaymentDetails = ({ payment }: IProps) => {
  const { isPaymentEditMode } = usePaymentStore(({ isPaymentEditMode }) => ({ isPaymentEditMode }));

  if (isPaymentEditMode) {
    return <PaymentForm />;
  }

  if (!payment) {
    return null;
  }

  return (
    <Flex gap={8} vertical>
      {!isPaymentEditMode && (
        <UploadFileButton
          relatedId={payment?.id}
          counterpartyId={
            payment.type === ContractPaymentType.Outgoing ? payment.supplierId : payment.payerId
          }
          entityName={
            payment.transactionType === TransactionType.CreditNote ? 'CreditNotes' : 'Payments'
          }
        />
      )}
      <PaymentView payment={payment} />

      {(payment.transactionType === TransactionType.CreditNote ||
        payment.transactionType === TransactionType.Rebate) &&
        payment.creditNoteItems && (
          <CreditNoteItemsBlock
            items={payment.creditNoteItems}
            transactionType={payment.transactionType}
          />
        )}

      <PaymentPurposeBlock payment={payment} />
    </Flex>
  );
};
