import React, { useEffect } from 'react';
import { Button, Flex, Form, Input, InputNumber, Popover, Tooltip } from 'antd';
import { useRebateReportStore } from 'store/rebateReportStore';
import { styled } from 'styled-components';
import { StyledDatePicker } from 'components/common/StyledDatePicker';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { useForm, useWatch } from 'antd/es/form/Form';
import { useCreatePayment } from 'queries/payment';
import {
  ContractPaymentType,
  PaymentStatus,
  RebateCreditNoteItemInput,
  TransactionType
} from 'services/api/data-contracts';
import dayjs from 'dayjs';
import { getAmountValue } from 'helpers';

const PopoverContainer = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
`;

export const CreditNoteCreationPopover = () => {
  const [isOpen, setOpen] = React.useState(false);
  const { selectedItems, setSelectedItems } = useRebateReportStore();

  const [form] = useForm();
  const receivedDate = useWatch('receivedDate', form);

  useEffect(() => {
    setOpen(selectedItems.length > 0);
  }, [selectedItems.length]);

  useEffect(() => {
    const precalculatedAmount = selectedItems.reduce(
      (acc, item) => acc + (item.rebateTotal || 0),
      0
    );
    const amount = getAmountValue(precalculatedAmount);
    form.setFieldsValue({ amount });
  }, [selectedItems]);

  const handleCancel = () => {
    form.resetFields();
    setSelectedItems([]);
  };

  const createMutation = useCreatePayment(handleCancel);

  const handleSubmit = () =>
    form
      .validateFields()
      .then(() => {
        if (!selectedItems.length) return;

        const formData = form.getFieldsValue();

        if (formData.amount) {
          formData.amount = Number(formData.amount);
        }

        const { currency, customerId } = selectedItems[0];

        createMutation.mutate({
          ...formData,
          transactionType: TransactionType.Rebate,
          status: PaymentStatus.DONE,
          posted: true,
          currency,
          payerId: customerId,
          type: ContractPaymentType.Incoming,
          items: selectedItems.map(
            ({ id, quantity, rebateAmount = 0, rebateTotal = 0 }) =>
              ({
                invoiceItemId: id,
                quantity,
                price: (rebateAmount || 0) / quantity,
                amount: rebateAmount,
                totalAmount: rebateTotal
              }) as RebateCreditNoteItemInput
          )
        });
      })
      .then(() => {
        handleCancel();
      })
      .catch((err) => err);

  const popoverContent = (
    <Form
      form={form}
      requiredMark={false}
      layout="vertical"
      className="styled-form"
      labelWrap={false}
      initialValues={{ plannedDate: dayjs(), sentDate: dayjs(), receivedDate: dayjs() }}>
      <Flex gap={12}>
        <Form.Item name="plannedDate" label="Planned date" style={{ flexBasis: '25%' }}>
          <StyledDatePicker
            id="plannedDate"
            placeholder="dd.mm.yyyy"
            format={DATE_FORMAT}
            onSetDate={(value) => {
              form && form.setFieldValue('plannedDate', value);
            }}
          />
        </Form.Item>

        <Form.Item name="sentDate" label="Sent date" style={{ flexBasis: '25%' }}>
          <StyledDatePicker
            id="sentDate"
            placeholder="dd.mm.yyyy"
            format={DATE_FORMAT}
            onSetDate={(value) => {
              form && form.setFieldValue('sentDate', value);
            }}
          />
        </Form.Item>

        <Form.Item name="receivedDate" label="Received date" style={{ flexBasis: '25%' }} required>
          <StyledDatePicker
            id="receivedDate"
            placeholder="dd.mm.yyyy"
            format={DATE_FORMAT}
            onSetDate={(value) => {
              form && form.setFieldValue('receivedDate', value);
            }}
          />
        </Form.Item>

        <Form.Item name="amount" label="Amount" style={{ flexBasis: '25%' }} required>
          <InputNumber size={CONTROL_SIZE} style={{ width: '100%' }} disabled />
        </Form.Item>
      </Flex>

      <Form.Item name="note" label="Note">
        <Input.TextArea size={CONTROL_SIZE} />
      </Form.Item>

      <Flex justify="end" gap={8} style={{ marginTop: 32 }}>
        <Button size={CONTROL_SIZE} onClick={handleCancel}>
          Close
        </Button>
        <Tooltip mouseEnterDelay={1} title={!receivedDate ? 'Fill in received date' : ''}>
          <Button
            size={CONTROL_SIZE}
            type="primary"
            onClick={handleSubmit}
            disabled={!receivedDate}>
            Save
          </Button>
        </Tooltip>
      </Flex>
    </Form>
  );

  return (
    <PopoverContainer>
      <Popover
        mouseEnterDelay={1}
        open={isOpen}
        title={
          <Flex vertical gap={4}>
            <span>
              Credit note for {selectedItems[0]?.customerName} in {selectedItems[0]?.currency}
            </span>
            <span style={{ color: '#00000073', fontWeight: 400 }}>
              {selectedItems.length} rebate item{selectedItems.length > 1 ? 's' : ''} selected
            </span>
          </Flex>
        }
        content={popoverContent}
        arrow={false}
        placement="leftTop"
      />
    </PopoverContainer>
  );
};
