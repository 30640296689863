import { getAmountValue, getUTCDate } from 'helpers';
import { DATE_FORMAT } from 'consts/common';
import { ColumnsType } from 'antd/es/table';
import { RebateReportItem } from 'store/rebateReportStore';

export const getInvoiceItemColumns = (): ColumnsType<RebateReportItem> => [
  {
    title: 'Inv',
    key: 'invoiceNumber',
    render: (_, record) => record.invoice.invoiceNumber
  },
  {
    title: 'Service',
    key: 'serviceName',
    render: (_, record) => record.serviceName?.name
  },
  {
    title: 'Date of supply',
    key: 'supplyDate',
    render: (_, record) => getUTCDate(record.invoice.supplyDate).format(DATE_FORMAT)
  },
  {
    title: 'Quantity',
    key: 'quantity',
    render: (_, record) => getAmountValue(record.quantity, 4),
    align: 'end'
  },
  {
    title: 'Rebate price',
    key: 'price',
    render: (_, record) =>
      record.rebateAmount ? getAmountValue(record.rebateAmount / record.quantity) : '-',
    align: 'end'
  },
  {
    title: 'Rebate amount',
    key: 'rebateAmount',
    render: (_, record) => getAmountValue(record.rebateAmount),
    align: 'end'
  },
  {
    title: 'Rebate total',
    key: 'rebateTotal',
    render: (_, record) => getAmountValue(record.rebateTotal),
    align: 'end'
  }
];
