import { PaymentType } from 'services/api/data-contracts';
import { BsFillCircleFill } from 'react-icons/bs';
import { Tooltip } from 'antd';
import React from 'react';
import { gold, green, red } from '@ant-design/colors';

const paymentIconColors: Record<PaymentType, string> = {
  [PaymentType.CREDIT]: green[5],
  [PaymentType.NN]: green[5],
  [PaymentType.PREPD]: green[5],
  [PaymentType.PREPP]: gold[2],
  [PaymentType.PREPN]: red[4]
};

export const paymentTypeTitles: Record<PaymentType, string> = {
  [PaymentType.CREDIT]: 'Credit',
  [PaymentType.NN]: 'Own service',
  [PaymentType.PREPD]: 'Prepayment done',
  [PaymentType.PREPP]: 'Prepayment in process',
  [PaymentType.PREPN]: 'Prepayment needed'
};

interface IProps {
  paymentType: PaymentType;
}

export const PaymentTypeIcon = ({ paymentType }: IProps) => (
  <Tooltip mouseEnterDelay={1} title={paymentTypeTitles[paymentType]}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <BsFillCircleFill fontSize={14} color={paymentIconColors[paymentType]} role="img" />
    </div>
  </Tooltip>
);
