import React from 'react';
import {
  BalanceViewItems,
  Invoice,
  Payment,
  PaymentPurposeInput
} from 'services/api/data-contracts';
import { useBalanceReportStore } from 'store/balanceReportStore';
import {
  areInvoicesInSameCurrency,
  arePaymentsInCurrency,
  isInvoice,
  sortInvoicesByDateAndAmountDue
} from '../utils/helpers';
import { Button, Flex, message, Space } from 'antd';
import { getAmountValue } from 'helpers';
import { styled } from 'styled-components';
import { CONTROL_SIZE, MESSAGE_DURATION } from 'consts/common';
import {
  SettlementEntityType,
  useCreatePaymentPurpose
} from 'queries/paymentPurpose/useCreatePaymentPurpose';

const Divider = styled('span')`
  margin: 0 8px;
  color: #0000000f;
`;

const StyledSpan = styled.span<{ isValid: boolean }>`
  color: ${({ isValid }) => (isValid ? 'inherit' : '#00000073')};
`;

interface IProps {
  items: BalanceViewItems;
}

export const BalanceFooter = ({ items }: IProps) => {
  const { selectedInvoices, selectedPayments, setSelectedEntities, setShouldUpdateBalanceView } =
    useBalanceReportStore(
      ({ selectedEntities, setSelectedEntities, setShouldUpdateBalanceView }) => ({
        selectedInvoices: selectedEntities.filter((entity) => isInvoice(entity)) as Invoice[],
        selectedPayments: selectedEntities.filter((entity) => !isInvoice(entity)) as (Payment & {
          advance: number;
        })[],
        setSelectedEntities,
        setShouldUpdateBalanceView
      })
    );

  const createMutation = useCreatePaymentPurpose(undefined, SettlementEntityType.BALANCE);

  if (!selectedInvoices.length && !selectedPayments.length) {
    return <div>Total: {items?.length ?? 0}</div>;
  }

  const currency = selectedInvoices[0]?.currency || selectedPayments[0]?.currency;
  const advanceTotal = selectedPayments.reduce((sum, entity) => sum + entity.advance, 0);
  const amountDueTotal = selectedInvoices.reduce((sum, entity) => sum + entity.amountDue, 0);
  const paymentRemains = advanceTotal - amountDueTotal;

  const invoicesAreInSameCurrency = areInvoicesInSameCurrency(selectedInvoices);
  const paymentsAreInInvoiceCurrency = arePaymentsInCurrency(
    selectedPayments,
    selectedInvoices[0]?.currency
  );

  const handleProcessInvoice = async (
    invoice: Invoice,
    advance: number,
    paymentId: number,
    paidInvoiceNumbers: string[]
  ) => {
    const data = {
      paymentId: paymentId,
      invoiceId: invoice.id,
      paidAmount: advance < invoice.amountDue ? advance : invoice.amountDue
    };

    try {
      await createMutation.mutateAsync(data as PaymentPurposeInput);
      advance -= invoice.invoiceTotal;
      paidInvoiceNumbers.push(invoice.invoiceNumber as string);
      return advance;
    } catch {
      const linkedInvoicesMessage =
        paidInvoiceNumbers.length > 0
          ? `Payment was linked to invoice: ${paidInvoiceNumbers.join(', ')}.`
          : 'No invoices were linked.';

      throw new Error(
        `Failed to link payment ${selectedPayments[0].id} to invoice ${invoice.invoiceNumber}. Linking process is stopped. ${
          linkedInvoicesMessage
        }`
      );
    }
  };

  const handleSubmit = async () => {
    let advance = advanceTotal;
    const sortedInvoices = sortInvoicesByDateAndAmountDue(selectedInvoices, advance);
    const paidInvoiceNumbers: string[] = [];

    try {
      for (const invoice of sortedInvoices) {
        if (advance > 0 && invoice.amountDue !== 0) {
          const lastInvoiceToLink =
            sortedInvoices.length === 1 || sortedInvoices.length - 1 === paidInvoiceNumbers.length;

          if (lastInvoiceToLink) {
            setShouldUpdateBalanceView(true);
          }

          advance = await handleProcessInvoice(
            invoice,
            advance,
            selectedPayments[0].id as number,
            paidInvoiceNumbers
          );

          if (advance === 0) {
            break;
          }
        }
      }
    } catch (error) {
      message.error((error as Error).message, MESSAGE_DURATION);
    } finally {
      setSelectedEntities([]);
    }
  };

  return (
    <Flex justify="space-between">
      <div>
        <StyledSpan isValid={paymentRemains !== 0}>
          Payment remains:
          <span style={{ color: paymentRemains >= 0 ? '#52C41A' : '#FF4D4F' }}>
            {paymentRemains !== 0 ? ' ' + currency + ' ' : ' '}
            {getAmountValue(paymentRemains)}
          </span>
        </StyledSpan>

        <Divider>|</Divider>

        <StyledSpan
          isValid={
            advanceTotal > 0 && paymentsAreInInvoiceCurrency && selectedPayments.length === 1
          }>
          {selectedPayments.length > 1
            ? 'Only one payment can be selected'
            : paymentsAreInInvoiceCurrency
              ? `Paid amount: ${advanceTotal > 0 ? currency + ' ' : ''}${getAmountValue(advanceTotal)}`
              : 'Payments should be in invoice currency'}
        </StyledSpan>

        <Divider>|</Divider>

        <StyledSpan isValid={amountDueTotal > 0 && invoicesAreInSameCurrency}>
          {invoicesAreInSameCurrency
            ? `Amount due: ${amountDueTotal > 0 ? currency + ' ' : ''}${getAmountValue(amountDueTotal)}`
            : 'All invoices should be in a same currency'}
        </StyledSpan>
      </div>

      <Space>
        <Button size={CONTROL_SIZE} onClick={() => setSelectedEntities([])}>
          Cancel
        </Button>
        <Button
          size={CONTROL_SIZE}
          type="primary"
          disabled={
            advanceTotal === 0 ||
            amountDueTotal === 0 ||
            !paymentsAreInInvoiceCurrency ||
            !invoicesAreInSameCurrency ||
            selectedPayments.length > 1
          }
          onClick={handleSubmit}
          loading={createMutation.isLoading}>
          Link the selected
        </Button>
      </Space>
    </Flex>
  );
};
