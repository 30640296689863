import { createContext } from 'react';

export enum DashboardType {
  'Dispatcher' = 'Dispatcher',
  'Settlement' = 'Settlement',
  'Balance' = 'Balance',
  'Rebate' = 'Rebate'
}

export const DashboardContext = createContext<{ type: DashboardType }>({
  type: DashboardType.Dispatcher
});

export const DASHBOARD_TOP_PADDING = 169;
export const SCROLL_DISCREPANCY_THRESHOLD = 1;
export const LIST_FOOTER_BULK_BLOCK_HEIGHT = 57;
export const DEFAULT_DOCUMENT_TEMPLATE_NAME = 'default';
