import { RebateReportItem } from 'store/rebateReportStore';

export const validateRebateItems = (
  selectedItems: RebateReportItem[],
  newItem: RebateReportItem
): void => {
  if (selectedItems.length === 0) return;

  const { currency, customerId } = selectedItems[0];

  if (newItem.customerId !== customerId) {
    throw new Error('Only one customer can be used to create a credit note');
  }

  if (newItem.currency !== currency) {
    throw new Error('Only one currency can be used to create a credit note');
  }
};
