import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { ContractPaymentType, Payment, SettlementInvoice } from 'services/api/data-contracts';
import { Button, Table, Tooltip } from 'antd';
import { getAmountValue, getUTCDate } from 'helpers';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { DocumentStatusTag } from '../../components/DocumentStatusTag/DocumentStatusTag';
import { getDocumentStatusTag } from 'helpers/dataTransformations/getDocumentStatusTag';
import { PercentageOutlined } from '@ant-design/icons';
import { geekblue, magenta } from '@ant-design/colors';
import { ArrowIcon } from '../../InvoicesWidget/components/ArrowIcon/ArrowIcon';

export const getColumns = (
  onOpenRecord: (id: number, type: 'invoice' | 'payment') => void
): ColumnsType<
  | SettlementInvoice
  | (Payment & {
      totalByOrderService: number;
    })
> => [
  Table.EXPAND_COLUMN,
  {
    title: '',
    dataIndex: 'type',
    key: 'type',
    width: 40,
    render: (_, record) => {
      const isInvoice = 'invoiceNumber' in record;

      const tooltip = isInvoice ? `Open ${record.type.toLowerCase()} invoice` : 'Open credit note';
      const icon = isInvoice ? (
        <ArrowIcon type={record.type} hideTooltip />
      ) : (
        <PercentageOutlined
          style={{
            color: record.type === ContractPaymentType.Incoming ? magenta[6] : geekblue[3]
          }}
        />
      );
      const entityType = isInvoice ? 'invoice' : 'payment';

      return (
        <Tooltip title={tooltip}>
          <Button
            type="link"
            size={CONTROL_SIZE}
            data-testid="open-invoice-btn"
            icon={icon}
            onClick={() => onOpenRecord(record.id, entityType)}
          />
        </Tooltip>
      );
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => (
      <DocumentStatusTag
        status={getDocumentStatusTag(
          (record as Payment).posted || (record as SettlementInvoice).isPosted,
          'invoiceNumber' in record
            ? (record as SettlementInvoice).status
            : (record as Payment).creditNoteStatus
        )}
      />
    )
  },
  {
    title: '№',
    dataIndex: 'number',
    key: 'number',
    render: (_, record) =>
      'invoiceNumber' in record ? record.invoiceNumber : record.creditNoteNumber
  },
  {
    title: 'Date',
    dataIndex: 'invoiceDate',
    key: 'invoiceDate',
    render: (value) => getUTCDate(value).format(DATE_FORMAT)
  },
  {
    title: 'Cur',
    dataIndex: 'currency',
    key: 'currency'
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: (_, record) => getAmountValue('total' in record ? record.total : record.amount),
    align: 'end'
  },
  {
    title: '',
    dataIndex: 'totalByOrderService',
    key: 'totalByOrderService',
    render: (_, record) => (
      <span style={{ color: '#00000073' }}>{getAmountValue(record.totalByOrderService)}</span>
    ),
    align: 'end'
  },
  {
    title: 'Agent fee',
    dataIndex: 'agentFeeAmount',
    key: 'agentFeeAmount',
    render: (_, record) =>
      'agentFeeAmount' in record ? getAmountValue(record.agentFeeAmount) : null,
    align: 'end'
  },
  {
    title: '',
    dataIndex: 'agentFeeAmountByOrderService',
    key: 'agentFeeAmountByOrderService',
    render: (_, record) =>
      'agentFeeAmountByOrderService' in record ? (
        <span style={{ color: '#00000073' }}>
          {getAmountValue(record.agentFeeAmountByOrderService)}
        </span>
      ) : null,
    align: 'end'
  }
];
