import { Badge, Button, Flex, Popover, Select } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { CONTROL_SIZE } from 'consts/common';
import React, { useState } from 'react';
import { getSelectOptions } from 'helpers';
import {
  BalanceUiFilter,
  InvoiceStatus,
  PaymentStatus,
  useBalanceReportStore
} from 'store/balanceReportStore';
import { isEqual } from 'lodash';
import { blue } from '@ant-design/colors';

const selectProps = {
  mode: 'multiple',
  style: { width: 220 },
  allowClear: true,
  size: CONTROL_SIZE,
  maxTagCount: 'responsive'
};

export const BalanceUIFilters = () => {
  const [isOpen, setOpen] = useState(false);
  const [filter, setFilter] = useState<BalanceUiFilter>({});
  const { uiFilter, setUiFilter } = useBalanceReportStore();

  const handleSelectChange = (name: string, value: string[]) => {
    setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
  };

  const handleApplyFilter = () => {
    setUiFilter(filter);
    setOpen(false);
  };

  const allSelectedValuesCount = Object.values(uiFilter).flat().length;

  return (
    <Popover
      content={
        <Flex gap={8}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          <Select
            {...selectProps}
            placeholder="All invoice statuses"
            options={getSelectOptions(Object.values(InvoiceStatus))}
            onChange={(value) => handleSelectChange('invoiceStatus', value)}
          />

          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          <Select
            {...selectProps}
            placeholder="All payment statuses"
            options={getSelectOptions(Object.values(PaymentStatus))}
            onChange={(value) => handleSelectChange('paymentStatus', value)}
          />

          <Button
            type="primary"
            disabled={isEqual(uiFilter, filter)}
            onClick={handleApplyFilter}
            size={CONTROL_SIZE}>
            Apply
          </Button>
        </Flex>
      }
      placement="bottomRight"
      open={isOpen}
      trigger="click"
      onOpenChange={() => setOpen(false)}>
      <Badge
        count={allSelectedValuesCount}
        size={CONTROL_SIZE}
        onClick={() => setOpen((isOpen) => !isOpen)}
        color={blue[5]}>
        <Button icon={<FilterOutlined />} size={CONTROL_SIZE} />
      </Badge>
    </Popover>
  );
};
