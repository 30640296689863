import React from 'react';
import { StyledFormItem, StyledHeader, StyledInput, StyledSelect } from '../NewMessage.styles';
import { FORM_FIELD_NAMES, FORM_VALIDATION_RULES } from '../constants';
import { CONTROL_SIZE } from 'consts/common';
import { getSelectOptions, useSelectAutoFocus } from 'helpers';

interface FormFieldsProps {
  emailAddressOptions: string[];
  isLoadingContacts: boolean;
  prefilledAddressOptions: string[];
  children?: React.ReactNode;
}

export const EmailHeader = ({
  emailAddressOptions,
  isLoadingContacts,
  prefilledAddressOptions,
  children
}: FormFieldsProps) => {
  const toAddressRef = useSelectAutoFocus();

  return (
    <StyledHeader vertical>
      <StyledFormItem
        name={FORM_FIELD_NAMES.to}
        rules={FORM_VALIDATION_RULES.to}
        data-testid="email_to"
        label="To">
        <StyledSelect
          mode="tags"
          style={{ width: '100%' }}
          size={CONTROL_SIZE}
          options={getSelectOptions([...prefilledAddressOptions, ...emailAddressOptions])}
          ref={toAddressRef}
          loading={isLoadingContacts}
        />
      </StyledFormItem>

      <StyledFormItem
        name={FORM_FIELD_NAMES.copy}
        data-testid="email_cc"
        rules={FORM_VALIDATION_RULES.copy}
        label="CC">
        <StyledSelect
          mode="tags"
          style={{ width: '100%' }}
          size={CONTROL_SIZE}
          options={emailAddressOptions.map((email) => ({ value: email, label: email }))}
        />
      </StyledFormItem>

      <StyledFormItem
        name={FORM_FIELD_NAMES.subject}
        rules={FORM_VALIDATION_RULES.subject}
        data-testid="email_subject">
        <StyledInput size={CONTROL_SIZE} placeholder="Subject" />
      </StyledFormItem>

      {children}
    </StyledHeader>
  );
};
