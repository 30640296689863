import { useQuery } from '@tanstack/react-query';
import { invoicesApi } from 'services/api';
import { QUERY_KEY } from './consts';

const fetchList = async (invoiceId: number) => await invoicesApi.invoiceReportsList(invoiceId);

export const useGetInvoiceDocumentsList = (id?: number) =>
  useQuery({
    queryKey: [QUERY_KEY, id],
    queryFn: async () => {
      if (id) {
        return await fetchList(id);
      }
    },
    enabled: !!id
  });
