import { useQuery } from '@tanstack/react-query';
import { paymentsApi } from 'services/api';
import { PAYMENT_QUERY_KEY } from './consts';
import { ReportEntityNames, TransactionType } from '../../services/api/data-contracts';

interface IFilter {
  templateType: ReportEntityNames;
}

const fetchList = async (filter: IFilter) => await paymentsApi.getPaymentTemplates(filter);

export const useGetPaymentTemplates = (transactionType?: TransactionType, enabled?: boolean) =>
  useQuery({
    queryKey: [PAYMENT_QUERY_KEY],
    queryFn: async () =>
      fetchList({
        templateType:
          transactionType === TransactionType.CreditNote
            ? ReportEntityNames.CreditNotes
            : transactionType === TransactionType.Rebate
              ? ReportEntityNames.RebateCreditNotes
              : ReportEntityNames.Payments
      }),
    keepPreviousData: true,
    enabled
  });
