import React, { useMemo } from 'react';
import { Table } from 'antd';
import { CreditNoteItemHydrated } from 'services/api/data-contracts';
import { CONTROL_SIZE } from 'consts/common';
import { getColumns } from './columns';
import { styled } from 'styled-components';
import { getAmountValue } from 'helpers';

const StyledTable = styled(Table<CreditNoteItemHydrated>)`
  .even {
    background-color: #fafafa;
  }
`;

interface IProps {
  items: CreditNoteItemHydrated[];
}

export const RebateItemsTable = ({ items }: IProps) => {
  const dataSource = items.map((i) => ({ ...i, key: i.id })) as CreditNoteItemHydrated[];
  const columns = useMemo(getColumns, []);

  return (
    <StyledTable
      dataSource={dataSource}
      columns={columns}
      size={CONTROL_SIZE}
      pagination={false}
      rowClassName={(_, idx) => (idx % 2 ? 'even' : '')}
      summary={(items) =>
        items.length > 0 && (
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={5} align="end">
              <span>
                {getAmountValue(items.reduce((acc, { totalAmount }) => acc + totalAmount, 0))}
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} />
          </Table.Summary.Row>
        )
      }
    />
  );
};
