import { getUTCDate } from 'helpers';
import {
  ContractPaymentType,
  CreditNoteItemInput,
  InvoiceHydrated,
  InvoiceType,
  PaymentHydrated,
  PaymentStatus,
  TransactionType
} from 'services/api/data-contracts';

export const transformForRequest = (
  formData: Pick<PaymentHydrated, 'notes'> & { creditNoteItems: CreditNoteItemInput[] },
  invoice: InvoiceHydrated
) => {
  const today = getUTCDate().format();

  return {
    transactionType: TransactionType.CreditNote,
    items: formData.creditNoteItems,
    amount: formData.creditNoteItems.reduce(
      (acc: number, item: CreditNoteItemInput) => acc + item.amount,
      0
    ),
    notes: formData.notes,
    status: PaymentStatus.DONE,
    plannedDate: today,
    sentDate: today,
    receivedDate: today,
    currency: invoice.currency,
    posted: true,
    correctedInvoiceId: invoice.id,
    supplierId: invoice.supplierId,
    payerId: invoice.payerId,
    type:
      invoice.type === InvoiceType.Received
        ? ContractPaymentType.Outgoing
        : ContractPaymentType.Incoming,
    contractId: invoice.contractId
  };
};
