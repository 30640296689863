import { Dayjs } from 'dayjs';
import { useFlightStore } from 'store/flightStore';
import { useCallback, useContext } from 'react';
import { FormInstance } from 'antd/es/form';
import { GHOrderInputWithDayJs } from '../components/utils/ghOrderUtils';
import { FuelOrderInputWithDayJs } from '../components/utils/fuelOrderUtils';
import { AddOrderInputWithDayJs } from '../components/utils/addOrderUtils';
import { computeEstimatedParkingTime } from './compute';
import { OrderType } from 'services/api/data-contracts';
import { getUTCDate } from 'helpers';
import { FormContext } from '../../components/OrderDrawer/OrderDrawer';

type FormType = GHOrderInputWithDayJs | FuelOrderInputWithDayJs | AddOrderInputWithDayJs;

export const useFormUpdate = <T extends FormType>(form: FormInstance, orderType?: OrderType) => {
  const { flights } = useFlightStore(({ list }) => ({ flights: list }));

  const getFlight = useCallback(
    (flightId?: number) => flights.find(({ id }) => id === flightId),
    [flights]
  );

  const { isTouched, setTouched } = useContext(FormContext);

  return (updated: Partial<T>, data: T) => {
    const didChange = form?.isFieldsTouched() !== isTouched;

    if (didChange && form) {
      setTouched && setTouched(form.isFieldsTouched());
    }

    const { isAlternateAirport, startEndDate, locationId, arrivalFlightId, departureFlightId } =
      data;

    if (updated.operatorId) {
      form.setFieldValue('aircraftId', undefined);
    }

    if (orderType === OrderType.PMT || !orderType || 'status' in updated) {
      return;
    }

    const arrivalFlight = getFlight(arrivalFlightId);
    const departureFlight = getFlight(departureFlightId);

    const updatedArrivalFlight = getFlight(updated.arrivalFlightId);
    const updatedDepartureFlight = getFlight(updated.departureFlightId);

    // Airport, operator
    if (arrivalFlightId || departureFlightId) {
      form.setFieldsValue({
        aircraftId: arrivalFlight?.aircraftId || departureFlight?.aircraftId,
        operatorId: arrivalFlight?.operatorId || departureFlight?.operatorId
      });
    }

    // Location, alternative airport
    if ((arrivalFlightId || departureFlightId) && orderType !== OrderType.ADD) {
      form.setFieldsValue({
        location: departureFlight?.departureAirport || arrivalFlight?.arrivalAirport,
        locationId: departureFlight?.departureAirportId || arrivalFlight?.arrivalAirportId,
        isAlternateAirport: false
      });
    }

    // Alternative airport
    if (arrivalFlight && locationId) {
      form.setFieldsValue({
        isAlternateAirport: !departureFlight && locationId !== arrivalFlight.arrivalAirportId
      });
    } else if (departureFlight) {
      form.setFieldsValue({ isAlternateAirport: false });
    }

    // Start D&T, end D&T
    let startDate = startEndDate[0];
    let endDate: Dayjs | undefined = startEndDate[1];

    if (updatedArrivalFlight && orderType !== OrderType.ADD) {
      startDate = updatedArrivalFlight
        ? getUTCDate(
            isAlternateAirport
              ? updatedArrivalFlight?.estimatedTimeDeparture
              : updatedArrivalFlight?.actualTimeArrival ||
                  updatedArrivalFlight?.estimatedTimeArrival
          )
        : startEndDate[0];
      endDate =
        !departureFlight && isAlternateAirport
          ? getUTCDate(updatedArrivalFlight?.estimatedTimeArrival)
          : undefined;
    }

    if (updatedDepartureFlight && orderType !== OrderType.ADD) {
      endDate = getUTCDate(
        updatedDepartureFlight?.actualTimeDeparture ||
          updatedDepartureFlight?.estimatedTimeDeparture
      );
    }

    form.setFieldsValue({
      startEndDate: [startDate, endDate]
    });

    // EPT
    form.setFieldsValue({
      estimatedParkingTime: computeEstimatedParkingTime(startDate, endDate)
    });
  };
};
