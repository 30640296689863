import styled from 'styled-components';
import { Tooltip } from 'antd';
import React from 'react';

interface DecoratedText {
  $isActive?: boolean;
}

const StyledText = styled('span')<DecoratedText>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => (props.$isActive ? 'inherit' : '#00000073')};
`;

export const DecoratedText = ({
  isActive,
  children
}: {
  isActive?: boolean;
  children: React.ReactNode;
}) => (
  <Tooltip mouseEnterDelay={1} title={isActive ? '' : 'Inactive'}>
    <StyledText $isActive={isActive}>{children}</StyledText>
  </Tooltip>
);
