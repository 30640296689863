import { AttachmentEntry as Attachment } from 'services/emailEngineApi/data-contracts';
import { useGetAttachments } from 'queries/messages/useGetAttachments';
import { AxiosResponse } from 'axios';

export const useDownloadFiles = (attachments: Attachment[]) => {
  const { refetchFiles } = useGetAttachments();
  const ids = attachments.map((a) => a.id).filter(Boolean) as string[];

  const downloadFilesOrThrowError = async () => {
    try {
      const responses = await refetchFiles(ids);
      responses.forEach((response, index) => {
        const binaryStream = (response as AxiosResponse<BlobPart, unknown>).data;
        const attachment = attachments[index];
        const blob = new Blob([binaryStream], { type: attachment.contentType });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', attachment.filename as string);
        link.click();

        window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.error('Error fetching binaries:', error);
      throw error;
    }
  };

  return {
    downloadFilesOrThrowError
  };
};
