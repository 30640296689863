import React, { useEffect } from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { Button, Flex, Form, Input, Select } from 'antd';
import { Contact, RecipientTagType } from 'services/api/data-contracts';
import { useCreateCounterpartyContact, useUpdateCounterpartyContact } from 'queries/counterparty';
import { useForm, useWatch } from 'antd/es/form/Form';
import { getSelectOptions } from 'helpers';

interface IProps {
  counterpartyId: number;
  onCancel: () => void;
  current?: Contact;
}

export const CounterpartyContactForm = ({ counterpartyId, onCancel, current }: IProps) => {
  const [form] = useForm<Contact>();

  const email = useWatch('emailAddress', form);
  const name = useWatch('contactName', form);

  useEffect(() => {
    current && form.setFieldsValue(current);
  }, [current]);

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const createMutation = useCreateCounterpartyContact(counterpartyId, handleCancel);
  const updateMutation = useUpdateCounterpartyContact(counterpartyId, handleCancel);

  const handleSubmit = () =>
    form.validateFields().then(() => {
      const data = form.getFieldsValue();

      data.id ? updateMutation.mutate(data) : createMutation.mutate(data);
    });

  return (
    <Form
      form={form}
      initialValues={{ recipientTag: [] }}
      className="styled-form"
      layout="vertical"
      requiredMark="optional"
      style={{ marginBottom: 12 }}>
      <Form.Item name="id" hidden />

      <Flex gap={8}>
        <Form.Item
          name="emailAddress"
          label="Email"
          required
          style={{ flexGrow: 1 }}
          rules={[
            { type: 'email', message: 'Please enter a valid email' },
            { required: true, message: 'Is required' }
          ]}>
          <Input size={CONTROL_SIZE} />
        </Form.Item>

        <Form.Item name="recipientTag" label="Recipient for">
          <Select
            size={CONTROL_SIZE}
            mode="multiple"
            options={getSelectOptions(Object.values(RecipientTagType))}
            style={{ width: 275 }}
            maxTagCount="responsive"
          />
        </Form.Item>
      </Flex>

      <Flex gap={8} align="flex-end">
        <Form.Item name="contactName" label="Contact name" required style={{ width: '100%' }}>
          <Input size={CONTROL_SIZE} />
        </Form.Item>

        <Button
          size={CONTROL_SIZE}
          style={{ marginBottom: 12 }}
          onClick={handleCancel}
          loading={createMutation.isLoading || updateMutation.isLoading}>
          Cancel
        </Button>

        <Button
          size={CONTROL_SIZE}
          style={{ marginBottom: 12 }}
          type="primary"
          disabled={!email || !name}
          onClick={handleSubmit}
          loading={createMutation.isLoading || updateMutation.isLoading}>
          {current ? 'Save' : 'Add'}
        </Button>
      </Flex>
    </Form>
  );
};
