import { Tooltip } from 'antd';
import React from 'react';
import { OrderStatus } from 'services/api/data-contracts';
import { SmallTag } from 'components/common/SmallTag';

interface IProps {
  status?: OrderStatus;
}

export const orderStatusMap = {
  [OrderStatus.CNFD]: 'Confirmed',
  [OrderStatus.DONE]: 'Completed',
  [OrderStatus.RINV]: 'Ready for invoicing',
  [OrderStatus.ESTD]: 'Estimated',
  [OrderStatus.INVD]: 'Invoiced',
  [OrderStatus.PART]: 'Partially invoiced',
  [OrderStatus.PRCG]: 'Processing',
  [OrderStatus.CNLD]: 'Cancelled'
};

const orderStatusColorsMap = {
  [OrderStatus.CNFD]: 'lime',
  [OrderStatus.DONE]: 'green',
  [OrderStatus.RINV]: undefined,
  [OrderStatus.ESTD]: 'geekblue',
  [OrderStatus.INVD]: 'purple',
  [OrderStatus.PART]: 'magenta',
  [OrderStatus.PRCG]: 'orange',
  [OrderStatus.CNLD]: 'red'
};

export const OrderStatusTag = ({ status }: IProps) => {
  if (!status) {
    return null;
  }

  return (
    <Tooltip mouseEnterDelay={1} title={orderStatusMap[status]}>
      <SmallTag data-testid="tag" color={orderStatusColorsMap[status]}>
        {status}
      </SmallTag>
    </Tooltip>
  );
};
