import React from 'react';
import { InvoiceItemHydrated, InvoiceType } from 'services/api/data-contracts';
import { getInvoiceItemsColumns } from './columns';
import { Button, Empty, Table, Typography } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import styled from 'styled-components';
import { useInvoiceStore } from 'store/invoiceStore';
import { EditOutlined } from '@ant-design/icons';
import { groupBy } from 'lodash';
import { getAmountValue } from 'helpers';
import { useDrawerStore } from 'store/drawerStore';
import { useHighlightRow } from './useHilightRow';

const TableWrapper = styled.div`
  position: relative;

  &:hover .float-btn {
    display: block;
  }

  #actions {
    display: none;
    position: absolute;
    right: 0;
  }

  #actions > button {
    margin-right: 4px;
  }

  .ant-table-cell-row-hover #actions {
    display: block;
  }

  .ant-table-summary .ant-table-cell {
    padding-left: 0 !important;
    border-bottom: none;
  }

  .highlighted {
    border: 1px solid #d9d9d9;
    background-color: #e6f7ff;
  }
`;

interface IProps {
  invoiceItems: InvoiceItemHydrated[];
  onSetEditMode: (isEditMode: boolean) => void;
}

export const InvoiceItemsView = ({ invoiceItems, onSetEditMode }: IProps) => {
  const { isIssuedInvoice, setCurrentInvoiceItem } = useInvoiceStore(
    ({ current, setCurrentInvoiceItem }) => ({
      isIssuedInvoice: current?.type === InvoiceType.Issued,
      setCurrentInvoiceItem
    })
  );
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const highlightedId = useHighlightRow();

  const handleOpenItem = (invoiceItem: InvoiceItemHydrated) => {
    setCurrentInvoiceItem(invoiceItem);
    setDrawerOpen('calculator');
  };

  const columns = getInvoiceItemsColumns(handleOpenItem, isIssuedInvoice).filter((col) =>
    isIssuedInvoice ? col.key !== 'outName' : col.key
  );
  const data = invoiceItems.map((item) => ({ ...item, key: item.id }));

  if (!invoiceItems.length) {
    return (
      <Empty
        description="Invoice items have not been added yet"
        image={Empty.PRESENTED_IMAGE_SIMPLE}>
        <Button size={CONTROL_SIZE} onClick={() => onSetEditMode(true)}>
          Add Invoice items
        </Button>
      </Empty>
    );
  }

  const ownPriceInvoiceItems = data.filter((item) => !!item.isOwnPrice);
  const agentFeeInvoiceItems = groupBy(
    data.filter((item) => !item.isOwnPrice),
    'agentFee'
  );

  if (isIssuedInvoice) {
    const tables = [ownPriceInvoiceItems, ...Object.values(agentFeeInvoiceItems)].filter(
      (data) => data.length > 0
    );

    return (
      <>
        {tables.map((tableData, idx) => {
          const isOwnPrice = tableData[0].isOwnPrice;
          const agentFee = tableData[0].agentFee || 0;
          const isFirstTable = idx === 0;
          const isLastTable = idx === tables.length - 1;
          const totalAgentFee = tableData.reduce(
            (acc, curr) => acc + (curr.agentFeeAmount || 0),
            0
          );

          return (
            <TableWrapper key={idx}>
              {!isOwnPrice && (
                <Typography.Title
                  style={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '22px',
                    marginTop: 24
                  }}>
                  {agentFee}% agent&apos;s fee
                </Typography.Title>
              )}
              <Table
                columns={columns}
                dataSource={tableData}
                size={CONTROL_SIZE}
                pagination={false}
                data-testid="invoice-items-view"
                showHeader={isFirstTable}
                rowClassName={(record) => (record.id === highlightedId ? 'highlighted' : '')}
                summary={() => (
                  <Table.Summary fixed="bottom">
                    {!isOwnPrice && (
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} />
                        <Table.Summary.Cell index={1} colSpan={2}>
                          <span style={{ padding: '0 4px' }}>Agent fee</span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell colSpan={7} index={0} align="right">
                          {getAmountValue(totalAgentFee)}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                    {isLastTable && (
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={10} index={0}>
                          <Button
                            data-testid="edit-invoice-items-btn"
                            onClick={() => onSetEditMode(true)}
                            type="link"
                            size={CONTROL_SIZE}
                            icon={<EditOutlined />}>
                            Edit invoice items
                          </Button>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                  </Table.Summary>
                )}
              />
            </TableWrapper>
          );
        })}
      </>
    );
  }

  return (
    <TableWrapper>
      <Table
        columns={columns}
        dataSource={data}
        size={CONTROL_SIZE}
        pagination={false}
        data-testid="invoice-items-view"
        rowClassName={(record) => (record.id === highlightedId ? 'highlighted' : '')}
        summary={() => (
          <Table.Summary fixed="bottom">
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={10} index={0}>
                <Button
                  data-testid="edit-invoice-items-btn"
                  onClick={() => onSetEditMode(true)}
                  type="link"
                  size={CONTROL_SIZE}
                  icon={<EditOutlined />}>
                  Edit invoice items
                </Button>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </TableWrapper>
  );
};
