import { Button, Flex, Popover, Select, Typography } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { getSelectOptions } from 'helpers';
import { OrderStatus } from 'services/api/data-contracts';
import { EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useUpdateFlightProgram } from 'queries/flightProgram';
import { useFlightProgramStore } from 'store/flightProgramStore';

export const ChangeStatusPopover = () => {
  const [isOpen, setOpen] = useState(false);
  const [status, setStatus] = useState<OrderStatus | undefined>(undefined);

  const { openProgramId } = useFlightProgramStore();

  useEffect(() => {
    setStatus(undefined);
  }, [isOpen]);

  const updateMutation = useUpdateFlightProgram(Number(openProgramId));

  const handleSubmit = async () => {
    if (!status) return;

    try {
      await updateMutation.mutateAsync({ status });
    } finally {
      setOpen(false);
    }
  };

  return (
    <Popover
      mouseEnterDelay={1}
      open={isOpen}
      onOpenChange={(newState) => setOpen(newState)}
      trigger="click"
      placement="bottomRight"
      content={
        <Flex vertical gap={8}>
          <Select
            loading={updateMutation.isLoading}
            value={status}
            onChange={setStatus}
            size={CONTROL_SIZE}
            options={getSelectOptions(Object.values(OrderStatus))}
            showSearch
            optionFilterProp="label"
            popupMatchSelectWidth={false}
            placeholder="Select status"
            style={{ width: 130 }}
          />

          <Typography.Text type="secondary">
            The changes will not affect future orders in CNLD status
          </Typography.Text>

          <Flex justify="end" gap={8}>
            <Button
              size={CONTROL_SIZE}
              onClick={() => setOpen(false)}
              loading={updateMutation.isLoading}>
              Close
            </Button>
            <Button
              size={CONTROL_SIZE}
              type="primary"
              disabled={!status}
              onClick={handleSubmit}
              loading={updateMutation.isLoading}>
              Save
            </Button>
          </Flex>
        </Flex>
      }>
      <Button icon={<EditOutlined />} size={CONTROL_SIZE} type="dashed">
        Change the status
      </Button>
    </Popover>
  );
};
