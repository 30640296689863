import React, { useEffect, useMemo, useState } from 'react';
import { Button, Flex, Form, FormListOperation, InputNumber, Select, Tooltip } from 'antd';
import { InvoiceItemHydrated, InvoiceType } from 'services/api/data-contracts';
import { CONTROL_SIZE, SERVER_DATE_FORMAT } from 'consts/common';
import { DeleteOutlined, ExportOutlined } from '@ant-design/icons';
import { ServiceNameInput } from 'components/ServiceNameInput/ServiceNameInput';
import { VatRateInput } from 'components/VatRateInput/VatRateInput';
import { useInvoiceStore } from 'store/invoiceStore';
import { FormInstance } from 'antd/es/form';
import { getAmountValue, getUTCDate, round } from 'helpers';
import { PriceInput } from './PriceInput';
import { AgentFeeInput } from './AgentFeeInput';
import { useWatch } from 'antd/es/form/Form';
import { OptionType } from './InvoiceItemsForm';
import { isNumber } from 'lodash';
import { RebateInput } from './RebateInput';

let ListOperation: FormListOperation;

interface IProps {
  formListItemKey: number;
  invoiceItems: Partial<InvoiceItemHydrated>[];
  remove: typeof ListOperation.remove;
  form: FormInstance<{ invoiceItems: InvoiceItemHydrated[] }>;
  agentFeeOptions: OptionType[];
  setAgentFeeOptions: React.Dispatch<React.SetStateAction<OptionType[]>>;
}

const setOwnPriceProps = (
  form: FormInstance<{ invoiceItems: InvoiceItemHydrated[] }>,
  formListItemKey: number,
  price?: number,
  ownPriceId?: number
) => {
  if (isNumber(price)) {
    form.setFieldValue(['invoiceItems', formListItemKey, 'price'], price);

    const quantity = form.getFieldValue(['invoiceItems', formListItemKey, 'quantity']);
    if (isNumber(quantity)) {
      form.setFieldValue(['invoiceItems', formListItemKey, 'amount'], round(quantity * price));
    }
  }

  form.setFieldValue(['invoiceItems', formListItemKey, 'ownPriceId'], ownPriceId);
};

export const InvoiceItemForm = ({
  formListItemKey,
  invoiceItems,
  remove,
  form,
  setAgentFeeOptions,
  agentFeeOptions
}: IProps) => {
  const [unitOfMeasureShortName, setUnitOfMeasureShortName] = useState<string | undefined>(
    undefined
  );
  const isOwnPrice = useWatch(['invoiceItems', formListItemKey, 'isOwnPrice'], form);
  const ownPriceId = useWatch(['invoiceItems', formListItemKey, 'ownPriceId'], form);
  const serviceNameId = useWatch(['invoiceItems', formListItemKey, 'serviceNameId'], form);
  const orderServiceId = useWatch(['invoiceItems', formListItemKey, 'orderServiceId'], form);

  const {
    isIssuedInvoice,
    services,
    invoiceSupplyDateOrCurrent,
    payerContractId,
    invoiceCurrency
  } = useInvoiceStore(({ current, services }) => ({
    isIssuedInvoice: current?.type === InvoiceType.Issued,
    invoiceSupplyDateOrCurrent: getUTCDate(current?.supplyDate || undefined).format(
      SERVER_DATE_FORMAT
    ),
    payerContractId: current?.contractId,
    services,
    invoiceCurrency: current?.currency
  }));
  const currentInvoiceItem = useMemo(
    () => invoiceItems[formListItemKey],
    [invoiceItems, formListItemKey]
  );
  const isReissuedItem = useMemo(
    () => !isIssuedInvoice && Boolean(currentInvoiceItem?.linkId),
    [isIssuedInvoice, currentInvoiceItem]
  );

  useEffect(() => {
    setUnitOfMeasureShortName(currentInvoiceItem?.unitOfMeasure?.shortName || undefined);
  }, [currentInvoiceItem]);

  const getLabel = (label: string) => (formListItemKey === 0 ? label : '');

  const agentFeeOptionsFiltered = useMemo(() => {
    if (!currentInvoiceItem?.id) return agentFeeOptions;

    return agentFeeOptions.filter((option) =>
      isOwnPrice ? option.value === 'isOwnPrice' : option.value !== 'isOwnPrice'
    );
  }, [isOwnPrice, agentFeeOptions, currentInvoiceItem?.id]);

  return (
    <div>
      <Flex align="start">
        <Form.Item name={[formListItemKey, 'id']} hidden />

        <AgentFeeInput
          getLabel={getLabel}
          formListItemKey={formListItemKey}
          agentFeeOptions={agentFeeOptionsFiltered}
          setAgentFeeOptions={setAgentFeeOptions}
          disabled={isReissuedItem}
        />

        <div style={{ flexBasis: 190, overflow: 'hidden', flexGrow: 1 }}>
          <ServiceNameInput
            name={[formListItemKey, 'serviceNameId']}
            label={getLabel('Item')}
            required
            disabled={isReissuedItem}
            onChange={(id, unitOfMeasure) => {
              setUnitOfMeasureShortName(unitOfMeasure.shortName);
              const invoiceItems = [...form.getFieldValue('invoiceItems')];
              invoiceItems[formListItemKey] = {
                ...invoiceItems[formListItemKey],
                unitOfMeasureId: unitOfMeasure.id
              };

              form.setFieldsValue({
                invoiceItems
              });

              if (isOwnPrice && isIssuedInvoice) {
                form.validateFields([['invoiceItems', formListItemKey, 'price']]);
              }
            }}
          />
        </div>

        <Form.Item
          label={getLabel('Service')}
          name={[formListItemKey, 'orderServiceId']}
          style={{ flexBasis: 130, overflow: 'hidden', flexGrow: 1 }}>
          <Select
            size={CONTROL_SIZE}
            options={services?.map((service) => ({
              label: `${service.order.number} - ${service.service.name}`,
              value: service.id
            }))}
            placeholder="Select service"
            popupMatchSelectWidth={false}
            disabled={isReissuedItem}
          />
        </Form.Item>

        {!isIssuedInvoice && (
          <div style={{ flexBasis: 182, overflow: 'hidden' }}>
            <ServiceNameInput
              name={[formListItemKey, 'outNameId']}
              label={getLabel('Out name')}
              disabled={isReissuedItem}
            />
          </div>
        )}

        <Form.Item
          label={getLabel('Quantity')}
          name={[formListItemKey, 'quantity']}
          rules={[{ required: true, message: 'Is required' }]}
          style={{ flexBasis: 122, overflow: 'hidden' }}>
          <InputNumber
            type="number"
            size={CONTROL_SIZE}
            placeholder="0.0000"
            precision={4}
            formatter={(value) => {
              const coercedNumber = Number(value);
              return isNaN(coercedNumber) ? '' : coercedNumber.toString();
            }}
            disabled={isReissuedItem}
            style={{ width: '100%' }}
          />
        </Form.Item>

        {payerContractId && invoiceCurrency && (
          <PriceInput
            payerContractId={payerContractId}
            serviceNameId={serviceNameId}
            orderServiceId={orderServiceId}
            invoiceSupplyDate={invoiceSupplyDateOrCurrent}
            invoiceCurrency={invoiceCurrency}
            isIssuedInvoice={isIssuedInvoice}
            isOwnPrice={!!isOwnPrice}
            onChange={(price, ownPriceId) =>
              setOwnPriceProps(form, formListItemKey, price, ownPriceId)
            }
            label={getLabel('Price')}
            itemName={formListItemKey}
            ownPriceId={ownPriceId}
            disabled={isReissuedItem || (isIssuedInvoice && !isOwnPrice)}
          />
        )}

        <Form.Item
          label={getLabel('Amount')}
          name={[formListItemKey, 'amount']}
          rules={[{ required: !isIssuedInvoice, message: 'Is required' }]}
          style={{ flexBasis: 122, overflow: 'hidden' }}>
          <InputNumber
            type="number"
            size={CONTROL_SIZE}
            placeholder="0.00"
            style={{ width: '100%' }}
            precision={2}
            min={0}
            disabled={(isIssuedInvoice && (isOwnPrice ? !!ownPriceId : true)) || isReissuedItem}
          />
        </Form.Item>

        <div style={{ flexBasis: 84, overflow: 'hidden' }}>
          <VatRateInput
            name={[formListItemKey, 'vatRateId']}
            label={getLabel('VAT, %')}
            required
            disabled={isReissuedItem}
          />
        </div>

        {isIssuedInvoice && (
          <div style={{ flexBasis: 95, overflow: 'hidden' }}>
            <RebateInput
              name={[formListItemKey, 'rebateId']}
              label={getLabel('Rebate')}
              serviceNameId={serviceNameId}
              orderServiceId={orderServiceId}
            />
          </div>
        )}

        {isReissuedItem ? (
          <Tooltip title="Open linked item in a new tab">
            <Button
              icon={<ExportOutlined />}
              size={CONTROL_SIZE}
              type="link"
              className="icon-button"
              data-testid="navigation-btn"
              style={{ marginTop: formListItemKey === 0 ? '24px' : 0 }}
              disabled={!currentInvoiceItem?.linkedInvoiceId}
              onClick={() => {
                const url = new URL(
                  `${window.location.origin}/dashboard/settlement/invoice/${currentInvoiceItem?.linkedInvoiceId}`
                );
                url.searchParams.set('invoiceItemId', `${currentInvoiceItem?.linkId}`);

                window.open(url, '_blank');
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Delete the row">
            <Button
              icon={<DeleteOutlined />}
              size={CONTROL_SIZE}
              type="link"
              className="icon-button"
              data-testid="delete-row-btn"
              style={{ marginTop: formListItemKey === 0 ? '24px' : 0 }}
              onClick={() => {
                remove(formListItemKey);
              }}
            />
          </Tooltip>
        )}
      </Flex>

      <Flex
        gap={32}
        justify={unitOfMeasureShortName ? 'space-between' : 'end'}
        style={{ marginRight: 34 }}>
        {unitOfMeasureShortName && (
          <Flex style={{ marginLeft: isIssuedInvoice ? 70 : 22 }}>
            <span style={{ color: 'rgba(0, 0, 0, 0.45)', padding: '0 4px' }}>UOM</span>
            <span>{unitOfMeasureShortName}</span>
          </Flex>
        )}

        {(!!currentInvoiceItem?.amount ||
          !!currentInvoiceItem?.vatAmount ||
          !!currentInvoiceItem?.total) && (
          <Flex gap={32} justify="end" style={{ fontSize: 12 }}>
            {[
              {
                label: 'VAT amount',
                value: getAmountValue(currentInvoiceItem?.vatAmount) || '-'
              },
              {
                label: 'Total',
                value: getAmountValue(currentInvoiceItem?.total) || '-'
              }
            ].map((prop) => (
              <span key={prop.label}>
                <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{prop.label}</span> {prop.value}
              </span>
            ))}
          </Flex>
        )}
      </Flex>
    </div>
  );
};
