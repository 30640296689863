import React from 'react';
import { Flex, Result } from 'antd';
import { isAxiosError } from 'axios';

type IProps = {
  children: React.ReactElement;
  onError?: () => void;
};

type IState = {
  hasError: boolean;
  errorCode?: string;
};

export class SettlementOrdersWidgetErrorBoundary extends React.Component<IProps, IState> {
  static handledErrors = new Set();

  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false, errorCode: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    if (isAxiosError(error) && !SettlementOrdersWidgetErrorBoundary.handledErrors.has(error)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return { hasError: true, errorCode: error.response?.data?.correlationId };
    }
  }

  componentDidCatch(error: Error) {
    this.props.onError && this.props.onError();
    SettlementOrdersWidgetErrorBoundary.handledErrors.add(error);
  }

  render() {
    const { hasError, errorCode } = this.state;

    if (hasError) {
      return (
        <Flex
          align="center"
          justify="center"
          style={{
            backgroundColor: 'white',
            height: '100%',
            borderRadius: '6px'
          }}>
          <Result
            status="500"
            title="Application error"
            subTitle={
              <>
                <span>Error fetching settlement widget.</span>
                <br />
                <span>{errorCode?.replace('Root=', '')}</span>
              </>
            }
          />
        </Flex>
      );
    }

    return this.props.children;
  }
}
