import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { ServiceName } from 'services/api/data-contracts';
import { stringSorter } from '../utils/tableSorters';
import { DecoratedText } from 'pages/components/DecoratedText';

export const getServiceNameColumns = (): ColumnsType<ServiceName> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (value, record) => <DecoratedText isActive={record.isActive}>{value}</DecoratedText>,
    sorter: (a, b) => stringSorter(a.name, b.name),
    ellipsis: true
  },
  {
    title: 'Unit',
    dataIndex: 'unitOfMeasure',
    key: 'unitOfMeasure',
    render: (value, record) => (
      <DecoratedText isActive={record.isActive}>
        {record.unitOfMeasure?.shortName || '-'}
      </DecoratedText>
    ),
    ellipsis: true,
    width: 60
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    render: (value, record) => <DecoratedText isActive={record.isActive}>{value}</DecoratedText>,
    ellipsis: true
  }
];
