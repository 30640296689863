import { Contract, ContractType, InvoiceHydrated, InvoiceType } from 'services/api/data-contracts';
import { invoiceInitialData } from 'consts/invoice';
import { BalanceFilter } from 'store/balanceReportStore';

export const getInvoiceData = (data: {
  type: InvoiceType;
  filter?: BalanceFilter;
  contracts: Contract[];
}) => {
  const counterparty =
    data.type === InvoiceType.Received
      ? {
          supplierId: data.filter?.counterpartyId
        }
      : {
          payerId: data.filter?.counterpartyId
        };

  const contractsFormFilter = data.filter?.contractIds?.split(',');
  const autofillContract = data.contracts.find(
    (contract) =>
      contractsFormFilter?.includes(contract.id.toString()) &&
      contract.type ===
        (data.type === InvoiceType.Received ? ContractType.WithSupplier : ContractType.WithCustomer)
  );

  return {
    ...invoiceInitialData,
    ...data,
    ...counterparty,
    contractId: autofillContract?.id
  } as unknown as InvoiceHydrated;
};
