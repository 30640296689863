import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { invoicesApi } from 'services/api';
import { ENTITY_NAME, QUERY_KEY } from './consts';
import { useInvoiceStore } from 'store/invoiceStore';
import { MESSAGE_DURATION } from 'consts/common';
import { SETTLEMENT_WIDGET_QUERY_KEY } from '../settlement/consts';
import { BALANCE_WIDGET_QUERY_KEY } from '../balance/consts';

const createInvoiceItem = async (
  data: [
    invoiceId: number,
    data: {
      orderServiceIds?: number[];
    }
  ]
) => {
  return await invoicesApi.addOrderServicesToInvoice(...data);
};

export const useAddServicesToInvoice = (onSuccess?: () => void) => {
  const { setCurrent } = useInvoiceStore(({ setCurrent }) => ({ setCurrent }));

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createInvoiceItem,
    onSuccess: async (response) => {
      if (response.data) {
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

        setCurrent(response.data);

        message.success(
          `${ENTITY_NAME} "${response.data.invoiceNumber}" has been successfully updated`,
          MESSAGE_DURATION
        );
        onSuccess && onSuccess();

        // due to performance issues with settlement widget moved after onSuccess callback
        await queryClient.refetchQueries([SETTLEMENT_WIDGET_QUERY_KEY]);
        await queryClient.refetchQueries([BALANCE_WIDGET_QUERY_KEY]);
      }
    }
  });
};
