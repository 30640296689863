import { useQuery } from '@tanstack/react-query';
import { rebatesApi } from 'services/api';
import { REBATE_REPORT_QUERY_KEY } from './consts';
import { SERVER_DATE_FORMAT } from 'consts/common';
import { Dayjs } from 'dayjs';
import { RebateReportFilter, useRebateReportStore } from 'store/rebateReportStore';

const fetchList = async (filter: RebateReportFilter) => {
  const date = filter.date;
  const startDate = date ? (date[0] as unknown as Dayjs)?.format(SERVER_DATE_FORMAT) : undefined;
  const endDate = date ? (date[1] as unknown as Dayjs)?.format(SERVER_DATE_FORMAT) : undefined;

  const filters = {
    startDate: startDate as string,
    endDate: endDate as string,
    customerIds: filter?.customerIds ? filter?.customerIds.join(',') : undefined,
    serviceNameIds: filter?.serviceNameIds ? filter?.serviceNameIds.join(',') : undefined,
    currencies: filter?.currencies ? filter?.currencies.join(',') : undefined
  };

  if (filters.startDate && filters.endDate) {
    return await rebatesApi.getRebatesReport(filters);
  }
};

export const useGetRebateReport = () => {
  const { filter, setData } = useRebateReportStore();

  return useQuery({
    queryKey: [REBATE_REPORT_QUERY_KEY, filter],
    queryFn: () => fetchList(filter),
    onSuccess: async (response) => {
      setData(response?.data);
    },
    enabled: !!filter?.date
  });
};
