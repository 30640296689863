import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { Button, Flex, Tooltip } from 'antd';
import { Invoice, PaymentPurpose } from 'services/api/data-contracts';
import { getAmountValue, getUTCDate } from 'helpers';

export type PaymentPurposeWithContract = PaymentPurpose & {
  invoice?: Invoice & {
    contract?: {
      id?: number;
      number?: string;
    };
  };
};

export const getColumns = (
  onDelete: (paymentPurpose: PaymentPurposeWithContract) => void,
  onOpen: (paymentPurpose: PaymentPurposeWithContract) => void
): ColumnsType<PaymentPurposeWithContract> => [
  {
    title: 'Contract',
    dataIndex: 'contract',
    key: 'contract',
    render: (_, record) => record.invoice?.contract?.number || '-',
    width: 80
  },
  {
    title: 'Invoice',
    dataIndex: 'invoice',
    key: 'invoice',
    render: (_, record) => (
      <div
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}>
        {[
          record.invoice?.invoiceNumber,
          record.invoice?.isPosted ? 'Posted' : 'Not posted',
          getUTCDate(record.invoice?.createdAt).format(DATE_FORMAT)
        ].join(' | ')}
      </div>
    ),
    width: 220
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    render: (_, record) => record.invoice?.currency,
    width: 80,
    ellipsis: true
  },
  {
    title: 'Paid amount',
    dataIndex: 'paidAmountInInvoiceCurrency',
    key: 'paidAmountInInvoiceCurrency',
    render: (value) => (
      <Tooltip mouseEnterDelay={1} title={getAmountValue(value)}>
        <div
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}>
          {getAmountValue(value)}
        </div>
      </Tooltip>
    ),
    align: 'end'
  },
  {
    title: 'In payment currency',
    dataIndex: 'paidAmount',
    key: 'paidAmount',
    render: (value, record) => (
      <Flex justify="flex-end" style={{ overflow: 'hidden' }}>
        <Tooltip mouseEnterDelay={1} title={getAmountValue(value)}>
          <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {getAmountValue(value)}
          </div>
        </Tooltip>
        <div id="actions">
          <Tooltip mouseEnterDelay={1} title="Unlink the invoice">
            <Button
              size={CONTROL_SIZE}
              shape="circle"
              icon={<DeleteOutlined />}
              data-testid="delete-btn"
              onClick={() => onDelete(record)}
            />
          </Tooltip>
          <Tooltip mouseEnterDelay={1} title="Edit linked">
            <Button
              size={CONTROL_SIZE}
              type="primary"
              shape="circle"
              data-testid="edit-btn"
              icon={<EditFilled />}
              onClick={() => onOpen(record)}
            />
          </Tooltip>
        </div>
      </Flex>
    ),
    align: 'end'
  }
];
