import { KG_PRECISION, LTR_PRECISION, MT_PRECISION, USG_PRECISION } from 'consts/order';

export const TON_COEFFICIENT = 1000;
export const GAL_COEFFICIENT = 3.78541;

export const convertKgToGallons = (kg: number, density: number) =>
  +(kg / (density * GAL_COEFFICIENT)).toFixed(USG_PRECISION);

export const convertKgToLitres = (kg: number, density: number) =>
  +(kg / density).toFixed(LTR_PRECISION);

export const convertKgToTonnes = (kg: number) => +(kg / TON_COEFFICIENT).toFixed(MT_PRECISION);

export const convertGallonsToKg = (gal: number, density: number) =>
  +(gal * GAL_COEFFICIENT * density).toFixed(KG_PRECISION);

export const convertLitresToKg = (litres: number, density: number) =>
  +(litres * density).toFixed(KG_PRECISION);

export const convertTonnesToKg = (tonnes: number) =>
  +(tonnes * TON_COEFFICIENT).toFixed(KG_PRECISION);
