import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, ConfigProvider, Empty, Flex, List, Tooltip } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import VirtualList from 'rc-virtual-list';
import { StyledList } from '../DispatcherOrdersWidget/DispatcherOrdersWidget';
import { RowState, useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { debounce } from 'lodash';
import { Footer } from '../components/Footer';
import { SettlementOrdersListItem } from './SettlementOrderListItem';
import { useGetSettlementWidget } from 'queries/settlement';
import { blue } from '@ant-design/colors';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { LinkedEntityTypes, OrderService } from 'services/api/data-contracts';
import { OrderServicesBulkOperationsPopover } from '../components/ServicesBulkOperationsPopover/OrderServicesBulkOperationsPopover';
import { useInvoiceStore } from 'store/invoiceStore';
import { useOrderStore } from 'store/orderStore';
import { DASHBOARD_TOP_PADDING, SCROLL_DISCREPANCY_THRESHOLD } from '../../consts';
import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import { HeaderWithRef } from '../components/Header';
import { InvoiceDrawer } from '../InvoicesWidget/components/InvoiceDetails/InvoiceDrawer';
import { OrderServiceDrawer } from '../components/OrderServiceDrawer';
import { usePaymentPurposeStore } from 'store/paymentPurposeStore';
import styled from 'styled-components';
import { ServicesDrawer } from '../components/ServicesDrawer/ServicesDrawer';
import { CalculatorDrawer } from '../components/CalculatorDrawer/CalculatorDrawer';
import { CreditNoteDrawer } from '../components/CreditNotesDrawer/CreditNoteDrawer';
import { PaymentPurposeDrawer } from '../components/PaymentPurposeDrawer/PaymentPurposeDrawer';
import { useInvoiceDrawerRouting, useOrderDrawerRouting, usePaymentDrawerRouting } from 'routing';
import { PaymentDrawer } from '../components/PaymentDrawer/PaymentDrawer';
import { useDrawerStore } from 'store/drawerStore';
import { OrderDrawer } from '../components/OrderDrawer/OrderDrawer';
import { DocumentsDrawer } from '../components/DocumentsDrawer/DocumentsDrawer';

const ListWrapper = styled.div`
  position: relative;
  overflow: hidden;

  .ant-drawer-mask {
    border-radius: 8px;
  }

  .ant-drawer-right {
    overflow-x: clip;
  }

  .ant-drawer-content {
    border-radius: 0 8px 8px 0;

    &.invoice-drawer,
    &.documents-drawer {
      border-radius: 8px;
      border: 1px solid #d9d9d9;
    }
  }
`;

interface IProps {
  onSetFullScreenMode: React.Dispatch<React.SetStateAction<boolean>>;
  isFullScreenMode: boolean;
}

export const SettlementOrdersWidget = ({ onSetFullScreenMode, isFullScreenMode }: IProps) => {
  const [containerHeight, setContainerHeight] = useState(
    window.innerHeight - DASHBOARD_TOP_PADDING
  );
  const [expandedOrderId, setExpandedOrderId] = useState<string | undefined>(undefined);
  const [isAddServiceDrawerOpen, setAddServiceDrawerOpen] = useState(false);

  const {
    list,
    total,
    selectedOrders,
    selectedServicesIds,
    setOrderRowsState,
    orderRowsState,
    page,
    setPage
  } = useSettlementDashboardStore(
    ({
      list,
      total,
      selectedOrders,
      selectedServicesIds,
      setOrderRowsState,
      orderRowsState,
      page,
      setPage
    }) => ({
      list,
      total,
      selectedOrders,
      selectedServicesIds,
      setOrderRowsState,
      orderRowsState,
      page,
      setPage
    })
  );
  const { setCurrentCreditNote } = useInvoiceStore(({ setCurrentCreditNote }) => ({
    setCurrentCreditNote
  }));
  const {
    setDrawerClosed,
    setDrawerOpen,
    isCreditNoteDrawerOpen,
    isPaymentDrawerOpen,
    isInvoiceDrawerOpen,
    isPaymentPurposeDrawerOpen,
    isCalculatorDrawerOpen,
    isServiceDrawerOpen,
    isOrderDrawerOpen,
    isDocumentsDrawerOpen
  } = useDrawerStore(({ setDrawerClosed, setDrawerOpen, openDrawers }) => ({
    setDrawerClosed,
    setDrawerOpen,
    isCreditNoteDrawerOpen: openDrawers.includes('creditNote'),
    isPaymentDrawerOpen: openDrawers.includes('payment'),
    isInvoiceDrawerOpen: openDrawers.includes('invoice'),
    isPaymentPurposeDrawerOpen: openDrawers.includes('paymentPurpose'),
    isCalculatorDrawerOpen: openDrawers.includes('calculator'),
    isServiceDrawerOpen: openDrawers.includes('orderService'),
    isOrderDrawerOpen: openDrawers.includes('order'),
    isDocumentsDrawerOpen: openDrawers.includes('documents')
  }));
  const { setSelectedServices, service, setService } = useOrderStore(
    ({ setSelectedServices, service, setService }) => ({
      setSelectedServices,
      service,
      setService
    })
  );
  const { setCurrent: setCurrentPaymentPurpose, setEditModeBase } = usePaymentPurposeStore(
    ({ setCurrent, setEditModeBase }) => ({
      setCurrent,
      setEditModeBase
    })
  );

  useOrderDrawerRouting();
  useInvoiceDrawerRouting();
  usePaymentDrawerRouting();

  useEffect(() => () => setSelectedServices([]), []);

  const { isFetching } = useGetSettlementWidget();

  const headerRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);

  const setListHeight = () => {
    const headerHeight = headerRef.current?.clientHeight ? headerRef.current?.clientHeight + 17 : 2;
    setContainerHeight(window.innerHeight - DASHBOARD_TOP_PADDING - headerHeight);
  };

  useEffect(() => {
    const resize = debounce(setListHeight, 100);
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  useEffect(() => {
    setListHeight();
  }, [isFetching, headerRef.current?.clientHeight]);

  const handleScroll = ({
    currentTarget: { scrollHeight, scrollTop }
  }: React.UIEvent<HTMLElement, UIEvent>) => {
    const isOnBottom =
      Math.abs(scrollHeight - scrollTop - containerHeight) < SCROLL_DISCREPANCY_THRESHOLD;
    const allDataLoaded = list.length >= total;

    if (isOnBottom && !allDataLoaded && !isFetching) {
      setPage(page + 1);
    }
  };

  const handleSelectAll = ({ target: { checked } }: CheckboxChangeEvent) => {
    setOrderRowsState(
      orderRowsState.map((row) => ({
        ...row,
        state: checked ? RowState.SELECTED : RowState.EMPTY,
        services: row.services.map((service) => ({
          ...service,
          state: checked ? RowState.SELECTED : RowState.EMPTY
        }))
      }))
    );

    const selectedServices: number[] = [];

    if (checked) {
      orderRowsState.forEach((row) => selectedServices.push(...row.services.map(({ id }) => id)));
    }

    setSelectedServices(selectedServices);
  };

  const handleOpenServiceDetails = (service: OrderService) => {
    setService(service);
    setDrawerOpen('orderService');
  };

  const handleCloseCreditNote = () => {
    setDrawerClosed('creditNote');
    setCurrentCreditNote(undefined);
  };

  const handleClosePaymentPurpose = () => {
    setDrawerClosed('paymentPurpose');
    setCurrentPaymentPurpose(undefined);
    setEditModeBase(undefined);
  };

  const handleCloseDocuments = () => {
    setDrawerClosed('documents');
  };

  useEffect(() => {
    service && setDrawerOpen('orderService');
  }, [service]);

  const header = total > 0 && (
    <HeaderWithRef
      ref={headerRef}
      total={total}
      slot={
        <Flex align="center" justify="flex-end" style={{ marginTop: '8px' }}>
          <Tooltip
            mouseEnterDelay={1}
            title={`${isFullScreenMode ? 'Close' : 'Open'} full screen mode`}>
            <Button
              size={CONTROL_SIZE}
              icon={
                isFullScreenMode ? (
                  <ShrinkOutlined style={{ transform: 'rotate(45deg)' }} />
                ) : (
                  <ArrowsAltOutlined style={{ transform: 'rotate(45deg)' }} />
                )
              }
              onClick={() => onSetFullScreenMode((prevState) => !prevState)}
            />
          </Tooltip>
        </Flex>
      }
    />
  );

  const footer = (
    <Footer
      showSelected
      showClearSelection={selectedServicesIds.length > 0}
      selectedItemsCount={selectedOrders.length}
      totalItemsCount={total}
      onSelectAll={handleSelectAll}
      widgetType={LinkedEntityTypes.Order}
    />
  );

  const listComponent = useMemo(() => {
    if (!list.length) {
      return (
        <ConfigProvider
          renderEmpty={() => (
            <Empty description="Orders not found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}>
          <StyledList
            dataSource={list}
            size={CONTROL_SIZE}
            header={header}
            footer={footer}
            bordered
            loading={isFetching}
            data-testid="list"
            style={{ height: containerHeight + 41 }}
          />
        </ConfigProvider>
      );
    }

    return (
      <StyledList
        size={CONTROL_SIZE}
        bordered
        header={header}
        footer={footer}
        loading={!list.length || isFetching}>
        <VirtualList
          data={list}
          height={containerHeight}
          itemHeight={82}
          itemKey="number"
          onScroll={handleScroll}>
          {(order) => (
            <List.Item
              key={order.number}
              style={{
                backgroundColor: selectedOrders.find((number) => number === order.number)
                  ? blue[0]
                  : 'inherit',
                padding: 0
              }}>
              <SettlementOrdersListItem
                order={order}
                isExpanded={expandedOrderId === order.number}
                onExpand={setExpandedOrderId}
                onOpenService={handleOpenServiceDetails}
                isFullScreenMode={isFullScreenMode}
              />
            </List.Item>
          )}
        </VirtualList>
      </StyledList>
    );
  }, [list, total, isFetching, containerHeight, selectedOrders, expandedOrderId, isFullScreenMode]);

  return (
    <>
      {!isInvoiceDrawerOpen && (
        <OrderServicesBulkOperationsPopover
          placement="topLeft"
          align={{
            offset: [
              isFullScreenMode && listRef.current ? listRef.current?.clientWidth : -364,
              isFullScreenMode && listRef.current ? listRef.current?.clientHeight - 39 : 0
            ]
          }}
        />
      )}

      <ListWrapper ref={listRef}>
        {listComponent}

        {isInvoiceDrawerOpen && (
          <InvoiceDrawer
            isFullScreenMode={isFullScreenMode}
            setAddServiceDrawerOpen={setAddServiceDrawerOpen}
          />
        )}

        {isPaymentDrawerOpen && <PaymentDrawer isFullScreenMode={isFullScreenMode} />}

        {isOrderDrawerOpen && <OrderDrawer isFullScreenMode={isFullScreenMode} />}

        {isServiceDrawerOpen && <OrderServiceDrawer isFullScreenMode={isFullScreenMode} />}

        {isAddServiceDrawerOpen && (
          <ServicesDrawer
            isOpen={isAddServiceDrawerOpen}
            setOpen={setAddServiceDrawerOpen}
            isFullScreenMode={isFullScreenMode}
          />
        )}

        {isCalculatorDrawerOpen && <CalculatorDrawer isFullScreenMode={isFullScreenMode} />}

        {isCreditNoteDrawerOpen && (
          <CreditNoteDrawer
            onClose={handleCloseCreditNote}
            isOpen={isCreditNoteDrawerOpen}
            isFullScreenMode={isFullScreenMode}
          />
        )}

        {isPaymentPurposeDrawerOpen && (
          <PaymentPurposeDrawer
            onClose={handleClosePaymentPurpose}
            isOpen={isPaymentPurposeDrawerOpen}
            isFullScreenMode={isFullScreenMode}
          />
        )}

        {isDocumentsDrawerOpen && (
          <DocumentsDrawer
            onClose={handleCloseDocuments}
            isOpen={isDocumentsDrawerOpen}
            isFullScreenMode={isFullScreenMode}
          />
        )}
      </ListWrapper>
    </>
  );
};
