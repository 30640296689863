import React, { useEffect, useState } from 'react';
import { Form, InputNumber, Button, Space, Flex, Tooltip } from 'antd';
import { PaymentsInput } from './PaymentsInput';
import { CONTROL_SIZE } from 'consts/common';
import { useInvoiceStore } from 'store/invoiceStore';
import {
  SettlementEntityType,
  useCreatePaymentPurpose
} from 'queries/paymentPurpose/useCreatePaymentPurpose';
import { PaymentHydrated, PaymentStatus } from 'services/api/data-contracts';
import { transformForRequest } from './helpers/transformForRequest';
import { RuleObject } from 'antd/es/form';
import { usePaymentPurposeStore } from 'store/paymentPurposeStore';
import { getAmountValue } from 'helpers';
import { InfoCircleOutlined } from '@ant-design/icons';
import { PaymentCreationMode, usePaymentStore } from 'store/paymentStore';

export const AddPaymentForm = () => {
  const [selectedPayment, setSelectedPayment] = useState<undefined | PaymentHydrated>();
  const [formInitialData, setFormInitialData] = useState({});

  const {
    current: payment,
    paymentCreationMode,
    setPaymentCreationMode
  } = usePaymentStore(({ current, paymentCreationMode, setPaymentCreationMode }) => ({
    current,
    paymentCreationMode,
    setPaymentCreationMode
  }));

  useEffect(() => {
    if (
      paymentCreationMode === PaymentCreationMode.FROM_INVOICE &&
      payment &&
      payment.status === PaymentStatus.DONE
    ) {
      form.setFieldValue('payment', payment.id);
      setSelectedPayment(payment as PaymentHydrated);
    }
  }, [paymentCreationMode, payment]);

  useEffect(() => {
    if (selectedPayment) {
      form.setFieldsValue({
        ...(selectedPayment.currency !== invoice?.currency && {
          paidAmountInInvoiceCurrency: invoice?.amountDue
        }),
        ...(selectedPayment.currency === invoice?.currency && {
          paidAmount: selectedPayment?.advance
        })
      });
    } else setFormInitialData({});
  }, [selectedPayment]);

  const { current: invoice } = useInvoiceStore(({ current }) => ({
    current
  }));

  const { setCurrent, setShowRevertActionPopUp, setCreationMode } = usePaymentPurposeStore(
    ({ setCurrent, setShowRevertActionPopUp, setCreationMode }) => ({
      setCurrent,
      setShowRevertActionPopUp,
      setCreationMode
    })
  );

  const [form] = Form.useForm();

  const paymentPurposeCreateMutation = useCreatePaymentPurpose((response) => {
    setCurrent(response);
    setShowRevertActionPopUp('link');
  }, SettlementEntityType.INVOICE);

  const handleSelectChange = (_: number, payment: PaymentHydrated) => {
    form.resetFields(['paidAmount', 'paidAmountInInvoiceCurrency']);
    if (payment) {
      form.setFieldsValue({
        ...(payment.currency !== invoice?.currency && {
          paidAmountInInvoiceCurrency: invoice?.amountDue
        }),
        ...(payment.currency === invoice?.currency && {
          paidAmount: payment?.advance
        })
      });
    }

    setSelectedPayment(payment);
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      if (selectedPayment?.id && invoice?.id) {
        const data = transformForRequest(selectedPayment.id, invoice.id, form.getFieldsValue());
        paymentPurposeCreateMutation.mutate(data);
        setCreationMode(false);
        setPaymentCreationMode(undefined);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isDifferentCurrency = selectedPayment && selectedPayment?.currency !== invoice?.currency;

  const validatePaidAmount = (_: RuleObject, value: number) => {
    if (selectedPayment && value > selectedPayment.advance) {
      return Promise.reject(`Limit is (${getAmountValue(selectedPayment.advance)})`);
    }

    if (invoice && !isDifferentCurrency && invoice.amountDue && value > invoice.amountDue) {
      return Promise.reject(`Limit is (${getAmountValue(invoice.amountDue)})`);
    }

    return Promise.resolve();
  };

  const validateAmountInInvoiceCurrency = (_: RuleObject, value: number) => {
    if (invoice && invoice.amountDue && value > invoice.amountDue) {
      return Promise.reject(`Limit is (${getAmountValue(invoice.amountDue)})`);
    }

    return Promise.resolve();
  };

  return (
    <Form
      form={form}
      name="paymentPurpose"
      layout="vertical"
      autoComplete="off"
      colon={false}
      validateTrigger="onBlur"
      requiredMark={false}
      initialValues={formInitialData}
      data-testid="add-payment-purpose-form"
      className="styled-form">
      <Flex align="flex-start" gap={8}>
        <div style={{ flexBasis: 190, overflow: 'hidden', flexGrow: 1 }}>
          <PaymentsInput
            name="payment"
            label="Payment"
            placeholder="Select or start typing to search by id, date or counterparty"
            onChange={handleSelectChange}
            invoiceId={invoice?.id}
            initialFilter={
              paymentCreationMode === PaymentCreationMode.FROM_INVOICE &&
              payment?.status === PaymentStatus.DONE
                ? payment.id?.toString()
                : undefined
            }
          />
        </div>

        <Form.Item
          name="paidAmount"
          label={
            <>
              <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                Paid Amount
              </span>
              <Tooltip
                mouseEnterDelay={1}
                title={
                  isDifferentCurrency
                    ? 'Can be equal or less than payment advance'
                    : 'Can be equal or less than payment advance or invoice amount due'
                }>
                <InfoCircleOutlined style={{ marginLeft: '6px', cursor: 'pointer' }} />
              </Tooltip>
            </>
          }
          rules={[
            {
              required: true,
              message: 'Is required'
            },
            {
              validator: validatePaidAmount
            }
          ]}
          style={{ width: 140 }}>
          <InputNumber
            type="number"
            addonAfter={isDifferentCurrency ? selectedPayment.currency : false}
            size={CONTROL_SIZE}
            min={0}
            style={{ width: '100%' }}
            precision={2}
          />
        </Form.Item>

        {isDifferentCurrency && (
          <Form.Item
            name="paidAmountInInvoiceCurrency"
            data-testid="paid-amount-in-invoice-currency"
            label={
              <>
                <span
                  style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                  In invoice currency
                </span>
                <Tooltip mouseEnterDelay={1} title="Can be equal or less than invoice amount due">
                  <InfoCircleOutlined style={{ marginLeft: '6px', cursor: 'pointer' }} />
                </Tooltip>
              </>
            }
            rules={[
              { required: true, message: 'Is required' },
              {
                validator: validateAmountInInvoiceCurrency
              }
            ]}
            style={{ width: 140 }}>
            <InputNumber
              type="number"
              addonAfter={invoice?.currency ?? ' - '}
              min={0}
              size={CONTROL_SIZE}
              style={{ width: '100%' }}
              precision={2}
            />
          </Form.Item>
        )}

        <Space style={{ marginTop: '26px' }}>
          <Button
            disabled={invoice?.amountDue === 0 || !selectedPayment?.id || !invoice?.id}
            type="primary"
            size={CONTROL_SIZE}
            onClick={handleSubmit}>
            Add
          </Button>

          <Button
            size={CONTROL_SIZE}
            onClick={() => {
              setCreationMode(false);
              setPaymentCreationMode(undefined);
            }}>
            Cancel
          </Button>
        </Space>
      </Flex>
    </Form>
  );
};
