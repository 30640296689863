import React from 'react';
import { ColumnsType } from 'antd/es/table';
import {
  ContractPaymentType,
  LinksResponse,
  Payment,
  SettlementInvoice,
  TransactionType
} from 'services/api/data-contracts';
import { Button, Table, Tooltip } from 'antd';
import { getAmountValue, getUTCDate } from 'helpers';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { DocumentStatusTag } from '../../components/DocumentStatusTag/DocumentStatusTag';
import { getDocumentStatusTag } from 'helpers/dataTransformations/getDocumentStatusTag';
import { LinkOutlined, PercentageOutlined } from '@ant-design/icons';
import { geekblue, magenta } from '@ant-design/colors';
import { ArrowIcon } from '../../InvoicesWidget/components/ArrowIcon/ArrowIcon';
import { GoUnlink } from 'react-icons/go';
import { LINK_STATUS } from './helpers/constants';
import { getLinkActionStatus } from './helpers/getLinkActionStatus';
import { MessageTag } from 'store/messageStore';

export const isInvoice = (
  entity:
    | SettlementInvoice
    | (Payment & {
        totalByOrderService: number;
      })
) => entity.hasOwnProperty('invoiceNumber');

export const getColumns = (
  onOpenRecord: (id: number, type: 'invoice' | 'payment') => void,
  onLinkInvoice: (
    entity:
      | SettlementInvoice
      | (Payment & {
          totalByOrderService: number;
        }),
    status: string
  ) => void,
  setCurrentEntity: (item: { id: string; isCreditNote: boolean } | null) => void,
  links: { data?: LinksResponse; isFetching: boolean },
  selectedMessages: MessageTag[]
): ColumnsType<
  | SettlementInvoice
  | (Payment & {
      totalByOrderService: number;
    })
> => [
  Table.EXPAND_COLUMN,
  {
    title: '',
    dataIndex: 'type',
    key: 'type',
    width: 40,
    render: (_, record) => {
      const isInvoice = 'invoiceNumber' in record;
      const isRebate = !isInvoice && record.transactionType === TransactionType.Rebate;

      const tooltip = isInvoice
        ? `Open ${record.type.toLowerCase()} invoice`
        : `Open ${isRebate ? 'rebate' : 'credit note'}`;
      const icon = isInvoice ? (
        <ArrowIcon type={record.type} hideTooltip />
      ) : (
        <PercentageOutlined
          style={{
            color: record.type === ContractPaymentType.Incoming ? magenta[6] : geekblue[3]
          }}
        />
      );
      const entityType = isInvoice ? 'invoice' : 'payment';

      return (
        <Tooltip mouseEnterDelay={1} title={tooltip}>
          <Button
            type="link"
            size={CONTROL_SIZE}
            data-testid="open-invoice-btn"
            icon={icon}
            onClick={() => onOpenRecord(record.id, entityType)}
          />
        </Tooltip>
      );
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => (
      <DocumentStatusTag
        status={getDocumentStatusTag(
          (record as Payment).posted || (record as SettlementInvoice).isPosted,
          'invoiceNumber' in record
            ? (record as SettlementInvoice).status
            : (record as Payment).creditNoteStatus
        )}
      />
    )
  },
  {
    title: '№',
    dataIndex: 'number',
    key: 'number',
    render: (_, record) =>
      'invoiceNumber' in record ? record.invoiceNumber : record.creditNoteNumber
  },
  {
    title: 'Date',
    dataIndex: 'invoiceDate',
    key: 'invoiceDate',
    render: (value) => getUTCDate(value).format(DATE_FORMAT)
  },
  {
    title: 'Cur',
    dataIndex: 'currency',
    key: 'currency'
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: (_, record) => getAmountValue('total' in record ? record.total : record.amount),
    align: 'end'
  },
  {
    title: '',
    dataIndex: 'totalByOrderService',
    key: 'totalByOrderService',
    render: (_, record) => (
      <span style={{ color: '#00000073' }}>{getAmountValue(record.totalByOrderService)}</span>
    ),
    align: 'end'
  },
  {
    title: 'Agent fee',
    dataIndex: 'agentFeeAmount',
    key: 'agentFeeAmount',
    render: (_, record) =>
      'agentFeeAmount' in record ? getAmountValue(record.agentFeeAmount) : null,
    align: 'end'
  },
  {
    title: '',
    dataIndex: 'agentFeeAmountByOrderService',
    key: 'agentFeeAmountByOrderService',
    render: (_, record) => {
      const isCreditNote = (record as Payment)?.transactionType === TransactionType.CreditNote;
      // const isRebate = (record as Payment)?.transactionType === TransactionType.Rebate;
      const linkActionStatus = getLinkActionStatus(links, selectedMessages, isCreditNote);

      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden'
          }}>
          {'agentFeeAmountByOrderService' in record && (
            <span style={{ color: '#00000073' }}>
              {getAmountValue(record.agentFeeAmountByOrderService)}
            </span>
          )}
          {((record as Payment).transactionType === TransactionType.CreditNote ||
            isInvoice(record)) && (
            <div id="actions">
              <Tooltip title={linkActionStatus} placement="left" key="Link">
                <Button
                  size={CONTROL_SIZE}
                  shape="circle"
                  data-testid="link-btn"
                  icon={
                    // linkActionStatus === LINK_STATUS.LINK_REBATE
                    linkActionStatus === LINK_STATUS.LINK_INVOICE ||
                    linkActionStatus === LINK_STATUS.LINK_CREDIT_NOTE ? (
                      <LinkOutlined style={{ marginTop: 4 }} />
                    ) : (
                      <GoUnlink style={{ marginTop: 4 }} />
                    )
                  }
                  onClick={() => onLinkInvoice(record, linkActionStatus)}
                  onMouseEnter={() => setCurrentEntity({ id: record.id.toString(), isCreditNote })}
                  onMouseLeave={() => setCurrentEntity(null)}
                  loading={links.isFetching}
                />
              </Tooltip>
            </div>
          )}
        </div>
      );
    },
    align: 'end'
  }
];
