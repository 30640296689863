import { ColumnsType } from 'antd/es/table';
import { RebateHydrated } from 'services/api/data-contracts';
import { stringSorter } from '../utils/tableSorters';
import { getAmountValue, getUTCDate } from 'helpers';
import React from 'react';
import dayjs from 'dayjs';
import { getRebateSign, isAgentFeeRebate } from 'store/rebateStore';
import { DecoratedText } from 'pages/components/DecoratedText';

export const getRebateColumns = (): ColumnsType<RebateHydrated> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (value, record) => (
      <DecoratedText isActive={record.isActive && getUTCDate(record.endDate).isAfter(dayjs())}>
        {value}
      </DecoratedText>
    ),
    sorter: (a, b) => stringSorter(a.name, b.name),
    ellipsis: true
  },
  {
    title: 'Service name',
    dataIndex: 'service',
    key: 'service',
    render: (_, record) => (
      <DecoratedText isActive={record.isActive && getUTCDate(record.endDate).isAfter(dayjs())}>
        {record.serviceName.name || '-'}
      </DecoratedText>
    ),
    sorter: (a, b) => stringSorter(a.serviceName.name, b.serviceName.name),
    ellipsis: true
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
    render: (_, record) => (
      <DecoratedText isActive={record.isActive && getUTCDate(record.endDate).isAfter(dayjs())}>
        {record.customer.name || '-'}
      </DecoratedText>
    ),
    sorter: (a, b) => stringSorter(a.customer.name, b.customer.name),
    ellipsis: true
  },
  {
    title: '',
    dataIndex: 'currency',
    key: 'currency',
    width: 50,
    render: (_, record) => (
      <DecoratedText isActive={record.isActive && getUTCDate(record.endDate).isAfter(dayjs())}>
        {record.currency || '%'}
      </DecoratedText>
    )
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    render: (value, record) => {
      const amount = getRebateSign(record.type) + getAmountValue(value) || '-';

      return (
        <DecoratedText isActive={record.isActive && getUTCDate(record.endDate).isAfter(dayjs())}>
          {amount}
        </DecoratedText>
      );
    },
    align: 'end'
  },
  {
    title: '',
    dataIndex: 'uom',
    key: 'uom',
    width: 50,
    render: (_, record) =>
      record.unitOfMeasure ? (
        <DecoratedText isActive={record.isActive && getUTCDate(record.endDate).isAfter(dayjs())}>
          {isAgentFeeRebate(record.type) ? '%' : record.unitOfMeasure.shortName || ''}
        </DecoratedText>
      ) : (
        '-'
      ),
    ellipsis: true
  }
];
