import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { BalanceViewItems, BalanceViewResponse } from 'services/api/data-contracts';
import { RangeValue } from 'store/helpers/types';

export interface BalanceFilter {
  currency?: string;
  contractDate?: RangeValue;
  counterpartyId?: number;
  contractIds?: string;
}

export enum InvoiceStatus {
  Paid = 'Paid',
  PartlyPaid = 'Partially paid',
  Unpaid = 'Unpaid',
  Expired = 'Expired'
}

export enum PaymentStatus {
  Linked = 'Linked',
  PartiallyLinked = 'Partially linked',
  NotLinked = 'Not linked'
}

export interface BalanceUiFilter {
  invoiceStatus?: InvoiceStatus[];
  paymentStatus?: PaymentStatus[];
}

type BalanceReportStore = {
  data?: BalanceViewResponse;
  setData: (data?: BalanceViewResponse) => void;
  filter: BalanceFilter;
  setFilter: (filter: BalanceFilter) => void;
  uiFilter: BalanceUiFilter;
  setUiFilter: (uiFilter: BalanceUiFilter) => void;
  selectedEntities: BalanceViewItems;
  setSelectedEntities: (selectedEntities: BalanceViewItems) => void;
  isContractViewMode: boolean;
  setContractViewMode: (isContractViewMode: boolean) => void;
  shouldUpdateBalanceView: boolean;
  setShouldUpdateBalanceView: (shouldUpdateBalanceView: boolean) => void;
};

export const useBalanceReportStore = create<BalanceReportStore>()(
  devtools((set) => ({
    data: undefined,
    setData: (data) => {
      set({ data });
    },
    filter: {},
    setFilter: (filter) => {
      set({ filter });
    },
    uiFilter: {},
    setUiFilter: (uiFilter) => {
      set({ uiFilter });
    },
    selectedEntities: [],
    setSelectedEntities: (selectedEntities) => {
      set({ selectedEntities });
    },
    isContractViewMode: false,
    setContractViewMode: (isContractViewMode) => {
      set({ isContractViewMode });
    },
    shouldUpdateBalanceView: false,
    setShouldUpdateBalanceView: (shouldBalanceWidgetUpdate) => {
      set({ shouldUpdateBalanceView: shouldBalanceWidgetUpdate });
    }
  }))
);
