export const NOTIFICATION = {
  NO_MESSAGES_SELECTED: 'Please select some messages to link',
  SOME_LINKS_EXIST: 'All selected messages have to be either linked or unlinked to invoice'
};

export const LINK_STATUS = {
  SELECT_MESSAGE: 'Select message to link',
  LOADING: 'Loading existing links...',
  LINK_INVOICE: 'Link invoice to selected',
  UNLINK_INVOICE: 'Unlink invoice from selected',
  INVALID_LINKS_COMBINATION: 'Invalid combination for linking',
  LINK_CREDIT_NOTE: 'Link credit note to selected',
  UNLINK_CREDIT_NOTE: 'Unlink credit note to selected'
  // LINK_REBATE: 'Link rebate to selected'
  // UNLINK_REBATE: 'Unlink rebate to selected'
};

export const LINK_ACTIONS = [
  LINK_STATUS.LINK_CREDIT_NOTE,
  LINK_STATUS.LINK_INVOICE,
  // LINK_STATUS.LINK_REBATE,
  LINK_STATUS.UNLINK_CREDIT_NOTE,
  LINK_STATUS.UNLINK_INVOICE
  // LINK_STATUS.UNLINK_REBATE
];
