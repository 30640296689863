import React, { useState } from 'react';
import { balanceApi } from 'services/api';
import {
  BalanceReportName,
  BalanceViewContract,
  Error,
  PaymentTemplatesListResponse,
  ReconciliationReportRequestBody,
  ReportFormat,
  UnpaidInvoicesRequestBody
} from 'services/api/data-contracts';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { ExportPdfPopover } from 'pages/widgets/components/ExportPdfPopover/ExportPdfPopover';
import { useGetReportTemplates } from 'queries/balance/useGetReportTemplates';
import { BalanceFilter, useBalanceReportStore } from 'store/balanceReportStore';
import { message } from 'antd';
import { SERVER_DATE_FORMAT } from 'consts/common';
import { Dayjs } from 'dayjs';
import { RequestParams } from 'services/api/http-client';

export enum BalanceReportFormat {
  Pdf = 'pdf',
  Xlsx = 'xlsx'
}

interface IProps {
  isLoading: boolean;
  tooltip: string;
  reportType: BalanceReportName;
  contract?: BalanceViewContract;
}

export const ExportPopover = ({ isLoading, reportType, contract, tooltip }: IProps) => {
  const [isOpen, setOpen] = useState(false);
  const [format, setFormat] = useState<BalanceReportFormat | ReportFormat>(BalanceReportFormat.Pdf);
  const [templateId, setTemplateId] = useState<string | undefined>(undefined);

  const { filter } = useBalanceReportStore(({ filter }) => ({
    filter
  }));

  const templatesQueryResult = useGetReportTemplates({ reportName: reportType }, isOpen);

  const getFilename = (contentDisposition: string | undefined) => {
    const match = contentDisposition?.match(/filename="(.+)"/);
    return match ? match[1] : 'report.pdf';
  };

  const getParams = (format: BalanceReportFormat | ReportFormat): RequestParams => ({
    format: 'arraybuffer',
    headers: {
      Accept:
        format === BalanceReportFormat.Pdf
          ? 'application/pdf, application/json, application/text'
          : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  });

  const createRequestBody = (
    reportType: string,
    filter: BalanceFilter,
    templateId: string,
    contract?: BalanceViewContract
  ) => {
    const date = filter.contractDate;
    const startDate = date && (date[0] as unknown as Dayjs).format(SERVER_DATE_FORMAT);
    const endDate = date && (date[1] as unknown as Dayjs).format(SERVER_DATE_FORMAT);

    const requestBody = {
      currency: filter.currency as string,
      counterpartyId: filter.counterpartyId as number,
      templateId,
      format
    };

    if (reportType === BalanceReportName.ReconciliationReports) {
      return {
        ...requestBody,
        startDate: startDate as string,
        endDate: endDate as string
      };
    }

    return {
      ...requestBody,
      contractId: contract?.contractId as number
    };
  };

  const fetchReport = async () => {
    if (!filter.currency) {
      message.error('Please select currency filter and apply it.');
      return Promise.reject('Currency is not selected.');
    }

    const params = getParams(format);
    const data = createRequestBody(
      reportType,
      filter,
      templateId as string,
      contract as BalanceViewContract
    );

    const response =
      reportType === 'UnpaidInvoicesReports'
        ? await balanceApi.generateUnpaidInvoicesReport(data as UnpaidInvoicesRequestBody, params)
        : await balanceApi.generateReconciliationReport(
            data as ReconciliationReportRequestBody,
            params
          );

    const contentType = response?.headers['content-type'];
    const blob = new Blob([response?.data], { type: contentType });
    const filename = getFilename(response?.headers['content-disposition']);

    return { blob, filename, contentType };
  };

  return (
    <ExportPdfPopover
      entityName={'report'}
      templatesQueryResult={
        templatesQueryResult as UseQueryResult<
          AxiosResponse<PaymentTemplatesListResponse, unknown>,
          Error
        >
      }
      isOpen={isOpen}
      onSetOpen={setOpen}
      templateId={templateId}
      onSetTemplateId={setTemplateId}
      format={format}
      onSetFormat={setFormat}
      isEntityLoading={isLoading}
      onFetchReport={fetchReport}
      tooltip={tooltip}
      reportFormats={Object.values(BalanceReportFormat)}
    />
  );
};
