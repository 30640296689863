import React from 'react';
import { Flex, Select, Tooltip } from 'antd';
import { StyledDatePicker } from 'components/common/StyledDatePicker';
import { Dayjs } from 'dayjs';
import { Filter } from 'pages/Dictionaries/Dictionaries';
import {
  FILTER_OWN_PRICE_OPTIONS,
  IS_SPECIAL_PRICE
} from 'pages/Dictionaries/components/DictionaryHeader/constants/own-prices';
import { CONTROL_SIZE, DATE_FORMAT } from 'consts/common';
import { getSelectOptions } from 'helpers';

interface IProps {
  filter: Filter;
  onFilter: (name: string, value: string | Dayjs | boolean | undefined | null) => void;
}

export const OwnPriceFilter = ({ filter, onFilter }: IProps) => (
  <Flex gap={12} style={{ width: '100%' }}>
    <Tooltip mouseEnterDelay={1} title="Special price">
      <Select
        options={getSelectOptions(FILTER_OWN_PRICE_OPTIONS)}
        size={CONTROL_SIZE}
        style={{ width: '160px' }}
        popupMatchSelectWidth={false}
        value={filter.isSpecialPrice}
        defaultValue={IS_SPECIAL_PRICE.ALL}
        onChange={(value) => onFilter('isSpecialPrice', value)}
      />
    </Tooltip>

    <div style={{ flexBasis: '115px', flexGrow: '1' }}>
      <Tooltip mouseEnterDelay={1} title="Date which is included in the price period">
        <StyledDatePicker
          id="ownPriceActiveDate"
          format={DATE_FORMAT}
          placeholder="Select active date"
          value={filter.activeDate}
          onSetDate={(value) => onFilter('activeDate', value)}
          onChange={(value) => onFilter('activeDate', value)}
        />
      </Tooltip>
    </div>
  </Flex>
);
