import { getUTCDate } from 'helpers';
import { useFlightStore } from 'store/flightStore';
import { FormInstance } from 'antd/es/form';
import { PermitOrderInputWithDayJs } from '../components/utils/permitOrderUtils';
import { OrderType } from 'services/api/data-contracts';
import { FlightTimeRange, getFlightsMaxTimeRange } from './getFlightsMaxTimeRange';
import { useContext } from 'react';
import { FormContext } from '../../components/OrderDrawer/OrderDrawer';

type OnFormUpdate = (
  updated: Partial<PermitOrderInputWithDayJs>,
  formData: PermitOrderInputWithDayJs
) => void;

export const usePermitFormUpdate = (form: FormInstance, orderType?: OrderType): OnFormUpdate => {
  const { flights } = useFlightStore(({ list }) => ({ flights: list }));

  const { isTouched, setTouched } = useContext(FormContext);

  return (updated, data) => {
    const didChange = form?.isFieldsTouched() !== isTouched;

    if (didChange && form) {
      setTouched && setTouched(form.isFieldsTouched());
    }

    if (updated.operatorId) {
      form.setFieldValue('aircraftId', undefined);
    }

    if (orderType !== OrderType.PMT || !orderType || 'status' in updated) {
      return;
    }

    const { flightIds } = data;

    const definedFlightIds = flightIds?.filter((id) => !!id) || [];

    if (updated.flightIds && definedFlightIds.length) {
      const firstFlight = flights.find(({ id }) => id === definedFlightIds[0]);

      if (firstFlight && definedFlightIds.length === 1) {
        form.setFieldsValue({
          operatorId: firstFlight.operatorId,
          aircraftId: firstFlight.aircraftId,
          startEndDate: [
            getUTCDate(firstFlight.estimatedTimeDeparture),
            getUTCDate(firstFlight.estimatedTimeArrival)
          ]
        });
      }

      if (definedFlightIds.length > 1) {
        const range = getFlightsMaxTimeRange(flights, definedFlightIds) as FlightTimeRange;

        form.setFieldsValue({
          startEndDate: [
            getUTCDate(range.minEstimatedTimeDeparture),
            getUTCDate(range.maxEstimatedTimeArrival)
          ]
        });
      }
    } else if (updated.flightIds) {
      form.setFieldsValue({
        startEndDate: [undefined, undefined]
      });
    }
  };
};
