import React, { useState } from 'react';
import { Upload, Button, message } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import { invoicesApi } from 'services/api';
import { CONTROL_SIZE, MESSAGE_DURATION } from 'consts/common';
import { RcFile, UploadProps } from 'antd/es/upload';
import { useProgressNotification } from './useProgressNotification';
import { ALLOWED_MIME_TYPES } from 'queries/documents/consts';

interface IProps {
  entityName: 'Invoices' | 'CreditNotes' | 'Payments';
  relatedId?: number;
  counterpartyId?: number;
}

type CustomRequestOptions = Parameters<NonNullable<UploadProps['customRequest']>>[0];

export const UploadFileButton = ({ relatedId, counterpartyId, entityName }: IProps) => {
  const [isLoading, setLoading] = useState(false);
  const { startProgressStatus, updateProgressStatus, finishProgressStatus } =
    useProgressNotification();

  const customUpload = async ({ file }: CustomRequestOptions) => {
    const document = file as RcFile;
    startProgressStatus(document.name);
    setLoading(true);

    try {
      updateProgressStatus(5);

      const {
        data: { url, headers }
      } = await invoicesApi.prepareUploadFile({
        relatedId,
        filename: document.name,
        counterpartyId,
        entityName
      });

      updateProgressStatus(30);

      if (!url || !headers) {
        throw new Error(
          `Upload of file  ${document.name} failed. Upload URL or headers not provided by server.`
        );
      }

      updateProgressStatus(50);

      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          ...headers,
          'Content-Type': document.type
        },
        body: file
      });

      if (!response.ok) {
        throw new Error(`Upload of file ${document.name} failed.`);
      }

      updateProgressStatus(100);
    } catch (error) {
      if (error instanceof Error) {
        finishProgressStatus(error.message);
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const isTypeAllowed = (type: string) => Object.values(ALLOWED_MIME_TYPES).includes(type);

  const validateFile = (file: RcFile) => {
    if (!isTypeAllowed(file.type)) {
      message.error(
        'Current format is not supported. Please upload pdf, docx, jpeg, png or gif file',
        MESSAGE_DURATION
      );
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  return (
    <Upload
      showUploadList={false}
      customRequest={customUpload}
      beforeUpload={validateFile}
      style={{ margin: 0 }}>
      <Button
        size={CONTROL_SIZE}
        style={{ marginBottom: '8px' }}
        type="dashed"
        icon={<PaperClipOutlined style={{ transform: 'rotate(135deg)' }} />}
        loading={isLoading}>
        Upload document
      </Button>
    </Upload>
  );
};
