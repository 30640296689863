import { ColumnsType } from 'antd/es/table';
import { Contact } from 'services/api/data-contracts';
import { Button, Flex, message, Tooltip } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { CopyOutlined, DeleteOutlined, EditFilled } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React from 'react';

const renderCopyToClipboard = (text: string, tooltip: string, successMessage: string) => (
  <CopyToClipboard
    text={text}
    onCopy={async () => {
      await message.success(successMessage);
    }}>
    <Tooltip mouseEnterDelay={1} title={tooltip}>
      <Button size={CONTROL_SIZE} icon={<CopyOutlined />} type="text" />
    </Tooltip>
  </CopyToClipboard>
);

export const getColumns = (
  allEmails: string,
  onOpen: (contact: Contact) => void,
  onDelete: (id: number) => void
): ColumnsType<Contact> => [
  {
    title: () =>
      allEmails.length > 0 &&
      renderCopyToClipboard(allEmails, 'Copy all emails', 'All email addresses have been copied'),
    dataIndex: 'copy',
    key: 'copy',
    render: (_, record) =>
      renderCopyToClipboard(record.emailAddress, 'Copy email', 'Email address has been copied'),
    width: 40
  },
  {
    title: 'Email',
    dataIndex: 'emailAddress',
    key: 'emailAddress'
  },
  {
    title: 'Recipient for',
    dataIndex: 'recipientTag',
    key: 'recipientTag',
    render: (value) => <span style={{ whiteSpace: 'pre-wrap' }}>{value?.join(`\n`)}</span>,
    ellipsis: true
  },
  {
    title: 'Contact name',
    dataIndex: 'contactName',
    key: 'contactName',
    render: (_, record) => (
      <Flex align="center" style={{ maxWidth: '450px' }}>
        <Tooltip mouseEnterDelay={1} title={record.contactName}>
          <span
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}>
            {record.contactName}
          </span>
        </Tooltip>

        <div id="actions">
          <Tooltip mouseEnterDelay={1} title="Edit contact">
            <Button
              size={CONTROL_SIZE}
              type="primary"
              shape="circle"
              data-testid="edit-btn"
              icon={<EditFilled />}
              onClick={() => onOpen(record)}
            />
          </Tooltip>
          <Tooltip mouseEnterDelay={1} title="Delete contact">
            <Button
              size={CONTROL_SIZE}
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => onDelete(record.id)}
            />
          </Tooltip>
        </div>
      </Flex>
    )
  }
];
