/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreditNoteInput,
  Error,
  Payment,
  PaymentFullyHydrated,
  PaymentHydrated,
  PaymentHydratedListResponse,
  PaymentInput,
  PaymentTemplatesListResponse,
  RebateCreditNoteInput,
  ReportFormat,
  ValidationError
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Payments<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Get a list of Payment records
   *
   * @tags payments
   * @name GetAllPayments
   * @summary Get a list of Payment records
   * @request GET:/payments
   * @secure
   */
  getAllPayments = (
    query?: {
      /**
       * The current page number
       * @format int32
       * @min 1
       * @max 10000
       * @default 1
       */
      page?: number;
      /**
       * The number of items to return per page
       * @format int32
       * @min 1
       * @max 10000
       * @default 10
       */
      pageSize?: number;
      /**
       * Parameter to search by one or more values in used endpoint entities
       * @pattern ^[^%]*$
       */
      search?: string;
      /**
       * Filter by an id of invoice, to get payments that can be linked to this invoice based on invoice type
       * @format int32
       * @min 1
       * @max 1000000
       */
      invoiceId?: number;
      /** Filter by an id of invoice, that was corrected by these credit note payments */
      correctedInvoiceId?: number;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PaymentHydratedListResponse, Error>({
      path: `/payments`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Create a new Payment record
   *
   * @tags payments
   * @name AddPayment
   * @summary Create a new Payment record
   * @request POST:/payments
   * @secure
   */
  addPayment = (
    data: PaymentInput | CreditNoteInput | RebateCreditNoteInput,
    params: RequestParams = {}
  ) =>
    this.http.request<PaymentHydrated, ValidationError | Error>({
      path: `/payments`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Get a specific Payment record by id
   *
   * @tags payments
   * @name GetPaymentById
   * @summary Get a specific Payment record
   * @request GET:/payments/{paymentId}
   * @secure
   */
  getPaymentById = (paymentId: number, params: RequestParams = {}) =>
    this.http.request<PaymentFullyHydrated, Error>({
      path: `/payments/${paymentId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params
    });
  /**
   * @description Update a specific Payment document by id
   *
   * @tags payments
   * @name UpdatePayment
   * @summary Update a specific Payment document
   * @request PUT:/payments/{paymentId}
   * @secure
   */
  updatePayment = (
    paymentId: number,
    data: PaymentInput | CreditNoteInput | RebateCreditNoteInput,
    params: RequestParams = {}
  ) =>
    this.http.request<Payment, ValidationError | Error>({
      path: `/payments/${paymentId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params
    });
  /**
   * @description Delete a specific Payment record by id
   *
   * @tags payments
   * @name DeletePayment
   * @summary Delete a specific Payment record
   * @request DELETE:/payments/{paymentId}
   * @secure
   */
  deletePayment = (paymentId: number, params: RequestParams = {}) =>
    this.http.request<void, ValidationError | Error>({
      path: `/payments/${paymentId}`,
      method: 'DELETE',
      secure: true,
      ...params
    });
  /**
   * @description Generate report by specific Payment
   *
   * @tags payments
   * @name GeneratePaymentReport
   * @summary Generate report by specific Payment
   * @request POST:/payments/{paymentId}/generate-report
   * @secure
   */
  generatePaymentReport = (
    paymentId: number,
    data: {
      templateId?: string;
      format?: ReportFormat;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<File, ValidationError | Error>({
      path: `/payments/${paymentId}/generate-report`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * @description Get a list of payment templates
   *
   * @tags payments
   * @name GetPaymentTemplates
   * @summary Get a list of payment templates
   * @request GET:/payments/templates
   * @secure
   */
  getPaymentTemplates = (
    query?: {
      /** Filter by creditNote type of payment */
      forCreditNotes?: boolean;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<PaymentTemplatesListResponse, Error>({
      path: `/payments/templates`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params
    });
}
