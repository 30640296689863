import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { geekblue, magenta } from '@ant-design/colors';
import { ContractPaymentType } from 'services/api/data-contracts';
import { Tooltip } from 'antd';

const arrowColors = {
  [ContractPaymentType.Incoming]: magenta[6],
  [ContractPaymentType.Outgoing]: geekblue[3]
};

export const ArrowIcon = ({ type }: { type: ContractPaymentType }) => (
  <Tooltip mouseEnterDelay={1} title={type}>
    <ArrowLeftOutlined
      style={{
        color: arrowColors[type],
        fontSize: '16px',
        width: '16px',
        height: '16px',
        transform: `rotate(${type === ContractPaymentType.Incoming ? '180' : '0'}deg)`
      }}
    />
  </Tooltip>
);
