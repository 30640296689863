import { Tooltip } from 'antd';
import React from 'react';
import { FlightStatus } from 'services/api/data-contracts';
import { SmallTag } from 'components/common/SmallTag';

interface IProps {
  status?: FlightStatus;
}

const flightStatusMap = {
  [FlightStatus.ESTD]: 'Estimated',
  [FlightStatus.DONE]: 'Completed',
  [FlightStatus.AIR]: 'In the air',
  [FlightStatus.CNLD]: 'Cancelled',
  [FlightStatus.PLND]: 'Planned'
};

const flightStatusColorsMap = {
  [FlightStatus.ESTD]: 'geekblue',
  [FlightStatus.DONE]: 'green',
  [FlightStatus.AIR]: 'lime',
  [FlightStatus.CNLD]: 'red',
  [FlightStatus.PLND]: 'orange'
};

export const FlightStatusTag = ({ status }: IProps) => {
  if (!status) {
    return null;
  }

  return (
    <Tooltip mouseEnterDelay={1} title={flightStatusMap[status]}>
      <SmallTag color={flightStatusColorsMap[status]}>{status}</SmallTag>
    </Tooltip>
  );
};
