import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { InvoicesReportsListResponse } from 'services/api/data-contracts';
import { Tooltip } from 'antd';
import { DATE_FORMAT_SHORT, DATE_FORMAT_SHORT_WITH_UNDERSCORE } from 'consts/common';
import { getAmountValue, getUTCDate } from 'helpers';
import { convertBytesToReadableSize } from 'pages/widgets/MessagesWidget/components/NewMessage/helpers/convertBytesToReadableSize';
import { dateSorter } from 'pages/Dictionaries/utils/tableSorters';
import { formatRelativeTime } from 'helpers/dataRendering/formatRelativeTime';

export const isInvoice = (entity: Documents[number]) => entity.hasOwnProperty('invoiceNumber');

export type DocumentItem = InvoiceDocument | CreditNoteDocument;

export interface InvoiceDocument {
  isUserFile: boolean;
  fileId: string;
  invoiceId: number;
  invoiceNumber: string;
  filename: string;
  currency: string;
  total: number;
  fileSize: number;
  createdAt: string;
}

export interface CreditNoteDocument {
  isUserFile: boolean;
  fileId: string;
  creditNoteId: number;
  creditNoteNumber: string;
  currency: string;
  amount?: number;
  fileSize: number;
  createdAt: string;
}

export type Documents = InvoicesReportsListResponse['items'];

export const getColumns = (counterpartyName?: string): ColumnsType<Documents[number]> => [
  {
    title: 'Name',
    dataIndex: ['name'],
    key: 'name',
    render: (_, record) => {
      let filename;

      if (record.isUserFile) {
        filename = (record as InvoiceDocument).filename;
      } else {
        const date = getUTCDate(record.createdAt)?.format(DATE_FORMAT_SHORT_WITH_UNDERSCORE);
        const entityNumber =
          'invoiceNumber' in record ? record.invoiceNumber : record.creditNoteNumber;
        filename = [date, entityNumber, counterpartyName].filter((i) => !!i).join('_');
      }

      return (
        <Tooltip mouseEnterDelay={1} title={filename}>
          <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {filename}
          </div>
        </Tooltip>
      );
    },
    width: 200
  },
  {
    title: 'Date',
    dataIndex: ['createdAt'],
    key: 'createdAt',
    render: (_, record) => {
      return record.createdAt && !record.isUserFile
        ? getUTCDate(record.createdAt)?.format(DATE_FORMAT_SHORT)
        : ' - ';
    },
    width: 90
  },
  {
    title: 'Cur',
    dataIndex: ['currency'],
    width: 50,
    render: (_, record) => (!record.isUserFile ? record.currency : ' - ')
  },
  {
    title: 'Total',
    dataIndex: ['total'],
    render: (_, record) => {
      const total = 'invoiceNumber' in record ? record.total : record.amount;
      return !record.isUserFile ? getAmountValue(total) : ' - ';
    },
    width: 100,
    align: 'end'
  },
  {
    title: 'Size',
    dataIndex: ['fileSize'],
    render: (_, record) => convertBytesToReadableSize(record.fileSize),
    width: 80
  },
  {
    title: 'Added',
    dataIndex: ['createdAt'],
    sorter: (a, b) => dateSorter(a.createdAt || '', b.createdAt || ''),
    render: (_, record) => (record.createdAt ? formatRelativeTime(record.createdAt) : ' - '),
    ellipsis: true,
    width: 150
  }
];
