import { useQuery } from '@tanstack/react-query';
import { balanceApi } from 'services/api';
import { BALANCE_WIDGET_QUERY_KEY } from './consts';
import { BalanceReportName } from 'services/api/data-contracts';

const fetchList = async (filter: { reportName: BalanceReportName }) =>
  await balanceApi.getBalanceTemplates(filter);

export const useGetReportTemplates = (
  filter: {
    reportName: BalanceReportName;
  },
  enabled?: boolean
) =>
  useQuery({
    queryKey: [BALANCE_WIDGET_QUERY_KEY, filter.reportName],
    queryFn: () => fetchList(filter),
    enabled
  });
