import React, { useMemo, useState } from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { Button, Row, Table } from 'antd';
import { getColumns } from './columns';
import { useDeleteCounterpartyContact, useGetCounterpartyContacts } from 'queries/counterparty';
import { hasCurrentUserRole, UserRole } from 'services/auth/hasUserRole';
import { Contact, RecipientTagType } from 'services/api/data-contracts';
import { CounterpartyContactForm } from './CounterpartyContactForm';
import styled from 'styled-components';

const StyledTable = styled(Table<Contact>)`
  #actions {
    display: none;
    position: absolute;
    right: 0;
  }

  #actions > button {
    margin-right: 10px;
  }

  .ant-table-cell-row-hover #actions {
    display: block;
  }
`;

interface IProps {
  counterpartyId: number;
}

export const CounterpartyContacts = ({ counterpartyId }: IProps) => {
  const [isEditMode, setEditMode] = useState(false);
  const [current, setCurrent] = useState<Contact | undefined>(undefined);

  const { isLoading, data } = useGetCounterpartyContacts({ counterpartyId }, true);
  const deleteMutation = useDeleteCounterpartyContact(counterpartyId);

  const dataSource = useMemo(() => {
    const mappedData = (data?.data.items || []).map((contact) => ({
      ...contact,
      key: contact.id
    }));

    const targetTag = hasCurrentUserRole([UserRole.Dispatcher])
      ? RecipientTagType.OPSAddress
      : RecipientTagType.BillingAddress;

    return mappedData.sort((a, b) => {
      const aHasTag = a.recipientTag?.includes(targetTag);
      const bHasTag = b.recipientTag?.includes(targetTag);
      return aHasTag === bHasTag ? 0 : aHasTag ? -1 : 1;
    });
  }, [data?.data.items]);

  const allEmails = useMemo(
    () => dataSource.map((contact) => contact.emailAddress).join(', '),
    [dataSource]
  );
  const columns = useMemo(
    () =>
      getColumns(
        allEmails,
        (contact) => {
          setEditMode(true);
          setCurrent(contact);
        },
        (id) => deleteMutation.mutate(id)
      ),
    [allEmails]
  );

  return (
    <>
      {isEditMode ? (
        <CounterpartyContactForm
          counterpartyId={counterpartyId}
          onCancel={() => {
            setEditMode(false);
            setCurrent(undefined);
          }}
          current={current}
        />
      ) : (
        <Row justify="end" style={{ marginBottom: 12 }}>
          <Button size={CONTROL_SIZE} onClick={() => setEditMode(true)} loading={isLoading}>
            Add a new one
          </Button>
        </Row>
      )}

      <StyledTable
        columns={columns}
        dataSource={dataSource}
        size={CONTROL_SIZE}
        loading={isLoading || deleteMutation.isLoading}
        pagination={false}
      />
    </>
  );
};
