import { Button, Flex, Input, Tooltip } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { CheckOutlined, CloseOutlined, EditFilled } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useUpdateFlightProgram } from 'queries/flightProgram';

interface IProps {
  value?: string;
  programId: number;
}

export const ProgramNotes = ({ value, programId }: IProps) => {
  const [isNoteEditMode, setNoteEditMode] = useState(false);
  const [notes, setNotes] = useState(value);

  useEffect(() => {
    setNotes(value);
  }, [isNoteEditMode]);

  const updateMutation = useUpdateFlightProgram(programId);

  const handleSubmit = async () => {
    try {
      await updateMutation.mutateAsync({ notes });
    } finally {
      setNoteEditMode(false);
    }
  };

  if (!isNoteEditMode) {
    return (
      <Flex justify="space-between">
        <span>{value}</span>
        <Tooltip mouseEnterDelay={1} title={`${value ? 'Edit' : 'Add'} note`}>
          <Button
            size={CONTROL_SIZE}
            shape="circle"
            icon={<EditFilled />}
            onClick={() => setNoteEditMode(true)}
            type="link"
          />
        </Tooltip>
      </Flex>
    );
  }

  return (
    <Flex justify="space-between" align="center" gap={8}>
      <Input.TextArea
        size={CONTROL_SIZE}
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
      />
      <Tooltip mouseEnterDelay={1} title="Cancel changes">
        <Button
          size={CONTROL_SIZE}
          shape="circle"
          icon={<CloseOutlined />}
          onClick={() => setNoteEditMode(false)}
          loading={updateMutation.isLoading}
        />
      </Tooltip>
      <Tooltip mouseEnterDelay={1} title="Save">
        <Button
          size={CONTROL_SIZE}
          shape="circle"
          icon={<CheckOutlined />}
          onClick={handleSubmit}
          type="primary"
          loading={updateMutation.isLoading}
        />
      </Tooltip>
    </Flex>
  );
};
