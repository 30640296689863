import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  DownloadOutlined as Download,
  ExportOutlined,
  PaperClipOutlined as PaperClip
} from '@ant-design/icons';
import { Flex, message, Tooltip } from 'antd';
import { AttachmentEntry as Attachment } from 'services/emailEngineApi/data-contracts';
import { useMessageStore } from 'store/messageStore';
import { useDownloadFile } from '../downloadHooks/useDownloadFile';
import { MessageInstance } from 'antd/es/message/interface';
import { getFileExtension } from '../AttachmentViewer/helpers/extensions';
import { supportedExtensionsToOpenInNewTab } from '../AttachmentViewer/constants';
import { MESSAGE_DURATION } from 'consts/common';
import { ATTACHMENT_NOTIFICATION } from '../../consts';

const StyledAttachment = styled(Flex)`
  background-color: white;
  font-size: 14px;
  flex: 0 0 calc(50% - 12px);
  max-width: calc(50% - 12px);

  .action-icons {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover {
    background-color: #fafafa;
    .action-icons {
      color: rgba(0, 0, 0, 0.45);
      opacity: 1;

      .icon {
        &:hover {
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }
  }
`;

const StyledDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  color: #1890ff !important;
`;

export const AttachmentActionBar = ({
  attachment,
  messageApi
}: {
  attachment: Attachment;
  messageApi: MessageInstance;
}) => {
  const [isDownloadInProcess, setDownloadInProcess] = useState(false);
  const [isNewTabInProcess, setNewTabInProcess] = useState(false);

  const { downloadFileOrThrowError } = useDownloadFile(attachment);

  const { setCurrentViewedAttachment } = useMessageStore(({ setCurrentViewedAttachment }) => ({
    setCurrentViewedAttachment
  }));
  const handleOpenFileViwer = () => {
    setCurrentViewedAttachment(attachment);
  };

  const extension = useMemo(() => {
    return getFileExtension(attachment.filename, attachment.contentType);
  }, [attachment.filename, attachment.contentType]);

  const openLoadingMessage = () => {
    messageApi.open({
      key: 'loading-attachment',
      type: 'loading',
      content: ATTACHMENT_NOTIFICATION.IS_LOADING
    });
  };

  const handleDownloadFile = async (event: React.MouseEvent<HTMLDivElement>) => {
    event?.stopPropagation();
    openLoadingMessage();
    setDownloadInProcess(true);
    try {
      await downloadFileOrThrowError();
      message.destroy('loading-attachment');
    } catch (error) {
      setDownloadInProcess(false);
      message.destroy('loading-attachment');
      console.error(ATTACHMENT_NOTIFICATION.IS_ERROR, error);
      messageApi.error(ATTACHMENT_NOTIFICATION.IS_ERROR);
    } finally {
      setDownloadInProcess(false);
    }
  };

  const handleOpenFileInNewTab = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (!supportedExtensionsToOpenInNewTab.includes(extension)) {
      messageApi.warning(
        `File extension ${extension} is not supported to be opened in browser tab. Please download instead.`,
        MESSAGE_DURATION
      );
      return;
    }

    openLoadingMessage();
    setNewTabInProcess(true);
    try {
      await downloadFileOrThrowError({ isNewTab: true });
      message.destroy('loading-attachment');
    } catch (error) {
      setNewTabInProcess(false);
      message.destroy('loading-attachment');
      console.error(ATTACHMENT_NOTIFICATION.IS_ERROR, error);
      messageApi.error(ATTACHMENT_NOTIFICATION.IS_ERROR);
    } finally {
      setNewTabInProcess(false);
    }
  };

  return (
    <StyledAttachment gap={12} onClick={handleOpenFileViwer}>
      <Flex gap={8} justify="space-between" style={{ width: '100%', padding: '4px' }}>
        <Flex gap={8}>
          <PaperClip style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
          <StyledDiv>{attachment.filename}</StyledDiv>
        </Flex>
        <Flex gap={10} className="action-icons">
          <Tooltip title={isNewTabInProcess ? 'Loading...' : 'Open in new tab'}>
            <ExportOutlined
              className="icon"
              onClick={handleOpenFileInNewTab}
              disabled={isNewTabInProcess}
            />
          </Tooltip>
          <Tooltip title={isDownloadInProcess ? 'Loading...' : 'Download'}>
            <Download
              className="icon"
              onClick={handleDownloadFile}
              disabled={isDownloadInProcess}
            />
          </Tooltip>
        </Flex>
      </Flex>
    </StyledAttachment>
  );
};
