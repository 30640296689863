import { useQuery } from '@tanstack/react-query';
import { settlementApi } from 'services/api';
import { SETTLEMENT_WIDGET_QUERY_KEY } from './consts';
import { InvoiceFilter, useSettlementDashboardStore } from 'store/settlementDashboardStore';
import { OrdersFilter, useOrdersDashboardStore } from 'store/ordersDashboardStore';
import { PAGE_SIZE_35, SERVER_DATE_FORMAT } from 'consts/common';
import { getJoinedProps, OptionPropName } from 'helpers/dataTransformations/getJoinedProps';
import { message } from 'antd';

type IFilter = InvoiceFilter &
  OrdersFilter & {
    endDatetime?: string;
    page: number;
  };

const fetchList = async (filter: IFilter) =>
  await settlementApi.getSettlementWidget({
    locationIds: getJoinedProps(filter.locations, OptionPropName.Value),
    supplierIds: getJoinedProps(filter.suppliers, OptionPropName.Value),
    payerIds: getJoinedProps(filter.payers, OptionPropName.Value),
    orderNumbers: filter.orderNumbers,
    types: filter.orderTypes?.join(','),
    endDatetime: filter.orderEndDate?.map((date) => date?.format(SERVER_DATE_FORMAT)).join(','),
    orderStatuses: filter.orderStatuses?.join(','),
    aircraftIds: getJoinedProps(filter.ordersAircrafts, OptionPropName.Value),
    flightNumbers: filter.flightNumbers,
    serviceIds: filter.services?.map(({ value }) => value).join(','),
    invoiceNumbers: filter.invoiceNumbers,
    isInProgram: filter.isNotInProgram ? false : undefined,
    page: filter.page,
    pageSize: PAGE_SIZE_35
  });

export const useGetSettlementWidget = () => {
  const { ordersFilter } = useOrdersDashboardStore();
  const { setList, addToList, page, invoiceFilter } = useSettlementDashboardStore(
    ({ setList, addToList, page, invoiceFilter }) => ({
      setList,
      addToList,
      page,
      invoiceFilter
    })
  );

  return useQuery({
    queryKey: [SETTLEMENT_WIDGET_QUERY_KEY, page, ordersFilter, invoiceFilter],
    queryFn: () => fetchList({ ...ordersFilter, ...invoiceFilter, page }),
    onSuccess: async (response) => {
      if (page > 1) {
        addToList(response.data.items, page);
      } else {
        setList(response.data, page);
      }
    },
    onError: () => {
      message.error('Settlement dashboard failed to load data.');
    }
  });
};
