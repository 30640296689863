import React, { useEffect, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useDrawerStore } from 'store/drawerStore';
import { UI_LOADING_DELAY } from './consts';

interface IProps {
  isLoading: boolean;
}

export const ExportDocumentsButton = ({ isLoading }: IProps) => {
  const { setDrawerOpen } = useDrawerStore(({ setDrawerOpen }) => ({ setDrawerOpen }));

  const [loading, setLoading] = useState(isLoading);

  useEffect(() => {
    const shouldExtendLoading = !isLoading && loading;

    if (shouldExtendLoading) {
      const timer = setTimeout(() => setLoading(false), UI_LOADING_DELAY);
      return () => clearTimeout(timer);
    }
    setLoading(isLoading);
  }, [isLoading, loading]);

  return (
    <Tooltip mouseEnterDelay={1} title={loading ? 'Updating documents list...' : 'Documents list'}>
      <Button
        icon={<DownloadOutlined />}
        size={CONTROL_SIZE}
        onClick={() => setDrawerOpen('documents')}
        loading={loading}
      />
    </Tooltip>
  );
};
