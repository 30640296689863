import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { VatRate } from 'services/api/data-contracts';
import { stringSorter } from '../utils/tableSorters';
import { DecoratedText } from 'pages/components/DecoratedText';

export const getVatRateColumns = (): ColumnsType<VatRate> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (value, record) => <DecoratedText isActive={record.isActive}>{value}</DecoratedText>,
    sorter: (a, b) => stringSorter(a.name, b.name)
  },
  {
    title: 'Rate',
    dataIndex: 'rate',
    key: 'rate',
    render: (value, record) => <DecoratedText isActive={record.isActive}>{value}</DecoratedText>
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    render: (value, record) => <DecoratedText isActive={record.isActive}>{value}</DecoratedText>,
    ellipsis: true
  }
];
