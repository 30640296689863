import React, { useMemo } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
  Form,
  InputNumber,
  Button,
  FormInstance,
  FormListOperation,
  Flex,
  Tooltip,
  Select
} from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { CreditNoteInput, PaymentHydrated } from 'services/api/data-contracts';
import { useInvoiceStore } from 'store/invoiceStore';

import { round } from 'helpers';
import { isNumber } from 'lodash';

let ListOperation: FormListOperation;

interface IProps {
  formListItemKey: number;
  remove: typeof ListOperation.remove;
  form: FormInstance<Partial<PaymentHydrated> & { creditNoteItems: CreditNoteInput[] }>;
}

export const CreditNoteItemsForm = ({ formListItemKey, remove, form }: IProps) => {
  const { current: invoice } = useInvoiceStore(({ current }) => ({
    current
  }));

  const getLabel = (label: string) => (formListItemKey === 0 ? label : '');

  const invoiceItemsOptions = useMemo(
    () =>
      (invoice?.invoiceItems || []).map((item) => ({
        value: item.id,
        label: item.serviceName.name
      })),
    [invoice]
  );

  const handleItemChange = (
    property: string,
    propertyValue: number | null,
    creditNoteIdx: number
  ) => {
    const formData = form.getFieldsValue(true);
    const item = formData.creditNoteItems[creditNoteIdx];

    if (property === 'invoiceItemId' && propertyValue) {
      const invoiceItem = invoice?.invoiceItems.find((item) => item.id === propertyValue);
      if (invoiceItem) {
        const creditNoteItems = form.getFieldValue('creditNoteItems') || [];

        creditNoteItems[formListItemKey] = {
          ...creditNoteItems[formListItemKey],
          quantity: invoiceItem.quantity,
          amount: invoiceItem.amount,
          price: invoiceItem.price
        };
        form.setFieldsValue({ creditNoteItems });
      }
    }
    if (property === 'quantity' && propertyValue && isNumber(item.price)) {
      const amount = round(propertyValue * item.price);
      form.setFieldValue(['creditNoteItems', creditNoteIdx, 'amount'], amount);
    }
    if (property === 'price' && propertyValue && isNumber(item.quantity)) {
      const amount = round(propertyValue * item.quantity);
      form.setFieldValue(['creditNoteItems', creditNoteIdx, 'amount'], amount);
    }
    if (property === 'amount' && propertyValue && isNumber(item.quantity) && isNumber(item.price)) {
      const price = round(propertyValue / item.quantity);
      form.setFieldValue(['creditNoteItems', creditNoteIdx, 'price'], price);
    }
  };

  return (
    <Flex align="start">
      <Form.Item
        label={getLabel('Item')}
        name={[formListItemKey, 'invoiceItemId']}
        rules={[{ required: true }]}
        style={{ flexGrow: 1 }}>
        <Select
          size={CONTROL_SIZE}
          options={invoiceItemsOptions}
          showSearch
          optionFilterProp="label"
          placeholder="Select invoice item"
          style={{ width: '100%' }}
          onChange={(value) => handleItemChange('invoiceItemId', value, formListItemKey)}
        />
      </Form.Item>

      <Form.Item
        label={getLabel('Quantity')}
        name={[formListItemKey, 'quantity']}
        rules={[{ required: true }]}
        style={{ flexBasis: 108 }}>
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          placeholder="0.0000"
          precision={4}
          style={{ width: '100%' }}
          min={0}
          onChange={(value) => handleItemChange('quantity', value, formListItemKey)}
        />
      </Form.Item>

      <Form.Item
        label={getLabel('Price')}
        name={[formListItemKey, 'price']}
        rules={[{ required: true }]}
        style={{ flexBasis: 130 }}>
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          placeholder="0.00"
          style={{ width: '100%' }}
          precision={2}
          min={0}
          onChange={(value) => handleItemChange('price', value, formListItemKey)}
        />
      </Form.Item>

      <Form.Item
        label={getLabel('Amount')}
        name={[formListItemKey, 'amount']}
        rules={[{ required: true }]}
        style={{ flexBasis: 130 }}>
        <InputNumber
          type="number"
          size={CONTROL_SIZE}
          placeholder="0.00"
          style={{ width: '100%' }}
          precision={2}
          min={0}
          onChange={(value) => handleItemChange('amount', value, formListItemKey)}
        />
      </Form.Item>

      <Tooltip mouseEnterDelay={1} title="Delete the row">
        <Button
          icon={<DeleteOutlined />}
          size={CONTROL_SIZE}
          type="link"
          className="icon-button"
          data-testid="delete-row-btn"
          style={{ marginTop: formListItemKey === 0 ? '24px' : 0, color: 'rgba(0, 0, 0, 0.45)' }}
          onClick={() => remove(formListItemKey)}
        />
      </Tooltip>
    </Flex>
  );
};
