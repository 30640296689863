import React, { useContext, useEffect, useState } from 'react';
import { Flex, Form, Input, Radio } from 'antd';
import { CONTROL_SIZE } from 'consts/common';
import { useParams } from 'react-router-dom';
import { DictionaryName } from '../../models';
import { FormContext } from '../DictionaryDetails';
import { CountriesInput } from 'components/CountriesInput/CountriesInput';
import { useCounterpartyStore } from 'store/counterpartyStore';
import { hasCurrentUserRole, UserRole } from 'services/auth/hasUserRole';
import styled from 'styled-components';

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
`;

export const CounterpartyDictionaryForm = () => {
  const form = useContext(FormContext);
  const [isOperator, setIsOperator] = useState(false);

  const { dictionaryName } = useParams<{ dictionaryName: DictionaryName }>();
  const { current } = useCounterpartyStore(({ current }) => ({ current }));

  useEffect(() => {
    setIsOperator(form?.getFieldValue('isOperator'));
  }, [form, form?.getFieldValue('isOperator')]);

  if (!form) {
    return null;
  }

  return (
    <Form
      name={dictionaryName}
      layout="vertical"
      form={form}
      colon={false}
      requiredMark="optional"
      initialValues={{ isActive: true, isOperator: false }}
      className="styled-form"
      onValuesChange={(updated) => {
        if ('isOperator' in updated) {
          setIsOperator(updated.isOperator);

          if (!updated.isOperator) {
            form.setFieldValue('icao', undefined);
            form.setFieldValue('iata', undefined);
          }
        }
      }}>
      <Form.Item label="Active" name="isActive" required>
        <Radio.Group
          size={CONTROL_SIZE}
          optionType="button"
          buttonStyle="solid"
          data-testid="Active">
          <Radio.Button value={true}>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <RowWrapper>
        <Form.Item label="Name" name="name" required>
          <Input size={CONTROL_SIZE} placeholder="Input short name" autoFocus />
        </Form.Item>

        <Form.Item label="Full name" name="fullName" required>
          <Input size={CONTROL_SIZE} placeholder="Input full name" />
        </Form.Item>
      </RowWrapper>

      <RowWrapper>
        <Form.Item label="Legal form" name="legalForm">
          <Input size={CONTROL_SIZE} placeholder="Input legal form" />
        </Form.Item>

        <CountriesInput
          name="countryId"
          label="Country"
          placeholder="Select registration country"
          initialFilter={current?.country?.name}
          onChange={(value) => form.setFieldsValue({ countryId: value })}
        />
      </RowWrapper>

      <RowWrapper>
        <Form.Item label="Registration number" name="registrationNumber" rules={[{ max: 64 }]}>
          <Input size={CONTROL_SIZE} placeholder="Input registration number" />
        </Form.Item>

        <Flex gap={8}>
          <Form.Item label="VAT number" name="vatNumber" rules={[{ max: 64 }]}>
            <Input size={CONTROL_SIZE} placeholder="Input VAT number" />
          </Form.Item>

          <Form.Item label="Operator" name="isOperator" required>
            <Radio.Group
              size={CONTROL_SIZE}
              optionType="button"
              buttonStyle="solid"
              data-testid="Operator"
              block>
              <Radio.Button value={true}>Yes</Radio.Button>
              <Radio.Button value={false}>No</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Flex>
      </RowWrapper>

      <RowWrapper>
        <Form.Item
          name="icao"
          label="ICAO"
          rules={[{ min: 3 }, { max: 3 }]}
          normalize={(value) => value.toUpperCase().replace(/ /g, '')}>
          <Input size={CONTROL_SIZE} placeholder="Input ICAO code" disabled={!isOperator} />
        </Form.Item>

        <Form.Item
          name="iata"
          label="IATA"
          rules={[{ min: 2 }, { max: 2 }]}
          normalize={(value) => value.toUpperCase().replace(/ /g, '')}>
          <Input size={CONTROL_SIZE} placeholder="Input IATA code" disabled={!isOperator} />
        </Form.Item>
      </RowWrapper>

      <Form.Item name="legalAddress" label="Legal address">
        <Input.TextArea size={CONTROL_SIZE} placeholder="Input legal address" />
      </Form.Item>

      <Form.Item name="actualAddress" label="Actual address">
        <Input.TextArea size={CONTROL_SIZE} placeholder="Input actual address" />
      </Form.Item>

      <Form.Item name="opsNotes" label="Ops notes">
        <Input.TextArea
          size={CONTROL_SIZE}
          placeholder="Input any additional important information"
          autoSize={{ minRows: 5, maxRows: 10 }}
          disabled={!hasCurrentUserRole([UserRole.Dispatcher])}
        />
      </Form.Item>

      <Form.Item name="settlementNotes" label="Settlement notes">
        <Input.TextArea
          size={CONTROL_SIZE}
          placeholder="Input any additional important information"
          autoSize={{ minRows: 5, maxRows: 10 }}
          disabled={!hasCurrentUserRole([UserRole.Settlement])}
        />
      </Form.Item>
    </Form>
  );
};
