import React from 'react';
import { CONTROL_SIZE } from 'consts/common';
import { CollapseProps } from 'antd/es/collapse';
import { StyledCollapse } from 'components/common/StyledCollapse';
import { StyledBadge } from 'components/common/StyledBadge';
import { blue } from '@ant-design/colors';
import { Flex } from 'antd';
import { CreditNoteItemHydrated, TransactionType } from 'services/api/data-contracts';
import { CreditNoteItemsTable } from '../../../../InvoicesWidget/components/InvoiceDetails/accordions/CreditNotesBlock/CreditNoteItemsTable/CreditNoteItemsTable';
import { RebateItemsTable } from './RebateItemsTable/RebateItemsTable';

interface IProps {
  items: CreditNoteItemHydrated[];
  transactionType: TransactionType.CreditNote | TransactionType.Rebate;
}

export const CreditNoteItemsBlock = ({ items, transactionType }: IProps) => {
  const collapseContent: CollapseProps['items'] = [
    {
      key: 'general',
      forceRender: true,
      label: (
        <Flex gap={4} align="center">
          {transactionType === TransactionType.CreditNote
            ? 'Credit note items'
            : 'Accounting services'}
          <StyledBadge count={items.length} color={blue[0]} />
        </Flex>
      ),
      children:
        transactionType === TransactionType.CreditNote ? (
          <CreditNoteItemsTable items={items} />
        ) : (
          <RebateItemsTable items={items} />
        )
    }
  ];

  return (
    <StyledCollapse
      items={collapseContent}
      defaultActiveKey={['general']}
      expandIconPosition="end"
      size={CONTROL_SIZE}
      ghost
      style={{ marginTop: '12px' }}
    />
  );
};
