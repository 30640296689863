import { Button, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { CONTROL_SIZE } from 'consts/common';
import React from 'react';

interface IProps {
  onClick: () => void;
}

export const CloseButton = ({ onClick }: IProps) => (
  <Tooltip mouseEnterDelay={1} title="Close">
    <Button
      icon={<CloseOutlined />}
      onClick={onClick}
      size={CONTROL_SIZE}
      style={{ marginLeft: '12px' }}
      type="text"
    />
  </Tooltip>
);
