import React from 'react';
import { Select, Tooltip } from 'antd';
import { Dayjs } from 'dayjs';
import { Filter } from 'pages/Dictionaries/Dictionaries';
import { CONTROL_SIZE } from 'consts/common';
import { getSelectOptions } from 'helpers';
import { FILTER_REBATES_OPTIONS, REBATE_TYPE_OPTIONS } from './constants/rebates';

interface IProps {
  filter: Filter;
  onFilter: (name: string, value: string | Dayjs | boolean | undefined | null) => void;
}

export const RebatesFilter = ({ filter, onFilter }: IProps) => (
  <Tooltip mouseEnterDelay={1} title="Rebate type">
    <Select
      options={getSelectOptions(FILTER_REBATES_OPTIONS)}
      size={CONTROL_SIZE}
      style={{ width: '100%' }}
      value={filter.rebateType}
      defaultValue={REBATE_TYPE_OPTIONS.ALL}
      onChange={(value) => onFilter('rebateType', value)}
    />
  </Tooltip>
);
