import React from 'react';
import {
  ContractPaymentType,
  CreditNoteItem,
  Payment,
  ServiceName,
  SettlementInvoiceItem,
  UnitOfMeasure,
  VatRate
} from 'services/api/data-contracts';
import { Button, Flex, Tooltip } from 'antd';
import { numericSorter } from '../../../Dictionaries/utils/tableSorters';
import { CONTROL_SIZE } from 'consts/common';
import { ArrowIcon } from '../../InvoicesWidget/components/ArrowIcon/ArrowIcon';
import { CheckOutlined, CloseOutlined, EditFilled, PercentageOutlined } from '@ant-design/icons';
import { getAmountValue } from 'helpers';
import { ColumnGroupType, ColumnType } from 'antd/es/table/interface';
import styled from 'styled-components';
import { VscArrowSwap } from 'react-icons/vsc';
import { geekblue, magenta } from '@ant-design/colors';
import { getRebateSign, RebateTypeMap } from 'store/rebateStore';
import { KG_PRECISION, MT_PRECISION, UOM_TAGS } from 'consts/order';
import { InvoiceProps } from './InvoiceItemsExpandableTable';

const CellWrapper = styled(Flex)<{ $isEditMode: boolean }>`
  margin-top: ${(p) => (p.$isEditMode ? 5 : 0)}px;
  transition: margin 500ms;
`;

export type SettlementCreditNoteItem = CreditNoteItem & {
  payment?: Payment;
  serviceName: ServiceName;
  unitOfMeasure: UnitOfMeasure;
  vatRate: VatRate;
};

type EditableColumnsType = (
  | (ColumnGroupType<(SettlementInvoiceItem & InvoiceProps) | SettlementCreditNoteItem> & {
      isEditable?: boolean;
    })
  | (ColumnType<(SettlementInvoiceItem & InvoiceProps) | SettlementCreditNoteItem> & {
      isEditable?: boolean;
    })
)[];

export const getColumns = (
  isFullScreenMode: boolean,
  editingId: number | undefined,
  onSetEditMode: (invoiceItem?: SettlementInvoiceItem) => void,
  onUpdate: () => void
): EditableColumnsType => [
  {
    title: '',
    dataIndex: 'type',
    key: 'type',
    width: 40,
    render: (_, record) => {
      const isPayment = 'payment' in record;
      const invoiceItem = record as SettlementInvoiceItem & InvoiceProps;
      const tooltip = isPayment
        ? `${(record as SettlementCreditNoteItem).payment?.type} credit note item`
        : editingId
          ? 'Please finish the invoice item update'
          : `${invoiceItem.linkId ? 'Reissued' : invoiceItem.type} invoice item`;

      const icon = isPayment ? (
        <PercentageOutlined
          style={{
            color: record.payment?.type === ContractPaymentType.Incoming ? magenta[6] : geekblue[3]
          }}
        />
      ) : invoiceItem.linkId ? (
        <VscArrowSwap style={{ color: '#00000073' }} />
      ) : (
        <ArrowIcon type={invoiceItem.type} hideTooltip />
      );

      return (
        <Tooltip mouseEnterDelay={1} title={tooltip}>
          {icon}
        </Tooltip>
      );
    }
  },
  {
    title: 'O',
    dataIndex: 'isOwnPrice',
    key: 'isOwnPrice',
    hidden: !isFullScreenMode,
    render: (_, record) =>
      'isOwnPrice' in record && record.isOwnPrice ? (
        <Tooltip mouseEnterDelay={1} title="Own price">
          <CheckOutlined
            style={{ marginTop: editingId === record.id ? '10px' : 0, transition: 'margin 500ms' }}
          />
        </Tooltip>
      ) : null,
    width: 27
  },
  {
    title: 'Item',
    dataIndex: 'serviceNameId',
    key: 'serviceNameId',
    ellipsis: true,
    render: (_, record) => {
      const { payment, isPosted, agentFee, rebate, rebateTotal } =
        record as SettlementCreditNoteItem & SettlementInvoiceItem & InvoiceProps;
      const isItemPosted = isPosted || payment?.posted;

      return (
        <>
          <Tooltip
            mouseEnterDelay={1}
            title={
              <>
                <div>{record.serviceName.name}</div>
                <div>{isItemPosted ? 'Posted' : 'Unposted'}</div>
              </>
            }>
            {record.serviceName.name}
          </Tooltip>
          {!!agentFee && agentFee > 0 && (
            <>
              <br />
              <span style={{ color: '#00000073' }}>Agent fee {agentFee}%</span>
            </>
          )}
          {rebate && !!rebateTotal && rebateTotal > 0 && (
            <>
              <br />
              <span style={{ color: '#00000073' }}>Rebate {RebateTypeMap[rebate.type]}</span>
            </>
          )}
        </>
      );
    },
    isEditable: true,
    width: 120
  },
  {
    title: '№',
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
    render: (_, record) => (
      <CellWrapper $isEditMode={editingId === record.id} align="center" justify="start">
        {'invoiceNumber' in record
          ? record.invoiceNumber
          : (record as SettlementCreditNoteItem).payment?.creditNoteNumber}
      </CellWrapper>
    ),
    width: 106
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (value, record) => (
      <Flex justify="end">
        {getAmountValue(
          value,
          record.unitOfMeasure.shortName === UOM_TAGS.MT ? MT_PRECISION : KG_PRECISION
        )}
      </Flex>
    ),
    ellipsis: true,
    sorter: (a, b) => numericSorter(a.quantity, b.quantity),
    isEditable: true,
    width: 150
  },
  {
    title: 'UOM',
    dataIndex: 'unitOfMeasure',
    key: 'unitOfMeasure',
    render: (_, record) => (
      <CellWrapper $isEditMode={editingId === record.id} align="center" justify="start">
        {record.unitOfMeasure.shortName}
      </CellWrapper>
    ),
    width: 60,
    ellipsis: true,
    isEditable: true
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    hidden: !isFullScreenMode,
    render: (value, record) => (
      <CellWrapper $isEditMode={editingId === record.id} align="center" justify="end">
        {getAmountValue(value)}
      </CellWrapper>
    ),
    width: 150,
    ellipsis: true,
    isEditable: true
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    hidden: !isFullScreenMode,
    render: (value, record) => (
      <CellWrapper $isEditMode={editingId === record.id} align="center" justify="end">
        {getAmountValue(value)}
      </CellWrapper>
    ),
    width: 100
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    render: (_, record) => (
      <CellWrapper $isEditMode={editingId === record.id} align="center" justify="start">
        {'currency' in record
          ? record.currency
          : (record as SettlementCreditNoteItem).payment?.currency}
      </CellWrapper>
    ),
    width: 80
  },
  {
    title: 'VAT, %',
    dataIndex: 'vatRate',
    key: 'vatRate',
    hidden: !isFullScreenMode,
    render: (_, record) => (
      <CellWrapper $isEditMode={editingId === record.id} align="center" justify="end">
        {getAmountValue(record.vatRate?.rate)}
      </CellWrapper>
    ),
    width: 120,
    isEditable: true
  },
  {
    title: 'Out name',
    dataIndex: 'outName',
    key: 'outName',
    hidden: !isFullScreenMode,
    render: (_, record) => (
      <CellWrapper $isEditMode={editingId === record.id} align="center" justify="start">
        {'outName' in record && record.outName ? record.outName?.name : '-'}
      </CellWrapper>
    ),
    width: 200,
    ellipsis: true,
    isEditable: true
  },
  {
    title: 'VAT, Amount',
    dataIndex: 'vatAmount',
    key: 'vatAmount',
    hidden: !isFullScreenMode,
    render: (value, record) => {
      const vatAmount = 'totalAmount' in record ? record.totalAmount - record.amount : value;

      return (
        <CellWrapper $isEditMode={editingId === record.id} align="center" justify="end">
          {getAmountValue(vatAmount)}
        </CellWrapper>
      );
    },
    width: 110
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    render: (value, record) => {
      const invoiceItem = record as SettlementInvoiceItem;
      const buttons = [];

      if (isFullScreenMode && editingId === record.id) {
        buttons.push(
          <Tooltip mouseEnterDelay={1} title="Cancel" key="Cancel">
            <Button
              size={CONTROL_SIZE}
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => onSetEditMode()}
            />
          </Tooltip>,
          <Tooltip mouseEnterDelay={1} title="Save" key="Save">
            <Button
              size={CONTROL_SIZE}
              shape="circle"
              icon={<CheckOutlined />}
              type="primary"
              onClick={onUpdate}
            />
          </Tooltip>
        );
      } else if (isFullScreenMode && 'invoiceId' in record) {
        buttons.push(
          <Tooltip mouseEnterDelay={1} title="Edit" key="Edit">
            <Button
              size={CONTROL_SIZE}
              shape="circle"
              icon={<EditFilled />}
              type="primary"
              onClick={() => onSetEditMode(invoiceItem)}
            />
          </Tooltip>
        );
      }

      return (
        <CellWrapper
          gap={8}
          style={{ overflow: 'hidden', marginRight: 8 }}
          align="center"
          justify="end"
          $isEditMode={editingId === record.id}>
          <div>
            {getAmountValue('totalAmount' in record ? record.totalAmount : value)}
            {!!invoiceItem.agentFeeAmount && invoiceItem.agentFeeAmount > 0 && (
              <>
                <br />
                <span style={{ float: 'inline-end', color: '#00000073' }}>
                  {getAmountValue(invoiceItem.agentFeeAmount)}
                </span>
              </>
            )}
            {invoiceItem.rebate && !!invoiceItem.rebateTotal && invoiceItem.rebateTotal > 0 && (
              <>
                <br />
                <span style={{ float: 'inline-end', color: '#00000073' }}>
                  {getRebateSign(invoiceItem.rebate.type) + getAmountValue(invoiceItem.rebateTotal)}
                </span>
              </>
            )}
          </div>
          <div id="actions">{buttons}</div>
        </CellWrapper>
      );
    },
    width: 142,
    sorter: (a, b) => numericSorter(a.amount, b.amount)
  }
];
