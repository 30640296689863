import { useQuery } from '@tanstack/react-query';
import { ownPricesApi } from 'services/api';
import { QUERY_KEY } from './consts';

const fetchList = async (filter: {
  serviceNameId: number;
  payerContractId: number;
  invoiceCurrency: string;
  orderServiceId?: number;
  invoiceSupplyDate?: string;
}) => await ownPricesApi.getAllOwnPricesForInvoiceItem(filter);

export const useGetOwnPricesForInvoiceItem = (
  payerContractId: number,
  serviceNameId: number,
  invoiceCurrency: string,
  orderServiceId?: number,
  invoiceSupplyDate?: string,
  enabled?: boolean
) =>
  useQuery({
    queryKey: [
      QUERY_KEY,
      serviceNameId,
      orderServiceId,
      payerContractId,
      invoiceSupplyDate,
      invoiceCurrency
    ],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: async () =>
      fetchList({
        payerContractId,
        serviceNameId,
        invoiceCurrency,
        orderServiceId,
        invoiceSupplyDate
      }),
    enabled
  });
