import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { counterpartiesApi } from 'services/api';
import { CONTACTS_QUERY_KEY } from './consts';
import { MESSAGE_DURATION } from 'consts/common';

const deleteItem = async (counterpartyId: number, id: number) =>
  await counterpartiesApi.deleteContact(counterpartyId, id);

export const useDeleteCounterpartyContact = (counterpartyId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => deleteItem(counterpartyId, id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CONTACTS_QUERY_KEY] });

      message.success(`Contact has been successfully removed`, MESSAGE_DURATION);
    }
  });
};
